import React from "react";
import constants, {
  STATUS_TEXT,
  REPORT_TYPE_TEXT,
} from "../../../config/constants";

// default page size
export const defaultPageSize = 10;

// page size option
export const pageSizeOptions = [
  { value: 10, label: "10 items" },
  { value: 20, label: "20 items" },
  { value: 50, label: "50 items" },
];

export const columnDefinitions = [
  {
    id: "type",
    header: "Report Type",
    label: (sortState) => {
      const isColumnSorted = sortState.sortingColumn === "type";
      const ascending = !sortState.sortingDescending;
      return `Report Type, ${
        isColumnSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => item.type,
    minWidth: constants.TABLE_DEFAULT_MIN_WIDTH,
  },
  {
    id: "project",
    header: "Project",
    label: (sortState) => {
      const isColumnSorted = sortState.sortingColumn === "project";
      const ascending = !sortState.sortingDescending;
      return `Project, ${
        isColumnSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => item.project,
    minWidth: constants.TABLE_DEFAULT_MIN_WIDTH,
  },
  {
    id: "build",
    header: "Build",
    label: (sortState) => {
      const isColumnSorted = sortState.sortingColumn === "build";
      const ascending = !sortState.sortingDescending;
      return `Build, ${
        isColumnSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => item.build,
    minWidth: constants.TABLE_DEFAULT_MIN_WIDTH,
  },
  {
    id: "version",
    header: "Version",
    label: (sortState) => {
      const isColumnSorted = sortState.sortingColumn === "version";
      const ascending = !sortState.sortingDescending;
      return `Version, ${
        isColumnSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => item.version,
    minWidth: constants.TABLE_DEFAULT_MIN_WIDTH,
  },
  {
    id: "created_user",
    header: "Created User",
    label: (sortState) => {
      const isColumnSorted = sortState.sortingColumn === "created_user";
      const ascending = !sortState.sortingDescending;
      return `Created User, ${
        isColumnSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => item.created_user,
    minWidth: constants.TABLE_DEFAULT_MIN_WIDTH,
  },
  {
    id: "created_time",
    header: "Created Time",
    label: (sortState) => {
      const isColumnSorted = sortState.sortingColumn === "created_time";
      const ascending = !sortState.sortingDescending;
      return `Created Time, ${
        isColumnSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => item.created_time,
    minWidth: constants.TABLE_DEFAULT_MIN_WIDTH,
  },
  {
    id: "status",
    header: "Status",
    label: (sortState) => {
      const isColumnSorted = sortState.sortingColumn === "status";
      const ascending = !sortState.sortingDescending;
      return `Status, ${
        isColumnSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => {
      let className = "";
      if (item.status === STATUS_TEXT[constants.STATUS_SUCCESS]) {
        className = "text-success";
      }
      // item.status === "error" is temporary to be compatible with current BE
      if (item.status === STATUS_TEXT[constants.STATUS_FAIL]) {
        className = "text-danger";
      }
      return <div className={className}>{item.status}</div>;
    },
    minWidth: constants.TABLE_DEFAULT_MIN_WIDTH,
  },
  {
    id: "url",
    header: "Url",
    label: (sortState) => {
      const isColumnSorted = sortState.sortingColumn === "url";
      const ascending = !sortState.sortingDescending;
      return `Url, ${
        isColumnSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => {
      if (item.url !== undefined && item.url !== null) {
        return (
          <div>
            <a href={item.url}>{item.url.split("/").reverse()[0]}</a>
          </div>
        );
      } else {
        return item.url;
      }
    },
    minWidth: constants.TABLE_DEFAULT_MIN_WIDTH,
  },
];

export const defaultSortingColumn = "created_time";

export const sortableColumns = [
  { id: "type", field: "type" },
  { id: "project", field: "project" },
  { id: "build", field: "build" },
  { id: "version", field: "version" },
  { id: "created_user", field: "created_user" },
  { id: "created_time", field: "created_time" },
  { id: "status", field: "status" },
  { id: "url", field: "url" },
];

export const visibleColumns = [
  {
    label: "Properties",
    options: [
      { id: "type", label: "Type", visible: true, editable: false },
      { id: "project", label: "Project", visible: true, editable: false },
      { id: "build", label: "Build", visible: true, editable: false },
      { id: "version", label: "Version", visible: true, editable: false },
      {
        id: "created_time",
        label: "Created Time",
        visible: true,
        editable: false,
      },
      { id: "url", label: "Url", visible: true, editable: false },
      { id: "status", label: "Status", visible: true },
      { id: "created_user", label: "Created_User", visible: true },
    ],
  },
];

export const filteringOptions = [
  {
    groupValuesLabel: "Type",
    propertyKey: "type",
    propertyLabel: "Type",
    values: Object.values(REPORT_TYPE_TEXT),
  },
  {
    groupValuesLabel: "Project",
    propertyKey: "project",
    propertyLabel: "Project",
    values: [],
  },
  {
    groupValuesLabel: "Build",
    propertyKey: "build",
    propertyLabel: "Build",
    values: [],
  },
  {
    groupValuesLabel: "Version",
    propertyKey: "version",
    propertyLabel: "Version",
    values: [],
  },
  {
    groupValuesLabel: "Status",
    propertyKey: "status",
    propertyLabel: "Status",
    values: Object.values(STATUS_TEXT),
  },
];
