export const ySelectMap = {
  efficiency: "Efficiency (dB)",
  gain: "Gain (dBi)",
};

export const ySelectOptions = Object.keys(ySelectMap).map((eachKey) => {
  return {
    id: eachKey,
    label: ySelectMap[eachKey],
  };
});

export const freqLimitMap = {
  "2G": {
    min: "2410",
    max: "2494",
  },
  "5G": {
    min: "5020",
    max: "5835",
  },
  "802.11y": {
    min: "3650",
    max: "3695",
  },
  LTE: {
    min: "700",
    max: "2600",
  },
};
