import { getName } from "country-list";
import { postData } from "../../../../utils/cognito-fetch";
import { config } from "../../../../config/config.js";
import endpoint from "../../../../config/endpoints";
import {
  COUNTRY,
  ENG_COM_TEST_TYPE,
  METRIC_INFO,
  TEST_CATEGORY,
} from "../../../../config/constants";
import { getLogData } from "../../../../utils/general-utils";

export const switchTestTypeList = [
  { id: ENG_COM_TEST_TYPE.POWER_TABLE, label: "Power Table Verification" },
  { id: ENG_COM_TEST_TYPE.ACTIVE_SCAN, label: "Passive/Active Scan" },
];

export const SWITCH_OPTION = "switch_option";
export const REPORT_HISTORY = "report_history";
export const QUICK_COMPARE = "quick_compare";
export const DELTA_COMPARE = "delta_compare";
export const BOX_PLOT = "box_plot";

export const switchTestToolList = [
  { id: SWITCH_OPTION, label: "Switch Test Tool" },
  { id: REPORT_HISTORY, label: "Report History" },
  { id: DELTA_COMPARE, label: "Delta Compare" },
  { id: BOX_PLOT, label: "Box Plot" },
];

export async function getSwitchLeftList(
  project,
  build,
  version,
  testCategory,
  testType
) {
  //AJAX call to get available left list dynamically
  //init response
  let switchLeftList = [{ id: "", label: "" }];

  try {
    const url =
      config.BASE_URL +
      endpoint.filterList(project, `${build}_${version}`, testCategory);

    if (
      testType === ENG_COM_TEST_TYPE.BAND_EDGE ||
      testType === ENG_COM_TEST_TYPE.HARMONICS
    ) {
      const bandEdgeData = {
        test_type: ENG_COM_TEST_TYPE.BAND_EDGE,
      };
      const harmonicsData = {
        test_type: ENG_COM_TEST_TYPE.HARMONICS,
      };
      // send requests for bandedge and harmonics
      let [bandEdgeResponse, harmonicsResponse] = await Promise.all([
        postData(
          url,
          bandEdgeData,
          getLogData(
            METRIC_INFO.TESTCASE,
            METRIC_INFO[TEST_CATEGORY.COMPLIANCE],
            null,
            project
          )
        ),
        postData(
          url,
          harmonicsData,
          getLogData(
            METRIC_INFO.TESTCASE,
            METRIC_INFO[TEST_CATEGORY.COMPLIANCE],
            null,
            project
          )
        ),
      ]);
      // combine results and remove duplicate and null/undefined
      let response = Array.from(
        new Set([...bandEdgeResponse.json, ...harmonicsResponse.json])
      ).filter((value) => {
        return value !== null && value !== undefined;
      });
      //device list
      switchLeftList = response.map((value) => {
        return { id: value, label: value };
      });
    } else {
      let response: any;
      if (
        testType === ENG_COM_TEST_TYPE.PASSIVE_SCAN ||
        testType === ENG_COM_TEST_TYPE.ACTIVE_SCAN
      ) {
        const passiveScanData = {
          test_type: ENG_COM_TEST_TYPE.PASSIVE_SCAN,
        };
        const activeScanData = {
          test_type: ENG_COM_TEST_TYPE.ACTIVE_SCAN,
        };
        let [passiveScanResponse, activeScanResponse] = await Promise.all([
          postData(
            url,
            passiveScanData,
            getLogData(
              METRIC_INFO.TESTCASE,
              METRIC_INFO[TEST_CATEGORY.COMPLIANCE],
              null,
              project
            )
          ),
          postData(
            url,
            activeScanData,
            getLogData(
              METRIC_INFO.TESTCASE,
              METRIC_INFO[TEST_CATEGORY.COMPLIANCE],
              null,
              project
            )
          ),
        ]);
        response = Array.from(
          new Set([...passiveScanResponse.json, ...activeScanResponse.json])
        ).filter((value) => {
          return value !== null && value !== undefined;
        });
      } else {
        const data = {
          test_type: testType,
        };
        response = await postData(
          url,
          data,
          getLogData(
            METRIC_INFO.TESTCASE,
            METRIC_INFO[TEST_CATEGORY.COMPLIANCE],
            null,
            project
          )
        );
        response = response.json;
      }
      //country list
      switchLeftList = response.map((value) => {
        switch (value) {
          case COUNTRY.EU:
            return { id: value, label: "Europe" };

          case COUNTRY.WW:
            return { id: value, label: "World Wide" };

          default:
            return { id: value, label: getName(value) };
        }
      });
    }
  } catch (error) {
    console.log(`get switch country list fail:${error.message}`);
  } finally {
    return switchLeftList;
  }
}
