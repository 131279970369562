const desenseConfigType = {
  GET_DESENSE_DEVICE_LIST: "GET_DESENSE_DEVICE_LIST",
  GET_DESENSE_DEVICE_LIST_COMMIT: "GET_DESENSE_DEVICE_LIST_COMMIT",
  GET_DESENSE_DEVICE_LIST_ROLLBACK: "GET_DESENSE_DEVICE_LIST_ROLLBACK",

  GET_DESENSE_TCS_LIST: "GET_DESENSE_TCS_LIST",
  GET_DESENSE_TCS_LIST_COMMIT: "GET_DESENSE_TCS_LIST_COMMIT",
  GET_DESENSE_TCS_LIST_ROLLBACK: "GET_DESENSE_TCS_LIST_ROLLBACK",

  DESENSE_DEVICE_OPTION: "DESENSE_DEVICE_OPTION",
  DESENSE_TCS_OPTION: "DESENSE_TCS_OPTION",
  // set user selection
  SET_USER_SELECTION: "DESENSE_SET_USER_SELECTION",
  // tcs main
  POST_DESENSE_TCS_RESULT: "POST_DESENSE_TCS_RESULT",
  POST_DESENSE_TCS_RESULT_COMMIT: "POST_DESENSE_TCS_RESULT_COMMIT",
  POST_DESENSE_TCS_RESULT_ROLLBACK: "POST_DESENSE_TCS_RESULT_ROLLBACK",
  //power config
  GET_CHANNEL_POWER: "GET_CHANNEL_POWER",
  GET_CHANNEL_POWER_COMMIT: "GET_CHANNEL_POWER_COMMIT",
  GET_CHANNEL_POWER_ROLLBACK: "GET_CHANNEL_POWER",
  GET_CUSTOM_POWER: "GET_CUSTOM_POWER",
  GET_CUSTOM_POWER_COMMIT: "GET_CUSTOM_POWER_COMMIT",
  GET_CUSTOM_POWER_ROLLBACK: "GET_CUSTOM_POWER",
  SET_LIMIT: "SET_LIMIT",
  SET_BANDWIDTH_CHANNEL: "SET_BANDWIDTH_CHANNEL",
  SET_TECHNOLOGY: "SET_TECHNOLOGY",
  SET_FREQ_CUSTOM: "SET_FREQ_CUSTOM",
  SET_BANDWIDTH_CUSTOM: "SET_BANDWIDTH_CUSTOM",
  SET_CUSTOM_FREQ_LIST: "SET_CUSTOM_FREQ_LIST",
  SET_CUSTOM_BANDWIDTH_LIST: "SET_CUSTOM_BANDWIDTH_LIST",
  SET_CUSTOM_CHANNEL_LIST: "SET_CUSTOM_CHANNEL_LIST",
  SET_CUSTOM_ID: "SET_CUSTOM_ID",
  SET_CUSTOM_RESPONSE_RESULT: "SET_CUSTOM_RESPONSE_RESULT",

  // desense power region selection option
  SET_POWER_REGION: "SET_POWER_REGION",
  // set band and tcs id for interact usage
  SET_DESENSE_BAND: "SET_DESENSE_BAND",
  SET_DESENSE_TCS_ID: "SET_DESENSE_TCS_ID",

  INIT_LOADING_STATUS: "INIT_LOADING_STATUS",
  CLEAN_UP_CUSTOM_POWER: "CLEAN_UP_CUSTOM_POWER",

  SET_TCS_RESULT_LOADING_STATUS: "SET_TCS_RESULT_LOADING_STATUS",
};

export default desenseConfigType;
