import React, { Component } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import constants from "../../../../config/constants";
import { ContainerFail } from "../../../../components/container-status-indicator";
import {
  plotCardDefinition,
  cardsPaginationOptions,
  cardsPerRow,
  getFilterCounterText,
  cardFeatures,
  filterFields,
} from "./plot-card/card-config";
import {
  Cards,
  CardsFiltering,
  CardsPageSizeSelector,
  CardsPagination,
  CardsPreferences,
  // CardsSelection,
  Checkbox,
} from "@amzn/awsui-components-react/polaris";
import {
  FilterNoMatchState,
  EmptyContentState,
} from "../../../../components/polaris/common-components";

import {
  formatBoxPlotCombineData,
  formatBoxPlotSplitData,
} from "./plot-data/format-box-plot-data";

import { getPlotData } from "../../../../redux/actions/box-plot-action"; // redux action
import { TEST_CATEGORY } from "../../../../config/constants";

interface StateProps {
  boxPlotReducer: any;
}
// declare prop check
type Props = {
  dispatch: Dispatch<any>;
} & typeof defaultProps &
  StateProps;
type State = typeof initialState;

// declare init state & default props
const defaultProps = Object.freeze({});

const initialState = Object.freeze({
  splitByDataRate: false,
  selectedPlots: [],
  filteringText: "",
  pageSize: 2,
});
class PlotsCards extends Component<Props, State> {
  static readonly defaultProps = defaultProps;
  readonly state = initialState;

  componentDidUpdate(prevProps, prevState) {
    const { project, build, testType, device } = this.props.boxPlotReducer;

    // when the device is selected
    if (
      this.props.boxPlotReducer.device &&
      this.props.boxPlotReducer.device !== prevProps.boxPlotReducer.device
    ) {
      // get box plot data
      const requestJson = {
        project: project,
        build: build,
        test_category: TEST_CATEGORY.COMPLIANCE,
        test_type: testType,
        device: device,
      };
      this.props.dispatch(getPlotData(requestJson));

      // reset splitByDataRate to be false
      this.setState({ splitByDataRate: false });
    }
  }

  _onClearFilter() {
    this.setState({ filteringText: "" });
  }

  _onFilteringChange = ({ detail }) => {
    this.setState({ filteringText: detail.filteringText });
  };

  _onPaginationChange = ({ detail }) => {
    this.setState({ pageSize: detail.pageSize });
  };

  _onSelectionChange = ({ detail }) => {
    this.setState({ selectedPlots: detail.selectedItems });
  };

  _onChangeCheckbox = ({ detail }) => {
    this.setState({ splitByDataRate: detail.checked });
  };

  getnum = () => {
    return Math.random() === 1;
  };

  render() {
    let { loadingStatus, device, plotData } = this.props.boxPlotReducer;

    let data: any = [];
    if (
      loadingStatus === constants.LOADING_SUCCESS &&
      plotData &&
      plotData["combine_data"] &&
      plotData["split_data"]
    ) {
      data = this.state.splitByDataRate
        ? formatBoxPlotSplitData(plotData["split_data"])
        : formatBoxPlotCombineData(plotData["combine_data"]);
    }

    let emptyContent: any = <EmptyContentState />;
    if (loadingStatus === constants.LOADING_FAIL) {
      emptyContent = <ContainerFail />;
    }

    // do not show plot
    let content: any = null;
    // device has been selected, show plot
    if (device !== null) {
      content = (
        <div>
          <Checkbox
            onChange={this._onChangeCheckbox}
            checked={this.state.splitByDataRate}
          >
            Split By Data Rate
          </Checkbox>
          <br />

          <Cards
            id="plots"
            cardDefinition={plotCardDefinition}
            items={data}
            cardsPerRow={cardsPerRow}
            features={cardFeatures}
            loading={loadingStatus === constants.LOADING_LOAD}
            loadingText="Loading resources"
            empty={emptyContent}
            noMatch={<FilterNoMatchState />}
            stickyHeader={true}
          >
            <CardsFiltering
              filteringFields={filterFields}
              filteringPlaceholder="Find plots"
              filteringText={this.state.filteringText}
              onFilteringChange={this._onFilteringChange}
              filteringCountTextFunction={getFilterCounterText}
            />
            <CardsPagination
              onPaginationChange={this._onPaginationChange}
              pageSize={this.state.pageSize}
            />
            <CardsPreferences
              title="Preferences"
              confirmLabel="Confirm"
              cancelLabel="Cancel"
            >
              <CardsPageSizeSelector
                title="Page size"
                options={cardsPaginationOptions}
              />
            </CardsPreferences>
          </Cards>
        </div>
      );
    }

    return content;
  }
}

const mapStateToProps = (state) => {
  return {
    boxPlotReducer: state.boxPlotReducer,
  };
};
export default connect<StateProps>(mapStateToProps)(PlotsCards);
