import base_config from "./base_config";

const beta_config = {
  ...base_config,
  STAGE: "beta",

  // Amplify config for cognito
  AMPLIFY_CONFIG: {
    aws_project_region: base_config.REGION,
    aws_cognito_region: base_config.REGION,
    aws_user_pools_id: "us-west-2_ttcebY1zR",
    aws_user_pools_web_client_id: "37e2vc1v1s80nmbgjslk8dd4vi",
    oauth: {
      domain: "wise-general-cog-beta.auth.us-west-2.amazoncognito.com",
      scope: base_config.TOKEN_SCOPES_ARRAY,
      redirectSignIn: base_config.CALL_BACK_URL,
      redirectSignOut: base_config.CALL_BACK_URL,
      responseType: base_config.OAUTH_RESPONSE_TYPE,
    },
  },

  // Base url
  BASE_URL: "https://jnmawhqpva.execute-api.us-west-2.amazonaws.com/beta",
  // Upload service base url
  UPLOAD_BASE_URL:
    "https://b6u1d4qumj.execute-api.us-west-2.amazonaws.com/beta",

  // Configuration for Cloudwatch RUM
  APPLICATION_ID: "0749f1b9-42a6-4f8b-a02f-18ff5e9a7b2b",
  APPLICATION_VERSION:"1.0.0",
  APPLICATION_REGION: "us-west-2",
  rum_config: {
    sessionSampleRate: 1,
    guestRoleArn: "arn:aws:iam::841052704235:role/RUM-Monitor-us-west-2-841052704235-4836185931271-Unauth",
    identityPoolId: "us-west-2:e03e0009-8014-47f6-8611-3443271f3f75",
    endpoint: "https://dataplane.rum.us-west-2.amazonaws.com",
    telemetries: ["performance","errors",["http",{recordAllRequests: true}]],
    allowCookies: true,
    enableXRay: false,
    disableAutoPageView: false
  },
};

export default beta_config;
