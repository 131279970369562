import React, { Component } from "react";
import {
  Table,
  Button,
  TablePropertyFiltering,
  TablePagination,
  TableSorting,
  TablePreferences,
  TablePageSizeSelector,
  TableWrapLines,
} from "@amzn/awsui-components-react/polaris";
import { connect } from "react-redux";
// import { exportCSVFunc } from "@amzn/lens-react-ui";
// import constants from "../../../../config/constants";
import {
  defaultPageSize,
  columnDefinitions,
  defaultSortingColumn,
  defaultSortingDescending,
  sortableColumns,
  filteringOptions,
  formatCompareData,
} from "./dynamic-col-table-config";
import { customizedFilteringFunction } from "../../../../../components/polaris/table/property-filtering-utils";

interface StateProps {
  desenseConfigReducer: any;
}

// declare prop check
type Props = {
  data: Object;
} & typeof defaultProps &
  StateProps;

type State = typeof initialState;

// declare init state & default props
const defaultProps = Object.freeze({});

const initialState = Object.freeze({
  currentPageIndex: 1,
  pagesCount: 0,
});

class DesenseDynamicColTable extends Component<Props, State> {
  static readonly defaultProps = defaultProps;
  readonly state = initialState;

  _onPaginationChange = (event) => {
    const { currentPageIndex, pagesCount } = event.detail;
    this.setState({
      currentPageIndex,
      pagesCount,
    });
  };

  render() {
    // data is the data with table keys, filtered in desense-root-table
    const { data } = this.props;
    const { powerRegion } = this.props.desenseConfigReducer;
    // map expandedCol
    const expandedCol = Object.keys(data).map((eachTC) => {
      return {
        id: `${eachTC}`,
        header: `Testcase ${eachTC}`,
        label: (sortState) => {
          const isColumnSorted = sortState.sortingColumn === `${eachTC}`;
          const ascending = !sortState.sortingDescending;
          return `${eachTC}, ${
            isColumnSorted
              ? `sorted ${ascending ? "ascending" : "descending"}`
              : "not sorted"
          }.`;
        },
        cell: (item) => (
          <div>
            {item[`${eachTC}`]
              ? item[`${eachTC}`].toFixed(4)
              : item[`${eachTC}`]}
          </div>
        ),
        minWidth: "50px",
        visible: true,
      };
    });
    // merge col to show
    const coltoShow = [...columnDefinitions, ...expandedCol];

    const tableData = formatCompareData(data, powerRegion);
    // let tableData = [];

    return (
      <Table
        loadingText="Loading resources"
        columnDefinitions={coltoShow}
        // loading={loadingStatus === constants.LOADING_LOAD}
        items={tableData}
        header={
          <div className="awsui-util-action-stripe">
            <div className="awsui-util-action-stripe-title">
              <h2>
                Comparison table &nbsp;
                <span className="awsui-util-header-counter">{`(${tableData.length})`}</span>
              </h2>
            </div>
          </div>
        }
        empty={
          <div className="awsui-util-t-c">
            <div className="awsui-util-pt-s awsui-util-mb-xs">
              <b>No resources</b>
            </div>
            <p className="awsui-util-mb-s">No resources to display.</p>
          </div>
        }
        noMatch={
          <div className="awsui-util-t-c">
            <div className="awsui-util-pt-s awsui-util-mb-xs">
              <b>No matches</b>
            </div>
            <p className="awsui-util-mb-s">We can’t find a match.</p>
            <div className="awsui-util-mb-m">
              <Button>Clear filter</Button>
            </div>
          </div>
        }
      >
        <TablePropertyFiltering
          filteringOptions={filteringOptions}
          filteringFunction={customizedFilteringFunction}
          groupValuesText="Values"
          groupPropertiesText="Properties"
          operationAndText="and"
          operationNotAndText="and not"
          operationOrText="or"
          operationNotOrText="or not"
          clearFiltersText="Clear filter"
          placeholder="Filter resources by property or value"
          allowFreeTextFiltering={true}
          filteringCountTextFunction={(count) =>
            `${count} ${count === 1 ? "match" : "matches"}`
          }
        ></TablePropertyFiltering>
        <TablePagination
          pageSize={defaultPageSize}
          onPaginationChange={this._onPaginationChange}
        ></TablePagination>
        <TableSorting
          sortingColumn={defaultSortingColumn}
          sortingDescending={defaultSortingDescending}
          sortableColumns={sortableColumns}
        ></TableSorting>
        <TablePreferences
          title="Preferences"
          confirmLabel="Confirm"
          cancelLabel="Cancel"
        >
          <TablePageSizeSelector
            title="Page size"
            options={[
              { value: 5, label: "5 items" },
              { value: 10, label: "10 items" },
              { value: 50, label: "50 items" },
            ]}
          />
          <TableWrapLines
            label="Wrap lines"
            description="Enable to wrap table cell content, disable to truncate text."
          />
          {/* <TableContentSelector
            title="Select visible columns"
            options={getVisibleColumns()}
          /> */}
        </TablePreferences>
      </Table>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    desenseConfigReducer: state.desenseConfigReducer,
  };
};

export default connect(mapStateToProps)(DesenseDynamicColTable);
