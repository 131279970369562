export const ANTENNA_PASSIVE_KEY = "passive";
export const ANTENNA_ACTIVE_KEY = "active";

export const formatPassiveSelectedData = (
  selectedData,
  xSelect,
  ySelect,
  isZoom
) => {
  const formattedData: Array<object> = [];
  Object.keys(selectedData).forEach((eachTC) => {
    // passive object
    const currentPassiveObj = selectedData[eachTC]["data"];
    if (currentPassiveObj && currentPassiveObj[ANTENNA_PASSIVE_KEY]) {
      Object.keys(currentPassiveObj[ANTENNA_PASSIVE_KEY]).forEach((eachAnt) => {
        const antennaObj = currentPassiveObj[ANTENNA_PASSIVE_KEY][eachAnt];
        if (antennaObj) {
          Object.keys(antennaObj).forEach((eachChamber) => {
            const tcsObject = antennaObj[eachChamber];
            if (tcsObject) {
              const currentTrace = {
                x: tcsObject[xSelect],
                y: tcsObject[ySelect],
                // text: tcsObject["comment"],
                name: `Testcase ${eachTC} ${eachAnt} ${eachChamber}`,
                // mode: "lines+markers",
                mode: isZoom ? "markers" : "lines+markers",
                id: `Testcase ${eachTC} ${eachAnt} ${eachChamber}`,
                hovertemplate: `<br><b>${xSelect}</b>: %{x}<br><i>${ySelect}</i>: %{y}`,
                marker: {
                  size: 6,
                },
              };

              formattedData.push(currentTrace);
            }
          });
        }
      });
    }
  });

  return formattedData;
};

export const showAlert = (min, max) => {
  let result = false;

  if (
    [min, max].some(
      (element) => element === "" || element === null || isNaN(element)
    )
  ) {
    if (min !== max) {
      result = true;
    }
  }
  if (min === max && min !== null) {
    result = true;
  }

  return result;
};
