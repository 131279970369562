import React from "react";
import { PlotData } from "../plot-data/format-box-plot-data";
import GeneralPlotlyChart from "../../../../../components/charts/general-plotly-chart";

export const plotCardDefinition = {
  header: (plot: PlotData) => plot.title,
  sections: [
    {
      id: "divider",
      content: () => <hr />,
    },
    {
      id: "plot",
      content: (plot: PlotData) => {
        return (
          <div>
            <GeneralPlotlyChart
              key={plot.title}
              domId={plot.title}
              data={plot.data}
              style={{ width: "100%" }}
              layout={plot.layout}
              config={{ displayModeBar: false }} // not showing mode bar
            />
          </div>
        );
      },
    },
  ],
};

export const cardsPerRow = [
  {
    cards: 1,
  },
  {
    minWidth: 500,
    cards: 1,
  },
];

export const cardFeatures = ["filtering", "pagination"];

export const cardsPaginationOptions = [
  { value: 2, label: "2 items" },
  { value: 4, label: "4 items" },
  { value: 6, label: "6 items" },
];

export const getFilterCounterText = (count) =>
  `${count} ${count === 1 ? "match" : "matches"}`;

export const filterFields = ["title"];
