export const getTestcases = (selectedData) => {
  const testcases: Array<Object> = [];
  const short_mode_key = "tcs";
  Object.keys(selectedData).forEach((id) => {
    const testcase = selectedData[id];

    if (!(short_mode_key in testcase)) {
      if (
        testcase &&
        testcase.project &&
        testcase.build &&
        testcase.version &&
        testcase.config_dsn &&
        testcase.firmware &&
        testcase.subsystem &&
        testcase.antenna &&
        testcase.band
        // && testcase.tcsId
      ) {
        testcases.push({
          testcase_key: id,
          // TODO: update tcsId
          tcs_id: testcase.subsystem.split("_")[0],
          sub_system: testcase.subsystem.split("_")[1],
          antenna: testcase.antenna,
          band: testcase.band,
        });
      }
    } else {
      if (
        testcase &&
        testcase.project &&
        testcase.buildCombine &&
        testcase.device &&
        testcase.tcs
        // && testcase.tcsId
      ) {
        const splitedTcs = testcase.tcs.split("_");

        testcases.push({
          testcase_key: id,
          // TODO: update tcsId
          tcs_id: splitedTcs[0],
          sub_system: splitedTcs[1],
          antenna: splitedTcs[2],
          band: splitedTcs[3],
        });
      }
    }
  });

  return testcases;
};

export const getCustomInput = (
  selectedData,
  customChannelList,
  customFreqList,
  customBandwidthList
) => {
  let regions: Array<any> = [];
  Object.keys(selectedData).forEach((id) => {
    const testcase = selectedData[id];
    if (
      testcase &&
      testcase.channelPower &&
      Array.isArray(Object.keys(testcase.channelPower)) &&
      Object.keys(testcase.channelPower).length > 0
    ) {
      regions = Object.keys(testcase.channelPower);
    }
  });

  const customInput = {};
  regions.forEach((region) => {
    customInput[region] = {
      custom_channel: customChannelList,
      custom_freq: customFreqList,
      custom_bandwidth: customBandwidthList,
    };
  });

  return customInput;
};
