import projectType from "../types/project-type";
import { config } from "../../config/config";
import endpoints from "../../config/endpoints";
import { METRIC_INFO } from "../../config/constants";
import { capitalizeFirstLetter } from "../../utils/general-utils";

export const getProjectList = (category: string): any => ({
  type: projectType.GET_PROJECT_LIST,
  http: {
    cognito: {
      effect: {
        url: config.BASE_URL + endpoints.getProjectListEndpoint(category),
        method: "GET",
      },
      commit: {
        type: projectType.GET_PROJECT_LIST_COMMIT,
        log: {
          initiative: METRIC_INFO.INITIATIVE,
          feature: METRIC_INFO.PROJECT,
          category: capitalizeFirstLetter(category),
        },
      },
      rollback: {
        type: projectType.GET_PROJECT_LIST_ROLLBACK,
        log: {
          initiative: METRIC_INFO.INITIATIVE,
          feature: METRIC_INFO.PROJECT,
          category: capitalizeFirstLetter(category),
        },
      },
    },
  },
});

export const setProject = (project: string): any => ({
  type: projectType.SET_PROJECT,
  project,
});
