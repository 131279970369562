const ACTIVE_CP_KEY = "conducted_power";
const ACTIVE_AE_KEY = "active_efficiency";
const ACTIVE_TRP_KEY = "trp";

export const formatActiveTableObj = (data) => {
  const result: Array<any> = [];

  const currentDataObject = data["data"] ? data["data"]["active"] : null;

  if (currentDataObject) {
    Object.keys(currentDataObject).forEach((subTech) => {
      Object.keys(currentDataObject[subTech]).forEach((eachTech) => {
        Object.keys(currentDataObject[subTech][eachTech]).forEach((eachAnt) => {
          Object.keys(currentDataObject[subTech][eachTech][eachAnt]).forEach(
            (eachChamber) => {
              const tcsObj =
                currentDataObject[subTech][eachTech][eachAnt][eachChamber];

              const loopArray = tcsObj[Object.keys(tcsObj)[0]];
              loopArray.forEach((value, index) => {
                const currentAddObj = {
                  technology: eachTech,
                  channel: tcsObj["channel"][index],
                  freq: tcsObj["freq"][index],
                  setPower: tcsObj["set_power"][index],
                  chamber: eachChamber,
                  // trp
                  //  wifi: eachTech === "wifi" ? tcsObj["power"][index] : null,
                  trpAntOne:
                    subTech === ACTIVE_TRP_KEY && eachAnt === "ant1"
                      ? tcsObj["power"][index]
                      : null,
                  trpAntTwo:
                    subTech === ACTIVE_TRP_KEY && eachAnt === "ant2"
                      ? tcsObj["power"][index]
                      : null,
                  trpAntThree:
                    subTech === ACTIVE_TRP_KEY && eachAnt === "ant3"
                      ? tcsObj["power"][index]
                      : null,
                  trpAntFour:
                    subTech === ACTIVE_TRP_KEY && eachAnt === "ant4"
                      ? tcsObj["power"][index]
                      : null,
                  trpAntFive:
                    subTech === ACTIVE_TRP_KEY && eachAnt === "ant5"
                      ? tcsObj["power"][index]
                      : null,
                  //cp
                  cpAntOne:
                    subTech === ACTIVE_CP_KEY && eachAnt === "ant1"
                      ? tcsObj["power"][index]
                      : null,
                  cpAntTwo:
                    subTech === ACTIVE_CP_KEY && eachAnt === "ant2"
                      ? tcsObj["power"][index]
                      : null,
                  cpAntThree:
                    subTech === ACTIVE_CP_KEY && eachAnt === "ant3"
                      ? tcsObj["power"][index]
                      : null,
                  cpAntFour:
                    subTech === ACTIVE_CP_KEY && eachAnt === "ant4"
                      ? tcsObj["power"][index]
                      : null,
                  cpAntFive:
                    subTech === ACTIVE_CP_KEY && eachAnt === "ant5"
                      ? tcsObj["power"][index]
                      : null,
                  //ae
                  aeAntOne:
                    subTech === ACTIVE_AE_KEY && eachAnt === "ant1"
                      ? tcsObj["power"][index]
                      : null,
                  aeAntTwo:
                    subTech === ACTIVE_AE_KEY && eachAnt === "ant2"
                      ? tcsObj["power"][index]
                      : null,
                  aeAntThree:
                    subTech === ACTIVE_AE_KEY && eachAnt === "ant3"
                      ? tcsObj["power"][index]
                      : null,
                  aeAntFour:
                    subTech === ACTIVE_AE_KEY && eachAnt === "ant4"
                      ? tcsObj["power"][index]
                      : null,
                  aeAntFive:
                    subTech === ACTIVE_AE_KEY && eachAnt === "ant5"
                      ? tcsObj["power"][index]
                      : null,
                };
                result.push(currentAddObj);
              });
            }
          );
        });
      });
    });
  }

  return result;
};

export const formatSignalingTableObj = (data, signal) => {
  const result: Array<any> = [];

  const currentDataObject = data["data"] ? data["data"][signal] : null;
  if (currentDataObject) {
    Object.keys(currentDataObject).forEach((eachTech) => {
      Object.keys(currentDataObject[eachTech]).forEach((eachChamber) => {
        const tcsObj = currentDataObject[eachTech][eachChamber];
        const loopArray = tcsObj[Object.keys(tcsObj)[0]];
        loopArray.forEach((value, index) => {
          const currentAddObj = {
            technology: eachTech,
            channel: tcsObj["channel"][index],
            freq: tcsObj["freq"][index],
            chamber: eachChamber,
            wifi: eachTech === "wifi" ? tcsObj["power"][index] : null,
            bt: eachTech === "bt" ? tcsObj["power"][index] : null,
            zigbee: eachTech === "zigbee" ? tcsObj["power"][index] : null,
          };
          result.push(currentAddObj);
        });
      });
    });
  }

  return result;
};

export const mapMainTabs = {
  active: "Pseudo Active",
  trp: "Signaling TRP",
  tis: "Signaling TIS",
};
