import metadataType from "../types/metadata-type";
import constants, {
  TEST_CATEGORY,
  DIRECT_TESTCASE_LIST,
} from "../../config/constants";
import { metadataResponse } from "../../model/http-json";
import { capitalizeFirstLetter } from "../../utils/general-utils";

const initialState = {
  metadata: [] as metadataResponse,
  sidebarData: [] as Array<any>,
  loadingStatus: constants.LOADING_LOAD,

  infoCollection: {},
  infoLoadingStatus: constants.LOADING_BEFORE_START,
  infoMessage: "",
};

const metadataReducer = (state = initialState, action) => {
  switch (action.type) {
    case metadataType.GET_METADATA:
      return {
        ...state,
        loadingStatus: constants.LOADING_LOAD,
      };
    case metadataType.GET_METADATA_COMMIT:
      return {
        ...state,
        loadingStatus: constants.LOADING_SUCCESS,
        metadata: Array.isArray(action.payload)
          ? (action.payload as metadataResponse)
          : [],
        sidebarData: Array.isArray(action.payload)
          ? generateSidebardata(action.payload)
          : [],
      };
    case metadataType.GET_METADATA_ROLLBACK:
      return {
        ...state,
        loadingStatus: constants.LOADING_FAIL,
      };

    //  project build status
    case metadataType.PROJECT_BUILD_INFO_CALL:
      return {
        ...state,
        infoLoadingStatus: constants.LOADING_LOAD,
      };
    case metadataType.PROJECT_BUILD_INFO_CALL_COMMIT:
      return {
        ...state,
        infoLoadingStatus: constants.LOADING_SUCCESS,
        infoCollection: action.payload.project_build,
      };
    case metadataType.PROJECT_BUILD_INFO_CALL_ROLLBACK:
      return {
        ...state,
        infoLoadingStatus: constants.LOADING_FAIL,
        infoMessage: action?.payload?.message,
      };

    default:
      return state;
  }
};

export default metadataReducer;

const generateSidebardata = (metadata: Array<{ [key: string]: any }>) => {
  // const enabledSidebarTestCategory = [TEST_CATEGORY.COMPLIANCE];

  const sidebarData: Array<{ [key: string]: any }> = [];
  metadata.forEach((category) => {
    const category_item = {
      text: capitalizeFirstLetter(category.name),
      children: [] as Array<any>,
    };
    if (category.children) {
      category.children.forEach((project) => {
        const project_item = {
          text: capitalizeFirstLetter(project.name),
          children: [] as Array<any>,
        };

        if (project.children) {
          project.children.forEach((build) => {
            const build_item = {
              text: build.name.toUpperCase(),
              children: [] as Array<any>,
            };

            if (build.children) {
              build.children.forEach((testCategory) => {
                testCategory = testCategory.toLowerCase();
                if (testCategory === TEST_CATEGORY.COMPLIANCE) {
                  build_item.children.push({
                    text: capitalizeFirstLetter(testCategory),
                    href: `/${project.name}/${build.name.split("_")[0]}/${
                      build.name.split("_")[1]
                    }/${testCategory}/overview`,
                  });
                }

                // test categories have test case page instead of overview page
                if (DIRECT_TESTCASE_LIST.includes(testCategory)) {
                  const text: string =
                    testCategory === TEST_CATEGORY.RVR
                      ? constants.RVR
                      : capitalizeFirstLetter(testCategory);
                  build_item.children.push({
                    text: text,
                    href: `/${project.name}/${build.name.split("_")[0]}/${
                      build.name.split("_")[1]
                    }/${testCategory}/testcase`,
                  });
                }
              });
            }

            // enabledSidebarTestCategory.forEach((testCategory) => {
            //   build_item.children.push({
            //     text: testCategory,
            //     href: `/${project.name}/${build.name.split("_")[0]}/${
            //       build.name.split("_")[1]
            //     }/${testCategory}/overview`,
            //   });
            // });
            // add compliance only

            project_item.children.push(build_item);
          });
        }

        category_item.children.push(project_item);
      });
    }

    sidebarData.push(category_item);
  });
  return sidebarData;
};
