import constants from "../../config/constants";

const overviewType = {
  // get overview data
  GET_OVERVIEW_STATUS: "GET_OVERVIEW_STATUS",
  GET_OVERVIEW_STATUS_COMMIT: "GET_OVERVIEW_STATUS_COMMIT",
  GET_OVERVIEW_STATUS_ROLLBACK: "GET_OVERVIEW_STATUS_ROLLBACK",
};

export const dynamicOverviewType = (
  status: number,
  testType: string | undefined
) => {
  switch (status) {
    case constants.LOADING_LOAD:
      return `GET_OVERVIEW_STATUS${
        testType ? `_${testType.toUpperCase()}` : ""
      }`;
    case constants.LOADING_SUCCESS:
      return `GET_OVERVIEW_STATUS${
        testType ? `_${testType.toUpperCase()}` : ""
      }_COMMIT`;
    case constants.LOADING_FAIL:
      return `GET_OVERVIEW_STATUS${
        testType ? `_${testType.toUpperCase()}` : ""
      }_ROLLBACK`;
    default:
      return "UNDEFINED_TYPE";
  }
};

export default overviewType;
