import deltaCompareType from "./../types/delta-compare-type";
import { config } from "../../config/config";
import endpoints from "../../config/endpoints";
import { CompareItem } from "../../model/delta-compare";
import { METRIC_INFO } from "../../config/constants";
import { capitalizeFirstLetter } from "../../utils/general-utils";

// update dropdown selection
export const updateSelection = (selectionKey, selectionValue) => ({
  type: deltaCompareType.UPDATE_SELECTION,
  key: selectionKey,
  value: selectionValue,
});

// update compare plot, two lines selection. Selections is the array with length <= 2
export const updateCompareSelection = (selections: CompareItem[]) => ({
  type: deltaCompareType.UPDATE_COMPARE_SELECTION,
  selections,
});

// ajax action to get testcases
export const getTestcases = (
  project: string,
  build: string,
  testCategory: string,
  testType: string,
  device: string
) => ({
  type: deltaCompareType.GET_TEST_CASES,
  http: {
    cognito: {
      effect: {
        url:
          config.BASE_URL +
          endpoints.testcaseStatus(
            project,
            build,
            testCategory,
            testType,
            device
          ),
        method: "GET",
      },
      commit: {
        type: deltaCompareType.GET_TEST_CASES_COMMIT,
        log: {
          initiative: METRIC_INFO.INITIATIVE,
          feature: METRIC_INFO.DELTA_COMPARE,
          project: capitalizeFirstLetter(project),
          testcategory: METRIC_INFO[testCategory],
        },
      },
      rollback: {
        type: deltaCompareType.GET_TEST_CASES_ROLLBACK,
        log: {
          initiative: METRIC_INFO.INITIATIVE,
          feature: METRIC_INFO.DELTA_COMPARE,
          project: capitalizeFirstLetter(project),
          testcategory: METRIC_INFO[testCategory],
        },
      },
    },
  },
});

// clear the testcases
export const clearTestcases = () => ({
  type: deltaCompareType.CLEAR_TEST_CASES,
});

// clear compare list
export const clearCompareList = () => ({
  type: deltaCompareType.CLEAR_COMPARE_LIST,
});

// update compare list, send ajax call to get dataRate, setPower info for selected testcase
export const updateCompareList = (
  tcsId: number,
  testCategory: string,
  testcaseInfo: object
) => ({
  type: deltaCompareType.UPDATE_COMPARE_LIST,
  tcsId,
  http: {
    cognito: {
      effect: {
        url: config.BASE_URL + endpoints.tcsResultURL(testCategory, tcsId),
        method: "GET",
      },
      commit: {
        type: deltaCompareType.UPDATE_COMPARE_LIST_COMMIT,
        tcsId,
        testcaseInfo,
        log: {
          initiative: METRIC_INFO.INITIATIVE,
          feature: METRIC_INFO.DELTA_COMPARE,
          testcategory: METRIC_INFO[testCategory],
        },
      },
      rollback: {
        type: deltaCompareType.UPDATE_COMPARE_LIST_ROLLBACK,
        tcsId,
        log: {
          initiative: METRIC_INFO.INITIATIVE,
          feature: METRIC_INFO.DELTA_COMPARE,
          testcategory: METRIC_INFO[testCategory],
        },
      },
    },
  },
});

// update history list, once user click on compare list, save into history list.
export const updateHistoryList = (historyList: CompareItem[]) => ({
  type: deltaCompareType.UPDATE_HISTORY_LIST,
  historyList,
});

// get data for compare plot
export const getPlotData = (
  tcsId: number,
  testCategory: string,
  selectedItem: CompareItem
) => ({
  type: deltaCompareType.GET_PLOT_DATA,
  selectedItem,
  http: {
    cognito: {
      effect: {
        url: config.BASE_URL + endpoints.tcsResultURL(testCategory, tcsId),
        method: "GET",
      },
      commit: {
        type: deltaCompareType.GET_PLOT_DATA_COMMIT,
        selectedItem,
        log: {
          initiative: METRIC_INFO.INITIATIVE,
          feature: METRIC_INFO.DELTA_COMPARE,
          testcategory: METRIC_INFO[testCategory],
        },
      },
      rollback: {
        type: deltaCompareType.GET_PLOT_DATA_ROLLBACK,
        selectedItem,
        log: {
          initiative: METRIC_INFO.INITIATIVE,
          feature: METRIC_INFO.DELTA_COMPARE,
          testcategory: METRIC_INFO[testCategory],
        },
      },
    },
  },
});
