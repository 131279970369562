import React, { Component } from "react";
// sub components
import { Select } from "@amzn/awsui-components-react/polaris";
// redux
import { Dispatch } from "redux";
import { connect } from "react-redux";
import {
  updateSelection,
  clearTestcases,
} from "../../../redux/actions/delta-compare-action"; // redux action
// Ajax
import { getData, postData } from "../../../utils/cognito-fetch";
import { config } from "../../../config/config.js";
import endpoint from "../../../config/endpoints";
// constants
import constants, {
  TEST_CATEGORY,
  ENG_COM_TEST_TYPE,
  ENG_COM_TEST_TYPE_TABLE_FOTMATTER,
  METRIC_INFO,
} from "../../../config/constants";
// utils
import {
  capitalizeFirstLetter,
  getLogData,
} from "../../../utils/general-utils";

const SELECTION_KEY = {
  PROJECT: "project",
  BUILD: "build",
  TEST_TYPE: "testType",
  DEVICE: "device",
};
const dropdownStyle = { width: "150px" };

interface StateProps {
  deltaCompareReducer: any;
}

// declare prop check
type Props = {
  dispatch: Dispatch<any>;
} & StateProps &
  typeof defaultProps;

type State = {
  [key: string]: any;
} & typeof initialState;

// declare init state & default props
const defaultProps = Object.freeze({});

const initialState = Object.freeze({
  // options for each dropdown
  projectOptions: [] as any[],
  buildOptions: [] as any[],
  testTypeOptions: [] as any[],
  deviceOptions: [] as any[],
  // loading status for each dropdown
  projectLoadingStatus: constants.LOADING_SUCCESS,
  buildLoadingStatus: constants.LOADING_SUCCESS,
  testTypeLoadingStatus: constants.LOADING_SUCCESS,
  deviceLoadingStatus: constants.LOADING_SUCCESS,
});

class DeltaCompareTestcaseInput extends Component<Props, State> {
  static readonly defaultProps = defaultProps;
  readonly state = initialState;

  componentDidMount() {
    this._getProjectOptions();
  }

  componentDidUpdate(prevProps, prevState) {
    // trigger build
    if (
      this.props.deltaCompareReducer.project &&
      this.props.deltaCompareReducer.project !==
        prevProps.deltaCompareReducer.project
    ) {
      this._getBuildOptions();
    }

    // trigger testType
    if (
      this.props.deltaCompareReducer.build &&
      this.props.deltaCompareReducer.build !==
        prevProps.deltaCompareReducer.build
    ) {
      this._getTestTypeOptions();
    }

    // trigger device
    if (
      this.props.deltaCompareReducer.testType &&
      this.props.deltaCompareReducer.testType !==
        prevProps.deltaCompareReducer.testType
    ) {
      this._getDeviceOptions();
    }
  }

  // Dropdown onChange function
  _onChange = (event: any, key: string) => {
    const selectionValue = event.detail.selectedId;
    this.props.dispatch(updateSelection(key, selectionValue));

    if (key === SELECTION_KEY.PROJECT) {
      this._clearSelection(SELECTION_KEY.BUILD);
      this._clearSelection(SELECTION_KEY.TEST_TYPE);
      this._clearSelection(SELECTION_KEY.DEVICE);
    }

    if (key === SELECTION_KEY.BUILD) {
      this._clearSelection(SELECTION_KEY.TEST_TYPE);
      this._clearSelection(SELECTION_KEY.DEVICE);
    }

    if (key === SELECTION_KEY.TEST_TYPE) {
      this._clearSelection(SELECTION_KEY.DEVICE);
    }
  };

  // clear selection when changing the selections
  _clearSelection = (key: string) => {
    // clear selections
    this.props.dispatch(updateSelection(key, null));
    this.setState({ [`{key}Options`]: [] });
    // clear testcase table
    this.props.dispatch(clearTestcases());
  };

  // Ajax call for project options
  _getProjectOptions = () => {
    // change load flag
    this.setState({
      projectLoadingStatus: constants.LOADING_LOAD,
    });

    // ajax call
    getData(
      config.BASE_URL + endpoint.getProjectListWithoutCategoryEndpoint(),
      getLogData(
        METRIC_INFO.DELTA_COMPARE,
        METRIC_INFO[TEST_CATEGORY.COMPLIANCE]
      )
    )
      .then((response) => {
        const projectOptions = response.json.map((item) => ({
          label: capitalizeFirstLetter(item),
          id: item,
        }));
        this.setState({
          projectOptions,
          projectLoadingStatus: constants.LOADING_SUCCESS,
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          projectLoadingStatus: constants.LOADING_FAIL,
        });
      });
  };

  // Ajax call for build options
  _getBuildOptions = () => {
    // change load flag
    this.setState({
      buildLoadingStatus: constants.LOADING_LOAD,
    });

    const { project } = this.props.deltaCompareReducer;
    // ajax call
    getData(
      config.BASE_URL + endpoint.getBuildList(project),
      getLogData(
        METRIC_INFO.DELTA_COMPARE,
        METRIC_INFO[TEST_CATEGORY.COMPLIANCE]
      )
    )
      .then((response) => {
        const buildOptions = response.json.map((item) => ({
          label: capitalizeFirstLetter(item),
          id: item,
        }));
        this.setState({
          buildLoadingStatus: constants.LOADING_SUCCESS,
          buildOptions,
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          buildLoadingStatus: constants.LOADING_FAIL,
        });
      });
  };

  // Ajax call for testType options
  _getTestTypeOptions = () => {
    this.setState({
      testTypeOptions: [
        {
          label: ENG_COM_TEST_TYPE_TABLE_FOTMATTER.band_edge,
          id: ENG_COM_TEST_TYPE.BAND_EDGE,
        },
        {
          label: ENG_COM_TEST_TYPE_TABLE_FOTMATTER.harmonics,
          id: ENG_COM_TEST_TYPE.HARMONICS,
        },
      ],
    });
  };

  // Ajax call for device options
  _getDeviceOptions = () => {
    // change load flag
    this.setState({
      deviceLoadingStatus: constants.LOADING_LOAD,
    });

    const { project, build, testType } = this.props.deltaCompareReducer;
    // ajax call
    postData(
      config.BASE_URL +
        endpoint.filterList(project, build, TEST_CATEGORY.COMPLIANCE),
      { test_type: testType },
      getLogData(
        METRIC_INFO.DELTA_COMPARE,
        METRIC_INFO[TEST_CATEGORY.COMPLIANCE],
        null,
        project
      )
    )
      .then((response) => {
        const deviceOptions = response.json.map((item) => ({
          label: item ? item : constants.NA,
          id: item ? item : constants.NA,
        }));
        this.setState({
          deviceOptions,
          deviceLoadingStatus: constants.LOADING_SUCCESS,
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          deviceLoadingStatus: constants.LOADING_FAIL,
        });
      });
  };

  render() {
    const { project, build, testType, device } = this.props.deltaCompareReducer;
    const {
      projectOptions,
      buildOptions,
      testTypeOptions,
      deviceOptions,
      projectLoadingStatus,
      buildLoadingStatus,
      testTypeLoadingStatus,
      deviceLoadingStatus,
    } = this.state;

    return (
      <div>
        <div className="d-flex flex-row">
          <div style={dropdownStyle} className="mr-3">
            <Select
              options={projectOptions}
              empty="No options"
              placeholder="Project"
              statusType={
                projectLoadingStatus === constants.LOADING_LOAD
                  ? "loading"
                  : null
              }
              selectedLabel="Selected"
              selectedOption={
                project
                  ? { id: project, label: capitalizeFirstLetter(project) }
                  : null
              }
              onChange={(event) => this._onChange(event, SELECTION_KEY.PROJECT)}
            ></Select>
          </div>

          <div style={dropdownStyle} className="mr-3">
            <Select
              options={buildOptions}
              empty="No options"
              placeholder="Build"
              statusType={
                buildLoadingStatus === constants.LOADING_LOAD ? "loading" : null
              }
              selectedLabel="Selected"
              selectedOption={
                build
                  ? { id: build, label: capitalizeFirstLetter(build) }
                  : null
              }
              onChange={(event) => this._onChange(event, SELECTION_KEY.BUILD)}
            ></Select>
          </div>
          <div style={dropdownStyle} className="mr-3">
            <Select
              options={testTypeOptions}
              empty="No options"
              placeholder="Test Type"
              statusType={
                testTypeLoadingStatus === constants.LOADING_LOAD
                  ? "loading"
                  : null
              }
              selectedLabel="Selected"
              selectedOption={
                testType
                  ? {
                      id: testType,
                      label: ENG_COM_TEST_TYPE_TABLE_FOTMATTER[testType],
                    }
                  : null
              }
              onChange={(event) =>
                this._onChange(event, SELECTION_KEY.TEST_TYPE)
              }
            ></Select>
          </div>

          <div style={{ width: "180px" }} className="mr-3">
            <Select
              options={deviceOptions}
              empty="No options"
              placeholder="Device"
              statusType={
                deviceLoadingStatus === constants.LOADING_LOAD
                  ? "loading"
                  : null
              }
              selectedLabel="Selected"
              selectedOption={device ? { id: device, label: device } : null}
              onChange={(event) => this._onChange(event, SELECTION_KEY.DEVICE)}
            ></Select>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    deltaCompareReducer: state.deltaCompareReducer,
  };
};
export default connect<StateProps>(mapStateToProps)(DeltaCompareTestcaseInput);
