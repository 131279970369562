import uploadType from "../types/upload-type";
// AJAX request related
import { config } from "../../config/config";
import endpoints from "../../config/endpoints";
import { UploadStatusRequest } from "../../model/http-json";
import { METRIC_INFO } from "../../config/constants";

export const getUploadStatus = (
  requestJson: UploadStatusRequest,
  syncMode: boolean // flag to set loading status back to 0 or not
) => ({
  type: uploadType.GET_UPLOAD_STATUS,
  syncMode,
  http: {
    cognito: {
      effect: {
        //simple local url here will change it later
        url: config.UPLOAD_BASE_URL + endpoints.uploadStatus(),
        method: "POST",
        json: requestJson,
      },
      commit: {
        type: uploadType.GET_UPLOAD_STATUS_COMMIT,
        log: {
          initiative: METRIC_INFO.INITIATIVE,
          feature: METRIC_INFO.MANUAL_UPLOAD,
        },
      },
      rollback: {
        type: uploadType.GET_UPLOAD_STATUS_ROLLBACK,
        log: {
          initiative: METRIC_INFO.INITIATIVE,
          feature: METRIC_INFO.MANUAL_UPLOAD,
        },
      },
    },
  },
});

export const triggerAutoUpload = (
  syncMode: boolean // flag to set loading status back to 0 or not
) => ({
  type: uploadType.GET_TRIGGER_AUTO_UPLOAD_STATUS,
  syncMode,
  http: {
    cognito: {
      effect: {
        //simple local url here will change it later
        url:
          config.UPLOAD_BASE_URL +
          endpoints.triggerAutoUpload(METRIC_INFO.INITIATIVE),
        method: "GET",
      },
      commit: {
        type: uploadType.GET_TRIGGER_AUTO_UPLOAD_STATUS_COMMIT,
        log: {
          initiative: METRIC_INFO.INITIATIVE,
          feature: METRIC_INFO.AUTO_UPLOAD,
        },
      },
      rollback: {
        type: uploadType.GET_TRIGGER_AUTO_UPLOAD_STATUS_ROLLBACK,
        log: {
          initiative: METRIC_INFO.INITIATIVE,
          feature: METRIC_INFO.AUTO_UPLOAD,
        },
      },
    },
  },
});
