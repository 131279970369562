import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Multiselect,
  Box,
  ColumnLayout,
  SpaceBetween,
} from "@amzn/awsui-components-react-v3";
import {
  mappedLitepointTestCategory,
  mappedTempList,
  mappedVoltageList,
  mappedLitepointWiFiAntennaList,
  mappedLitepointZigbeeBTAntennaList,
  getLitepointStandard,
  filterSubSelectOptions,
  formatMultiSelectOptions,
  getLitepointBandList,
  checkLitepointAntenna,
  checkLitepointNonSigOptions,
  filterSelectedAntennas,
} from "./testcase-config";
import {
  clearNonSigLitepointSelect,
  setCanLitepointSubmit,
  setNonSigLitepointSelect,
} from "../../../redux/actions/build-action";
import { NONSIG_TEST_LEVEL } from "../../../config/constants";

const ValueWithLabel = ({ label, children }) => (
  <div>
    <Box margin={{ bottom: "xxxs" }} color="text-label">
      {label}
    </Box>
    <div>{children}</div>
  </div>
);

const LitepointTestcaseManagement = () => {
  const dispatch = useDispatch();
  const { buildReducer } = useSelector((state: any) => ({
    buildReducer: state.buildReducer,
  }));
  const { nonSigLitepointSelect } = buildReducer;

  useEffect(() => {
    const canSubmit = checkLitepointNonSigOptions(nonSigLitepointSelect);
    dispatch(setCanLitepointSubmit(canSubmit));
  }, [nonSigLitepointSelect, dispatch]);

  useEffect(() => {
    // Clear litepoint options when component unmounted
    return () => {
      dispatch(clearNonSigLitepointSelect());
    };
  }, [dispatch]);

  const onChangeTestCategory = ({ detail }) => {
    // currently select
    const currSelect = detail.selectedOptions.map((each) => each.value);
    const currBandLeft = getLitepointBandList(currSelect);

    const currSubList = getLitepointStandard(currSelect, currBandLeft);
    let currSubSelect = [...nonSigLitepointSelect[NONSIG_TEST_LEVEL.STANDARD]];

    dispatch(
      setNonSigLitepointSelect({
        [NONSIG_TEST_LEVEL.TECHNOLOGY]: currSelect,
        [NONSIG_TEST_LEVEL.STANDARD]: filterSubSelectOptions(
          currSubSelect,
          currSubList,
          false
        ),
        ...filterSelectedAntennas(currSelect),
      })
    );
  };

  const onChangeBand = ({ detail }) => {
    // currently select
    const currBand = detail.selectedOptions.map((each) => each.value);

    const currSubList = getLitepointStandard(
      nonSigLitepointSelect[NONSIG_TEST_LEVEL.TECHNOLOGY],
      currBand
    );
    let currSubSelect = [...nonSigLitepointSelect[NONSIG_TEST_LEVEL.STANDARD]];

    dispatch(
      setNonSigLitepointSelect({
        [NONSIG_TEST_LEVEL.BAND]: currBand,
        [NONSIG_TEST_LEVEL.STANDARD]: filterSubSelectOptions(
          currSubSelect,
          currSubList,
          false
        ),
      })
    );
  };

  const { showAnt, showZigbeeBtAnt, showWifi } = checkLitepointAntenna(
    nonSigLitepointSelect[NONSIG_TEST_LEVEL.TECHNOLOGY]
  );

  return (
    <div className={"testcase-management-main"}>
      <h4>Litepoint</h4>

      <ColumnLayout columns={showAnt ? 4 : 3} variant="text-grid">
        <SpaceBetween size="l">
          <ValueWithLabel label="Technology Category">
            <Multiselect
              selectedOptions={formatMultiSelectOptions(
                nonSigLitepointSelect[NONSIG_TEST_LEVEL.TECHNOLOGY]
              )}
              onChange={onChangeTestCategory}
              deselectAriaLabel={(e) => `Remove ${e.label}`}
              options={mappedLitepointTestCategory}
              placeholder="Choose options"
              filteringType="auto"
              selectedAriaLabel="Selected"
            />
          </ValueWithLabel>
          <ValueWithLabel label="Band Selection">
            <Multiselect
              selectedOptions={formatMultiSelectOptions(
                nonSigLitepointSelect[NONSIG_TEST_LEVEL.BAND]
              )}
              onChange={onChangeBand}
              deselectAriaLabel={(e) => `Remove ${e.label}`}
              options={formatMultiSelectOptions(
                getLitepointBandList(
                  nonSigLitepointSelect[NONSIG_TEST_LEVEL.TECHNOLOGY]
                )
              )} // add default
              placeholder="Choose options"
              filteringType="auto"
              selectedAriaLabel="Selected"
            />
          </ValueWithLabel>
        </SpaceBetween>
        <SpaceBetween size="l">
          <ValueWithLabel label="Standard">
            <Multiselect
              selectedOptions={formatMultiSelectOptions(
                nonSigLitepointSelect[NONSIG_TEST_LEVEL.STANDARD]
              )}
              onChange={({ detail }) => {
                dispatch(
                  setNonSigLitepointSelect({
                    ...nonSigLitepointSelect,
                    [NONSIG_TEST_LEVEL.STANDARD]: detail.selectedOptions.map(
                      (each) => each.value
                    ),
                  })
                );
              }}
              deselectAriaLabel={(e) => `Remove ${e.label}`}
              options={formatMultiSelectOptions(
                getLitepointStandard(
                  nonSigLitepointSelect[NONSIG_TEST_LEVEL.TECHNOLOGY],
                  nonSigLitepointSelect[NONSIG_TEST_LEVEL.BAND]
                )
              )}
              placeholder="Choose options"
              selectedAriaLabel="Selected"
              filteringType="auto"
            />
          </ValueWithLabel>
        </SpaceBetween>
        <SpaceBetween size="l">
          <ValueWithLabel label="Temperature">
            <Multiselect
              selectedOptions={formatMultiSelectOptions(
                nonSigLitepointSelect[NONSIG_TEST_LEVEL.TEMPERATURE]
              )}
              onChange={({ detail }) => {
                dispatch(
                  setNonSigLitepointSelect({
                    [NONSIG_TEST_LEVEL.TEMPERATURE]: detail.selectedOptions.map(
                      (each) => each.value
                    ),
                  })
                );
              }}
              deselectAriaLabel={(e) => `Remove ${e.label}`}
              options={mappedTempList}
              placeholder="Choose options"
              selectedAriaLabel="Selected"
              filteringType="auto"
            />
          </ValueWithLabel>
          <ValueWithLabel label="Voltage">
            <Multiselect
              selectedOptions={formatMultiSelectOptions(
                nonSigLitepointSelect[NONSIG_TEST_LEVEL.VOLTAGE]
              )}
              onChange={({ detail }) => {
                dispatch(
                  setNonSigLitepointSelect({
                    [NONSIG_TEST_LEVEL.VOLTAGE]: detail.selectedOptions.map(
                      (each) => each.value
                    ),
                  })
                );
              }}
              deselectAriaLabel={(e) => `Remove ${e.label}`}
              options={mappedVoltageList}
              placeholder="Choose options"
              selectedAriaLabel="Selected"
              filteringType="auto"
            />
          </ValueWithLabel>
        </SpaceBetween>
        {showAnt && (
          <SpaceBetween size="l">
            {showZigbeeBtAnt && (
              <ValueWithLabel label="Zigbee & BT antenna selection">
                <Multiselect
                  // Display BT values, but will match with Zigbee values
                  selectedOptions={formatMultiSelectOptions(
                    nonSigLitepointSelect[
                      NONSIG_TEST_LEVEL.BT_ANTENNA // track BT, ZigBee should be identical for now
                    ]
                  )}
                  onChange={({ detail }) => {
                    dispatch(
                      // When drop is modified, change BT + Zigbee Ant values
                      setNonSigLitepointSelect({
                        [NONSIG_TEST_LEVEL.BT_ANTENNA]:
                          detail.selectedOptions.map((each) => each.value),
                        [NONSIG_TEST_LEVEL.ZIGBEE_ANTENNA]:
                          detail.selectedOptions.map((each) => each.value),
                      })
                    );
                  }}
                  deselectAriaLabel={(e) => `Remove ${e.label}`}
                  options={mappedLitepointZigbeeBTAntennaList}
                  placeholder="Choose options"
                  selectedAriaLabel="Selected"
                  filteringType="auto"
                />
              </ValueWithLabel>
            )}
            {showWifi && (
              <ValueWithLabel label="Wifi antenna selection">
                <Multiselect
                  selectedOptions={formatMultiSelectOptions(
                    nonSigLitepointSelect[NONSIG_TEST_LEVEL.WIFI_ANTENNA]
                  )}
                  onChange={({ detail }) => {
                    dispatch(
                      setNonSigLitepointSelect({
                        [NONSIG_TEST_LEVEL.WIFI_ANTENNA]:
                          detail.selectedOptions.map((each) => each.value),
                      })
                    );
                  }}
                  deselectAriaLabel={(e) => `Remove ${e.label}`}
                  options={mappedLitepointWiFiAntennaList}
                  placeholder="Choose options"
                  selectedAriaLabel="Selected"
                  filteringType="auto"
                />
              </ValueWithLabel>
            )}
          </SpaceBetween>
        )}
      </ColumnLayout>
    </div>
  );
};

export default LitepointTestcaseManagement;
