import React, { Component } from "react";
// redux
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { getReportsHistory } from "../../../redux/actions/report-action";

import ReportHistoryTable from "./report-history-table";

interface StateProps {
  reportReducer: any;
}

// declare prop check
type Props = {
  dispatch: Dispatch<any>;
} & typeof defaultProps &
  StateProps;

// declare init state & default props
const defaultProps = Object.freeze({});

class ReportHistory extends Component<Props> {
  static readonly defaultProps = defaultProps;

  interval: any;
  componentDidMount() {
    this._getDate();

    //fetch data every 30s
    this.interval = setInterval(() => this._getDate(true), 30000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  _getDate = (syncMode: boolean = false) => {
    this.props.dispatch(getReportsHistory(syncMode));
  };

  render() {
    const { reportHistoryData, reportHistoryStatus } = this.props.reportReducer;
    return (
      <div>
        <ReportHistoryTable
          data={reportHistoryData}
          getData={this._getDate}
          loadingStatus={reportHistoryStatus}
        ></ReportHistoryTable>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    reportReducer: state.reportReducer,
  };
};
export default connect<StateProps>(mapStateToProps)(ReportHistory);
