import { Select } from "@amzn/awsui-components-react/polaris";
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import constants, {
  TEST_CATEGORY,
  METRIC_INFO,
  BATTERY_SELECTION_KEY,
  DROPDOWN_NAMING_COLLECTION,
} from "../../../../config/constants";
import {
  setUserSelectDict,
  getTCSDropdown,
  postTcsResult,
  downloadTestcasesResult,
  initBatteryLoadingStatus,
} from "../../../../redux/actions/battery-config-action";
import { getData } from "../../../../utils/cognito-fetch";
import { config as CONFIG} from "../../../../config/config.js";
import endpoint from "../../../../config/endpoints";
import {
  capitalizeFirstLetter,
  getLogData,
} from "../../../../utils/general-utils";
import {
  getSublvlOptions,
  handleLvlOptions,
  mapListOption,
} from "../../utils/dropdown-helper-func";

const initialState = Object.freeze({
  projectOptions: [] as any[],
  buildOptions: [] as any[],
  projectLoadingStatus: constants.LOADING_BEFORE_START,
  buildLoadingStatus: constants.LOADING_BEFORE_START,
  project: "",
  build: "",
  version: "",
  config: "",
  dsn: "",
  temperature: "",
  testCycleType: ""
});


const BatteryDropdownList = (props) => {

  const dispatch = useDispatch();
  const dropdownRef = useRef<HTMLInputElement>(null);

  const batteryConfigReducer = useSelector((state: any) => state.batteryConfigReducer);
  const { selectedData, testcaseDropdown, testcaseDropdownLoadingStatus } = batteryConfigReducer;

  const [state, setState] = useState(initialState);
  
  const { indexNumber, project, build, version, requestId, labId } = props;

  const {
    projectOptions,
    buildOptions,
    projectLoadingStatus,
    buildLoadingStatus,
    config,
    dsn,
    temperature,
    testCycleType,
  } = state;

  useEffect(() => {
    if(buildLoadingStatus === constants.LOADING_BEFORE_START){
      getProjectOptions();
      const getBuildOptions = (projectName: string) => {
        setState((prevState) => ({
          ...prevState,
          buildLoadingStatus: constants.LOADING_LOAD,
        }));
        const project =
          projectName !== "null"
            ? projectName
            : selectedData[indexNumber].project;
    
        getData(
          CONFIG.BASE_URL + endpoint.getBuildList(project),
          getLogData(METRIC_INFO.BATTERY_TESTCASE, METRIC_INFO[TEST_CATEGORY.BATTERY])
        )
          .then((response) => {
            const buildOptions = response.json.map((item) => item);
            setState((prevState) => ({
              ...prevState,
              buildLoadingStatus: constants.LOADING_SUCCESS,
              buildOptions,
            }));
          })
          .catch((error) => {
            console.log(error);
            setState((prevState) => ({
              ...prevState,
              buildLoadingStatus: constants.LOADING_FAIL,
            }));
          });
      };
      const currentObject = { project, build, version };
      const updateSelected = { ...selectedData };
      updateSelected[indexNumber] = currentObject;
      dispatch(setUserSelectDict(updateSelected));
      getBuildOptions(project);
    }
  },[build, dispatch, indexNumber, project, selectedData, version, buildLoadingStatus]);

  useEffect(() => {
    dispatch(getTCSDropdown(project, build, version, TEST_CATEGORY.BATTERY));
    setState((prevState) => ({
      ...prevState,
      config: "",
      dsn: "",
      temperature: "",
      testCycleType: ""
    }));
    dispatch(initBatteryLoadingStatus());
  }, [dispatch,project, build, version]);
  const getProjectOptions = () => {
    setState((prevState) => ({
      ...prevState,
      projectLoadingStatus: constants.LOADING_LOAD,
    }));

    getData(
      CONFIG.BASE_URL + endpoint.getProjectListWithoutCategoryEndpoint(),
      getLogData(METRIC_INFO.BATTERY_TESTCASE, METRIC_INFO[TEST_CATEGORY.BATTERY])
    )
      .then((response) => {
        const projectOptions = response.json.map((item) => ({
          label: capitalizeFirstLetter(item),
          id: item,
        }));
        setState((prevState) => ({
          ...prevState,
          projectOptions,
          projectLoadingStatus: constants.LOADING_SUCCESS,
        }));
      })
      .catch((error) => {
        console.log(error);
        setState((prevState) => ({
          ...prevState,
          projectLoadingStatus: constants.LOADING_FAIL,
        }));
      });
  };


  const onClickRemoveDropdown = () => {
    const currentTestCastObject = { ...selectedData };

    if (Object.keys(currentTestCastObject).length >= 2) {
      delete currentTestCastObject[indexNumber];
    } else {
      window.alert("The only left one cannot be removed");
    }

    dispatch(setUserSelectDict(currentTestCastObject));
  };

  const dataOnChange = (event: any) => {
    const currentValue: string = event.detail.selectedOption.id;
    const key = event.target.id;

    const batteryKeyArray = Object.values(BATTERY_SELECTION_KEY);
    const currentCleanArray = batteryKeyArray.slice(batteryKeyArray.indexOf(key) + 1);
    clearSelectionArray(currentCleanArray);

    setState((prevState) => ({
      ...prevState,
      [key]: currentValue,
    }));

    setSelected(key, currentValue);
    if(key === BATTERY_SELECTION_KEY.testCycleType){
      const tcsid = testcaseDropdown[config]?.[dsn]?.[temperature]?.[currentValue] ?? "";
      if(tcsid){
        const requestJson = {
          test_case_key: indexNumber,
        };
        dispatch(downloadTestcasesResult(tcsid))
        dispatch(postTcsResult(project, TEST_CATEGORY.BATTERY, tcsid, requestJson))
      }
    }
  };

  const setSelected = (key: any, currentValue: string) => {
    let assignValue = currentValue;
    const updateSelected = { ...selectedData };
    updateSelected[indexNumber][key] = assignValue;
    dispatch(setUserSelectDict(updateSelected));
  };

  const clearSelectionArray = (cleanArray: Array<string>) => {
    if (Array.isArray(cleanArray)) {
      cleanArray.forEach((key) => {
        clearSelection(key);
      });
    }
  };

  const clearSelection = (key: string) => {
    setState((prevState) => ({
      ...prevState,
      [key]: null,
    }));
  };

  

  const buildListOptions = handleLvlOptions(buildOptions, BATTERY_SELECTION_KEY.BUILD);
  const versionListOption = getSublvlOptions(buildOptions, build);
  const configList = mapListOption(Object.keys(testcaseDropdown ?? []));
  const dsnList = mapListOption(Object.keys(testcaseDropdown[config] ?? []));
  const temperatureList = mapListOption(Object.keys(testcaseDropdown[config]?.[dsn] ?? []));
  const testCycleTypeList = mapListOption(Object.keys(testcaseDropdown[config]?.[dsn]?.[temperature] ?? []));

  const mapObject = {
    project: {
      placeholder: DROPDOWN_NAMING_COLLECTION.project,
      options: projectOptions,
      status: projectLoadingStatus,
      select: project,
      disable: requestId && labId ? true : false,
    },
    build: {
      placeholder: DROPDOWN_NAMING_COLLECTION.build,
      options: buildListOptions,
      status: buildLoadingStatus,
      select: build,
      disable: requestId && labId ? true : false,
    },
    version: {
      placeholder: DROPDOWN_NAMING_COLLECTION.version,
      options: versionListOption,
      status: buildLoadingStatus,
      select: version,
      disable: requestId && labId ? true : false,
    },
    config: {
      placeholder: DROPDOWN_NAMING_COLLECTION.config,
      options: configList,
      status: testcaseDropdownLoadingStatus,
      select: config,
    },
    dsn: {
      placeholder: DROPDOWN_NAMING_COLLECTION.device,
      options: dsnList,
      status: testcaseDropdownLoadingStatus,
      select: dsn,
    },
    temperature: {
      placeholder: DROPDOWN_NAMING_COLLECTION.temperature,
      options: temperatureList,
      status: testcaseDropdownLoadingStatus,
      select: temperature,
    },
    testCycleType: {
      placeholder: DROPDOWN_NAMING_COLLECTION.testCycleType,
      options: testCycleTypeList,
      status: testcaseDropdownLoadingStatus,
      select: testCycleType,
    },
  };

  const mappedDropdown = Object.keys(mapObject).map((eachKey, index) => {
    return (
      <div key={index} className={"testcase-dropdown-element"}>
        <Select
          id={eachKey}
          empty="No options"
          placeholder={mapObject[eachKey].placeholder}
          selectedLabel="Selected"
          options={mapObject[eachKey].options}
          statusType={
            mapObject[eachKey].status === constants.LOADING_LOAD
              ? constants.SELECT_STATUS_TYPE_LOADING
              : null
          }
          selectedOption={
            mapObject[eachKey].select
              ? {
                  id: mapObject[eachKey].select,
                  label: capitalizeFirstLetter(mapObject[eachKey].select),
                }
              : null
          }
          disabled={
            mapObject[eachKey].disable ? mapObject[eachKey].disable : false
          }
          onChange={dataOnChange}
          filteringType="auto"
        ></Select>
      </div>
    );
  });

  return (
    <div className={"testcase-dropdown-list"} ref={dropdownRef}>
      {mappedDropdown}
      {indexNumber !== "0" && (
        <button className={"icon-btn"} onClick={onClickRemoveDropdown}>
          <i className="fas fa-times fa-lg"></i>
        </button>
      )}
    </div>
  );
};

export default BatteryDropdownList;
