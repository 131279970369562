import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
// redux
import { Dispatch } from "redux";
import { connect } from "react-redux";
import {
  getTcsResult,
  setTcsResult,
} from "../../../redux/actions/tcs-result-action"; // redux action
import PlotsCards from "./plot-card/plot-card";
import constants from "../../../config/constants";
import { TcsResultResponse } from "../../../model/http-json";

interface StateProps {
  tcsResultReducer: any;
}

interface MatchParams {
  project: string;
  build: string;
  version: string;
  test_category: string;
  country: string;
}

// declare prop check
type Props = {
  testType: string;
  tcsID: number;
  technology: string;
  mode: string;
  bandwidth: string;
  antenna: string;
  firmware: string;
  dispatch: Dispatch<any>;
} & typeof defaultProps &
  RouteComponentProps<MatchParams> &
  StateProps;
// declare init state & default props
const defaultProps = Object.freeze({});

class TestcasePlot extends Component<Props> {
  static readonly defaultProps = defaultProps;
  componentDidMount() {
    this._getDate();
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.tcsID !== this.props.tcsID) {
      this._getDate();
    }
  }

  componentWillUnmount() {
    this.props.dispatch(
      setTcsResult(constants.LOADING_BEFORE_START, {} as TcsResultResponse)
    );
  }

  _getDate = () => {
    const { project, test_category } = this.props.match.params;
    const { tcsID } = this.props;
    this.props.dispatch(getTcsResult(project, test_category, tcsID));
  };

  render() {
    const {
      testType,
      technology,
      mode,
      bandwidth,
      antenna,
      firmware,
    } = this.props;
    const header =
      "Technology: " +
      technology +
      (mode === null ? "" : "; Mode: " + mode) +
      (bandwidth === null ? "" : "; Bandwidth: " + bandwidth) +
      (antenna === null ? "" : "; Antenna: " + antenna) +
      "; Firmware: " +
      firmware;

    return <PlotsCards header={header} testType={testType} />;
  }
}

const mapStateToProps = (state) => {
  return {
    tcsResultReducer: state.tcsResultReducer,
  };
};
export default connect<StateProps>(mapStateToProps)(withRouter(TestcasePlot));
