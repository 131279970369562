import React from "react";
import Plot from "react-plotly.js";

type ChartProps = {
  title: string;
  data: Array<any>;
  layoutProps?: Object;
  axisLabels?: Object;
  config?: Object;
};

const NonsigLineChart = ({
  title,
  data,
  layoutProps = { showlegend: true },
  axisLabels = {},
  config = { displayModeBar: false },
}: ChartProps) => {
  return (
    <React.Fragment>
      {data && (
        <Plot
          data={data || []}
          style={{ width: "100%" }}
          layout={{
            title: title,
            ...layoutProps,
            ...axisLabels,
          }}
          useResizeHandler={true}
          config={{ displayModeBar: false, ...config }} // not showing mode bar
        />
      )}
    </React.Fragment>
  );
};

export default NonsigLineChart;
