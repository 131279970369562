// We have 3kinds of factory, value will not be changed based on users' requirement
// Congestion Value are 62% & 44% the same as old Lens:
// How we get .38 and .56  is 1-62%, 1-44%

import { DEFAULT_TECHNOLOGY } from "../../../../config/constants";

const factorCollection = {
  singlefamily: 0.56,
  apartment: 0.38,
  townhouse: 0.5,
  "5G": 0.75,
};

const factorCollection5G = {
  "5G": 0.75,
};

const factorCollection2G = {
  singlefamily: 0.56,
  apartment: 0.38,
  townhouse: 0.5
};

const xRangeDigit = 1;
// func to do range calculator
export const getFormatRangeCalculateResult = (
  // what we have
  selectedData,
  // userinput
  rangeCalculator
) => {
  // user's input
  let fromattedData: Array<Object> = [];
  const txGain = rangeCalculator.txGain;
  const rxGain = rangeCalculator.rxGain;
  const shadowFading = rangeCalculator.shadowFading;
  const desense = rangeCalculator.desense;
  const frequency = rangeCalculator.frequency;

  // the same method in our old lens
  const rangeConstant =
    txGain * 1.0 +
    rxGain * 1.0 -
    shadowFading * 1.0 -
    desense * 1.0 -
    20 * Math.log10(frequency * Math.pow(10, 6)) -
    20 * Math.log10(5) +
    147.5;

  Object.keys(selectedData).forEach((eachTC) => {
    const currentObj = selectedData[eachTC]["data"];

    if (currentObj) {
      const pathLoss = currentObj.path_loss;
      const attenuation = currentObj.attenuation;
      const throughputList = currentObj.throughput_mbps;

      if (currentObj && throughputList && attenuation && pathLoss) {
        const rangeList = throughputList.map((eachtput, index) => {
          return (
            5 *
            Math.pow(
              10,
              (pathLoss[index] * 1.0 +
                attenuation[index] * 1.0 +
                rangeConstant) /
                35
            )
          ).toFixed(xRangeDigit);
        });

        const currentFromatDataArray = rangeCalculate(
          eachTC,
          throughputList,
          rangeList,
          factorCollection
        );
        fromattedData = fromattedData.concat(currentFromatDataArray);
      }
    }
  });

  return fromattedData;
};

export const getFormatOutdoorRangeCalculateResult = (
  // what we have
  selectedData,
  // userinput
  rangeCalculator
) => {
  // user's input
  let fromattedData: Array<Object> = [];
  const apAntG = rangeCalculator.txGain * 1.0 ;
  const dutAntG = rangeCalculator.rxGain * 1.0 ;
  const dutToDutVariation = rangeCalculator.dutToDutVariation * 1.0 ;
  const belMedian = rangeCalculator.belMedian * 1.0 ;
  const belSigma = rangeCalculator.belSigma * 1.0 ;
  const sfSigma = rangeCalculator.sfSigma * 1.0 ;
  const referenceDistance= 5
  const distanceBreakpoint = 1

  const RMS = 10 * Math.log(Math.sqrt(Math.pow(Math.pow(10,(belSigma / 10)), 2) + Math.pow(Math.pow(10,(sfSigma / 10)), 2)))
  const factorCollection = rangeCalculator.selectedTechnology === DEFAULT_TECHNOLOGY ? factorCollection5G: factorCollection2G;
  //const RMS =  1 
  Object.keys(selectedData).forEach((eachTC) => {
    const currentObj = selectedData[eachTC]["data"];

    if (currentObj) {
      const pathLoss = currentObj.path_loss;
      const attenuation = currentObj.attenuation;
      const throughputList = currentObj.throughput_mbps;

      if (currentObj && throughputList && attenuation && pathLoss) {
        const rangeList = throughputList.map((eachtput, index) => {

          const ota = (( (pathLoss[index] * 1.0) + (attenuation[index] * 1.0)) - dutToDutVariation + apAntG + dutAntG) - RMS
          const exponent = ota + 147.5 - belMedian - (20 * Math.log10(referenceDistance / distanceBreakpoint)) - (20 * Math.log10(belMedian)) 
          return (
            5 * 
            Math.pow(10,(exponent/35))
          ).toFixed(xRangeDigit);
          });

        const currentFromatDataArray = rangeCalculate(
          eachTC,
          throughputList,
          rangeList,
          factorCollection
        );
        fromattedData = fromattedData.concat(currentFromatDataArray);
      }
    }
  });

  return fromattedData;
};

const rangeCalculate = (testcaseKey, throughputList, rangeList, factorCollection) => {
  const fromattedData: Array<Object> = [];
  
  Object.keys(factorCollection).forEach((eachType) => {
    //   format data for each type
    const eachTrace = {
      x: rangeList,
      y: throughputList.map((value) =>
        (value * factorCollection[eachType]).toFixed(4)
      ),
      hovertemplate: "<br><b>range</b>: %{x}<br><i>throughput</i>: %{y}",
      name: `testcase${testcaseKey}-${eachType}`,
      mode: "lines+markers",
      marker: {
        size: 6,
      },
    };

    fromattedData.push(eachTrace);
  });

  return fromattedData;
};
