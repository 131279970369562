import base_config from "./base_config";

const dev_config = {
  ...base_config,
  STAGE: "dev",

  // Amplify config for cognito
  AMPLIFY_CONFIG: {
    aws_project_region: base_config.REGION,
    aws_cognito_region: base_config.REGION,
    aws_user_pools_id: "us-west-2_98VCRNfCo",
    aws_user_pools_web_client_id: "6utmd5dcgqb781q9q4urlkvv8i",
    oauth: {
      domain: "wise-general-cog-dev.auth.us-west-2.amazoncognito.com",
      scope: base_config.TOKEN_SCOPES_ARRAY,
      redirectSignIn: base_config.CALL_BACK_URL,
      redirectSignOut: base_config.CALL_BACK_URL,
      responseType: base_config.OAUTH_RESPONSE_TYPE,
    },
  },

  // Base url
  BASE_URL: "https://ldd5idcog4.execute-api.us-west-2.amazonaws.com/dev",
  // Upload service base url
  UPLOAD_BASE_URL: "https://wrcqb3byf5.execute-api.us-west-2.amazonaws.com/dev",

  // Configuration for Cloudwatch RUM
  APPLICATION_ID: "ad9821a9-bb8e-4b76-8bb7-347c3ba2b546",
  APPLICATION_VERSION:"1.0.0",
  APPLICATION_REGION: "us-west-2",
  rum_config: {
    sessionSampleRate: 1,
    guestRoleArn: "arn:aws:iam::563781235045:role/RUM-Monitor-us-west-2-563781235045-3200710041271-Unauth",
    identityPoolId: "us-west-2:ce0650fd-af62-4cb9-b9c8-3eff511d1c5c",
    endpoint: "https://dataplane.rum.us-west-2.amazonaws.com",
    telemetries: ["performance","errors",["http",{recordAllRequests: true}]],
    allowCookies: true,
    enableXRay: false,
    disableAutoPageView: false
  },

};

export default dev_config;
