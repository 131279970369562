import React, { Component } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { Select } from "@amzn/awsui-components-react/polaris";
import {
  DESENSE_TABLE_KEYS_DEFAULT,
  //   DESENSE_TABLE_KEYS_CUSTOM,
} from "../../../../../../config/constants";
import { capitalizeFirstLetter } from "../../../../../../utils/general-utils";
import { setSelectedPowerRegion } from "../../../../../../redux/actions/desense-config-action";
const defaultProps = Object.freeze({});

const initialState = Object.freeze({});

interface StateProps {
  desenseConfigReducer: any;
}

// declare prop check
type Props = {
  data: Object;
  loadingStatus: number;
  dispatch: Dispatch<any>;
} & typeof defaultProps &
  StateProps;

type State = typeof initialState;

// DesenseRegionSelect is a subcomponent of test-case-table
class DesenseRegionSelect extends Component<Props, State> {
  static readonly defaultProps = defaultProps;
  readonly state = initialState;

  _onChangeRegion = (event) => {
    const currentSelected = event.detail.selectedId;
    this.props.dispatch(setSelectedPowerRegion(currentSelected));
  };

  formatSelect = (selectedRegion) => {
    return {
      id: selectedRegion,
      label: capitalizeFirstLetter(selectedRegion),
    };
  };

  render() {
    const { selectedData, powerRegion } = this.props.desenseConfigReducer;
    const filteredSelect = {};
    if (Object.keys(selectedData)) {
      Object.keys(selectedData).forEach((eachTC) => {
        if (DESENSE_TABLE_KEYS_DEFAULT in selectedData[eachTC]) {
          filteredSelect[eachTC] = selectedData[eachTC];
        }
      });
    }

    const regionOptions =
      filteredSelect[0] && filteredSelect[0][DESENSE_TABLE_KEYS_DEFAULT]
        ? filteredSelect[0][DESENSE_TABLE_KEYS_DEFAULT]
        : [];
    const regionOptionList: Array<any> = [];
    // DATA = {0:{DESENSE_TABLE_KEYS_DEFAULT:{} }}

    if (Object.keys(regionOptions).length > 0) {
      Object.keys(regionOptions).forEach((eachKey) => {
        const currentOption = this.formatSelect(eachKey);
        regionOptionList.push(currentOption);
      });
    }
    const showSelect = regionOptionList.length > 1 ? true : false;

    return (
      <div>
        {showSelect && (
          <Select
            id={"region-dropdown"}
            selectedLabel="Selected"
            empty="No options"
            placeholder="Build"
            options={regionOptionList}
            selectedOption={this.formatSelect(powerRegion)}
            onChange={this._onChangeRegion}
            filteringType="auto"
          ></Select>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    desenseConfigReducer: state.desenseConfigReducer,
  };
};
export default connect(mapStateToProps)(DesenseRegionSelect);
