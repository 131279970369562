export const getAxisOptions = (selectedData) => {
  // This func runs faster than set loading status, so need to add if check
  const optionObject = selectedData[Object.keys(selectedData)[0]];
  const lookupObj = optionObject ? optionObject["data"] : {};

  if (!optionObject || !lookupObj) {
    return [];
  }

  const options = Object.keys(lookupObj).map((eachKey) => {
    return { id: eachKey, label: eachKey };
  });

  return options;
};

export const formatSelectedData = (selectedData, xSelect, ySelect) => {
  const formattedData: Array<object> = [];
  Object.keys(selectedData).forEach((eachTC) => {
    ySelect.forEach((eachY) => {
      const currentObj = selectedData[eachTC]["data"];
      if (currentObj) {
        const currentTrace = {
          x: currentObj[xSelect],
          y: currentObj[eachY],
          name: `Testcase ${eachTC} ${eachY}`,
          mode: "lines+markers",
          id: `Testcase ${eachTC}`,
          hovertemplate: `<br><b>${xSelect}</b>: %{x}<br><i>${eachY}</i>: %{y}`,
          marker: {
            size: 6,
          },
        };

        formattedData.push(currentTrace);
      }
    });
  });

  return formattedData;
};

export const showAlert = (min, max) => {
  let result = false;

  if (
    [min, max].some(
      (element) => element === "" || element === null || isNaN(element)
    )
  ) {
    if (min !== max) {
      result = true;
    }
  }
  if (min === max && min !== null) {
    result = true;
  }

  return result;
};
