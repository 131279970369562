import React, { Component } from "react";
import { connect } from "react-redux";
// import constants from "../../../../config/constants";
import DesenseTabs from "./test-case-tab-table/desense-tabs";
import DesenseDynamicColTable from "./dynamic-compare/dynamic-col-table";
import {
  DESENSE_TABLE_KEYS_DEFAULT,
  //   DESENSE_TABLE_KEYS_CUSTOM,
} from "../../../../config/constants";

const defaultProps = Object.freeze({});

const initialState = Object.freeze({
  //   currentPageIndex: 1,
  //   pagesCount: 0,
});

interface StateProps {
  desenseConfigReducer: any;
}

// declare prop check
type Props = {
  data: Object;
  loadingStatus: number;
} & typeof defaultProps &
  StateProps;

type State = typeof initialState;

// declare init state & default props
class DesenseRootTable extends Component<Props, State> {
  static readonly defaultProps = defaultProps;
  readonly state = initialState;

  render() {
    const { selectedData } = this.props.desenseConfigReducer;

    // add a filter, when user add new dropdown, table not broken
    const tableData = {};
    // for table data we only check DESENSE_TABLE_KEYS_DEFAULT for this is
    // the main key we use in below tables
    if (Object.keys(selectedData)) {
      Object.keys(selectedData).forEach((eachTC) => {
        if (DESENSE_TABLE_KEYS_DEFAULT in selectedData[eachTC]) {
          tableData[eachTC] = selectedData[eachTC];
        }
      });
    }
    return (
      <div>
        <DesenseTabs data={tableData} />
        <DesenseDynamicColTable data={tableData} />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    desenseConfigReducer: state.desenseConfigReducer,
  };
};
export default connect(mapStateToProps)(DesenseRootTable);
