// formatSelect for select
import { capitalizeFirstLetter } from "../../../utils/general-utils";
import { DESENSE_SELECTION_KEY } from "../../../config/constants";

const SPLIT_BY = "_";

// input array: buildName_version, or config_dsn_firmware
// sepcifal case tcs_SUBSYSTEM_antenna_band, id is tcs_SUBSYSTEM but what we show to user
// is tcs_SUBSYSTEM
export const handleLvlOptions = (
  inputArray: Array<string>,
  levelKey: String
) => {
  let sliceStart = 0;
  let sliceEnd = 0;
  // slice differently for build api, config api, and tcs api
  if (levelKey === DESENSE_SELECTION_KEY.BUILD) {
    sliceEnd = 1;
  }

  const filteredArray = inputArray.map((eachCombine) =>
    eachCombine.includes(SPLIT_BY)
      ? eachCombine.split(SPLIT_BY).slice(sliceStart, sliceEnd).join(SPLIT_BY)
      : null
  );
  const uniqueList = Array.from(new Set(filteredArray));

  let resultList = uniqueList.map((item) => ({
    label: item ? capitalizeFirstLetter(item) : "",
    id: item,
  }));
  // take tcs_id, but dont show to user

  return resultList;
};

// control the input "test_test_xxx" use inputArray and selectedValue,
// then filter to test_xxx,
// if want to get xxx, we input inputArray and test_test for selectedValue,
export const getSublvlOptions = (
  inputArray: Array<string>,
  selectedValue: string
) => {
  // filter selectedValues in inputArray,
  // each item format in filteredlistare the same as input array
  const filteredlist = inputArray.filter((eachValue) =>
    eachValue.includes(`${selectedValue}_`)
  );
  // using set to get unique
  const uniqueList = Array.from(new Set(filteredlist));
  const resultList = uniqueList.map((item) => {
    // chaneg item to current level
    const currentItem = item.replace(`${selectedValue}_`, "");
    return {
      label: currentItem ? capitalizeFirstLetter(currentItem) : "",
      id: currentItem,
    };
  });
  return resultList;
};

export const mapListOption = (inputList: Array<string>) => {
  return inputList.map((eachKey) => {
    return {
      label: eachKey ? capitalizeFirstLetter(eachKey) : "",
      id: eachKey,
    };
  });
};
