import buildType from "../types/build-type";
import { config } from "../../config/config";
import endpoints from "../../config/endpoints";
import { METRIC_INFO } from "../../config/constants";
import { capitalizeFirstLetter } from "../../utils/general-utils";

export const getBuildList = (category: string, project: string): any => ({
  type: buildType.GET_BUILD_LIST,
  http: {
    cognito: {
      effect: {
        url: config.BASE_URL + endpoints.getBuildList(project),
        method: "GET",
      },
      commit: {
        type: buildType.GET_BUILD_LIST_COMMIT,
        log: {
          initiative: METRIC_INFO.INITIATIVE,
          feature: METRIC_INFO.BUILD,
          category: capitalizeFirstLetter(category),
          project: capitalizeFirstLetter(project),
        },
      },
      rollback: {
        type: buildType.GET_BUILD_LIST_ROLLBACK,
        log: {
          initiative: METRIC_INFO.INITIATIVE,
          feature: METRIC_INFO.BUILD,
          category: capitalizeFirstLetter(category),
          project: capitalizeFirstLetter(project),
        },
      },
    },
  },
});

export const setBuild = (build: string): any => ({
  type: buildType.SET_BUILD,
  build,
});

export const setCanCoconutSubmit = (canCoconutSubmit: boolean): any => ({
  type: buildType.SET_CAN_COCONUT_SUBMIT,
  canCoconutSubmit,
});

export const setCanLitepointSubmit = (canLitepointSubmit: boolean): any => ({
  type: buildType.SET_CAN_LITEPOINT_SUBMIT,
  canLitepointSubmit,
});

export const setNonSigCoconutSelect = (nonSigCoconutSelect: any): any => ({
  type: buildType.SET_NONSIG_COCONUT_SELECT,
  nonSigCoconutSelect,
});

export const setNonSigLitepointSelect = (newSelected: any): any => ({
  type: buildType.SET_NONSIG_LITEPOINT_SELECT,
  newSelected,
});

export const setNonSigLitepointVersion = (version: number): any => ({
  type: buildType.SET_NONSIG_LITEPOINT_VERSION,
  version,
});

export const clearNonSigLitepointSelect = (): any => ({
  type: buildType.CLEAR_NONSIG_LITEPOINT_SELECT,
});
