import { Select } from "@amzn/awsui-components-react/polaris";
import React, { Component } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import constants, {
  TEST_CATEGORY,
  METRIC_INFO,
  ANTENNA_SELECTION_KEY,
  DROPDOWN_NAMING_COLLECTION,
} from "../../../../config/constants";
import {
  setUserSelectDict,
  getTCSDropdown,
  postTcsResult,
} from "../../../../redux/actions/antenna-config-action";
// ajax
import { getData } from "../../../../utils/cognito-fetch";
import { config } from "../../../../config/config.js";
import endpoint from "../../../../config/endpoints";
import {
  capitalizeFirstLetter,
  getLogData,
} from "../../../../utils/general-utils";
import {
  getSublvlOptions,
  handleLvlOptions,
  mapListOption,
} from "../../utils/dropdown-helper-func";

interface StateProps {
  antennaConfigReducer: any;
}

type Props = {
  dispatch: Dispatch<any>;
  indexNumber: string;
  antennaStatus: string;
  project: string;
  build: string;
  version: string;
  // external url
  requestId: number;
  labId: number;
} & typeof defaultProps &
  StateProps;
// type State = typeof initialState;

const defaultProps = Object.freeze({});
const initialState = Object.freeze({
  projectOptions: [] as any[],
  // build-version
  buildOptions: [] as any[],
  projectLoadingStatus: constants.LOADING_BEFORE_START,
  buildLoadingStatus: constants.LOADING_BEFORE_START,
  // all projects
  project: "",
  // build_version
  build: "",
  version: "",

  dsn: "",
});

class AntennaDropdownList extends Component<Props> {
  static readonly defaultProps = defaultProps;
  readonly state = initialState;
  private dropdownRef: React.RefObject<HTMLInputElement>;

  constructor(props) {
    super(props);
    this.dropdownRef = React.createRef();
  }

  componentDidMount() {
    const { indexNumber, project, build, version, requestId, labId } =
      this.props;
    this.setState({
      project: project,
      build: build,
      version: version,
    });
    const { selectedData } = this.props.antennaConfigReducer;
    const currentObject = {
      project: project,
      build: build,
      version: version,
    };
    const updateSelected = { ...selectedData };
    updateSelected[indexNumber] = currentObject;
    this.props.dispatch(setUserSelectDict(updateSelected));

    this._getProjectOptions();
    this._getBuildOptions(currentObject.project);

    if (requestId && labId) {
      this.props.dispatch(
        getTCSDropdown(
          project,
          build,
          version,
          TEST_CATEGORY.ANTENNA,
          requestId,
          labId
        )
      );
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { project, build, version, dsn } = this.state;
    const { indexNumber, antennaStatus } = this.props;
    const { testcaseDropdown } = this.props.antennaConfigReducer;

    if (project && project !== prevState.project) {
      //project
      this._getBuildOptions(this.state.project);
    }
    if (
      version &&
      version !== prevState.version &&
      (!this.props.requestId || !this.props.labId)
    ) {
      //build, version
      this.props.dispatch(
        getTCSDropdown(project, build, version, TEST_CATEGORY.ANTENNA)
      );
    }
    if (dsn && dsn !== prevState.dsn) {
      const tcsid = testcaseDropdown[dsn][antennaStatus];
      const requestJson = {
        test_case_key: indexNumber,
      };
      this.props.dispatch(
        postTcsResult(project, TEST_CATEGORY.ANTENNA, tcsid, requestJson)
      );
    }
  }

  // Ajax call for Project options
  _getProjectOptions = () => {
    // change load flag
    this.setState({
      projectLoadingStatus: constants.LOADING_LOAD,
    });

    // ajax call
    getData(
      config.BASE_URL + endpoint.getProjectListWithoutCategoryEndpoint(),
      getLogData(
        METRIC_INFO.ANTENNA_TESTCASE,
        METRIC_INFO[TEST_CATEGORY.ANTENNA]
      )
    )
      .then((response) => {
        const projectOptions = response.json.map((item) => ({
          label: capitalizeFirstLetter(item),
          id: item,
        }));
        this.setState({
          projectOptions,
          projectLoadingStatus: constants.LOADING_SUCCESS,
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          projectLoadingStatus: constants.LOADING_FAIL,
        });
      });
  };

  // Ajax call for build options
  _getBuildOptions = (projectName: string) => {
    const { indexNumber } = this.props;
    // change load flag
    this.setState({
      buildLoadingStatus: constants.LOADING_LOAD,
    });
    const project =
      projectName !== "null"
        ? projectName
        : this.props.antennaConfigReducer.selectedData[indexNumber].project;
    // ajax call
    getData(
      config.BASE_URL + endpoint.getBuildList(project),
      getLogData(
        METRIC_INFO.ANTENNA_TESTCASE,
        METRIC_INFO[TEST_CATEGORY.ANTENNA]
      )
    )
      .then((response) => {
        const buildOptions = response.json.map((item) => item);
        this.setState({
          buildLoadingStatus: constants.LOADING_SUCCESS,
          buildOptions,
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          buildLoadingStatus: constants.LOADING_FAIL,
        });
      });
  };

  onClickRemoveDropdown = () => {
    const { selectedData } = this.props.antennaConfigReducer;
    const { indexNumber } = this.props;

    const currentTestCastObject = { ...selectedData };

    if (Object.keys(currentTestCastObject).length >= 2) {
      delete currentTestCastObject[indexNumber];
    } else {
      window.alert("The only left one cannot be removed");
    }

    this.props.dispatch(setUserSelectDict(currentTestCastObject));
  };

  _dataOnChange = (event: any) => {
    // event.detail {selectedId: "test", selectedOption: {…}}
    const currentValue: string = event.detail.selectedOption.id;
    const key = event.target.id;
    // dynamic add state

    const keyArray = Object.values(ANTENNA_SELECTION_KEY);
    // checks user changes & clear keys

    const currentCleanArray = keyArray.slice(keyArray.indexOf(key) + 1);
    this._clearSelectionArray(currentCleanArray);

    this.setState({
      [key]: currentValue,
    });

    this._setSelected(key, currentValue);
  };

  // set in reducer
  _setSelected = (key: any, currentValue: string) => {
    const { selectedData } = this.props.antennaConfigReducer;
    const { indexNumber } = this.props;
    // TODO remove tcs infront of subsystem
    let assignValue = currentValue;

    const updateSelected = { ...selectedData };
    updateSelected[indexNumber][key] = assignValue;

    this.props.dispatch(setUserSelectDict(updateSelected));
  };

  // array to clean set
  _clearSelectionArray = (cleanArray: Array<string>) => {
    if (Array.isArray(cleanArray)) {
      cleanArray.forEach((key) => {
        this._clearSelection(key);
      });
    }
  };

  _clearSelection = (key: string) => {
    // clear selections
    this.setState({
      [key]: null,
    });
  };

  render() {
    const { indexNumber, requestId, labId } = this.props;
    const {
      // selectedData,
      testcaseDropdown,
      testcaseDropdownLoadingStatus,
    } = this.props.antennaConfigReducer;

    const {
      projectOptions,
      buildOptions,
      // loading status
      projectLoadingStatus,
      buildLoadingStatus,
      // selected value
      project,
      build,
      version,
      dsn,
    } = this.state;

    const buildListOptions = handleLvlOptions(
      buildOptions,
      ANTENNA_SELECTION_KEY.BUILD
    );
    const versionListOption = getSublvlOptions(buildOptions, build);

    const dsnList = mapListOption(
      testcaseDropdown ? Object.keys(testcaseDropdown) : []
    );

    // config for dropdown
    const mapObject = {
      project: {
        placeholder: DROPDOWN_NAMING_COLLECTION.project,
        options: projectOptions,
        status: projectLoadingStatus,
        select: project,
        disable: requestId && labId ? true : false,
      },
      build: {
        placeholder: DROPDOWN_NAMING_COLLECTION.build,
        options: buildListOptions,
        status: buildLoadingStatus,
        select: build,
        disable: requestId && labId ? true : false,
      },
      version: {
        placeholder: DROPDOWN_NAMING_COLLECTION.version,
        options: versionListOption,
        status: buildLoadingStatus,
        select: version,
        disable: requestId && labId ? true : false,
      },
      dsn: {
        placeholder: DROPDOWN_NAMING_COLLECTION.device,
        options: dsnList,
        status: testcaseDropdownLoadingStatus,
        select: dsn,
      },
    };

    // map dropdown, based on mapObject above
    const mappedDropdown = Object.keys(mapObject).map((eachKey, index) => {
      return (
        <div key={index} className={"testcase-dropdown-element"}>
          <Select
            id={eachKey}
            empty="No options"
            placeholder={mapObject[eachKey].placeholder}
            selectedLabel="Selected"
            options={mapObject[eachKey].options}
            statusType={
              mapObject[eachKey].status === constants.LOADING_LOAD
                ? constants.SELECT_STATUS_TYPE_LOADING
                : null
            }
            selectedOption={
              mapObject[eachKey].select
                ? {
                    id: mapObject[eachKey].select,
                    label: capitalizeFirstLetter(mapObject[eachKey].select),
                  }
                : null
            }
            disabled={
              mapObject[eachKey].disable ? mapObject[eachKey].disable : false
            }
            onChange={this._dataOnChange}
            filteringType="auto"
          ></Select>
        </div>
      );
    });

    return (
      <div className={"testcase-dropdown-list"} ref={this.dropdownRef}>
        {/* mapped dropdown based on mapObject above*/}
        {mappedDropdown}
        {indexNumber !== "0" && (
          <button className={"icon-btn"} onClick={this.onClickRemoveDropdown}>
            <i className="fas fa-times fa-lg"></i>
          </button>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    antennaConfigReducer: state.antennaConfigReducer,
  };
};
export default connect<StateProps>(mapStateToProps)(AntennaDropdownList);
