import antennaConfigType from "../types/antenna-config-type";
import constants from "../../config/constants";
import { handleMultiTestcaseData } from "../../utils/general-utils";

const initialState = {
  testcaseDropdown: {},
  testcaseDropdownMessage: "",
  testcaseDropdownLoadingStatus: constants.LOADING_LOAD,

  loadingStatus: constants.LOADING_LOAD,
  tcsResultLoadingStatus: constants.LOADING_BEFORE_START,
  tcsResultMessage: "",

  // Main Object for control all data in antenna page and mult-dropdown
  selectedData: {
    0: {},
  },
};

const antennaConfigReducer = (state = initialState, action) => {
  switch (action.type) {
    case antennaConfigType.GET_ANTENNA_DEVICE_LIST:
      return {
        ...state,
        testcaseDropdownLoadingStatus: constants.LOADING_LOAD,
      };
    case antennaConfigType.GET_ANTENNA_DEVICE_LIST_COMMIT:
      return {
        ...state,
        testcaseDropdownLoadingStatus: constants.LOADING_SUCCESS,
        testcaseDropdown: action.payload,
      };
    case antennaConfigType.GET_ANTENNA_DEVICE_LIST_ROLLBACK:
      return {
        ...state,
        testcaseDropdownLoadingStatus: constants.LOADING_FAIL,
        testcaseDropdownMessage: action.payload.message,
      };

    // --------------init loading for clean components -------------
    case antennaConfigType.INIT_LOADING_STATUS:
      return {
        ...state,
        loadingStatus: constants.LOADING_BEFORE_START,
        tcsResultLoadingStatus: constants.LOADING_BEFORE_START,
      };
    // --------------TCS ----------------
    case antennaConfigType.POST_ANTENNA_TCS_RESULT:
      return {
        ...state,
        tcsResultLoadingStatus: constants.LOADING_LOAD,
      };
    case antennaConfigType.POST_ANTENNA_TCS_RESULT_COMMIT:
      return {
        ...state,
        tcsResultLoadingStatus: constants.LOADING_SUCCESS,
        selectedData: handleMultiTestcaseData(
          state.selectedData,
          action.payload.data,
          action.payload.key
        ),
        tcsResultMessage: action.payload.message,
      };
    case antennaConfigType.POST_ANTENNA_TCS_RESULT_ROLLBACK:
      return {
        ...state,
        tcsResultLoadingStatus: constants.LOADING_FAIL,
        tcsResultMessage: action.payload.message,
      };

    // --------------control user selection---------
    case antennaConfigType.SET_USER_SELECTION:
      return {
        ...state,
        selectedData: action.selectedData,
      };

    default:
      return state;
  }
};
export default antennaConfigReducer;
