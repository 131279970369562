import React from "react";
import { Alert } from "@amzn/awsui-components-react/polaris";
import styled from "styled-components";

const StyledDiv = styled.div`
  width: 100%;
  text-align: center;
`;

// loading -> spinner
export const ContainerSpninner = () => {
  return (
    <StyledDiv>
      <div className="spinner-border text-secondary" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </StyledDiv>
  );
};

// loading failed -> cross mark
export const ContainerFail = () => {
  return <Alert type="error">Error ocurred. Please try it again.</Alert>;
};

// no data
export const ContainerNoData = () => {
  return <Alert type="warning">No data found.</Alert>;
};
