import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Sidebar as LensSidebar } from "@amzn/lens-react-ui";
// redux
import { connect } from "react-redux";
// this is a sample config, replace with real data with the same format
// import { sidebarData } from "./sidebar-config";
import constants from "../../config/constants";

interface StateProps {
  metadataReducer: any;
}

// declare prop check
type Props = {
  collapse: boolean;
  onClick: () => void;
} & typeof defaultProps &
  StateProps &
  RouteComponentProps<any>;

// declare init state & default props
const defaultProps = Object.freeze({});

class Sidebar extends Component<Props> {
  // static readonly defaultProps = defaultProps;

  render() {
    const { collapse, onClick } = this.props;
    const { loadingStatus, sidebarData } = this.props.metadataReducer;
    return (
      <LensSidebar
        title="Project List"
        loading={loadingStatus === constants.LOADING_LOAD}
        failed={loadingStatus === constants.LOADING_FAIL}
        collapse={collapse}
        data={sidebarData}
        onClickToggle={onClick}
        routerHistory={this.props.history}
      />
    );
  }
}

// export default withRouter(Sidebar);
const mapStateToProps = (state) => {
  return {
    metadataReducer: state.metadataReducer,
  };
};
export default connect<StateProps>(mapStateToProps)(withRouter(Sidebar));
