import React from "react";
import { useSelector } from "react-redux";
import {
  SpaceBetween,
  Container,
  Alert,
} from "@amzn/awsui-components-react-v3/polaris";
import NonsigLineChart from "./line-chart/nonsig-line-chart";
import { litepointLineChartFormat } from "./line-chart/nonsig-litepoint-line-chart-config";
import { coconutLineChartFormat } from "./line-chart/nonsig-coconut-line-chart-config";
import { NONSIG_TEST_FORMAT } from "../../../config/constants";
import {awsRum} from "../../../index";

const NonSigVisualization = () => {
  // non sig visualization main
  const { nonsigReducer } = useSelector((state: any) => ({
    nonsigReducer: state.nonsigReducer,
  }));
  const { tcsResult, testFormat } = nonsigReducer;
  const hasData = tcsResult && Object.keys(tcsResult).length > 0;

  awsRum?.recordEvent("non_signaling",{
    metricCategory: 'non_signaling'
  });

  return (
    <SpaceBetween direction="vertical" size="xl">
      {
        // Litepoint Data
        hasData &&
          testFormat === NONSIG_TEST_FORMAT.LITEPOINT &&
          Object.keys(tcsResult).map((eachRate, index) => {
            const { x_label, y_label, data } = litepointLineChartFormat(
              tcsResult[eachRate]
            );
            const labels = {
              xaxis: { title: x_label },
              yaxis: { title: y_label },
            };
            return (
              <Container key={index}>
                <NonsigLineChart
                  title={eachRate}
                  data={data}
                  axisLabels={labels}
                />
              </Container>
            );
          })
      }
      {
        // Coconut Data
        hasData &&
          testFormat === NONSIG_TEST_FORMAT.COCONUT &&
          Object.keys(tcsResult).map((eachRate, index) => {
            const formattedData = coconutLineChartFormat(tcsResult[eachRate]);

            return (
              <Container key={index}>
                <NonsigLineChart title={eachRate} data={formattedData} />
              </Container>
            );
          })
      }

      {!hasData && <Alert type="warning">No Data</Alert>}
    </SpaceBetween>
  );
};

export default NonSigVisualization;
