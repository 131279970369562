import React, { Component } from "react";
// redux
import { Dispatch } from "redux";
// directly use plotly
import Plot from "react-plotly.js";
import { connect } from "react-redux";
import { Input, Icon, Button } from "@amzn/awsui-components-react/polaris";
import { formatPassiveSelectedData, showAlert } from "./antenna-passive-helper";
import { ySelectMap, freqLimitMap } from "./antenna-passive-config";
import { capitalizeFirstLetter } from "../../../../utils/general-utils";

import "./antenna-passive-style.css";

interface StateProps {
  antennaConfigReducer: any;
}

// declare prop check
type Props = {
  filterObject: Object;
  ySelect: string;
  dispatch: Dispatch<any>;
} & typeof defaultProps &
  StateProps;

// declare init state & default props
const defaultProps = Object.freeze({});
const initialState = Object.freeze({
  // inherit
  mainPlotWidth: window.innerWidth,
  xTitle: "Frequency",
  xSelect: "freq",
  //   ySelect: [ySelectOptions[0]["id"]],
  xMin: null,
  xMax: null,
  yMin: null,
  yMax: null,
});

class AntennaPassiveChart extends Component<Props> {
  readonly state = initialState;
  private plotRef: React.RefObject<HTMLInputElement>;

  constructor(props) {
    super(props);
    this.plotRef = React.createRef();
  }

  componentDidMount() {
    // handle resize for the visualization
    const antennaChartNode = this.plotRef.current;
    if (antennaChartNode) {
      this.setState({
        mainPlotWidth: antennaChartNode.clientWidth,
      });
    }
    // handle init new value
  }

  updateWindowDimensions = () => {
    // const node = this.dashboardRightRef.current;
    const antennaChartNode = this.plotRef.current;
    if (antennaChartNode) {
      this.setState({
        mainPlotWidth: antennaChartNode.clientWidth,
      });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    // Add event listener to window resize event
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  _resetInput = () => {
    this.setState({
      xSelect: "freq",
      //   ySelect: ["efficiency"],
      xMin: null,
      xMax: null,
      yMin: null,
      yMax: null,
    });
  };

  render() {
    const { xSelect, xMin, yMin, xMax, yMax } = this.state;
    const { ySelect } = this.props;

    const yTitle = ySelectMap[ySelect];

    const { selectedData } = this.props.antennaConfigReducer;
    // chart layout
    const layout = {
      title: `Antenna Passive ${capitalizeFirstLetter(ySelect)}`,
      width: this.state.mainPlotWidth - 20,
      margin: {
        t: 30, //top margin
        b: 25, //bottom margin
      },
      yaxis: {
        title: yTitle,
      },
      xaxis: {
        // rangeslider: {}, TODO add an user operation later
      },
    };
    // if user input range & also double check
    if ([xMin, xMax].every((ele) => ele !== null)) {
      if ([xMin, xMax].every((ele) => !isNaN(Number(ele)))) {
        layout["xaxis"]["range"] = [xMin, xMax];
      }
    }

    if ([yMin, yMax].every((ele) => ele !== null)) {
      if ([yMin, yMax].every((ele) => !isNaN(Number(ele)))) {
        layout["yaxis"]["range"] = [yMin, yMax];
      }
    }
    // chart data
    const formatData = formatPassiveSelectedData(
      selectedData,
      // passiveExampleData, // example data
      xSelect,
      ySelect,
      xMin == null || xMax == null
    );

    // User chart setting, set x y:  title, select, min, max
    // Too long for each, so split x & y for easiler reading & faster search
    const userSettingX = (
      <div className={"axis-select-component"}>
        {/* xmin */}
        <div className={"axis-dropdown-element-range"}>
          <div className={"axis-dropdown-label"}>X Min:</div>
          <div className={"axis-dropdown-input"}>
            <Input
              onChange={({ detail }) => {
                this.setState({
                  xMin: detail.value,
                });
              }}
              value={xMin}
            />
          </div>
        </div>
        {/* xmax */}
        <div className={"axis-dropdown-element-range"}>
          <div className={"axis-dropdown-label"}>X Max:</div>
          <div className={"axis-dropdown-input"}>
            <Input
              onChange={({ detail }) => {
                this.setState({
                  xMax: detail.value,
                });
              }}
              value={xMax}
            />
          </div>
        </div>

        {showAlert(xMin, xMax) && (
          <div className={"axis-input-warnning"}>
            <Icon name="status-warning" size="normal" variant="error" />
            <span className="rvr-input-warnning-text">
              Need both Min&Max to filter
            </span>
          </div>
        )}
      </div>
    );
    const userSettingY = (
      <div className={"axis-select-component"}>
        {/* y min */}
        <div className={"axis-dropdown-element-range"}>
          <div className={"axis-dropdown-label"}>Y Min:</div>
          <div className={"axis-dropdown-input"}>
            <Input
              onChange={({ detail }) => {
                this.setState({
                  yMin: detail.value,
                });
              }}
              value={yMin}
            />
          </div>
        </div>
        {/* ymax */}
        <div className={"axis-dropdown-element-range"}>
          <div className={"axis-dropdown-label"}>Y Max:</div>
          <div className={"axis-dropdown-input"}>
            <Input
              onChange={({ detail }) => {
                this.setState({
                  yMax: detail.value,
                });
              }}
              value={yMax}
            />
          </div>
        </div>

        {showAlert(yMin, yMax) && (
          <div className={"axis-input-warnning"}>
            <Icon name="status-warning" size="normal" variant="error" />
            <span className="rvr-input-warnning-text">
              Need both Min&Max to filter
            </span>
          </div>
        )}
      </div>
    );

    // a list of button in footer for user one click zoom in
    const quickRangeSelect = Object.keys(freqLimitMap).map((eachKey, index) => {
      return (
        <span key={index} style={{ marginRight: "7px" }}>
          <Button
            onClick={() => {
              this.setState({
                xMin: freqLimitMap[eachKey]["min"],
                xMax: freqLimitMap[eachKey]["max"],
              });
            }}
          >
            {eachKey}
          </Button>
        </span>
      );
    });

    return (
      <div className={"rvr-base-chart"} ref={this.plotRef}>
        <div className="awsui-util-container rvr-chart-container">
          <div className={"rvr-plot"}>
            <Plot
              data={formatData ? formatData : []}
              layout={layout}
              config={{ displayModeBar: false }} // not showing mode bar
            />
          </div>
          <div className={"rvr-x-title"}>{this.state.xTitle}</div>
          <div className="awsui-util-container-footer">{quickRangeSelect}</div>
          <div className="visual-reset-btn">
            <Button onClick={this._resetInput}>
              <Icon name="undo" size="normal" variant="normal" />
            </Button>
          </div>
        </div>

        {userSettingX}
        {userSettingY}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    antennaConfigReducer: state.antennaConfigReducer,
  };
};

export default connect<StateProps>(mapStateToProps)(AntennaPassiveChart);
