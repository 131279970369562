import React, { useRef } from "react";
// directly use plotly
import { connect } from "react-redux";
import BatteryBaseChart from "./battery-base-chart/battery-base-chart";
import {awsRum} from "../../../index";

const BatteryVisualization = (props) => {
  // Create a ref using useRef
  const plotRef = useRef(null);
  awsRum?.recordEvent("battery_visualization",{
    metricCategory: 'battery_visualization'
  });
  return (
    <div className={"rvr-main-chart"} ref={plotRef}>
      <BatteryBaseChart />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    batteryConfigReducer: state.batteryConfigReducer,
  };
};

// Connect the component to the Redux store
export default connect(mapStateToProps)(BatteryVisualization);