import React from "react";
import constants from "../../../config/constants";
import "./file-list.css";

/*
    generate status icon
    given status, and message
    will show message when status is failed

    loading => spinner
    success => green check mark
    fail => red cross mark
*/
export const getIcon = (status: number, message: string = "") => {
  if (status === constants.LOADING_SUCCESS) {
    return (
      // file name check succeeds
      <i className="fa fa-check" style={{ color: "green" }} />
    );
  }
  if (status === constants.LOADING_FAIL) {
    // file name check fails
    return (
      <span style={{ color: "red" }}>
        <i className="fa fa-times" style={{ color: "red" }} />
        &nbsp;error: &nbsp;
        {message}
      </span>
    );
  }
  // file name is checking ...
  return <i className="fa fa-sync fa-spin" style={{ fontSize: "18px" }} />;
};

// delete button component
export const deleteButton = (
  onClick: (filename: string) => void,
  filename: string
) => {
  return (
    <i
      className="fas fa-trash-alt engineering-upload-file-delete-btn"
      onClick={() => {
        onClick(filename);
      }}
    ></i>
  );
};
