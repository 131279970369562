import React from "react";
// utils
import { capitalizeFirstLetter } from "../../../utils/general-utils";
// constants
import { ENG_COM_TEST_TYPE_TABLE_FOTMATTER } from "../../../config/constants";
// css
import "./single-item.css";

// compare list single item
export const CompareListSingleItem = ({
  testcaseInfo,
  testLines,
  isLast,
  selection,
  onClick,
}) => {
  if (!testcaseInfo || !testLines) {
    return null;
  }

  const { tcs_id, testCategory } = testcaseInfo;
  return (
    <div className="text-secondary ">
      <div>
        {`[${
          ENG_COM_TEST_TYPE_TABLE_FOTMATTER[testcaseInfo.testType]
        }] ${capitalizeFirstLetter(
          testcaseInfo.project
        )}_${testcaseInfo.build.toUpperCase()}, ${testcaseInfo.device}`}
      </div>
      <div>{`Tech: ${testcaseInfo.technology}, Mode: ${testcaseInfo.mode}, BW: ${testcaseInfo.bandwidth}, Ant: ${testcaseInfo.antenna}, Firmware: ${testcaseInfo.firmware}`}</div>
      {Object.keys(testLines).map((dataRate) => (
        <div key={`${testCategory}_${tcs_id}_${dataRate}`}>
          <div className="d-flex flex-row">
            <div className="delta-compare-single-item-data-rate-line"></div>
            <div>{`Data Rate: ${dataRate}`}</div>
          </div>
          {testLines[dataRate].map((eachLine) => {
            const key = `${testCategory}_${tcs_id}_${dataRate}_${eachLine}`;
            return (
              <div
                key={key}
                className={`delta-compare-single-item-test-line${
                  selection.some((item) => item.key === key)
                    ? " delta-compare-single-item-test-line-selected"
                    : ""
                }`}
                onClick={() =>
                  onClick({ ...testcaseInfo, key, dataRate, line: eachLine })
                }
              >
                {eachLine === "limit" ? "Limit Line" : `Set Power: ${eachLine}`}
              </div>
            );
          })}
        </div>
      ))}
      {!isLast && <hr />}
    </div>
  );
};

// history list single items
export const HistoryListSingleItem = ({
  historyItem,
  isLast,
  selection,
  onClick,
}) => {
  return (
    <div className="text-secondary ">
      <div>
        {`[${
          ENG_COM_TEST_TYPE_TABLE_FOTMATTER[historyItem.testType]
        }] ${capitalizeFirstLetter(
          historyItem.project
        )}_${historyItem.build.toUpperCase()}, ${historyItem.device}`}
      </div>
      <div>{`Tech: ${historyItem.technology}, Mode: ${historyItem.mode}, BW: ${historyItem.bandwidth}, Ant: ${historyItem.antenna}, Firmware: ${historyItem.firmware}`}</div>
      <div className="d-flex flex-row">
        <div className="delta-compare-single-item-data-rate-line"></div>
        <div>{`Data Rate: ${historyItem.dataRate}`}</div>
      </div>
      <div
        className={`delta-compare-single-item-test-line${
          selection.some((item) => item.key === historyItem.key)
            ? " delta-compare-single-item-test-line-selected"
            : ""
        }`}
        onClick={() => onClick(historyItem)}
      >
        {historyItem.line === "limit"
          ? "Limit Line"
          : `Set Power: ${historyItem.line}`}
      </div>
      {!isLast && <hr />}
    </div>
  );
};
