import { combineReducers } from "redux";
import sampleReducer from "./reducers/sample-reducer";
import uploadReducer from "./reducers/upload-reducer";
import landingReducer from "./reducers/landing-reducer";
import metadataReducer from "./reducers/metadata-reducer";
import overviewReducer from "./reducers/overview-reducer";
import authzReducer from "./reducers/authz-reducer";
import tcsResultReducer from "./reducers/tcs-result-reducer";
import testcaseReducer from "./reducers/testcase-reducer";
import categoryReducer from "./reducers/category-reducer";
import projectReducer from "./reducers/project-reducer";
import buildReducer from "./reducers/build-reducer";
import deltaCompareReducer from "./reducers/delta-compare-reducer";
import boxPlotReducer from "./reducers/box-plot-reducer";
import reportReducer from "./reducers/report-reducer";
import desenseConfigReducer from "./reducers/desense-config-reducer";
import rvrConfigReducer from "./reducers/rvr-config-reducer";
import batteryConfigReducer from "./reducers/battery-config-reducer";
import antennaConfigReducer from "./reducers/antenna-config-reducer";
import nonsigReducer from "./reducers/nonsig-config-reducer";

//reducer list
const reducers = {
  sampleReducer,
  uploadReducer,
  metadataReducer,
  overviewReducer,
  landingReducer,
  authzReducer,
  tcsResultReducer,
  testcaseReducer,
  categoryReducer,
  projectReducer,
  buildReducer,
  deltaCompareReducer,
  boxPlotReducer,
  reportReducer,
  desenseConfigReducer,
  rvrConfigReducer,
  batteryConfigReducer,
  antennaConfigReducer,
  nonsigReducer,
};

// combine all reducers to rootReducer
const rootReducer = combineReducers(reducers);

export default rootReducer;
