import React, { Component } from "react";
// redux
import { Dispatch } from "redux";
// directly use plotly
import { connect } from "react-redux";
import RvRRangeChart from "./rvr-range-chart/rvr-range-chart";
import RvRBaseChart from "./rvr-base-chart/rvr-base-chart";
import {awsRum} from "../../../index";

interface StateProps {
  rvrConfigReducer: any;
}

// declare prop check
type Props = {
  filterObject: Object;
  dispatch: Dispatch<any>;
} & typeof defaultProps &
  StateProps;

// declare init state & default props
const defaultProps = Object.freeze({});
const initialState = Object.freeze({
  mainPlotWidth: window.innerWidth,
  showRangeChart: true,
});

class RvRVisualization extends Component<Props> {
  readonly state = initialState;
  private plotRef: React.RefObject<HTMLInputElement>;

  constructor(props) {
    super(props);
    this.plotRef = React.createRef();
    awsRum?.recordEvent("rvr_visualization",{
      metricCategory: 'rvr_visualization'
    });
  }

  render() {
    return (
      <div className={"rvr-main-chart"} ref={this.plotRef}>
        {this.state.showRangeChart && <RvRRangeChart />}

        <RvRBaseChart />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    rvrConfigReducer: state.rvrConfigReducer,
  };
};

export default connect<StateProps>(mapStateToProps)(RvRVisualization);
