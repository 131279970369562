import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { Tabs } from "@amzn/awsui-components-react-v3";
import { getProjectBuildInfo } from "../../../redux/actions/metadata-action";
// child components
import NonsigTableChartContainer from "./tab-containers/nonsig-table-chart-container";
import ComparisonContainer from "./tab-containers/nonsig-comparison-container";
import BreadcrumbGroup from "../../../components/breadcrumb-group";
import NonSigDropdownList from "./dropdown/dropdown-list";

const NonSigTestcase = () => {
  const dispatch = useDispatch();

  const [activeTabId, setActiveTabId] = useState("testdata");

  useEffect(() => {
    dispatch(getProjectBuildInfo());
  }, [dispatch]);

  return (
    <>
      <BreadcrumbGroup
        textGroup={[
          { text: "Dashboard", href: "/" },
          { text: `NonSig Testcase View` }, // TestCategory
        ]}
      />

      <br />
      <NonSigDropdownList />
      <br />

      <Tabs
        onChange={({ detail }) => setActiveTabId(detail.activeTabId)}
        activeTabId={activeTabId}
        tabs={[
          {
            label: "Test Data",
            id: "testdata",
            content: <NonsigTableChartContainer />,
          },
          {
            label: "Comparison",
            id: "comparison",
            content: <ComparisonContainer />,
          },
        ]}
      />
    </>
  );
};
export default NonSigTestcase;
