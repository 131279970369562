import React, { Component } from "react";
import { Select } from "@amzn/awsui-components-react";
import styled from "styled-components";
import { ENG_COM_TEST_TYPE } from "../../../../config/constants";
import {
  switchTestTypeList,
  switchTestToolList,
  getSwitchLeftList,
  DELTA_COMPARE,
  BOX_PLOT,
  REPORT_HISTORY,
} from "./switch-config";
import { addRequestLabToLink } from "../../../../utils/general-utils";

const StyledSelectDiv1 = styled.a`
  min-width: 300px;
  margin-right: 50px;
`;

const StyledSelectDiv2 = styled.a`
  min-width: 300px;
`;

// declare prop check
type Props = {
  project: string;
  build: string;
  version: string;
  testCategory: string;
  testType: string;
  countryOrDevice: string;
  routerHistory: any;
  requestId?: number | undefined;
  labId?: number | undefined;
} & typeof defaultProps;

type State = typeof initialState;

// declare init state & default props
const defaultProps = Object.freeze({});

const initialState = Object.freeze({
  switchLeftList: [{ id: "", label: "" }],
  switchLeftCurrent: undefined as { id: string; label: string } | undefined,
});

class TestcaseSwitch extends Component<Props, State> {
  static readonly defaultProps = defaultProps;
  readonly state = initialState;

  componentDidUpdate(prevProps, prevState) {
    // get left switch list dynamically
    if (
      prevProps.project !== this.props.project ||
      prevProps.build !== this.props.build ||
      prevProps.version !== this.props.version ||
      prevProps.testCategory !== this.props.testCategory ||
      prevProps.testType !== this.props.testType
    ) {
      const {
        project,
        build,
        version,
        testCategory,
        testType,
        countryOrDevice,
      } = this.props;
      getSwitchLeftList(project, build, version, testCategory, testType).then(
        (rst) => {
          const switchLeftList = rst;
          const switchLeftCurrent = switchLeftList.find(
            (item) => item.id === (countryOrDevice ? countryOrDevice : "")
          );
          this.setState({
            switchLeftList,
            switchLeftCurrent,
          });
        }
      );
    }
  }

  _changeLeft = (e) => {
    const {
      project,
      build,
      version,
      testCategory,
      testType,
      requestId,
      labId,
    } = this.props;
    //url: put "country or device" based on testType
    const urlKey =
      testType === ENG_COM_TEST_TYPE.BAND_EDGE ||
      testType === ENG_COM_TEST_TYPE.HARMONICS
        ? "device"
        : "country";
    let link = `/${project}/${build}/${version}/${testCategory}/testcase?${urlKey}=${e.detail.selectedOption.id}&test_type=${testType}`;
    link = addRequestLabToLink(link, requestId, labId);
    this.props.routerHistory.push(link);
  };

  _changeRight = (e) => {
    const selectedId = e.detail.selectedId;
    const {
      project,
      build,
      version,
      testCategory,
      countryOrDevice,
      requestId,
      labId,
    } = this.props;

    // only routing when switch between power_table or passive_acitve_scan
    if (
      selectedId === ENG_COM_TEST_TYPE.POWER_TABLE ||
      selectedId === ENG_COM_TEST_TYPE.PASSIVE_SCAN ||
      selectedId === ENG_COM_TEST_TYPE.ACTIVE_SCAN
    ) {
      let link = `/${project}/${build}/${version}/${testCategory}/testcase?country=${countryOrDevice}&test_type=${e.detail.selectedOption.id}`;
      // if (requestId) {
      //   link = `${link}&request_id=${requestId}`;
      // }
      // if (labId) {
      //   link = `${link}&lab_id=${labId}`;
      // }
      link = addRequestLabToLink(link, requestId, labId);
      this.props.routerHistory.push(link);
    } else if (selectedId === DELTA_COMPARE) {
      this.props.routerHistory.push("/compliance/delta-compare");
    } else if (selectedId === BOX_PLOT) {
      this.props.routerHistory.push("/compliance/box-plot");
    } else if (selectedId === REPORT_HISTORY) {
      this.props.routerHistory.push("/reports/history");
    }
  };

  render() {
    let { testType } = this.props;
    const { switchLeftList, switchLeftCurrent } = this.state;

    const switchRightList =
      testType === ENG_COM_TEST_TYPE.BAND_EDGE ||
      testType === ENG_COM_TEST_TYPE.HARMONICS
        ? switchTestToolList
        : switchTestTypeList;

    let switchRightCurrent: any = null;

    if (
      testType === ENG_COM_TEST_TYPE.BAND_EDGE ||
      testType === ENG_COM_TEST_TYPE.HARMONICS
    ) {
      switchRightCurrent = switchRightList[0];
    }

    if (
      testType === ENG_COM_TEST_TYPE.POWER_TABLE ||
      testType === ENG_COM_TEST_TYPE.PASSIVE_SCAN ||
      testType === ENG_COM_TEST_TYPE.ACTIVE_SCAN
    ) {
      // passive scan and active scan are combined as one selection, use active scan as key to get the combination
      if (
        testType === ENG_COM_TEST_TYPE.PASSIVE_SCAN ||
        testType === ENG_COM_TEST_TYPE.ACTIVE_SCAN
      ) {
        testType = ENG_COM_TEST_TYPE.ACTIVE_SCAN;
      }

      switchRightCurrent = switchRightList.find(
        (item) => item.id === (testType ? testType : "")
      );
    }

    return (
      <div className="d-flex">
        <StyledSelectDiv1>
          <h4>
            Switch{" "}
            {testType === ENG_COM_TEST_TYPE.BAND_EDGE ||
            testType === ENG_COM_TEST_TYPE.HARMONICS
              ? "Device"
              : "Country"}
          </h4>
          <Select
            selectedLabel="Selected"
            options={switchLeftList}
            selectedOption={switchLeftCurrent}
            onChange={this._changeLeft}
            ariaRequired={true}
          />
        </StyledSelectDiv1>

        <StyledSelectDiv2>
          <h4>
            Switch{" "}
            {testType === ENG_COM_TEST_TYPE.BAND_EDGE ||
            testType === ENG_COM_TEST_TYPE.HARMONICS
              ? "Test Tool"
              : "Test Type"}
          </h4>
          <Select
            selectedLabel="Selected"
            options={switchRightList}
            selectedOption={switchRightCurrent}
            onChange={this._changeRight}
            ariaRequired={true}
          />
        </StyledSelectDiv2>
      </div>
    );
  }
}

export default TestcaseSwitch;
