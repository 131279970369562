import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Multiselect,
  Box,
  ColumnLayout,
  SpaceBetween,
} from "@amzn/awsui-components-react-v3";
import {
  mappedCoconutTestCategory,
  mappedTempList,
  mappedVoltageList,
  mappedCoconutAntennaList,
  getCoconutStandard,
  filterSubSelectOptions,
  getCoconutBandList,
  checkCoconutAntenna,
  checkCoconutNonSigOptions,
} from "./testcase-config";
import {
  setCanCoconutSubmit,
  setNonSigCoconutSelect,
} from "../../../redux/actions/build-action";

const ValueWithLabel = ({ label, children }) => (
  <div>
    <Box margin={{ bottom: "xxxs" }} color="text-label">
      {label}
    </Box>
    <div>{children}</div>
  </div>
);

const CoconutTestcaseManagement = () => {
  const dispatch = useDispatch();

  const [selectedTechOptions, setSelectedTechOptions] = useState<any[]>([]);

  const [selectedBandOptions, setSelectedBandOptions] = useState<any[]>([]);

  const [selectedStandard, setSelectedStandard] = useState<any[]>([]);

  const [selectedTempOptions, setSelectedTempOptions] = useState<any[]>([]);
  const [selectedVoltOptions, setSelectedVoltOptions] = useState<any[]>([]);

  const [selectedBtLoraAnt, setSelectedBtLoraAnt] = useState<any[]>([]);
  const [selectedWifiAnt, setSelectedWifiAnt] = useState<any[]>([]);

  useEffect(() => {
    // When component changed update object sent up to build form
    const updatedNonSigObj = {
      tech: selectedTechOptions.map((each) => each.value),
      band: selectedBandOptions.map((each) => each.value),
      standard: selectedStandard.map((each) => each.value),
      temp: selectedTempOptions.map((each) => each.value),
      volt: selectedVoltOptions.map((each) => each.value),
      bt_lora_ant: selectedBtLoraAnt.map((each) => each.value),
      wifi_ant: selectedWifiAnt.map((each) => each.value),
    };
    const canSubmit = checkCoconutNonSigOptions(updatedNonSigObj);

    dispatch(setCanCoconutSubmit(canSubmit));
    dispatch(setNonSigCoconutSelect(updatedNonSigObj));
  }, [
    selectedTechOptions,
    selectedBandOptions,
    selectedStandard,
    selectedTempOptions,
    selectedVoltOptions,
    selectedBtLoraAnt,
    selectedWifiAnt,
    dispatch,
  ]);

  const onChangeTestCategory = ({ detail }) => {
    setSelectedTechOptions(detail.selectedOptions as any);
    // currently select
    const currSelect = detail.selectedOptions;

    const currBandLeft = getCoconutBandList(currSelect);
    setSelectedBandOptions(currBandLeft);

    const currSubList = getCoconutStandard(currSelect, currBandLeft);
    let currSubSelect = [...selectedStandard];
    setSelectedStandard(
      filterSubSelectOptions(currSubSelect, currSubList, true)
    );
  };

  const onChangeBand = ({ detail }) => {
    setSelectedBandOptions(detail.selectedOptions as any);
    // currently select
    const currBand = detail.selectedOptions;

    const currSubList = getCoconutStandard(selectedTechOptions, currBand);
    let currSubSelect = [...selectedStandard];
    setSelectedStandard(
      filterSubSelectOptions(currSubSelect, currSubList, true)
    );
  };

  const { showAnt, showBtLoraAnt, showWifiAnt } =
    checkCoconutAntenna(selectedTechOptions);

  return (
    <div className={"testcase-management-main"}>
      <h4>Non-Litepoint</h4>

      <ColumnLayout columns={showAnt ? 4 : 3} variant="text-grid">
        <SpaceBetween size="l">
          <ValueWithLabel label="Technology Category">
            <Multiselect
              selectedOptions={selectedTechOptions}
              onChange={onChangeTestCategory}
              deselectAriaLabel={(e) => `Remove ${e.label}`}
              options={mappedCoconutTestCategory}
              placeholder="Choose options"
              filteringType="auto"
              selectedAriaLabel="Selected"
            />
          </ValueWithLabel>
          <ValueWithLabel label="Band Selection">
            <Multiselect
              selectedOptions={selectedBandOptions}
              onChange={onChangeBand}
              deselectAriaLabel={(e) => `Remove ${e.label}`}
              options={getCoconutBandList(selectedTechOptions)}
              placeholder="Choose options"
              filteringType="auto"
              selectedAriaLabel="Selected"
            />
          </ValueWithLabel>
        </SpaceBetween>
        <SpaceBetween size="l">
          <ValueWithLabel label="Standard">
            <Multiselect
              selectedOptions={selectedStandard}
              onChange={({ detail }) =>
                setSelectedStandard(detail.selectedOptions as any)
              }
              deselectAriaLabel={(e) => `Remove ${e.label}`}
              options={getCoconutStandard(
                selectedTechOptions,
                selectedBandOptions
              )}
              placeholder="Choose options"
              selectedAriaLabel="Selected"
              filteringType="auto"
            />
          </ValueWithLabel>
        </SpaceBetween>
        <SpaceBetween size="l">
          <ValueWithLabel label="Temperature">
            <Multiselect
              selectedOptions={selectedTempOptions}
              onChange={({ detail }) =>
                setSelectedTempOptions(detail.selectedOptions as any)
              }
              deselectAriaLabel={(e) => `Remove ${e.label}`}
              options={mappedTempList}
              placeholder="Choose options"
              selectedAriaLabel="Selected"
              filteringType="auto"
            />
          </ValueWithLabel>
          <ValueWithLabel label="Voltage">
            <Multiselect
              selectedOptions={selectedVoltOptions}
              onChange={({ detail }) =>
                setSelectedVoltOptions(detail.selectedOptions as any)
              }
              deselectAriaLabel={(e) => `Remove ${e.label}`}
              options={mappedVoltageList}
              placeholder="Choose options"
              selectedAriaLabel="Selected"
              filteringType="auto"
            />
          </ValueWithLabel>
        </SpaceBetween>
        {showAnt && (
          <SpaceBetween size="l">
            {showBtLoraAnt && (
              <ValueWithLabel label="BT & LoRa antenna selection">
                <Multiselect
                  selectedOptions={selectedBtLoraAnt}
                  onChange={({ detail }) =>
                    setSelectedBtLoraAnt(detail.selectedOptions as any)
                  }
                  deselectAriaLabel={(e) => `Remove ${e.label}`}
                  options={mappedCoconutAntennaList}
                  placeholder="Choose options"
                  selectedAriaLabel="Selected"
                  filteringType="auto"
                />
              </ValueWithLabel>
            )}
            {showWifiAnt && (
              <ValueWithLabel label="Wifi antenna selection">
                <Multiselect
                  selectedOptions={selectedWifiAnt}
                  onChange={({ detail }) =>
                    setSelectedWifiAnt(detail.selectedOptions as any)
                  }
                  deselectAriaLabel={(e) => `Remove ${e.label}`}
                  options={mappedCoconutAntennaList}
                  placeholder="Choose options"
                  selectedAriaLabel="Selected"
                  filteringType="auto"
                />
              </ValueWithLabel>
            )}
          </SpaceBetween>
        )}
      </ColumnLayout>
    </div>
  );
};

export default CoconutTestcaseManagement;
