import React from "react";
import { TEST_STATUS_COLOR } from "../../../../config/color-config";
import { ENG_COM_TEST_TYPE } from "../../../../config/constants";

export const defaultPageSize = 5;

// define columns
export const columnDefinitions = [
  {
    id: "technology",
    header: "Technology",
    label: (sortState) => {
      const isColumnSorted = sortState.sortingColumn === "technology";
      const ascending = !sortState.sortingDescending;
      return `Technology, ${
        isColumnSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => (item.technology ? item.technology.toUpperCase() : ""),
    minWidth: "100px",
    visible: true,
  },
  {
    id: "mode",
    header: "Mode",
    label: (sortState) => {
      const isColumnSorted = sortState.sortingColumn === "mode";
      const ascending = !sortState.sortingDescending;
      return `Mode, ${
        isColumnSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => item.mode,
    minWidth: "100px",
    visible: true,
  },
  {
    id: "bandwidth",
    header: "Bandwidth",
    label: (sortState) => {
      const isColumnSorted = sortState.sortingColumn === "bandwidth";
      const ascending = !sortState.sortingDescending;
      return `Bandwidth, ${
        isColumnSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => item.bandwidth,
    minWidth: "100px",
    visible: true,
  },
  {
    id: "antenna",
    header: "Antenna",
    label: (sortState) => {
      const columnIsSorted = sortState.sortingColumn === "antenna";
      const ascending = !sortState.sortingDescending;
      return `Antenna, ${
        columnIsSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => item.antenna,
    minWidth: "100px",
    visible: true,
  },
  {
    id: "firmware",
    header: "Firmware",
    label: (sortState) => {
      const isColumnSorted = sortState.sortingColumn === "firmware";
      const ascending = !sortState.sortingDescending;
      return `Firmware, ${
        isColumnSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => item.firmware,
    minWidth: "100px",
    visible: true,
  },
  {
    id: "test_type",
    header: "Test Type",
    label: (sortState) => {
      const isColumnSorted = sortState.sortingColumn === "test_type";
      const ascending = !sortState.sortingDescending;
      return `Test Type, ${
        isColumnSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => item.test_type,
    minWidth: "120px",
    visible: true,
  },
  {
    id: "timestamp",
    header: "Upload Time(local)",
    label: (sortState) => {
      const isColumnSorted = sortState.sortingColumn === "timestamp";
      const ascending = !sortState.sortingDescending;
      return `Upload Time, ${
        isColumnSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => item.timestamp,
    minWidth: "100px",
    visible: true,
  },
  {
    id: "status",
    header: "Status",
    label: (sortState) => {
      const isColumnSorted = sortState.sortingColumn === "status";
      const ascending = !sortState.sortingDescending;
      return `Status, ${
        isColumnSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => (
      <span
        style={{ color: TEST_STATUS_COLOR[item.status], cursor: "pointer" }}
      >{` ${item.status}`}</span>
    ),
    minWidth: "100px",
    visible: true,
  },
  {
    id: "tcsID",
    header: "TCS ID",
    label: (sortState) => {
      const isColumnSorted = sortState.sortingColumn === "tcsID";
      const ascending = !sortState.sortingDescending;
      return `TCS ID, ${
        isColumnSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => item.tcs_id,
    minWidth: "100px",
    visible: true,
  },
  {
    id: "id",
    header: "ID",
    label: (sortState) => {
      const isColumnSorted = sortState.sortingColumn === "id";
      const ascending = !sortState.sortingDescending;
      return `ID, ${
        isColumnSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => item.test_case_id,
    minWidth: "100px",
    visible: true,
  },
  {
    id: "s3",
    header: "S3 Log",
    label: (sortState) => {
      const isColumnSorted = sortState.sortingColumn === "s3";
      const ascending = !sortState.sortingDescending;
      return `S3 Log, ${
        isColumnSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => item.s3_log,
    minWidth: "100px",
    visible: true,
  },
  {
    id: "band",
    header: "Band",
    label: (sortState) => {
      const isColumnSorted = sortState.sortingColumn === "band";
      const ascending = !sortState.sortingDescending;
      return `Band, ${
        isColumnSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => formatBand(item.band),
    minWidth: "100px",
    visible: true,
  },
];

const formatBand = (band: number) => {
  let result: string = "";

  if (band === 2) {
    result = "2G";
  }
  if (band === 5) {
    result = "5G";
  }

  return result;
};

// define default sorting column
export const defaultSortingColumn = "timestamp";
export const defaultSortingDescending = true;

// define sortable columns
export const sortableColumns = [
  { id: "technology", field: "technology" },
  { id: "mode", field: "mode" },
  { id: "bandwidth", field: "bandwidth" },
  { id: "antenna", field: "antenna" },
  { id: "firmware", field: "firmware" },
  { id: "test_type", field: "test_type" },
  {
    id: "timestamp",
    field: "timestamp",
    comparator: (item1, item2) => {
      // if both timestamp and status equal, sort firmware.
      // Since this column default sorting by descending order, return an descending comparator
      if (
        item1.timestamp === item2.timestamp &&
        item1.status === item2.status &&
        typeof item1.firmware === "string" &&
        typeof item2.firmware === "string"
      ) {
        return item2.firmware.localeCompare(item1.firmware);
      }
      // if timestamp equals, sort status.
      if (
        item1.timestamp === item2.timestamp &&
        typeof item1.status === "string" &&
        typeof item2.status === "string"
      ) {
        return item2.status.localeCompare(item1.status);
      }
      if (
        typeof item1.timestamp === "string" &&
        typeof item2.timestamp === "string"
      ) {
        return item1.timestamp.localeCompare(item2.timestamp);
      }
      return 0;
    },
  },
  { id: "status", field: "status" },
  { id: "tcsID", field: "tcsID" },
  { id: "id", field: "id" },
  { id: "s3", field: "s3" },
  { id: "band", field: "band" },
];

// define visiable status for test type
let isTestTypeVisible: boolean = false;
export const setTestTypeVisibleStatus = (testType: string) => {
  isTestTypeVisible =
    testType === ENG_COM_TEST_TYPE.BAND_EDGE ||
    testType === ENG_COM_TEST_TYPE.HARMONICS ||
    testType === ENG_COM_TEST_TYPE.PASSIVE_SCAN ||
    testType === ENG_COM_TEST_TYPE.ACTIVE_SCAN;
};

// define visiable status for band
let isBandVisible: boolean = false;
export const setBandVisibleStatus = (testType: string) => {
  isBandVisible =
    testType === ENG_COM_TEST_TYPE.PASSIVE_SCAN ||
    testType === ENG_COM_TEST_TYPE.ACTIVE_SCAN;
};

// define visiable columns
export const getVisibleColumns = () => [
  {
    label: "Properties",
    options: [
      { id: "technology", label: "Technology", visible: true, editable: false },
      { id: "mode", label: "Mode", visible: true, editable: false },
      { id: "bandwidth", label: "Bandwidth", visible: true, editable: false },
      { id: "antenna", label: "Antenna", visible: true, editable: false },
      { id: "firmware", label: "Firmware", visible: true, editable: false },
      {
        id: "test_type",
        label: "Test Type",
        visible: isTestTypeVisible,
        editable: false,
      },
      { id: "band", label: "Band", visible: isBandVisible, editable: false },
      { id: "timestamp", label: "Upload Time", visible: true, editable: false },
      { id: "status", label: "Status", visible: true, editable: false },
      { id: "tcsID", label: "TCS ID", visible: false },
      { id: "id", label: "ID", visible: false },
      { id: "s3", label: "S3 Log", visible: true },
    ],
  },
];

// define filtering options
export const filteringOptions = [
  {
    groupValuesLabel: "Technology",
    propertyKey: "technology",
    propertyLabel: "Technology",
    values: [],
  },
  {
    groupValuesLabel: "Mode",
    propertyKey: "mode",
    propertyLabel: "Mode",
    values: [],
  },
  {
    groupValuesLabel: "Bandwidth",
    propertyKey: "bandwidth",
    propertyLabel: "Bandwidth",
    values: [],
  },
  {
    groupValuesLabel: "Antenna",
    propertyKey: "antenna",
    propertyLabel: "Antenna",
    values: [],
  },
  {
    groupValuesLabel: "Firmware",
    propertyKey: "firmware",
    propertyLabel: "Firmware",
    values: [],
  },
  {
    groupValuesLabel: "Test Type",
    propertyKey: "test_type",
    propertyLabel: "Test Type",
    values: [],
  },
  {
    groupValuesLabel: "Upload Time",
    propertyKey: "timestamp",
    propertyLabel: "Upload Time",
    values: [],
  },
  {
    groupValuesLabel: "Status",
    propertyKey: "status",
    propertyLabel: "Status",
    values: [],
  },
  {
    groupValuesLabel: "S3 Log",
    propertyKey: "s3",
    propertyLabel: "S3 Log",
    values: [],
  },
  {
    groupValuesLabel: "Band",
    propertyKey: "band",
    propertyLabel: "Band",
    values: [],
  },
];
