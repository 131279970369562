import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import querySearch from "query-string";
// redux
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { getTestcaseStatus } from "../../../redux/actions/testcase-action"; // redux action
// child components
import BreadcrumbGroup from "../../../components/breadcrumb-group";
import TestcasePlot from "../../visualization/compliance/compliance-plot";
import TestcaseTable from "./table/table";
import TestcaseSwitch from "./switch/switch";
// utils & constatns
import {
  addRequestLabToLink,
  capitalizeFirstLetter,
} from "../../../utils/general-utils";
import constants, {
  ENG_COM_TEST_TYPE,
  ENG_COM_TEST_TYPE_PLOT_FOTMATTER,
} from "../../../config/constants";
import { TestcaseStatusResponse } from "../../../model/http-json";

interface StateProps {
  testcaseReducer: any;
}

interface MatchParams {
  project: string;
  build: string;
  version: string;
  test_category: string;
}

interface QueryString {
  test_type?: string;
  country?: string;
  device?: string;
  request_id?: number;
  lab_id?: number;
}

// declare prop check
type Props = {
  dispatch: Dispatch<any>;
} & typeof defaultProps &
  RouteComponentProps<MatchParams> &
  StateProps &
  QueryString;

// declare init state & default props
const defaultProps = Object.freeze({});
const initialState = Object.freeze({
  project: "",
  build: "",
  version: "",
  test_category: "",
  test_type: "",
  country_or_device: "",
  tcs_id: -1,
  technology: "",
  mode: "",
  bandwidth: "",
  antenna: "",
  firmware: "",
  request_id: undefined,
  lab_id: undefined,
});

class ComplianceTestcase extends Component<Props> {
  readonly state = initialState;
  private plotRef: React.RefObject<HTMLInputElement>;

  constructor(props) {
    super(props);
    this.plotRef = React.createRef();
  }

  componentDidMount() {
    this._getAllData();
  }

  componentDidUpdate(prevProps, prevState) {
    // same component, but when the url changes caused by switch country/test_type/device
    if (
      prevProps.location.pathname !== this.props.location.pathname ||
      prevProps.location.search !== this.props.location.search
    ) {
      // re-fetch data
      this.setState({ tcs_id: -1 });
      this._getAllData();
    }
  }

  // get all test cases for table data
  _getAllData = () => {
    // get state data from url
    const { project, build, version, test_category } = this.props.match.params;
    let queryString: QueryString = {};
    if (this.props.location && this.props.location.search) {
      queryString = querySearch.parse(this.props.location.search);
    }
    const test_type = queryString.test_type ? queryString.test_type : "";
    let country_or_device = "";
    if (
      test_type === ENG_COM_TEST_TYPE.POWER_TABLE ||
      test_type === ENG_COM_TEST_TYPE.ACTIVE_SCAN ||
      test_type === ENG_COM_TEST_TYPE.PASSIVE_SCAN
    ) {
      country_or_device = queryString.country ? queryString.country : "";
    } else {
      country_or_device = queryString.device ? queryString.device : "";
    }
    const request_id = queryString.request_id
      ? queryString.request_id
      : undefined;
    const lab_id = queryString.lab_id ? queryString.lab_id : undefined;
    this.setState({
      project,
      build,
      version,
      test_category,
      test_type,
      country_or_device,
      request_id,
      lab_id,
    });
    // dynamically get test cases based on test_type
    if (test_type === ENG_COM_TEST_TYPE.POWER_TABLE) {
      this._getData(
        ENG_COM_TEST_TYPE.POWER_TABLE,
        country_or_device,
        request_id,
        lab_id
      );
    }
    if (
      test_type === ENG_COM_TEST_TYPE.ACTIVE_SCAN ||
      test_type === ENG_COM_TEST_TYPE.PASSIVE_SCAN
    ) {
      this._getData(
        ENG_COM_TEST_TYPE.ACTIVE_SCAN,
        country_or_device,
        request_id,
        lab_id
      );
      this._getData(
        ENG_COM_TEST_TYPE.PASSIVE_SCAN,
        country_or_device,
        request_id,
        lab_id
      );
    }
    if (
      test_type === ENG_COM_TEST_TYPE.BAND_EDGE ||
      test_type === ENG_COM_TEST_TYPE.HARMONICS
    ) {
      this._getData(
        ENG_COM_TEST_TYPE.BAND_EDGE,
        country_or_device,
        request_id,
        lab_id
      );
      this._getData(
        ENG_COM_TEST_TYPE.HARMONICS,
        country_or_device,
        request_id,
        lab_id
      );
    }
  };

  // get data for one type
  _getData = (
    testType: string,
    countryOrDevice: string,
    request_id: number | undefined,
    lab_id: number | undefined
  ) => {
    const { project, build, version, test_category } = this.props.match.params;
    this.props.dispatch(
      getTestcaseStatus(
        project,
        `${build}_${version}`,
        test_category,
        testType,
        countryOrDevice,
        request_id,
        lab_id
      )
    );
  };

  _scrollToPlotRef = () => {
    if (this.plotRef && this.plotRef.current) {
      window.scrollTo({
        top: this.plotRef.current.offsetTop,
        left: 0,
        behavior: "smooth",
      });
    }
  };
  _generatePlot = (e: any | undefined = undefined) => {
    // console.log(e);
    const tcs_id = e.detail.item.tcs_id ? e.detail.item.tcs_id : -1;
    const technology = e.detail.item.technology ? e.detail.item.technology : "";
    const mode = e.detail.item.technology ? e.detail.item.mode : "";
    const bandwidth = e.detail.item.technology ? e.detail.item.bandwidth : "";
    const antenna = e.detail.item.technology ? e.detail.item.antenna : "";
    const firmware = e.detail.item.technology ? e.detail.item.firmware : "";
    let test_type = e.detail.item.test_type
      ? ENG_COM_TEST_TYPE_PLOT_FOTMATTER[e.detail.item.test_type]
      : "";
    // console.log(tcs_id);
    this.setState({
      tcs_id,
      technology,
      mode,
      bandwidth,
      antenna,
      firmware,
      test_type,
    });

    // scroll to the plot
    this._scrollToPlotRef();
  };

  render() {
    const {
      project,
      build,
      version,
      test_category,
      test_type,
      country_or_device,
      tcs_id,
      technology,
      mode,
      bandwidth,
      antenna,
      firmware,
      request_id,
      lab_id,
    } = this.state;
    const { testcaseReducer } = this.props;
    // dynamically get data and loading status for table
    let testcaseData = [] as TestcaseStatusResponse;
    let loadingStatus = constants.LOADING_LOAD;
    if (test_type === ENG_COM_TEST_TYPE.POWER_TABLE) {
      testcaseData =
        testcaseReducer[`${ENG_COM_TEST_TYPE.POWER_TABLE}TestcaseData`];
      loadingStatus =
        testcaseReducer[`${ENG_COM_TEST_TYPE.POWER_TABLE}LoadingStatus`];
    }
    if (
      test_type === ENG_COM_TEST_TYPE.ACTIVE_SCAN ||
      test_type === ENG_COM_TEST_TYPE.PASSIVE_SCAN
    ) {
      testcaseData = [
        ...testcaseReducer[`${ENG_COM_TEST_TYPE.ACTIVE_SCAN}TestcaseData`],
        ...testcaseReducer[`${ENG_COM_TEST_TYPE.PASSIVE_SCAN}TestcaseData`],
      ];
      loadingStatus =
        testcaseReducer[`${ENG_COM_TEST_TYPE.ACTIVE_SCAN}LoadingStatus`] &
        testcaseReducer[`${ENG_COM_TEST_TYPE.PASSIVE_SCAN}LoadingStatus`];
    }
    if (
      test_type === ENG_COM_TEST_TYPE.BAND_EDGE ||
      test_type === ENG_COM_TEST_TYPE.HARMONICS
    ) {
      testcaseData = [
        ...testcaseReducer[`${ENG_COM_TEST_TYPE.BAND_EDGE}TestcaseData`],
        ...testcaseReducer[`${ENG_COM_TEST_TYPE.HARMONICS}TestcaseData`],
      ];
      loadingStatus =
        testcaseReducer[`${ENG_COM_TEST_TYPE.BAND_EDGE}LoadingStatus`] &
        testcaseReducer[`${ENG_COM_TEST_TYPE.HARMONICS}LoadingStatus`];
    }
    // console.log(this.state);
    return (
      <div>
        {/* path title */}
        <BreadcrumbGroup
          textGroup={[
            { text: capitalizeFirstLetter(project) }, // Project
            { text: `${build.toUpperCase()}_${version}` }, // BUILD_version
            { text: capitalizeFirstLetter(test_category) }, // TestCategory
            {
              text: "Overview",
              href: addRequestLabToLink(
                `/${project}/${build}/${version}/${test_category}/overview`,
                request_id,
                lab_id
              ),
            },
            {
              text: "Test Cases",
              href: addRequestLabToLink(
                `/${project}/${build}/${version}/${test_category}/testcase?${
                  test_type === ENG_COM_TEST_TYPE.POWER_TABLE ||
                  test_type === ENG_COM_TEST_TYPE.ACTIVE_SCAN ||
                  test_type === ENG_COM_TEST_TYPE.PASSIVE_SCAN
                    ? "country"
                    : "device"
                }=${country_or_device}&test_type=${test_type}`,
                request_id,
                lab_id
              ),
            },
          ]}
        />
        <br />
        {/* dynamically update based on test_type */}
        <TestcaseSwitch
          project={project}
          build={build}
          version={version}
          testCategory={test_category}
          testType={test_type}
          countryOrDevice={country_or_device}
          routerHistory={this.props.history}
          requestId={request_id}
          labId={lab_id}
        />
        <br />
        {/* dynamically update based on test_type */}
        <TestcaseTable
          testType={test_type}
          data={testcaseData}
          loadingStatus={loadingStatus}
          getData={this._getAllData}
          generatePlot={this._generatePlot}
        />
        <br />
        {/* dynamically update based on test_type */}
        {tcs_id !== -1 && (
          <div ref={this.plotRef} style={{ minHeight: "100vh" }}>
            <TestcasePlot
              testType={this.state.test_type ? this.state.test_type : test_type}
              tcsID={tcs_id}
              technology={technology}
              mode={mode}
              bandwidth={bandwidth}
              antenna={antenna}
              firmware={firmware}
            />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    testcaseReducer: state.testcaseReducer,
  };
};
export default withRouter(
  connect<StateProps>(mapStateToProps)(ComplianceTestcase)
);
