import React, { Component } from "react";
import {
  Table,
  Button,
  TablePropertyFiltering,
  TablePagination,
  TableSorting,
  TablePreferences,
  TablePageSizeSelector,
  TableWrapLines,
  TableContentSelector,
} from "@amzn/awsui-components-react/polaris";
// import { exportCSVFunc } from "@amzn/lens-react-ui";
// import constants from "../../../../config/constants";
import {
  defaultPageSize,
  columnDefinitions,
  defaultSortingColumn,
  defaultSortingDescending,
  sortableColumns,
  getVisibleColumns,
  filteringOptions,
} from "./table-config-active-antenna";
import { customizedFilteringFunction } from "../../../../components/polaris/table/property-filtering-utils";

// declare prop check
type Props = {
  data: Array<any>;
} & typeof defaultProps;

type State = typeof initialState;

// declare init state & default props
const defaultProps = Object.freeze({});

const initialState = Object.freeze({
  currentPageIndex: 1,
  pagesCount: 0,
});

class AntennaActiveTable extends Component<Props, State> {
  static readonly defaultProps = defaultProps;
  readonly state = initialState;

  _onPaginationChange = (event) => {
    const { currentPageIndex, pagesCount } = event.detail;
    this.setState({
      currentPageIndex,
      pagesCount,
    });
  };

  render() {
    const { data } = this.props;

    return (
      <Table
        // onRowClick={(e) => detailedinfo(e)}
        loadingText="Loading resources"
        columnDefinitions={columnDefinitions}
        // loading={loadingStatus === constants.LOADING_LOAD}
        items={data}
        header={
          <div className="awsui-util-action-stripe">
            <div className="awsui-util-action-stripe-title">
              <h2>
                Pseudo Active
                <span className="awsui-util-header-counter">{`(${data.length})`}</span>
              </h2>
            </div>
          </div>
        }
        empty={
          <div className="awsui-util-t-c">
            <div className="awsui-util-pt-s awsui-util-mb-xs">
              <b>No resources</b>
            </div>
            <p className="awsui-util-mb-s">No resources to display.</p>
          </div>
        }
        noMatch={
          <div className="awsui-util-t-c">
            <div className="awsui-util-pt-s awsui-util-mb-xs">
              <b>No matches</b>
            </div>
            <p className="awsui-util-mb-s">We can’t find a match.</p>
            <div className="awsui-util-mb-m">
              <Button>Clear filter</Button>
            </div>
          </div>
        }
      >
        <TablePropertyFiltering
          filteringOptions={filteringOptions}
          filteringFunction={customizedFilteringFunction}
          groupValuesText="Values"
          groupPropertiesText="Properties"
          operationAndText="and"
          operationNotAndText="and not"
          operationOrText="or"
          operationNotOrText="or not"
          clearFiltersText="Clear filter"
          placeholder="Filter resources by property or value"
          allowFreeTextFiltering={true}
          filteringCountTextFunction={(count) =>
            `${count} ${count === 1 ? "match" : "matches"}`
          }
        ></TablePropertyFiltering>
        <TablePagination
          pageSize={defaultPageSize}
          onPaginationChange={this._onPaginationChange}
        ></TablePagination>
        <TableSorting
          sortingColumn={defaultSortingColumn}
          sortingDescending={defaultSortingDescending}
          sortableColumns={sortableColumns}
        ></TableSorting>
        <TablePreferences
          title="Preferences"
          confirmLabel="Confirm"
          cancelLabel="Cancel"
        >
          <TablePageSizeSelector
            title="Page size"
            options={[
              { value: 5, label: "5 items" },
              { value: 10, label: "10 items" },
              { value: 50, label: "50 items" },
            ]}
          />
          <TableWrapLines
            label="Wrap lines"
            description="Enable to wrap table cell content, disable to truncate text."
          />
          <TableContentSelector
            title="Select visible columns"
            options={getVisibleColumns()}
          />
        </TablePreferences>
      </Table>
    );
  }
}

export default AntennaActiveTable;
