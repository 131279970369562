import projectType from "../types/project-type";
import constants from "../../config/constants";
import { capitalizeFirstLetter } from "../../utils/general-utils";

const initialState = {
  project: "", // project created
  projectList: [] as Array<string>,
  loadingStatus: constants.LOADING_LOAD,
};

const projectReducer = (state = initialState, action) => {
  switch (action.type) {
    case projectType.GET_PROJECT_LIST:
      return {
        ...state,
        loadingStatus: constants.LOADING_LOAD,
      };
    case projectType.GET_PROJECT_LIST_COMMIT:
      return {
        ...state,
        loadingStatus: constants.LOADING_SUCCESS,
        projectList: Array.isArray(action.payload)
          ? formatProjectList(action.payload as Array<string>)
          : [],
      };
    case projectType.GET_PROJECT_LIST_ROLLBACK:
      return {
        ...state,
        loadingStatus: constants.LOADING_FAIL,
      };
    case projectType.SET_PROJECT:
      return {
        ...state,
        project: action.project,
      };
    default:
      return state;
  }
};

const formatProjectList = (projectList: Array<string>): any => {
  projectList.sort()
  const newProjectList: Array<string> = [];
  projectList.forEach((project) => {
    newProjectList.push(capitalizeFirstLetter(project));
  });
  return newProjectList;
};

export default projectReducer;
