import { NONSIG_COMPARE_KEYS } from "./comparison-constants";

export const formatChartFromPreferences = (
  plotData,
  plotMetadata,
  preferences
) => {
  // Return variable containing filtered plot data
  const filteredSeries: Object[] = [];

  // Convert array to set for lookup speed
  const visibleSet = new Set(preferences["visibleContent"]);

  // Iterate through each series metadata & compute new formatted Legend Name for Series
  plotMetadata.forEach((item, index) => {
    // 1. Filter through and only include USL/LSL based on user preferences
    // - series names are programatically created in Upload Lambda
    // - only limit series will start with "usl"/"lsl"
    if (
      (plotData[index].name.toLowerCase().startsWith(NONSIG_COMPARE_KEYS.usl) &&
        !visibleSet.has(NONSIG_COMPARE_KEYS.usl)) ||
      (plotData[index].name.toLowerCase().startsWith(NONSIG_COMPARE_KEYS.lsl) &&
        !visibleSet.has(NONSIG_COMPARE_KEYS.lsl))
    ) {
      // ignore USL/LSL series due to user preference
      return;
    }

    // 2. Modify series name for legend based on user preferences
    const strBuilder: string[] = [];

    // - 2A. [project build - config dsn ]
    strBuilder.push("[");
    if (visibleSet.has(NONSIG_COMPARE_KEYS.project)) {
      strBuilder.push(item[NONSIG_COMPARE_KEYS.project]);
      strBuilder.push(" ");
    }
    if (visibleSet.has(NONSIG_COMPARE_KEYS.build)) {
      strBuilder.push(item[NONSIG_COMPARE_KEYS.build]);
      strBuilder.push(" ");
    }

    // only include a dash if Project or build was added
    // avoids [ - config dsn ]
    if (strBuilder.length > 1) {
      strBuilder.pop();
      strBuilder.push(" - ");
    }

    if (visibleSet.has(NONSIG_COMPARE_KEYS.config)) {
      strBuilder.push(item[NONSIG_COMPARE_KEYS.config]);
      strBuilder.push(" ");
    }
    if (visibleSet.has(NONSIG_COMPARE_KEYS.device)) {
      strBuilder.push(item[NONSIG_COMPARE_KEYS.device]);
      strBuilder.push(" ");
    }

    // Handle including/removing "]" based on if anything was added yet
    // avoids [] if PBV Config DSN not set by preferences
    if (strBuilder.length !== 1) {
      // something has been added, so keep brackets
      strBuilder.pop();
      strBuilder.push("]");
      strBuilder.push(" ");
    } else {
      // nothing added, remove opening bracket
      strBuilder.pop();
    }

    // 2B. Handle Datarate + Power
    // only add : if one if them has been aded
    // [ project build - config dsn ] datarate power:
    if (visibleSet.has(NONSIG_COMPARE_KEYS.dataRate)) {
      strBuilder.push(item[NONSIG_COMPARE_KEYS.dataRate]);
      strBuilder.push(" ");
    }
    if (visibleSet.has(NONSIG_COMPARE_KEYS.power)) {
      strBuilder.push(item[NONSIG_COMPARE_KEYS.power]);
      strBuilder.push(" ");
    }

    // If power or data rate added, include the colon
    if (
      visibleSet.has(NONSIG_COMPARE_KEYS.power) ||
      visibleSet.has(NONSIG_COMPARE_KEYS.dataRate)
    ) {
      strBuilder.pop(); // pop prior added space
      strBuilder.push(":");
      strBuilder.push(" ");
    }

    // 2C. Handle test specific attributes
    [
      NONSIG_COMPARE_KEYS.technology,
      NONSIG_COMPARE_KEYS.standard,
      NONSIG_COMPARE_KEYS.band,
      NONSIG_COMPARE_KEYS.bandwidth,
      NONSIG_COMPARE_KEYS.chain,
      NONSIG_COMPARE_KEYS.temperature,
      NONSIG_COMPARE_KEYS.voltage,
      NONSIG_COMPARE_KEYS.test_type,
      NONSIG_COMPARE_KEYS.test_item,
      NONSIG_COMPARE_KEYS.result,
    ].forEach((i) => {
      if (visibleSet.has(i)) {
        strBuilder.push(item[i]);
        strBuilder.push(" ");
      }
    });

    // 2D. Replace default plot name with formatted plot name
    filteredSeries.push({
      ...plotData[index],
      name: strBuilder.join("").trimEnd(),
    });
  });

  return filteredSeries;
};
