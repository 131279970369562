import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import ProjectForm from "./project-form";
import "./create-form.css";

interface StateProps {
  categoryReducer: any;
}

// declare prop check
type Props = {
  dispatch: Dispatch<any>;
} & StateProps &
  RouteComponentProps<any>;

class CreateProject extends Component<Props> {
  render() {
    return (
      <div className="create-form">
        <ProjectForm history={this.props.history} />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    categoryReducer: state.categoryReducer,
  };
};

export default withRouter(connect<StateProps>(mapStateToProps)(CreateProject));
