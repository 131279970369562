import React, { Component } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import styled from "styled-components";
import Dashboard from "../landing/dashboard";
import Overview from "../overview/overview";
import Upload from "../upload/upload";
import Testcase from "../testcase/testcase";
import NotFound from "../not-found";
import Analytics from "../analytics";
import ComplianceDeltaCompare from "../delta-compare/compliance-delta-compare";
import ComplianceBoxPlot from "../box-plot/compliance-box-plot/compliance-box-plot";
import CreateCategory from "../project-management/create/create-category";
import CreateProject from "../project-management/create/create-project";
import CreateBuild from "../project-management/create/create-build";
import EditBuild from "../project-management/edit/edit-build";
import ReportHistory from "../report-generation/report-history/report-history";

// declare prop check
type Props = typeof defaultProps;

// declare init state & default props
const defaultProps = Object.freeze({});

// styled component
const StyledDiv = styled.div`
  padding: 30px;
`;

class MainRouter extends Component<Props> {
  render() {
    return (
      <StyledDiv>
        <Switch>
          <Route path="/" exact component={Dashboard} />
          <Route
            path="/:project/:build/:version/:test_category/overview"
            exact
            component={Overview}
          />
          <Route
            path="/:project/:build/:version/:test_category/testcase"
            exact
            component={Testcase}
          />
          <Route path="/upload" exact component={Upload} />
          <Route path="/category/create" exact component={CreateCategory} />
          <Route path="/project/create" exact component={CreateProject} />
          <Route path="/build/create" exact component={CreateBuild} />
          <Route path="/build/edit" exact component={EditBuild} />
          <Route path="/analytics" exact component={Analytics} />
          <Route path="/reports/history" exact component={ReportHistory} />
          <Route
            path="/compliance/delta-compare"
            exact
            component={ComplianceDeltaCompare}
          />
          <Route
            path="/compliance/box-plot"
            exact
            component={ComplianceBoxPlot}
          />
          <Route path="/not-found" exact component={NotFound} />
          <Redirect to="/not-found" />
        </Switch>
      </StyledDiv>
    );
  }
}

export default MainRouter;
