import React, { Component } from "react";
// redux
import { Dispatch } from "redux";
// directly use plotly
import Plot from "react-plotly.js";
import { connect } from "react-redux";
import { getFormatRangeCalculateResult , getFormatOutdoorRangeCalculateResult} from "./range-calculator-utils";
import { Button, Icon } from "@amzn/awsui-components-react/polaris";
import { RANGE_TYPE_OUTDOOR , DEFAULT_TECHNOLOGY, RANGE_CONSTANT_TITLE } from "../../../../config/constants";
import "./range-chart-style.css";

interface StateProps {
  rvrConfigReducer: any;
}

// declare prop check
type Props = {
  filterObject: Object;
  dispatch: Dispatch<any>;
} & typeof defaultProps &
  StateProps;

// declare init state & default props
const defaultProps = Object.freeze({});
const initialState = Object.freeze({
  mainPlotWidth: window.innerWidth,
  xTitle: "range(m)",
  yTitle: "thoughput(Mbps)",
  xMin: null,
  xMax: null,
  yMin: null,
  yMax: null,
});

class RvRRangeChart extends Component<Props> {
  readonly state = initialState;
  private plotRef: React.RefObject<HTMLInputElement>;

  constructor(props) {
    super(props);
    this.plotRef = React.createRef();
  }

  componentDidMount() {
    // handle resize for the visualization
    const rvrChartNode = this.plotRef.current;
    if (rvrChartNode) {
      this.setState({
        mainPlotWidth: rvrChartNode.clientWidth,
      });
    }
    // handle init new value
  }

  updateWindowDimensions = () => {
    // const node = this.dashboardRightRef.current;
    const rvrChartNode = this.plotRef.current;
    if (rvrChartNode) {
      this.setState({
        mainPlotWidth: rvrChartNode.clientWidth,
      });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    // Add event listener to window resize event
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  _resetInput = () => {
    this.setState({
      xMin: null,
      xMax: null,
      yMin: null,
      yMax: null,
    });
  };

  render() {
    const { rangeCalculator, selectedData } = this.props.rvrConfigReducer;
    const { range } = this.props.rvrConfigReducer.selectedData[0] ? this.props.rvrConfigReducer.selectedData[0] : "";
    let title = RANGE_CONSTANT_TITLE
    if(range === RANGE_TYPE_OUTDOOR){
      title += " ( "
      title += rangeCalculator.selectedTechnology ? rangeCalculator.selectedTechnology.toUpperCase() :  DEFAULT_TECHNOLOGY.toUpperCase();
      title += " )";
    }
    const layout = {
      title: title,
      width: this.state.mainPlotWidth - 20,
      margin: {
        t: 25, //top margin
        b: 30, //bottom margin
      },
      yaxis: { title: this.state.yTitle },
      xaxis: {
        title: this.state.xTitle,
        rangemode: "tozero",
      },
      legend: {
        x: 1,
        xanchor: "right",
        y: 1,
      },
    };

    // const {selectedData} = this.props.rvrConfigReducer
    let formattedData;

    if(range === RANGE_TYPE_OUTDOOR){
      formattedData = getFormatOutdoorRangeCalculateResult(
        selectedData,
        rangeCalculator
      );
    }else{
      formattedData = getFormatRangeCalculateResult(
        selectedData,
        rangeCalculator
      );
    }
    return (
      <div className={"rvr-range-chart"} ref={this.plotRef}>
        <div className="awsui-util-container">
          <div>
            <Plot
              data={formattedData}
              layout={layout}
              config={{ displayModeBar: false }} // not showing mode bar
            />
          </div>
          <div className="awsui-util-container-footer rvr-range-footer">
            <span className="rvr-range-footer-text">
            Congestion Values:
              {
                range === RANGE_TYPE_OUTDOOR ? 
                rangeCalculator.selectedTechnology === DEFAULT_TECHNOLOGY?
                    " 5G: 25%"
                  : " singlefamily:44%, apartment:62%, townhouse:50%"
                : " singlefamily:44%, apartment:62%, townhouse:50%, 5G: 25%"
              }
              
            </span>
            <span className="rvr-range-footer-btn">
              <Button onClick={this._resetInput}>
                <Icon name="undo" size="normal" variant="normal" />
              </Button>
            </span>
            {/* current Hover point : {currentHover} */}
          </div>
          
        </div>

        {/* path title */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    rvrConfigReducer: state.rvrConfigReducer,
  };
};

export default connect<StateProps>(mapStateToProps)(RvRRangeChart);
