const boxPlotType = {
  // update dropdown selection
  UPDATE_SELECTION: "BOX_PLOT_UPDATE_SELECTION",
  // ajax action to get plot data
  GET_PLOT_DATA: "BOX_PLOT_GET_PLOT_DATA",
  GET_PLOT_DATA_COMMIT: "BOX_PLOT_GET_PLOT_DATA_COMMIT",
  GET_PLOT_DATA_ROLLBACK: "DBOX_PLOT_GET_PLOT_DATA_ROLLBACK",
};

export default boxPlotType;
