import React, { Component } from "react";
// redux
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { getMetadata } from "../../redux/actions/metadata-action"; // redux action
import Navbar from "../navbar/navbar";
import Sidebar from "./../sidebar/sidebar";
import MainRouter from "./main-router";
import { Footer } from "@amzn/lens-react-ui";
import styled from "styled-components";

interface StateProps {}
// declare prop check
type Props = {
  dispatch: Dispatch<any>;
} & typeof defaultProps &
  StateProps;

type State = typeof initialState;

// declare init state & default props
const defaultProps = Object.freeze({});

const initialState = Object.freeze({
  collapse: false, // sidebar collpase flag
});

const StyledMain = styled.div<{ collapse: boolean }>`
  margin-left: ${(props) => (props.collapse ? "50px" : "250px")};
  margin-top: 50px;
`;

const StyledMainRouter = styled.div`
  min-height: calc(100vh - 50px - 50px);
`;

class MainContent extends Component<Props, State> {
  static readonly defaultProps = defaultProps;
  readonly state = initialState;

  componentDidMount() {
    // get metadata
    this.props.dispatch(getMetadata());
  }

  // sidebar toggle button onClick func
  _onClickToggle = () => {
    this.setState({
      collapse: !this.state.collapse,
    });
  };

  render() {
    const { collapse } = this.state;
    return (
      <React.Fragment>
        <Navbar />
        <Sidebar collapse={collapse} onClick={this._onClickToggle} />
        <StyledMain collapse={collapse}>
          <StyledMainRouter>
            <MainRouter />
          </StyledMainRouter>
          <Footer />
        </StyledMain>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};
export default connect<StateProps>(mapStateToProps)(MainContent);
