import React, { Component } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import {
  ColumnLayout,
  Button,
  FormField,
  Input,
  ButtonDropdown,
  Alert,
} from "@amzn/awsui-components-react/polaris";
import { postData, Response } from "../../../utils/cognito-fetch";
import { config } from "../../../config/config.js";
import endpoints from "../../../config/endpoints";
import { getCategoryList } from "../../../redux/actions/category-action";
import { setCategory } from "../../../redux/actions/category-action";
import { setProject } from "../../../redux/actions/project-action";
import "./create-form.css";
import constants, {
  COMPONENT_ID,
  DROPDOWN_DEFAULT,
  METRIC_INFO,
} from "../../../config/constants";
import { getMetadata } from "../../../redux/actions/metadata-action";
import {
  capitalizeFirstLetter,
  getLogData,
} from "../../../utils/general-utils";
import { validateProject } from "../build-form-config";

interface StateProps {
  categoryReducer: any;
}

// declare prop check
type Props = {
  dispatch: Dispatch<any>;
  history: any;
} & StateProps;

type State = {
  category: string; // user input for creating project
  categoryError: string; // input error
  project: string; // user input for creating project
  projectError: string; // input error
  responseError: string; // response error from BE
  loading: number;
};

class ProjectForm extends Component<Props, State> {
  readonly state: State = Object.freeze({
    category: DROPDOWN_DEFAULT.CATEGORY,
    categoryError: "",
    project: "",
    projectError: "",
    responseError: "",
    // loading is only used for showing spinner when call BE, so set default as SUCCESS.
    loading: constants.LOADING_SUCCESS,
  });

  componentDidMount() {
    // load data for category dropdown
    this.props.dispatch(getCategoryList());

    // get category from create category
    const { category } = this.props.categoryReducer;

    if (category) {
      // prefill dropdown for category
      this.setState({ category });
    }
  }

  //validate input
  validate = (validateAll: boolean, id: string) => {
    const isCategoryValid: boolean = this.validateById(COMPONENT_ID.CATEGORY);
    const isProjectValid: boolean = this.validateById(COMPONENT_ID.PROJECT);
    return isCategoryValid && isProjectValid;
  };

  validateById = (id: string): boolean => {
    let isValid: boolean = true;

    // validate category
    if (id === COMPONENT_ID.CATEGORY) {
      let categoryError: string = "";
      if (this.state.category === DROPDOWN_DEFAULT.CATEGORY) {
        categoryError = "Required";
        isValid = false;
      }

      this.setState({
        categoryError,
      });
    }

    // validate project
    if (id === COMPONENT_ID.PROJECT) {
      const projectError: string = validateProject(this.state.project);
      this.setState({
        projectError,
      });

      if (projectError) {
        isValid = false;
      }
    }

    return isValid;
  };

  /* 
    onClick submit button
    1. validate user input, show input error if find any
    2. sent request to BE
    3. show response to user
      3.1 If failed, show response error
      3.2 If success, ask user choose to continue creating build or not
  */
  _onSubmit = () => {
    // clean up response error from last submit
    this.setState({ responseError: "" });

    const isValid = this.validate(true, "");
    if (!isValid) {
      return;
    }

    const category_name: string = this.state.category;
    const project: string = this.state.project;
    const values: {} = {
      category_name,
    };
    const url = config.BASE_URL + endpoints.createProjectEndpoint(project);

    this.setState({
      loading: constants.LOADING_LOAD,
    });

    postData(
      url,
      values,
      getLogData(METRIC_INFO.PROJECT, null, category_name, project)
    ).then((response: Response) => {
      // update side bar data
      this.props.dispatch(getMetadata());

      // update reducers
      this.props.dispatch(setCategory(capitalizeFirstLetter(category_name)));
      this.props.dispatch(setProject(capitalizeFirstLetter(project)));

      this.setState({
        loading: constants.LOADING_SUCCESS,
      });

      if (response.ok) {
        const isConfirmed = window.confirm(
          "Successfully created project. Continue creating a build?"
        );
        if (isConfirmed) {
          // navigate to create build
          this.props.history.push("/build/create");
        } else {
          // stay on create project, clear input
          this.clear();
        }
      } else {
        this.setState({
          responseError: response.json.message,
          loading: constants.LOADING_FAIL,
        });
      }
    });
  };

  clear = () => {
    this.setState({
      category: DROPDOWN_DEFAULT.CATEGORY,
      categoryError: "",
      project: "",
      projectError: "",
      responseError: "",
      loading: constants.LOADING_SUCCESS,
    });
  };

  _onChangeCategory = (event: any) => {
    const category: string = event.detail.id;
    this.setState({
      category,
      categoryError: "",
    });
  };

  _onChangeProject = (event: any) => {
    const project: string = event.detail.value;
    this.setState({
      project,
    });

    // validate user input
    const id: string = event.target.id;
    this.validate(false, id);
  };

  getItems = (categoryList: Array<string>): any => {
    const items: Array<ButtonDropdown.Item> = [];

    categoryList.forEach((category) => {
      const item: ButtonDropdown.Item = { id: category, text: category };
      items.push(item);
    });

    return items;
  };

  render() {
    const { categoryList } = this.props.categoryReducer;
    const items = this.getItems(categoryList);

    return (
      <div className="awsui-util-container">
        <div className="awsui-util-container-header">
          <h2>Create Project</h2>
        </div>
        <div>
          {/* main form portion */}
          <ColumnLayout>
            <div data-awsui-column-layout-root="true">
              <FormField label="Category" errorText={this.state.categoryError}>
                <ButtonDropdown
                  items={items}
                  onItemClick={this._onChangeCategory}
                  children={this.state.category}
                ></ButtonDropdown>
              </FormField>

              <FormField
                label="Project"
                hintText="Letters and numbers only"
                errorText={this.state.projectError}
              >
                <div className="create-form-input">
                  <Input
                    id={COMPONENT_ID.PROJECT}
                    placeholder="Enter project name"
                    value={this.state.project}
                    onChange={this._onChangeProject}
                  ></Input>
                </div>
              </FormField>
            </div>
          </ColumnLayout>
          <br />

          {this.state.responseError && (
            <React.Fragment>
              <Alert
                header="Errors Detected"
                type="error"
                content={this.state.responseError}
              ></Alert>
              <br />
            </React.Fragment>
          )}
          <br />
          <br />

          {/* action stripe group */}
          <div className="awsui-util-action-stripe-group">
            <Button onClick={this.clear}>Clear</Button>
            <Button
              variant="primary"
              onClick={this._onSubmit}
              loading={this.state.loading === constants.LOADING_LOAD}
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    categoryReducer: state.categoryReducer,
  };
};

export default connect<StateProps>(mapStateToProps)(ProjectForm);
