const nonsigConfigType = {
  // meaning dsh level
  GET_NONSIG_DEVICE_LIST: "GET_NONSIG_DEVICE_LIST",
  GET_NONSIG_DEVICE_LIST_COMMIT: "GET_NONSIG_DEVICE_LIST_COMMIT",
  GET_NONSIG_DEVICE_LIST_ROLLBACK: "GET_NONSIG_DEVICE_LIST_ROLLBACK",
  CLEAR_NONSIG_DEVICE_LIST: "CLEAR_NONSIG_DEVICE_LIST",

  // set user test format
  SET_TEST_FORMAT: "SET_TEST_FORMAT",
  CLEAR_TEST_FORMAT: "CLEAR_TEST_FORMAT",

  // GET TCS table data
  NONSIG_TCS_STATUS_RESULT: "NONSIG_TCS_STATUS_RESULT",
  NONSIG_TCS_STATUS_RESULT_COMMIT: "NONSIG_TCS_STATUS_RESULT_COMMIT",
  NONSIG_TCS_STATUS_RESULT_ROLLBACK: "NONSIG_TCS_STATUS_RESULT_ROLLBACK",

  // tcs main
  POST_NONSIG_TCS_RESULT: "POST_NONSIG_TCS_RESULT",
  POST_NONSIG_TCS_RESULT_COMMIT: "POST_NONSIG_TCS_RESULT_COMMIT",
  POST_NONSIG_TCS_RESULT_ROLLBACK: "POST_NONSIG_TCS_RESULT_ROLLBACK",

  // set user selection
  SET_TCS_SELECTION: "NONSIG_SET_TCS_SELECTION",

  // comparison chart features
  ADD_TEST_FOR_COMPARISON: "ADD_TEST_FOR_COMPARISON",
  REMOVE_TESTS_FOR_COMPARISON: "REMOVE_TESTS_FOR_COMPARISON",

  INIT_LOADING_STATUS: "INIT_LOADING_STATUS",
};

export default nonsigConfigType;
