import React from "react";
import { NavLink } from "react-router-dom";
import constants, {
  TEST_STATUS,
  ENG_COM_TEST_TYPE,
} from "../../../../config/constants";
import {
  TEST_STATUS_COLOR,
  TEST_STATUS_TEXT_COLOR,
} from "../../../../config/color-config";
import $ from "jquery";

const SingleProgress = (props) => {
  // init tooltip
  // https://getbootstrap.com/docs/4.5/components/tooltips/
  $(function () {
    $('[data-toggle="tooltip"]').tooltip();
  });

  const { text, href, testType, data, showPercentage, requestId, labId } =
    props;
  const { total } = data;
  // percentage dict for each test status
  const percentages = {
    [TEST_STATUS.UNTESTED]:
      total !== 0
        ? ((data[TEST_STATUS.UNTESTED] / total) * 100).toFixed(2)
        : "100.00",
    [TEST_STATUS.PASSED]:
      total !== 0 ? ((data[TEST_STATUS.PASSED] / total) * 100).toFixed(2) : "0",
    [TEST_STATUS.FAILED]:
      total !== 0 ? ((data[TEST_STATUS.FAILED] / total) * 100).toFixed(2) : "0",
    [TEST_STATUS.WARNING]:
      total !== 0
        ? ((data[TEST_STATUS.WARNING] / total) * 100).toFixed(2)
        : "0",
    [TEST_STATUS.NO_LIMIT]:
      total !== 0
        ? ((data[TEST_STATUS.NO_LIMIT] / total) * 100).toFixed(2)
        : "0",
  };

  return (
    <div className="d-flex align-items-center">
      {/* text */}
      <div style={{ width: "30%" }}>
        <NavLink
          to={`${href}?${
            testType === ENG_COM_TEST_TYPE.POWER_TABLE ||
            testType === ENG_COM_TEST_TYPE.ACTIVE_SCAN ||
            testType === ENG_COM_TEST_TYPE.PASSIVE_SCAN
              ? "country"
              : "device"
          }=${text}&test_type=${testType}${
            requestId === undefined ? "" : "&request_id=" + requestId
          }${labId === undefined ? "" : "&lab_id=" + labId}`} // add query string
        >
          {typeof text === "string" ? text.toUpperCase() : constants.NA}
        </NavLink>
        &nbsp; <span className="text-secondary">{`(${total})`}</span>
      </div>

      {/* progress bar */}
      <div className="progress" style={{ width: "70%", height: "20px" }}>
        {Object.keys(TEST_STATUS).map((key, index) => {
          const testStatus = TEST_STATUS[key];

          return (
            <div
              key={index}
              className="progress-bar"
              role="progressbar"
              style={{
                width: `${percentages[testStatus]}%`,
                backgroundColor: TEST_STATUS_COLOR[testStatus],
                color: TEST_STATUS_TEXT_COLOR[testStatus],
              }}
              aria-valuenow={25}
              aria-valuemin={0}
              aria-valuemax={100}
              data-toggle="tooltip"
              title={`${data[testStatus]} ${testStatus} (${percentages[testStatus]}%)`}
            >
              {showPercentage
                ? `${percentages[testStatus]}%`
                : data[testStatus]}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SingleProgress;
