import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
//polaris
import { Select } from "@amzn/awsui-components-react/polaris";
//redux
import { Dispatch } from "redux";
import { connect } from "react-redux";
// ajax
import { getData, postData } from "../../../../utils/cognito-fetch";
import { config } from "../../../../config/config.js";
import endpoint from "../../../../config/endpoints";
//style
import "./dropdown-list.css";
import constants, {
  TEST_CATEGORY,
  METRIC_INFO,
  DESENSE_SPLIT_BY,
  DESENSE_SHORT_SELECTION_KEY,
} from "../../../../config/constants";
import {
  capitalizeFirstLetter,
  getLogData,
} from "../../../../utils/general-utils";
import { formatTCSSelect } from "./dropdown-helper-func";
import {
  // selectedData add key-value for dropdown
  setUserSelectDict,
  // post to get data for dropdow selected
  getDesenseTCSList,
  PostTcsResult,
  getChannelPower,
  getCustomPower,
  // set band and tcs id in reducer
  setSelectedBand,
  setSelectedTCSID,
} from "../../../../redux/actions/desense-config-action";
import { getCustomInput } from "../utils/power-helper";

interface StateProps {
  // testcaseReducer: any;
  desenseConfigReducer: any;
}

interface indexProps {
  indexNumber: string;
}

interface MatchParams {
  project: string;
  build: string;
  version: string;
  test_category: string;
}

// declare prop check
type Props = {
  indexNumber: string;
  dispatch: Dispatch<any>;
  requestId: number;
  labId: number;
} & typeof defaultProps &
  RouteComponentProps<MatchParams> &
  StateProps &
  indexProps;

// declare init state & default props
const defaultProps = Object.freeze({});
const initialState = Object.freeze({
  projectOptions: [] as any[],
  // build-version
  buildOptions: [] as any[],
  // config_dsn and firmware
  deviceOptions: [] as any[],
  tcsOptions: [] as any[],
  // all projects
  project: "",
  // build_version
  buildCombine: "",
  // config_dsn + firmware
  device: "",
  // subsystem + antenna + band
  tcs: "",
  // loading
  projectLoadingStatus: constants.LOADING_SUCCESS,
  buildLoadingStatus: constants.LOADING_SUCCESS,
  deviceLoadingStatus: constants.LOADING_SUCCESS,
  tcsLoadingStatus: constants.LOADING_SUCCESS,
});

class DesenseDropdownList extends Component<Props> {
  readonly state = initialState;
  private dropdownRef: React.RefObject<HTMLInputElement>;

  constructor(props) {
    super(props);
    this.dropdownRef = React.createRef();
  }

  componentDidMount() {
    const { project, build, version } = this.props.match.params;
    const { indexNumber } = this.props;
    this.setState({
      project: project,
      buildCombine: `${build}_${version}`,
    });

    const { selectedData } = this.props.desenseConfigReducer;
    const currentObject = {
      project: project,
      buildCombine: `${build}_${version}`,
    };
    const updateSelected = { ...selectedData };
    updateSelected[indexNumber] = currentObject;

    this.props.dispatch(setUserSelectDict(updateSelected));

    this._getProjectOptions();

    this._getBuildOptions(currentObject.project);
    this._getDeviceOptions(currentObject.buildCombine);
  }

  componentDidUpdate(prevProps, prevState) {
    const { indexNumber } = this.props;
    const { test_category } = this.props.match.params;
    const { project, tcs } = this.state;

    if (this.state.project && this.state.project !== prevState.project) {
      //reload build option
      const currentProject = this.state.project;
      this._getBuildOptions(currentProject);
    }

    if (
      this.state.buildCombine &&
      this.state.buildCombine !== prevState.buildCombine
    ) {
      //reload device option
      const currentBuild = this.state.buildCombine;
      this._getDeviceOptions(currentBuild);
    }

    if (this.state.device && this.state.device !== prevState.device) {
      //reload device option
      this._getTCSOptions();
    }

    // the final check level, once select dirrectly send post
    if (tcs && tcs !== prevState.tcs) {
      //reload device option
      // 9_EMMC_ANT1_2G

      const splitedTcs = tcs.split(DESENSE_SPLIT_BY);
      const tcsId = parseInt(splitedTcs[0]);
      if (splitedTcs.length >= 4) {
        const requestJson = {
          test_case_key: indexNumber.toString(),
          tcs_id: splitedTcs[0],
          sub_system: splitedTcs[1],
          antenna: splitedTcs[2],
          band: splitedTcs[3],
        };

        this.props.dispatch(
          PostTcsResult(project, test_category, tcsId, requestJson)
        );

        const desenseReducer = this.props.desenseConfigReducer;
        const testcases = [
          {
            testcase_key: indexNumber.toString(),
            // TODO: update tcsId
            tcs_id: splitedTcs[0],
            sub_system: splitedTcs[1],
            antenna: splitedTcs[2],
            band: splitedTcs[3],
          },
        ];

        // generate channel power
        if (
          desenseReducer.band &&
          desenseReducer.limit &&
          desenseReducer.bandwidthChannel &&
          desenseReducer.technology
        ) {
          const requestJsonChannelPower = {
            testcases,
            band: desenseReducer.band,
            limit: parseFloat(desenseReducer.limit),
            bandwidth: parseFloat(desenseReducer.bandwidthChannel),
            technology: desenseReducer.technology,
          };

          this.props.dispatch(
            getChannelPower(TEST_CATEGORY.DESENSE, requestJsonChannelPower)
          );
        }

        // get custom power
        if (
          desenseReducer.selectedData &&
          desenseReducer.freq &&
          desenseReducer.bandwidthCustom
        ) {
          const custom_input = getCustomInput(
            desenseReducer.selectedData,
            desenseReducer.customChannelList,
            desenseReducer.customFreqList,
            desenseReducer.customBandwidthList
          );
          // no custom freq and bandwidth added
          if (Object.keys(custom_input).length === 0) {
            return;
          }

          if (desenseReducer.limit && desenseReducer.technology) {
            const requestJsonCustomPower: {} = {
              testcases,
              limit: parseFloat(desenseReducer.limit),
              technology: desenseReducer.technology,
              custom_input,
            };
            this.props.dispatch(
              getCustomPower(TEST_CATEGORY.DESENSE, requestJsonCustomPower)
            );
          }
        }
      }
    }
  }

  // this page may completely change logic
  // Ajax call for Project options
  _getProjectOptions = () => {
    // change load flag
    this.setState({
      projectLoadingStatus: constants.LOADING_LOAD,
    });

    // ajax call
    getData(
      config.BASE_URL + endpoint.getProjectListWithoutCategoryEndpoint(),
      getLogData(
        METRIC_INFO.DESENSE_TESTCASE,
        METRIC_INFO[TEST_CATEGORY.DESENSE]
      )
    )
      .then((response) => {
        const projectOptions = response.json.map((item) => ({
          label: capitalizeFirstLetter(item),
          id: item,
        }));
        this.setState({
          projectOptions,
          projectLoadingStatus: constants.LOADING_SUCCESS,
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          projectLoadingStatus: constants.LOADING_FAIL,
        });
      });
  };

  // Ajax call for build options
  _getBuildOptions = (projectName: string) => {
    // change load flag
    const { indexNumber } = this.props;
    this.setState({
      buildLoadingStatus: constants.LOADING_LOAD,
    });
    const project =
      projectName !== "null"
        ? projectName
        : this.props.desenseConfigReducer.selectedData[indexNumber].project;
    // ajax call
    getData(
      config.BASE_URL + endpoint.getBuildList(project),
      getLogData(
        METRIC_INFO.DESENSE_TESTCASE,
        METRIC_INFO[TEST_CATEGORY.DESENSE]
      )
    )
      .then((response) => {
        const buildOptions = response.json.map((item) => ({
          label: capitalizeFirstLetter(item),
          id: item,
        }));
        this.setState({
          buildLoadingStatus: constants.LOADING_SUCCESS,
          buildOptions,
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          buildLoadingStatus: constants.LOADING_FAIL,
        });
      });
  };

  _getDeviceOptions = (buildName: string) => {
    // change load flag
    const { indexNumber, requestId, labId } = this.props;
    this.setState({
      deviceLoadingStatus: constants.LOADING_LOAD,
    });
    const { selectedData } = this.props.desenseConfigReducer;
    const build =
      buildName !== null ? buildName : selectedData[indexNumber].buildCombine;
    let buildArray = [] as Array<string>;
    if (build.includes(DESENSE_SPLIT_BY)) {
      buildArray = build.split(DESENSE_SPLIT_BY);
    }
    const requestJson = {
      project: selectedData[indexNumber].project,
      build: buildArray[0],
      version: buildArray[1],
      requestId: requestId,
      labId: labId,
    };
    // ajax call
    if (buildArray.length === 2) {
      // TODO FIX ENDPOINT
      postData(
        config.BASE_URL + endpoint.getDesenseDeviceListEndpoint(),
        requestJson,
        getLogData(
          METRIC_INFO.DESENSE_TESTCASE,
          METRIC_INFO[TEST_CATEGORY.DESENSE]
        )
      )
        .then((response) => {
          const deviceOptions = response.json.map((item) => ({
            label: capitalizeFirstLetter(item),
            id: item,
          }));
          this.setState({
            deviceLoadingStatus: constants.LOADING_SUCCESS,
            deviceOptions,
          });
        })
        .catch((error) => {
          console.log(error);
          this.setState({
            deviceLoadingStatus: constants.LOADING_FAIL,
          });
        });
    }
  };

  _getTCSOptions = () => {
    // change load flag
    this.setState({
      deviceLoadingStatus: constants.LOADING_LOAD,
    });

    const { requestId, labId } = this.props;
    const { project, buildCombine, device } = this.state;

    let requestBuild = "";
    let requestVersion = "";
    let requestConfigDsn = "";
    let requestfirmware = "";

    if (buildCombine && buildCombine.includes(DESENSE_SPLIT_BY)) {
      const buildLvlArray = buildCombine.split(DESENSE_SPLIT_BY);
      if (buildLvlArray.length >= 2) {
        requestBuild = buildLvlArray[0];
        requestVersion = buildLvlArray[1];
      }
    }
    if (device && device.includes(DESENSE_SPLIT_BY)) {
      const deviceLvlArray = device.split(DESENSE_SPLIT_BY);
      if (deviceLvlArray.length >= 3) {
        requestConfigDsn = `${deviceLvlArray[0]}_${deviceLvlArray[1]}`;
        requestfirmware = deviceLvlArray[2];
      }
    }
    const requestJson = {
      project: project,
      build: requestBuild,
      version: requestVersion,
      device: requestConfigDsn,
      firmware: requestfirmware,
      requestId: requestId,
      labId: labId,
    };
    this.props.dispatch(getDesenseTCSList(requestJson));
  };

  //DYNAMIC id for setState
  _dataOnChange = (event: any) => {
    // event.detail {selectedId: "test", selectedOption: {…}}
    const currentValue: string = event.detail.selectedOption.id;
    const key = event.target.id;

    // TODO for TCS data, saved in reducer not combine with subsys
    // dynamic add state
    this.setState({
      [key]: currentValue,
    });

    this._setSelected(key, currentValue);

    // check & clear key
    if (key === DESENSE_SHORT_SELECTION_KEY.PROJECT) {
      this._clearSelection(DESENSE_SHORT_SELECTION_KEY.BUILD);
      this._clearSelection(DESENSE_SHORT_SELECTION_KEY.DEVICE);
      this._clearSelection(DESENSE_SHORT_SELECTION_KEY.TCS);
    }
    if (key === DESENSE_SHORT_SELECTION_KEY.BUILD) {
      this._clearSelection(DESENSE_SHORT_SELECTION_KEY.DEVICE);
      this._clearSelection(DESENSE_SHORT_SELECTION_KEY.TCS);
    }
    if (key === DESENSE_SHORT_SELECTION_KEY.DEVICE) {
      this._clearSelection(DESENSE_SHORT_SELECTION_KEY.TCS);
    }
  };

  // set in reducer
  _setSelected = (key: any, currentValue: string) => {
    const { selectedData } = this.props.desenseConfigReducer;
    const { indexNumber } = this.props;
    const updateSelected = { ...selectedData };
    updateSelected[indexNumber][key] = currentValue;
    // tcs here
    if (key === DESENSE_SHORT_SELECTION_KEY.TCS) {
      const currentTCSId = currentValue.split(DESENSE_SPLIT_BY)[0];
      const currentBand =
        currentValue.split(DESENSE_SPLIT_BY)[
          currentValue.split(DESENSE_SPLIT_BY).length - 1
        ];
      this.props.dispatch(setSelectedTCSID(parseInt(currentTCSId)));
      this.props.dispatch(setSelectedBand(currentBand));
    }
    // updateSelection(event.target.id)
    this.props.dispatch(setUserSelectDict(updateSelected));
  };

  _clearSelection = (key: string) => {
    // clear selections
    this.setState({
      [key]: null,
    });
  };

  onClickRemoveDropdown = () => {
    const { selectedData } = this.props.desenseConfigReducer;
    const { indexNumber } = this.props;

    const currentTestCastObject = { ...selectedData };
    if (Object.keys(currentTestCastObject).length >= 2) {
      delete currentTestCastObject[indexNumber];
    } else {
      window.alert("The only left one cannot be removed");
    }

    this.props.dispatch(setUserSelectDict(currentTestCastObject));
  };

  render() {
    const {
      projectOptions,
      buildOptions,
      deviceOptions,
      // loading status
      projectLoadingStatus,
      buildLoadingStatus,
      deviceLoadingStatus,
      // selected value
      project,
      buildCombine,
      device,
      tcs,
    } = this.state;
    const { indexNumber, requestId, labId } = this.props;
    const { desenseTCSList, tcsListLoadingStatus } =
      this.props.desenseConfigReducer;
    // map desense
    const desenseTcsOptions = desenseTCSList.map((item) => ({
      label: formatTCSSelect(item),
      id: item,
    }));
    // check value changes
    return (
      <div>
        <div className={"testcase-dropdown-list"} ref={this.dropdownRef}>
          {/* project */}
          <div className={"testcase-dropdown-element"}>
            <Select
              id={"project"}
              empty="No options"
              placeholder="Project"
              selectedLabel="Selected"
              options={projectOptions}
              statusType={
                projectLoadingStatus === constants.LOADING_LOAD
                  ? "loading"
                  : null
              }
              selectedOption={
                project
                  ? { id: project, label: capitalizeFirstLetter(project) }
                  : null
              }
              disabled={requestId && labId ? true : false}
              onChange={this._dataOnChange}
              filteringType="auto"
            ></Select>
          </div>

          {/* build_version */}
          <div className={"testcase-dropdown-element"}>
            <Select
              id={"buildCombine"}
              selectedLabel="Selected"
              empty="No options"
              placeholder="Build"
              options={buildOptions}
              statusType={
                buildLoadingStatus === constants.LOADING_LOAD ? "loading" : null
              }
              selectedOption={
                buildCombine
                  ? {
                      id: buildCombine,
                      label: capitalizeFirstLetter(buildCombine),
                    }
                  : null
              }
              disabled={requestId && labId ? true : false}
              onChange={this._dataOnChange}
              filteringType="auto"
            ></Select>
          </div>
          {/* config_dsn */}
          <div className={"testcase-dropdown-element"}>
            <Select
              id={"device"}
              selectedLabel="Selected"
              empty="No options"
              placeholder="Config_dsn_firmware"
              options={deviceOptions}
              statusType={
                deviceLoadingStatus === constants.LOADING_LOAD
                  ? "loading"
                  : null
              }
              selectedOption={
                device
                  ? { id: device, label: capitalizeFirstLetter(device) }
                  : null
              }
              onChange={this._dataOnChange}
              filteringType="auto"
            ></Select>
          </div>

          {/* TCS */}
          <div className={"testcase-dropdown-element"}>
            <Select
              id={"tcs"}
              selectedLabel="Selected"
              empty="No options"
              placeholder="subSystem_antenna_band"
              options={desenseTcsOptions}
              statusType={
                tcsListLoadingStatus === constants.LOADING_LOAD
                  ? "loading"
                  : null
              }
              selectedOption={
                tcs ? { id: tcs, label: formatTCSSelect(tcs) } : null
              }
              onChange={this._dataOnChange}
              filteringType="auto"
            ></Select>
          </div>

          {/* remove index btn */}
          {indexNumber !== "0" && (
            <button
              className={"icon-btn "}
              onClick={this.onClickRemoveDropdown}
            >
              <i className="fas fa-times fa-lg"></i>
            </button>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    desenseConfigReducer: state.desenseConfigReducer,
  };
};

export default withRouter(
  connect<StateProps>(mapStateToProps)(DesenseDropdownList)
);
