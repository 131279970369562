import React, { Component } from "react";
import { connect } from "react-redux";
import constants from "../../../../config/constants";
import { ContainerFail } from "../../../../components/container-status-indicator";
import {
  powerTableBandEdgeHarmonicsPlotCardDefinition,
  PassiveScanPlotCardDefinition,
  ActiveScanPlotCardDefinition,
  cardsPaginationOptions,
  cardsPerRow,
  getFilterCounterText,
  cardFeatures,
  filterFields,
} from "./card-config";
import {
  Cards,
  CardsFiltering,
  CardsPageSizeSelector,
  CardsPagination,
  CardsPreferences,
  // CardsSelection,
} from "@amzn/awsui-components-react/polaris";
import {
  FilterNoMatchState,
  EmptyContentState,
} from "../../../../components/polaris/common-components";
import { powerTalbePlotlyFormat } from "../plot-data/format-power-table-data";
import { bandedgeHarmonicsPlotlyFormat } from "../plot-data/format-bandedge-harmonics-data";
import { passiveScanPlotlyFormat } from "../plot-data/format-passive-scan-data";
import { activeScanPlotlyFormat } from "../plot-data/format-active-scan-data";
import { ENG_COM_TEST_TYPE } from "../../../../config/constants";

interface StateProps {
  tcsResultReducer: any;
}
// declare prop check
type Props = {
  header: string;
  testType: string;
} & typeof defaultProps &
  StateProps;
type State = typeof initialState;

// declare init state & default props
const defaultProps = Object.freeze({});

const initialState = Object.freeze({
  selectedPlots: [],
  filteringText: "",
  pageSize: 2,
});
class PlotsCards extends Component<Props, State> {
  static readonly defaultProps = defaultProps;
  readonly state = initialState;

  _onClearFilter() {
    this.setState({ filteringText: "" });
  }

  _onFilteringChange = ({ detail }) => {
    this.setState({ filteringText: detail.filteringText });
  };

  _onPaginationChange = ({ detail }) => {
    this.setState({ pageSize: detail.pageSize });
  };

  _onSelectionChange = ({ detail }) => {
    // console.log(detail);
    this.setState({ selectedPlots: detail.selectedItems });
  };

  render() {
    const { header, testType } = this.props;

    let { tcsResultLoadingStatus, tcsResultData } = this.props.tcsResultReducer;
    let emptyContent: any = null;
    if (tcsResultLoadingStatus !== constants.LOADING_LOAD) {
      if (tcsResultLoadingStatus === constants.LOADING_FAIL) {
        emptyContent = <ContainerFail />;
      } else if (Object.keys(tcsResultData).length === 0) {
        emptyContent = <EmptyContentState />;
      }
    }
    let data: any = null;
    let plotCardDefinition: any = null;
    if (testType === ENG_COM_TEST_TYPE.POWER_TABLE) {
      data = powerTalbePlotlyFormat(tcsResultData);
      plotCardDefinition = powerTableBandEdgeHarmonicsPlotCardDefinition;
    }
    if (
      testType === ENG_COM_TEST_TYPE.BAND_EDGE ||
      testType === ENG_COM_TEST_TYPE.HARMONICS
    ) {
      data = bandedgeHarmonicsPlotlyFormat(tcsResultData);
      plotCardDefinition = powerTableBandEdgeHarmonicsPlotCardDefinition;
    }
    if (testType === ENG_COM_TEST_TYPE.PASSIVE_SCAN) {
      data = passiveScanPlotlyFormat(tcsResultData);
      plotCardDefinition = PassiveScanPlotCardDefinition;
    }
    if (testType === ENG_COM_TEST_TYPE.ACTIVE_SCAN) {
      data = activeScanPlotlyFormat(tcsResultData);
      plotCardDefinition = ActiveScanPlotCardDefinition;
    }
    return (
      <Cards
        id="plots"
        cardDefinition={plotCardDefinition}
        items={data}
        cardsPerRow={cardsPerRow}
        features={cardFeatures}
        loading={tcsResultLoadingStatus === constants.LOADING_LOAD}
        loadingText="Loading resources"
        header={
          <div className="awsui-util-action-stripe">
            <div className="awsui-util-action-stripe-title">
              <h2>
                {header} &nbsp;
                {this.state.selectedPlots.length > 0 ? (
                  <span className="awsui-util-header-counter">{`(${this.state.selectedPlots.length}/${data.length})`}</span>
                ) : (
                  <span className="awsui-util-header-counter">{`(${data.length})`}</span>
                )}
              </h2>
            </div>
            <div className="awsui-util-action-stripe-group">
              {/* <ExportPDF
                  domId="power-table-plots" // map to the dom id which you want to export
                  filename="power_table_verification"
                  button={Button}
                  buttonProps={{ icon: "download", variant: "primary" }}
                >
                  Export
                </ExportPDF> */}
            </div>
          </div>
        }
        empty={emptyContent}
        noMatch={<FilterNoMatchState />}
        // onContentSelectionChange={this.onContentSelectionChange}
        stickyHeader={true}
      >
        <CardsFiltering
          filteringFields={filterFields}
          filteringPlaceholder="Find plots"
          filteringText={this.state.filteringText}
          onFilteringChange={this._onFilteringChange}
          filteringCountTextFunction={getFilterCounterText}
        />
        <CardsPagination
          onPaginationChange={this._onPaginationChange}
          pageSize={this.state.pageSize}
        />
        {/* <CardsSelection
          onSelectionChange={this._onSelectionChange}
          selectedItems={this.state.selectedPlots}
          trackBy="title"
        /> */}
        <CardsPreferences
          title="Preferences"
          confirmLabel="Confirm"
          cancelLabel="Cancel"
        >
          <CardsPageSizeSelector
            title="Page size"
            options={cardsPaginationOptions}
          />
        </CardsPreferences>
      </Cards>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    tcsResultReducer: state.tcsResultReducer,
  };
};
export default connect<StateProps>(mapStateToProps)(PlotsCards);
