import { ReportHistoryRow } from "../../model/report";
import { ReportHistoryResponse } from "../../model/http-json";
import constants, {
  STATUS_TEXT,
  REPORT_TYPE_TEXT,
} from "../../config/constants";
import reportType from "../types/report-type";
import { changeDateFormat } from "../../utils/date-utils";

const initialState = {
  reportHistoryData: [] as ReportHistoryResponse,
  reportHistoryStatus: constants.LOADING_LOAD,
};
const reportReducer = (state = initialState, action) => {
  switch (action.type) {
    case reportType.GET_REPORT_STATUS:
      if (action.syncMode) {
        return state;
      }
      return {
        ...state,
        reportHistoryStatus: constants.LOADING_LOAD,
      };
    case reportType.GET_REPORT_STATUS_COMMIT:
      return {
        ...state,
        reportHistoryStatus: constants.LOADING_SUCCESS,
        reportHistoryData: Array.isArray(action.payload)
          ? formatReportHistoryData(action.payload as ReportHistoryResponse)
          : [],
      };
    case reportType.GET_REPORT_STATUS_ROLLBACK:
      return {
        ...state,
        reportHistoryStatus: constants.LOADING_FAIL,
      };
    default:
      return state;
  }
};

export default reportReducer;

const formatReportHistoryData = (
  data: Array<ReportHistoryRow>
): Array<ReportHistoryRow> => {
  data.forEach((item) => {
    item.created_time = changeDateFormat(item.created_time);
    item.type = REPORT_TYPE_TEXT[item.type];
    item.status = STATUS_TEXT[item.status];
  });
  return data;
};
