import metadataType from "../types/metadata-type";
import { config } from "../../config/config";
import endpoints from "../../config/endpoints";
import { METRIC_INFO } from "../../config/constants";

export const getMetadata = () => ({
  type: metadataType.GET_METADATA,
  http: {
    cognito: {
      effect: {
        //simple local url here will change it later
        url: config.BASE_URL + endpoints.metadataEndpoint(),
        method: "GET",
      },
      commit: {
        type: metadataType.GET_METADATA_COMMIT,
        log: {
          initiative: METRIC_INFO.INITIATIVE,
          feature: METRIC_INFO.META_DATA,
        },
      },
      rollback: {
        type: metadataType.GET_METADATA_ROLLBACK,
        log: {
          initiative: METRIC_INFO.INITIATIVE,
          feature: METRIC_INFO.META_DATA,
        },
      },
    },
  },
});

export const getProjectBuildInfo = () => ({
  type: metadataType.PROJECT_BUILD_INFO_CALL,
  http: {
    cognito: {
      effect: {
        //simple local url here will change it later
        url: config.BASE_URL + endpoints.getProjectBuildInfo(),
        method: "GET",
        // json: { username: username, bindle_type: bindleType },
      },
      commit: {
        type: metadataType.PROJECT_BUILD_INFO_CALL_COMMIT,
        log: {
          initiative: METRIC_INFO.INITIATIVE,
          feature: METRIC_INFO.PROJECT_BUILD_INFO,
        },
      },
      rollback: {
        type: metadataType.PROJECT_BUILD_INFO_CALL_ROLLBACK,
        log: {
          initiative: METRIC_INFO.INITIATIVE,
          feature: METRIC_INFO.PROJECT_BUILD_INFO,
        },
      },
    },
  },
});
