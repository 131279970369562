export const xOptionMap = {
  freq: "Frequency",
  channel: "Channel",
};

export const xSelectOptions = Object.keys(xOptionMap).map((eachKey) => {
  return {
    id: eachKey,
    label: xOptionMap[eachKey],
  };
});
