export const layout = {
  xaxis: {
    title: "channel",
    titlefont: {
      family: "Roboto",
      size: 18,
    },
  },
  xaxis2: {
    title: "channel",
    titlefont: {
      family: "Roboto",
      size: 18,
    },
    overlaying: "x1",
    side: "top",
  },
  yaxis: {
    title: "power",
    titlefont: {
      family: "Roboto",
      size: 18,
    },
  },
  yaxis2: {
    title: "delta",
    titlefont: {
      family: "Roboto",
      size: 18,
    },
    overlaying: "y1",
    side: "right",
  },
  showlegend: true,
  legend: {
    xanchor: "left",
    yanchor: "top",
    x: 0,
    y: -0.5,
  },
  margin: {
    t: 30,
  },
};
