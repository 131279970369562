// AJAX request related
import { config } from "../../../config/config.js";
import endpoint from "../../../config/endpoints";
import {
  UploadFileCheckRequest,
  UploadFileCheckResponse,
  UploadPreSignedURLRequest,
  UploadPreSignedURLResponse,
  FileTemplatePresignedURLResponse,
} from "../../../model/http-json";
import { postData, Response, getData } from "../../../utils/cognito-fetch";
// constatns
import { FileStatus } from "../../../model/upload";
import constants, { METRIC_INFO } from "../../../config/constants";
import { username } from "../../../cognito-auth/session";
import { getLogData } from "../../../utils/general-utils";

export async function fileNameCheckCall(file: File): Promise<FileStatus> {
  /*
    Ajax call to check 1 single file name

*/
  // init return
  const rst: FileStatus = {
    status: constants.LOADING_FAIL,
    message: "",
  };

  try {
    const filename = file.name;
    const url: string = config.UPLOAD_BASE_URL + endpoint.uploadFileNameCheck();
    const data: UploadFileCheckRequest = {
      initiative: constants.INITIATIVE,
      file_name: filename,
    };

    const response: Response = await postData(
      url,
      data,
      getLogData(METRIC_INFO.MANUAL_UPLOAD)
    );

    // http reponse ok
    if (response.ok) {
      const fileCheckResponse: UploadFileCheckResponse = response.json as UploadFileCheckResponse;
      if (
        fileCheckResponse.status &&
        fileCheckResponse.status === constants.UPLOAD_CHECK_FILE_SUCCESS
      ) {
        rst.status = constants.LOADING_SUCCESS;
      } else {
        rst.status = constants.LOADING_FAIL;
        rst.message = `${
          fileCheckResponse.error_message
            ? fileCheckResponse.error_message
            : "Unknown error."
        } ${
          fileCheckResponse.error_description
            ? fileCheckResponse.error_description
            : ""
        }`;
      }
    } else {
      //http resonse on ok
      rst.status = constants.LOADING_SUCCESS;
      rst.message = response.status_text;
    }
  } catch (error) {
    rst.status = constants.LOADING_FAIL;
    rst.message = error.message;
  } finally {
    return rst;
  }
}

export async function fileUploadS3Call(file: File): Promise<FileStatus> {
  /*
      Ajax call to upload 1 file to s3
      
      Two steps:
       1. send call to upload service to get pre-signed url
       2. put request to s3
  */
  // init return
  const rst: FileStatus = {
    status: constants.LOADING_FAIL,
    message: "",
  };

  try {
    // step 1: get pre-signed url
    const filename = file.name;
    const url: string = config.UPLOAD_BASE_URL + endpoint.uploadPreSignedURL();
    const data: UploadPreSignedURLRequest = {
      initiative: constants.INITIATIVE,
      file_name: filename,
      user_name: username,
    };

    // the response1 is our own defined type
    const response1: Response = await postData(
      url,
      data,
      getLogData(METRIC_INFO.MANUAL_UPLOAD)
    );
    if (!response1.ok) {
      throw response1.status_text;
    }

    const preSignedURLResponse: UploadPreSignedURLResponse = response1.json as UploadPreSignedURLResponse;
    const preSignedUrl = preSignedURLResponse.url;
    const metaDataDict = preSignedURLResponse.meta_data_result;

    // step2: put request to s3
    // the response2 is fetch native response type
    const response2 = await fetch(preSignedUrl, {
      method: "PUT",
      body: file,
      headers: {
        "content-type": "",
        ...metaDataDict,
      },
    });

    if (!response2.ok) {
      throw response2.statusText;
    }

    rst.status = constants.LOADING_SUCCESS;
    rst.message = "";
  } catch (error) {
    rst.status = constants.LOADING_FAIL;
    rst.message = error.message;
  } finally {
    return rst;
  }
}


export async function downloadFileTemplate(filename) {
 /**
  * Fetches Presigned URL for a filename
  * Opens URL in new tab to download file
  */
  try {
    const url: string = config.BASE_URL + endpoint.fileTemplate(filename);
    const response: Response = await getData(
      url,
      getLogData(METRIC_INFO.TEMPLATE_DOWNLOAD)
    );

    const resp: FileTemplatePresignedURLResponse = response.json as FileTemplatePresignedURLResponse;

    if (response.ok) {
      // Auto-download file from URL
      window.open(resp.url, "_blank");
    }
    return
  } catch (error) {
    // Do nothing on error.
    return
  }
}