import { capitalizeFirstLetter } from "../../../utils/general-utils";

export const antennaPassive = "passive";
export const antennaActive = "active";

export const antennaOptions = [antennaPassive, antennaActive].map((eachKey) => {
  return {
    id: eachKey,
    label: capitalizeFirstLetter(eachKey),
  };
});
