import React, { Component } from "react";
// sub components
import Plot from "react-plotly.js";
import { layout } from "./plot-config";
// redux
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { getPlotData } from "../../../redux/actions/delta-compare-action"; // redux action

interface StateProps {
  deltaCompareReducer: any;
}

// declare prop check
type Props = {
  dispatch: Dispatch<any>;
} & StateProps &
  typeof defaultProps;

type State = {} & typeof initialState;

// declare init state & default props
const defaultProps = Object.freeze({});

const initialState = Object.freeze({});

class DeltaComparePlot extends Component<Props, State> {
  static readonly defaultProps = defaultProps;
  readonly state = initialState;

  componentDidUpdate(prevProps, prevState) {
    const { compareSelection, plotData } = this.props.deltaCompareReducer;

    if (
      this.props.deltaCompareReducer.compareSelection &&
      this.props.deltaCompareReducer.compareSelection !==
        prevProps.deltaCompareReducer.compareSelection
    ) {
      compareSelection.forEach((selectedItem) => {
        if (
          !(selectedItem.key in plotData) ||
          !("data" in plotData[selectedItem.key])
        ) {
          this.props.dispatch(
            getPlotData(
              selectedItem.tcs_id,
              selectedItem.testCategory,
              selectedItem
            )
          );
        }
      });
    }
  }

  render() {
    const { compareSelection, plotData } = this.props.deltaCompareReducer;
    const data: any[] = [];
    let comparable: boolean = false;

    // line 1
    if (compareSelection.length > 0) {
      const line1Key = compareSelection[0].key;
      const line1Item = compareSelection[0];

      if (plotData[line1Key] && plotData[line1Key].data) {
        const line1Data = plotData[line1Key].data;
        if (line1Data.x && line1Data.y) {
          data.push({
            name: `${line1Item.project}_${line1Item.build}_${
              line1Item.testCategory
            }_${line1Item.testType}_${line1Item.device}_${
              line1Item.technology
            }_${line1Item.mode}_${line1Item.bandwidth}_${line1Item.antenna}_${
              line1Item.firmware
            }_${
              line1Item.line === "limit"
                ? "limit"
                : `SetPower:${line1Item.line}`
            }`,
            x: line1Data.x,
            y: line1Data.y,
            type: "scatter",
          });
        }
      }
    }

    // line 2
    if (compareSelection.length > 1) {
      const line2Key = compareSelection[1].key;
      const line2Item = compareSelection[1];

      if (plotData[line2Key] && plotData[line2Key].data) {
        const line2Data = plotData[line2Key].data;
        if (line2Data.x && line2Data.y) {
          data.push({
            name: `${line2Item.project}_${line2Item.build}_${
              line2Item.testCategory
            }_${line2Item.testType}_${line2Item.device}_${
              line2Item.technology
            }_${line2Item.mode}_${line2Item.bandwidth}_${line2Item.antenna}_${
              line2Item.firmware
            }_${
              line2Item.line === "limit"
                ? "limit"
                : `setpower:${line2Item.line}`
            }`,
            x: line2Data.x,
            y: line2Data.y,
            type: "scatter",
          });
        }
      }
    }

    // delta line
    if (data.length === 2) {
      comparable =
        data[0].x &&
        data[1].x &&
        data[0].x.length === data[1].x.length &&
        data[0].x.every((item, index) => item === data[1].x[index]);

      if (comparable) {
        data.push({
          name: "Delta",
          x: data[0].x,
          y:
            data[0].y && data[1].y && data[0].y.length === data[1].y.length
              ? data[0].y.map((item, index) => item - data[1].y[index])
              : [],
          line: {
            dash: "dash",
            color: "black",
          },
          yaxis: "y2",
        });
      }
    }

    return (
      <div className="awsui-util-container">
        <div className="awsui-util-container-header">
          <h2>Compare Plot</h2>
        </div>
        <div style={{ minHeight: "200px" }}>
          {data.length === 0 && <div>No item selected from Compare List.</div>}
          {data.length >= 2 && (
            <div>
              <b>Comparable: {comparable.toString()}</b>
              <br />
            </div>
          )}
          {data.length > 0 && (
            <Plot
              data={data}
              layout={layout}
              style={{ width: "100%" }}
              useResizeHandler={true}
              config={{ displayModeBar: false }}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    deltaCompareReducer: state.deltaCompareReducer,
  };
};
export default connect<StateProps>(mapStateToProps)(DeltaComparePlot);
