import React from "react";
import { Box, Button, SpaceBetween } from "@amzn/awsui-components-react-v3";

export const getHeaderCounterText = (items = [], selectedItems = []) => {
  return selectedItems && selectedItems.length > 0
    ? `(${selectedItems.length}/${items.length})`
    : `(${items.length})`;
};

export const getServerHeaderCounterText = (totalCount, selectedItems) => {
  return selectedItems && selectedItems.length > 0
    ? `(${selectedItems.length}/${totalCount})`
    : `(${totalCount})`;
};

export const getServerFilterCounterText = (totalCount: number) => {
  const count = totalCount + "";
  return count === "1" ? `1 match` : `${count} matches`;
};

export const getFilterCounterText = (count) =>
  `${count} ${count === 1 ? "match" : "matches"}`;

export const FilterNoMatchState = () => {
  return (
    <div className="awsui-util-t-c awsui-util-mv-s">
      <b>No matches</b>
      <p className="awsui-util-mb-xs">We can't find a match.</p>
      <Button>Clear filter</Button>
    </div>
  );
};

export const EmptyContentState = () => {
  return (
    <div className="awsui-util-t-c">
      <div className="awsui-util-pt-s awsui-util-mb-xs">
        <b>No resources</b>
      </div>
      <p className="awsui-util-mb-s">No resources to display.</p>
    </div>
  );
};

export const TableEmptyState = ({ resourceName }) => (
  <Box margin={{ vertical: "xs" }} textAlign="center" color="inherit">
    <SpaceBetween size="xxs">
      <div>
        <b>No {resourceName.toLowerCase()}s</b>
        <Box variant="p" color="inherit">
          No {resourceName.toLowerCase()}s associated with this resource.
        </Box>
      </div>
    </SpaceBetween>
  </Box>
);

export const EmptyState = ({ title, subtitle, action }) => {
  return (
    <Box textAlign="center" color="inherit">
      <Box variant="strong" textAlign="center" color="inherit">
        {title}
      </Box>
      <Box variant="p" padding={{ bottom: "s" }} color="inherit">
        {subtitle}
      </Box>
      {action}
    </Box>
  );
};

export const getMatchesCountText = (matchesCount): string => {
  if (!matchesCount) return "No Matches";
  return `${matchesCount} matches`;
};

export const paginationLabels = {
  nextPageLabel: "Next page",
  previousPageLabel: "Previous page",
  pageLabel: (pageNumber: number) => `Page ${pageNumber} of all pages`,
};
