const antennaConfigType = {
  // meaning dsh level
  GET_ANTENNA_DEVICE_LIST: "GET_ANTENNA_DEVICE_LIST",
  GET_ANTENNA_DEVICE_LIST_COMMIT: "GET_ANTENNA_DEVICE_LIST_COMMIT",
  GET_ANTENNA_DEVICE_LIST_ROLLBACK: "GET_ANTENNA_DEVICE_LIST_ROLLBACK",

  // set user selection
  SET_USER_SELECTION: "ANTENNA_SET_USER_SELECTION",

  // tcs main
  POST_ANTENNA_TCS_RESULT: "POST_ANTENNA_TCS_RESULT",
  POST_ANTENNA_TCS_RESULT_COMMIT: "POST_ANTENNA_TCS_RESULT_COMMIT",
  POST_ANTENNA_TCS_RESULT_ROLLBACK: "POST_ANTENNA_TCS_RESULT_ROLLBACK",

  INIT_LOADING_STATUS: "INIT_LOADING_STATUS",
};

export default antennaConfigType;
