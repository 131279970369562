import constants from "../../config/constants";

const testcaseType = {
  // get testcase data
  GET_TESTCASE_STATUS: "GET_TESTCASE_STATUS",
  GET_TESTCASE_STATUS_COMMIT: "GET_TESTCASE_STATUS_COMMIT",
  GET_TESTCASE_STATUS_ROLLBACK: "GET_TESTCASE_STATUS_ROLLBACK",
};

export const dynamicTestcaseType = (
  status: number,
  testType: string | undefined
) => {
  switch (status) {
    case constants.LOADING_LOAD:
      return `GET_TESTCASE_STATUS${
        testType ? `_${testType.toUpperCase()}` : ""
      }`;
    case constants.LOADING_SUCCESS:
      return `GET_TESTCASE_STATUS${
        testType ? `_${testType.toUpperCase()}` : ""
      }_COMMIT`;
    case constants.LOADING_FAIL:
      return `GET_TESTCASE_STATUS${
        testType ? `_${testType.toUpperCase()}` : ""
      }_ROLLBACK`;
    default:
      return "UNDEFINED_TYPE";
  }
};

export default testcaseType;
