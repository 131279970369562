import React, { Component } from "react";
// redux
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { getLandingData } from "../../redux/actions/landing-action"; // redux action
import DashboardTable from "./table/table";

interface StateProps {
  landingReducer: any;
}

// declare prop check
type Props = {
  dispatch: Dispatch<any>;
} & StateProps;

class Dashboard extends Component<Props> {
  componentDidMount() {
    // const { landingData } = this.props.landingReducer;
    // if (landingData.length === 0) {
    //   this._getDate();
    // }
    this._getDate();
  }

  _getDate = () => {
    this.props.dispatch(getLandingData());
  };

  render() {
    const { landingData, loadingStatus } = this.props.landingReducer;
    return (
      <div>
        <DashboardTable
          data={landingData}
          loadingStatus={loadingStatus}
          getLandingData={this._getDate}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    landingReducer: state.landingReducer,
  };
};
export default connect<StateProps>(mapStateToProps)(Dashboard);
