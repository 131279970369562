const uploadType = {
  // get upload STATUS
  GET_UPLOAD_STATUS: "GET_UPLOAD_STATUS",
  GET_UPLOAD_STATUS_COMMIT: "GET_UPLOAD_STATUS_COMMIT",
  GET_UPLOAD_STATUS_ROLLBACK: "GET_UPLOAD_STATUS_ROLLBACK",

  GET_TRIGGER_AUTO_UPLOAD_STATUS: "GET_TRIGGER_AUTO_UPLOAD_STATUS",
  GET_TRIGGER_AUTO_UPLOAD_STATUS_COMMIT:
    "GET_TRIGGER_AUTO_UPLOAD_STATUS_COMMIT",
  GET_TRIGGER_AUTO_UPLOAD_STATUS_ROLLBACK:
    "GET_TRIGGER_AUTO_UPLOAD_STATUS_ROLLBACK",
};

export default uploadType;
