import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
// integrate Bootstrap
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.min.js";
// font awesome
import "@fortawesome/fontawesome-free/css/all.css";
// our own library, contains css style and colors
// https://code.amazon.com/packages/LENS-React-UI/blobs/mainline/--/src/index.css
import "@amzn/lens-react-ui/lib/main.min.css";
// bind redux
import { Provider } from "react-redux";
import store from "./redux/store";
// react router dom
import { BrowserRouter } from "react-router-dom";
// load config
import { loadAppConfig } from "./config/config";
// global css
import "./index.css";
// aws-rum
import { AwsRum } from "aws-rum-web";
// color-config
import "./config/color-config.css";
// toast
import "react-toastify/dist/ReactToastify.css";

export let awsRum;

async function configRumClient(config) {
    try {
        awsRum = new AwsRum(
            config.APPLICATION_ID,
            config.APPLICATION_VERSION,
            config.APPLICATION_REGION,
            config.rum_config
        );
    } catch (error) {
        // Ignore errors thrown during CloudWatch RUM web client initialization
        console.log("CloudWatch RUM web client initialization failed");
        console.log(error);
    }
}

loadAppConfig().then((config) => {
  configRumClient(config);
  ReactDOM.render(
    <div>
      <Provider store={store}>
        <BrowserRouter>
          <div className="awsui">
            <App config={config} />
          </div>
        </BrowserRouter>
      </Provider>
    </div>,
    document.getElementById("root")
  );
});
