import React, { Component } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import {
  ColumnLayout,
  Button,
  FormField,
  Input,
  Alert,
} from "@amzn/awsui-components-react/polaris";
import { postData, Response } from "../../../utils/cognito-fetch";
import { config } from "../../../config/config.js";
import endpoints from "../../../config/endpoints";
import "./create-form.css";
import constants, {
  COMPONENT_ID,
  METRIC_INFO,
} from "../../../config/constants";
import { setCategory } from "../../../redux/actions/category-action";
import { getMetadata } from "../../../redux/actions/metadata-action";
import {
  capitalizeFirstLetter,
  getLogData,
} from "../../../utils/general-utils";
import { validateCategory } from "../build-form-config";

interface StateProps {
  categoryReducer: any;
}

// declare prop check
type Props = {
  dispatch: Dispatch<any>;
  history: any;
} & StateProps;

type State = {
  category: string; // user input for creating category
  categoryError: string; // input error
  responseError: string; // response error from BE
  loading: number;
};

class CategoryForm extends Component<Props, State> {
  readonly state: State = Object.freeze({
    category: "",
    categoryError: "",
    responseError: "",
    // loading is only used for showing spinner when call BE, so set default as SUCCESS.
    loading: constants.LOADING_SUCCESS,
  });

  //validate input
  validate = (id: string) => {
    let isValid: boolean = true;

    // validate category
    if (id === COMPONENT_ID.CATEGORY) {
      const categoryError: string = validateCategory(this.state.category);

      this.setState({
        categoryError,
      });

      if (categoryError) {
        isValid = false;
      }
    }

    return isValid;
  };

  /* 
    onClick submit button
    1. validate user input, show input error if find any
    2. sent request to BE
    3. show response to user
      3.1 If failed, show response error
      3.2 If success, ask user choose to continue creating project or not
  */
  _onSubmit = () => {
    // clean up response error from last submit
    this.setState({ responseError: "" });

    const isValid = this.validate(COMPONENT_ID.CATEGORY);
    if (!isValid) {
      return;
    }

    const category: string = this.state.category;
    const values: {} = {};
    const url = config.BASE_URL + endpoints.createCategoryEndpoint(category);

    this.setState({
      loading: constants.LOADING_LOAD,
    });

    postData(
      url,
      values,
      getLogData(METRIC_INFO.CATEGORY, null, category)
    ).then((response: Response) => {
      // update side bar data
      this.props.dispatch(getMetadata());

      // update created category
      this.props.dispatch(setCategory(capitalizeFirstLetter(category)));

      this.setState({
        loading: constants.LOADING_SUCCESS,
      });

      if (response.ok) {
        const isConfirmed = window.confirm(
          "Successfully created category. Continue creating a project?"
        );
        if (isConfirmed) {
          // navigate to create project
          this.props.history.push("/project/create");
        } else {
          // stay on create category, clear up input
          this.clear();
        }
      } else {
        this.setState({
          responseError: response.json.message,
          loading: constants.LOADING_FAIL,
        });
      }
    });
  };

  clear = () => {
    this.setState({
      category: "",
      categoryError: "",
      responseError: "",
      loading: constants.LOADING_SUCCESS,
    });
  };

  _onChange = (event: any) => {
    const category: string = event.detail.value;
    this.setState({
      category,
    });

    // validate user input
    const id: string = event.target.id;
    this.validate(id);
  };

  render() {
    return (
      <div className="awsui-util-container">
        <div className="awsui-util-container-header">
          <h2>Create Category</h2>
        </div>
        <div>
          {/* main form portion */}
          <ColumnLayout>
            <div data-awsui-column-layout-root="true">
              <FormField
                label="Category"
                hintText="Letters only"
                errorText={this.state.categoryError}
              >
                <div className="create-form-input">
                  <Input
                    id={COMPONENT_ID.CATEGORY}
                    placeholder="Enter category name"
                    value={this.state.category}
                    onChange={this._onChange}
                  ></Input>
                </div>
              </FormField>
            </div>
          </ColumnLayout>
          <br />

          {this.state.responseError && (
            <React.Fragment>
              <Alert
                header="Errors Detected"
                type="error"
                content={this.state.responseError}
              ></Alert>
              <br />
            </React.Fragment>
          )}
          <br />
          <br />

          {/* action stripe group */}
          <div className="awsui-util-action-stripe-group">
            <Button onClick={this.clear}>Clear</Button>
            <Button
              variant="primary"
              onClick={this._onSubmit}
              loading={this.state.loading === constants.LOADING_LOAD}
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    categoryReducer: state.categoryReducer,
  };
};

export default connect<StateProps>(mapStateToProps)(CategoryForm);
