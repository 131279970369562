import React, { Component } from "react";
// redux
import { Dispatch } from "redux";
import { connect } from "react-redux";
// child components
import UserInput from "./user-input";
import Plot from "./plot/plot";
// css
import "./compliance-box-plot.css";

interface StateProps {
  boxPlotReducer: any;
}

// declare prop check
type Props = {
  dispatch: Dispatch<any>;
} & typeof defaultProps &
  StateProps;

// declare init state & default props
const defaultProps = Object.freeze({});

class ComplianceBoxPlot extends Component<Props> {
  static readonly defaultProps = defaultProps;

  render() {
    return (
      <div>
        <div className="text-center compliance-box-plot-page-title">
          Compliance Box Plot
        </div>
        <br />

        <div className="d-flex flex-row">
          <UserInput />
        </div>
        <br />

        <Plot />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    boxPlotReducer: state.boxPlotReducer,
  };
};
export default connect<StateProps>(mapStateToProps)(ComplianceBoxPlot);
