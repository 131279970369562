import { capitalizeFirstLetter } from "../../../../../utils/general-utils";

export const ANTENNA_ACTIVE_KEY = "active";

// active:{ active_efficiency, conducted_power, trp }, tis, trp

// for all below formatting operations, refer this wiki for data example
// https://wiki.labcollab.net/confluence/display/WDI/Engineer+Antenna+Requirements+and+Notes#EngineerAntennaRequirementsandNotes-UpdatedataformatforP2
export const formatSelectedData = (
  selectedData,
  xSelect,
  ySelect,
  activeTech,
  chartType
) => {
  let formatData = {};
  if (chartType === ANTENNA_ACTIVE_KEY) {
    formatData = formatActiveSelectedData(
      selectedData,
      xSelect,
      ySelect,
      activeTech,
      chartType
    );
  } else {
    formatData = formatSignalingSelectedData(
      selectedData,
      xSelect,
      ySelect,
      activeTech,
      chartType
    );
  }

  return formatData;
};

const formatActiveSelectedData = (
  selectedData,
  xSelect,
  ySelect,
  activeTech,
  chartType
) => {
  const formattedData: Array<object> = [];
  Object.keys(selectedData).forEach((eachTC) => {
    // passive object
    const currentActiveObj = selectedData[eachTC]["data"];
    if (currentActiveObj && currentActiveObj[chartType]) {
      Object.keys(currentActiveObj[chartType]).forEach((eachSubActive) => {
        const antennaObj = currentActiveObj[chartType][eachSubActive];
        if (antennaObj && antennaObj[activeTech]) {
          // check if exist
          const currentAntennaLvlObj = antennaObj[activeTech];
          if (currentAntennaLvlObj) {
            Object.keys(currentAntennaLvlObj).forEach((eachAntenna) => {
              Object.keys(currentAntennaLvlObj[eachAntenna]).forEach(
                (eachChamber) => {
                  const tcsObject =
                    currentAntennaLvlObj[eachAntenna][eachChamber];
                  if (tcsObject) {
                    const currentTrace = {
                      x: tcsObject[xSelect],
                      y: tcsObject[ySelect],
                      // text: tcsObject["comment"],
                      name: `Testcase ${eachTC} ${eachSubActive} ${eachAntenna} ${eachChamber}`,
                      mode: "lines+markers",
                      id: `Testcase ${eachTC} ${eachSubActive} ${eachAntenna} ${eachChamber}`,
                      // hovertemplate: `<br><b>${xSelect}</b>: %{x}<br><i>${ySelect}</i>: %{y}`,
                      marker: {
                        size: 6,
                      },
                    };

                    formattedData.push(currentTrace);
                  }
                }
              );
            });
          }
        }
      });
    }
  });

  return formattedData;
};

const formatSignalingSelectedData = (
  selectedData,
  xSelect,
  ySelect,
  activeTech,
  chartType
) => {
  const formattedData: Array<object> = [];
  Object.keys(selectedData).forEach((eachTC) => {
    // passive object
    const currentActiveObj = selectedData[eachTC]["data"];
    if (currentActiveObj && currentActiveObj[chartType]) {
      if (currentActiveObj[chartType]) {
        const activeTechLvl = currentActiveObj[chartType][activeTech];
        if (activeTechLvl) {
          Object.keys(activeTechLvl).forEach((eachChamber) => {
            const tcsObject = activeTechLvl[eachChamber];
            if (tcsObject) {
              const currentTrace = {
                x: tcsObject[xSelect],
                y: tcsObject[ySelect],
                // text: tcsObject["comment"],
                name: `Testcase ${eachTC} ${eachChamber}`,
                mode: "lines+markers",
                id: `Testcase ${eachTC} ${eachChamber}`,
                // hovertemplate: `<br><b>${xSelect}</b>: %{x}<br><i>${ySelect}</i>: %{y}`,
                marker: {
                  size: 6,
                },
              };

              formattedData.push(currentTrace);
            }
          });
        }
      }
    }
  });

  return formattedData;
};

export const showAlert = (min, max) => {
  let result = false;

  if (
    [min, max].some(
      (element) => element === "" || element === null || isNaN(element)
    )
  ) {
    if (min !== max) {
      result = true;
    }
  }
  if (min === max && min !== null) {
    result = true;
  }

  return result;
};

const mappedSheetType = {
  active: "Pseudo Active",
  trp: "Signaling TRP",
  tis: "Signaling TIS",
};

export const updateLayoutName = (name, chartType, width) => {
  return {
    title: `${capitalizeFirstLetter(name)} ${mappedSheetType[chartType]} Power`,
    width: width,
    margin: {
      t: 30, //top margin
      b: 40, //bottom margin
    },
    // yaxis: {},
    // xaxis: {},
    legend: { orientation: "h" },
  };
};

export const getListofTech = (selectedData, chartType) => {
  let result: Array<any> = [];
  const currentActiveObj = selectedData[Object.keys(selectedData)[0]]["data"];

  if (chartType === ANTENNA_ACTIVE_KEY) {
    result =
      currentActiveObj[chartType] && currentActiveObj[chartType]["trp"]
        ? Object.keys(currentActiveObj[chartType]["trp"])
        : [];
  } else {
    result = currentActiveObj[chartType]
      ? Object.keys(currentActiveObj[chartType])
      : [];
  }
  return result;
};
