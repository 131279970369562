import React from "react";

const ACTIVE_AE_HEADER = "Active Efficiency";
const ACTIVE_CP_HEADER = "Conducted Power";
const ACTIVE_TRP_HEADER = "TRP";

export const defaultPageSize = 5;

// define columns
export const columnDefinitions = [
  {
    id: "technology",
    header: "Technology",
    label: (sortState) => {
      const isColumnSorted = sortState.sortingColumn === "technology";
      const ascending = !sortState.sortingDescending;
      return `Technology, ${
        isColumnSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => item.technology,
    minWidth: "50px",
    visible: true,
  },
  {
    id: "channel",
    header: "Channel",
    label: (sortState) => {
      const isColumnSorted = sortState.sortingColumn === "channel";
      const ascending = !sortState.sortingDescending;
      return `Channel, ${
        isColumnSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => item.channel,
    minWidth: "50px",
    visible: true,
  },
  {
    id: "freq",
    header: "Freq",
    label: (sortState) => {
      const isColumnSorted = sortState.sortingColumn === "freq";
      const ascending = !sortState.sortingDescending;
      return `Freq, ${
        isColumnSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => item.freq,
    minWidth: "100px",
    visible: true,
  },
  {
    id: "chamber",
    header: "Chamber",
    label: (sortState) => {
      const isColumnSorted = sortState.sortingColumn === "chamber";
      const ascending = !sortState.sortingDescending;
      return `Chamber, ${
        isColumnSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => <span>{item.chamber}</span>,
    minWidth: "100px",
    visible: true,
  },
  {
    id: "setPower",
    header: "Set Power",
    label: (sortState) => {
      const isColumnSorted = sortState.sortingColumn === "setPower";
      const ascending = !sortState.sortingDescending;
      return `setPower, ${
        isColumnSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => <span>{item.setPower}</span>,
    minWidth: "100px",
    visible: true,
  },
  //ACTIVE_TRP_HEADER+  antenna
  {
    id: "trpAntOne",
    header: ACTIVE_TRP_HEADER + " Ant1",
    label: (sortState) => {
      const isColumnSorted = sortState.sortingColumn === "trpAntOne";
      const ascending = !sortState.sortingDescending;
      return `trpAntOne, ${
        isColumnSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => <span>{item.trpAntOne}</span>,
    minWidth: "100px",
    visible: true,
  },
  {
    id: "trpAntTwo",
    header: ACTIVE_TRP_HEADER + " Ant2",
    label: (sortState) => {
      const isColumnSorted = sortState.sortingColumn === "trpAntTwo";
      const ascending = !sortState.sortingDescending;
      return `trpAntTwo, ${
        isColumnSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => <span>{item.trpAntTwo}</span>,
    minWidth: "100px",
    visible: true,
  },
  {
    id: "trpAntThree",
    header: ACTIVE_TRP_HEADER + " Ant3",
    label: (sortState) => {
      const isColumnSorted = sortState.sortingColumn === "trpAntThree";
      const ascending = !sortState.sortingDescending;
      return `trpAntThree, ${
        isColumnSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => <span>{item.trpAntThree}</span>,
    minWidth: "100px",
    visible: true,
  },
  {
    id: "trpAntFour",
    header: ACTIVE_TRP_HEADER + " Ant4",
    label: (sortState) => {
      const isColumnSorted = sortState.sortingColumn === "trpAntFour";
      const ascending = !sortState.sortingDescending;
      return `trpAntFour, ${
        isColumnSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => <span>{item.trpAntFour}</span>,
    minWidth: "100px",
    visible: true,
  },
  {
    id: "trpAntFive",
    header: ACTIVE_TRP_HEADER + " Ant5",
    label: (sortState) => {
      const isColumnSorted = sortState.sortingColumn === "trpAntFive";
      const ascending = !sortState.sortingDescending;
      return `trpAntFive, ${
        isColumnSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => <span>{item.trpAntFive}</span>,
    minWidth: "100px",
    visible: true,
  },
  //cp
  {
    id: "cpAntOne",
    header: ACTIVE_CP_HEADER + " Ant1",
    label: (sortState) => {
      const isColumnSorted = sortState.sortingColumn === "cpAntOne";
      const ascending = !sortState.sortingDescending;
      return `cpAntOne, ${
        isColumnSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => <span>{item.cpAntOne}</span>,
    minWidth: "100px",
    visible: true,
  },
  {
    id: "cpAntTwo",
    header: ACTIVE_CP_HEADER + " Ant2",
    label: (sortState) => {
      const isColumnSorted = sortState.sortingColumn === "cpAntTwo";
      const ascending = !sortState.sortingDescending;
      return `cpAntTwo, ${
        isColumnSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => <span>{item.cpAntTwo}</span>,
    minWidth: "100px",
    visible: true,
  },
  {
    id: "cpAntThree",
    header: ACTIVE_CP_HEADER + " Ant3",
    label: (sortState) => {
      const isColumnSorted = sortState.sortingColumn === "cpAntThree";
      const ascending = !sortState.sortingDescending;
      return `cpAntThree, ${
        isColumnSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => <span>{item.cpAntThree}</span>,
    minWidth: "100px",
    visible: true,
  },
  {
    id: "cpAntFour",
    header: ACTIVE_CP_HEADER + " Ant4",
    label: (sortState) => {
      const isColumnSorted = sortState.sortingColumn === "cpAntFour";
      const ascending = !sortState.sortingDescending;
      return `cpAntFour, ${
        isColumnSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => <span>{item.cpAntFour}</span>,
    minWidth: "100px",
    visible: true,
  },
  {
    id: "cpAntFive",
    header: ACTIVE_CP_HEADER + " Ant5",
    label: (sortState) => {
      const isColumnSorted = sortState.sortingColumn === "cpAntFive";
      const ascending = !sortState.sortingDescending;
      return `cpAntFive, ${
        isColumnSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => <span>{item.cpAntFive}</span>,
    minWidth: "100px",
    visible: true,
  },
  //ae
  {
    id: "aeAntOne",
    header: ACTIVE_AE_HEADER + " Ant1",
    label: (sortState) => {
      const isColumnSorted = sortState.sortingColumn === "aeAntOne";
      const ascending = !sortState.sortingDescending;
      return `aeAntOne, ${
        isColumnSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => <span>{item.aeAntOne}</span>,
    minWidth: "100px",
    visible: true,
  },
  {
    id: "aeAntTwo",
    header: ACTIVE_AE_HEADER + " Ant2",
    label: (sortState) => {
      const isColumnSorted = sortState.sortingColumn === "aeAntTwo";
      const ascending = !sortState.sortingDescending;
      return `aeAntTwo, ${
        isColumnSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => <span>{item.aeAntTwo}</span>,
    minWidth: "100px",
    visible: true,
  },
  {
    id: "aeAntThree",
    header: ACTIVE_AE_HEADER + " Ant3",
    label: (sortState) => {
      const isColumnSorted = sortState.sortingColumn === "aeAntThree";
      const ascending = !sortState.sortingDescending;
      return `aeAntThree, ${
        isColumnSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => <span>{item.aeAntThree}</span>,
    minWidth: "100px",
    visible: true,
  },
  {
    id: "aeAntFour",
    header: ACTIVE_AE_HEADER + " Ant4",
    label: (sortState) => {
      const isColumnSorted = sortState.sortingColumn === "aeAntFour";
      const ascending = !sortState.sortingDescending;
      return `aeAntFour, ${
        isColumnSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => <span>{item.aeAntFour}</span>,
    minWidth: "100px",
    visible: true,
  },
  {
    id: "aeAntFive",
    header: ACTIVE_AE_HEADER + " Ant5",
    label: (sortState) => {
      const isColumnSorted = sortState.sortingColumn === "aeAntFive";
      const ascending = !sortState.sortingDescending;
      return `aeAntFive, ${
        isColumnSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => <span>{item.aeAntFive}</span>,
    minWidth: "100px",
    visible: true,
  },
];

// define default sorting column
export const defaultSortingColumn = "technology";
export const defaultSortingDescending = true;

// define sortable columns
export const sortableColumns = [
  { id: "technology", field: "technology" },
  { id: "channel", field: "channel" },
  { id: "freq", field: "freq" },
  { id: "chamber", field: "chamber" },
  { id: "setPower", field: "setPower" },
  // trp
  { id: "trpAntOne", field: "trpAntOne" },
  { id: "trpAntTwo", field: "trpAntTwo" },
  { id: "trpAntThree", field: "trpAntThree" },
  { id: "trpAntFour", field: "trpAntFour" },
  { id: "trpAntFive", field: "trpAntFive" },
  // { id: "trpAntFive", field: "trpAntFive" },
  //conducted_power
  { id: "cpAntOne", field: "cpAntOne" },
  { id: "cpAntTwo", field: "cpAntTwo" },
  { id: "cpAntThree", field: "cpAntThree" },
  { id: "cpAntFour", field: "cpAntFour" },
  { id: "cpAntFive", field: "cpAntFive" },
  // { id: "cpAntFive", field: "cpAntFive" },
  // active_efficiency
  { id: "aeAntOne", field: "aeAntOne" },
  { id: "aeAntTwo", field: "aeAntTwo" },
  { id: "aeAntThree", field: "aeAntThree" },
  { id: "aeAntFour", field: "aeAntFour" },
  { id: "aeAntFive", field: "aeAntFive" },
  // { id: "aeAntFive", field: "aeAntFive" },
];

// define visiable columns
export const getVisibleColumns = () => [
  {
    label: "Properties",
    options: [
      { id: "technology", label: "Technology", visible: true, editable: false },
      { id: "channel", label: "Channel", visible: true, editable: false },
      { id: "freq", label: "Freq", visible: true, editable: false },
      { id: "chamber", label: "Chamber", visible: true, editable: false },
      { id: "setPower", label: "Set Power", visible: true, editable: false },
      // trp
      {
        id: "trpAntOne",
        label: ACTIVE_TRP_HEADER + " Ant1",
        visible: true,
        editable: false,
      },
      {
        id: "trpAntTwo",
        label: ACTIVE_TRP_HEADER + " Ant2",
        visible: true,
        editable: false,
      },
      {
        id: "trpAntThree",
        label: ACTIVE_TRP_HEADER + " Ant3",
        visible: true,
        editable: false,
      },
      {
        id: "trpAntFour",
        label: ACTIVE_TRP_HEADER + " Ant4",
        visible: true,
        editable: false,
      },
      {
        id: "trpAntFive",
        label: ACTIVE_TRP_HEADER + " Ant5",
        visible: true,
        editable: false,
      },
      //conducted power
      {
        id: "cpAntOne",
        label: ACTIVE_CP_HEADER + " Ant1",
        visible: true,
        editable: false,
      },
      {
        id: "cpAntTwo",
        label: ACTIVE_CP_HEADER + " Ant2",
        visible: true,
        editable: false,
      },
      {
        id: "cpAntThree",
        label: ACTIVE_CP_HEADER + " Ant3",
        visible: true,
        editable: false,
      },
      {
        id: "cpAntFour",
        label: ACTIVE_CP_HEADER + " Ant4",
        visible: true,
        editable: false,
      },
      {
        id: "cpAntFive",
        label: ACTIVE_CP_HEADER + " Ant5",
        visible: true,
        editable: false,
      },
      // active effiency
      {
        id: "aeAntOne",
        label: ACTIVE_AE_HEADER + " Ant1",
        visible: true,
        editable: false,
      },
      {
        id: "aeAntTwo",
        label: ACTIVE_AE_HEADER + " Ant2",
        visible: true,
        editable: false,
      },
      {
        id: "aeAntThree",
        label: ACTIVE_AE_HEADER + " Ant3",
        visible: true,
        editable: false,
      },
      {
        id: "aeAntFour",
        label: ACTIVE_AE_HEADER + " Ant4",
        visible: true,
        editable: false,
      },
      {
        id: "aeAntFive",
        label: ACTIVE_AE_HEADER + " Ant5",
        visible: true,
        editable: false,
      },
    ],
  },
];

// define filtering options
export const filteringOptions = [
  {
    groupValuesLabel: "Technology",
    propertyKey: "technology",
    propertyLabel: "Technology",
    values: [],
  },
  {
    groupValuesLabel: "Channel",
    propertyKey: "channel",
    propertyLabel: "Channel",
    values: [],
  },
  {
    groupValuesLabel: "Freq",
    propertyKey: "freq",
    propertyLabel: "Freq",
    values: [],
  },
];
