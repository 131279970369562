import { ScanTableRow } from "../../../../model/passive-active-scan";

export const passiveScanPlotlyFormat = (data: {}) => {
  let rst: PlotData[] = [];
  Object.keys(data).forEach((dataRate) => {
    let onePlot = new PlotData(dataRate);

    // get table data
    const tableData: {} = { ...data[dataRate]["table"] };
    if (
      tableData["channel"] &&
      tableData["result"] &&
      tableData["limit"] &&
      tableData["status"] &&
      tableData["channel"].length === tableData["result"].length &&
      tableData["channel"].length === tableData["limit"].length &&
      tableData["channel"].length === tableData["status"].length
    ) {
      for (let i = 0; i < tableData["channel"].length; i++) {
        const row: ScanTableRow = {
          channel: tableData["channel"][i],
          limit: isNaN(tableData["limit"][i])
            ? ""
            : tableData["limit"][i] === 1
            ? "on"
            : "off",
          result: isNaN(tableData["result"][i])
            ? ""
            : tableData["result"][i] === 1
            ? "on"
            : "off",
          status: tableData["status"][i],
        };
        onePlot.tableData.push(row);
      }
    }

    // get plot data
    const plotData: {} = { ...data[dataRate]["plot"] };
    if (plotData["x"] && plotData["y"] && plotData["limit"]) {
      const plotChannel = plotData["x"];
      const plotLimit = formatPlotData(plotData["limit"], "limit");
      onePlot.plotData.push(getLineData("limit", plotChannel, plotLimit));
      const plotResult = formatPlotData(plotData["y"], "power");
      onePlot.plotData.push(getLineData("power", plotChannel, plotResult));
    }

    rst.push(onePlot);
  });

  return rst;
};

export class PlotData {
  tableData: any[];
  plotData: any[];
  layout: {};
  title: string;
  constructor(dataRate: string) {
    this.tableData = [] as any;
    this.plotData = [] as any;
    this.title = dataRate;
    this.layout = {
      xaxis: {
        title: "channel",
        titlefont: {
          family: "Roboto",
          size: 18,
        },
      },
      yaxis: {
        title: {
          text: "power",
          titlefont: {
            family: "Roboto",
            size: 18,
          },
          standoff: 1,
        },
        tickvals: [0, 1, 2, 3, 4],
        ticktext: [
          "No value",
          "Limit off",
          "Result off",
          "Limit on",
          "Result on",
        ],
      },
      margin: {
        t: 30,
      },
    };
  }
}

const getLineData = (name: string, x: string[], y: string[]): {} => {
  let line = {};
  if (name === "limit") {
    line["dash"] = "dot";
  }
  return { name, x, y, line };
};

// mapping rule
// value: [0, 1, 2, 3, 4]
// plot text: ["No value", "Limit off","Result off", "Limit on", "Result on",]
const formatPlotData = (numbers: number[], line: string): any => {
  const result: number[] = [];
  numbers.forEach((num) => {
    let val: number = 0;
    if (typeof num === "number" && line === "limit") {
      val = num * 2 + 1;
    }
    if (typeof num === "number" && line === "power") {
      val = num * 2 + 2;
    }
    result.push(val);
  });
  return result;
};
