// leave constants here because only work for Non sig
import contains, {
  NONSIG_COCONUT_TECH_KEY,
  NONSIG_COCONUT_TECH_MAP,
  NONSIG_LITEPOINT_TECH_KEY,
  NONSIG_LITEPOINT_TECH_MAP,
  NONSIG_TEST_LEVEL,
  TEST_CATEGORY,
} from "../../../config/constants";

// ignore bt&lora antenna selection in checkNonSigOptions
const IGNORE_ANT_CHECK_KEY = "bt_lora_ant";

export const showNonSig = (inputArray) => {
  if (inputArray.includes(TEST_CATEGORY.NON_SIG)) {
    return true;
  }
  return false;
};

export const checkCoconutNonSigOptions = (inputObj) => {
  // check if each key in Non-sig options populated w options
  const res = { ...inputObj };
  if (IGNORE_ANT_CHECK_KEY in res) {
    delete res[IGNORE_ANT_CHECK_KEY];
  }

  return Object.keys(res).every(
    (eachKey) => res[eachKey] && res[eachKey].length > 0
  );
};

export const checkLitepointNonSigOptions = (inputObj) => {
  // check if each key in Non-sig options populated w options
  const res = { ...inputObj };

  // if BT or ZigBee not in tech, delete their antenna options
  if (
    [
      NONSIG_LITEPOINT_TECH_KEY.NONSIG_BT,
      NONSIG_LITEPOINT_TECH_KEY.NONSIG_ZIGBEE,
    ].every((eachKey) => !res[NONSIG_TEST_LEVEL.TECHNOLOGY].includes(eachKey))
  ) {
    delete res[NONSIG_TEST_LEVEL.BT_ANTENNA];
    delete res[NONSIG_TEST_LEVEL.ZIGBEE_ANTENNA]; // have zigbee and bt track bt for now
  }
  // if Lora not in selected tech, remove lora antenna
  if (
    !res[NONSIG_TEST_LEVEL.TECHNOLOGY].includes(
      NONSIG_LITEPOINT_TECH_KEY.NONSIG_LORA
    )
  ) {
    delete res[NONSIG_TEST_LEVEL.LORA_ANTENNA];
  }
  // If wifi not in selected tech, remove wifi antenna
  if (
    !res[NONSIG_TEST_LEVEL.TECHNOLOGY].includes(
      NONSIG_LITEPOINT_TECH_KEY.NONSIG_WIFI
    )
  ) {
    delete res[NONSIG_TEST_LEVEL.WIFI_ANTENNA];
  }
  return Object.keys(res).every(
    (eachKey) => res[eachKey] && res[eachKey].length > 0
  );
};

export const checkCoconutAntenna = (inputTechArray) => {
  const formatTech = inputTechArray.map((each) => each.value);

  let showBtLoraAnt = false;
  let showWifiAnt = false;
  let showAnt = false;
  if (
    formatTech.includes(NONSIG_COCONUT_TECH_KEY.NONSIG_BT) ||
    formatTech.includes(NONSIG_COCONUT_TECH_KEY.NONSIG_LORA)
  ) {
    showBtLoraAnt = true;
  }
  if (
    formatTech.includes(NONSIG_COCONUT_TECH_KEY.NONSIG_WIFI_AX) ||
    formatTech.includes(NONSIG_COCONUT_TECH_KEY.NONSIG_WIFI)
  ) {
    showWifiAnt = true;
  }
  if (showBtLoraAnt || showWifiAnt) {
    showAnt = true;
  }
  return { showAnt, showBtLoraAnt, showWifiAnt };
};

export const checkLitepointAntenna = (inputTechArray) => {
  const formatTech = inputTechArray;

  let showZigbeeBtAnt = false;
  let showWifi = false;
  let showAnt = false;
  // TODO: Support LoRa Ant1 later
  if (
    formatTech.includes(NONSIG_LITEPOINT_TECH_KEY.NONSIG_ZIGBEE) ||
    formatTech.includes(NONSIG_LITEPOINT_TECH_KEY.NONSIG_BT) // ||
    //  formatTech.includes(NONSIG_LITEPOINT_TECH_KEY.NONSIG_LORA)
  ) {
    showZigbeeBtAnt = true;
  }
  if (formatTech.includes(NONSIG_LITEPOINT_TECH_KEY.NONSIG_WIFI)) {
    showWifi = true;
  }
  if (showZigbeeBtAnt || showWifi) {
    showAnt = true;
  }
  return { showAnt, showZigbeeBtAnt, showWifi };
};

export const filterSelectedAntennas = (inputTechArray) => {
  // Clear antenna lists for technologies that are not in tech list
  // Clear them to empty list -> do not delete!
  const res = {};

  // 1) Clear WiFi ant if tech not selected
  if (
    !inputTechArray.includes(NONSIG_LITEPOINT_TECH_KEY.NONSIG_WIFI)
  ) {
    res[NONSIG_TEST_LEVEL.WIFI_ANTENNA] = [];
  }

  // 2) Clear BT and ZigBee if they BOTH dont exist - they should track each other
  if (
    [
      NONSIG_LITEPOINT_TECH_KEY.NONSIG_BT,
      NONSIG_LITEPOINT_TECH_KEY.NONSIG_ZIGBEE,
    ].every((eachKey) => !inputTechArray.includes(eachKey))
  ) {
    res[NONSIG_TEST_LEVEL.BT_ANTENNA] = [];
    res[NONSIG_TEST_LEVEL.ZIGBEE_ANTENNA] = []; // have zigbee and bt track bt for now
  }

  // 3) Clear LoRa ant if LoRa is not selected
  // but set the value to["0"] if it IS selected
  // only 1 option, no drop down, so we control changes here
  res[NONSIG_TEST_LEVEL.LORA_ANTENNA] = inputTechArray.includes(
    NONSIG_LITEPOINT_TECH_KEY.NONSIG_LORA
  )
    ? ["0"]
    : [];

  return res;
};

export const getCoconutBandList = (inputArray) => {
  let res: any = new Set();
  inputArray.forEach((obj) => {
    const currVal = obj.value;
    if (currVal && currVal in NONSIG_COCONUT_TECH_MAP) {
      const addedArray = Object.keys(NONSIG_COCONUT_TECH_MAP[currVal]);
      res = new Set([...res, ...addedArray]);
    }
  });
  return formatMultiSelectOptions(Array.from(res));
};

export const getLitepointBandList = (inputArray) => {
  let res: any = new Set();
  inputArray.forEach((currVal) => {
    if (currVal && currVal in NONSIG_LITEPOINT_TECH_MAP) {
      const addedArray = Object.keys(NONSIG_LITEPOINT_TECH_MAP[currVal]);
      res = new Set([...res, ...addedArray]);
    }
  });
  return Array.from(res);
};

export const getCoconutStandard = (techList, bandList) => {
  let res: any = new Set();

  const flatTechList = techList.map((each) => each.value);
  const flatBandList = bandList.map((each) => each.value);

  if (Array.isArray(techList) && Array.isArray(bandList)) {
    flatTechList.forEach((eachTech) => {
      flatBandList.forEach((eachBand) => {
        if (
          NONSIG_COCONUT_TECH_MAP[eachTech] &&
          NONSIG_COCONUT_TECH_MAP[eachTech][eachBand]
        ) {
          const addedArray = NONSIG_COCONUT_TECH_MAP[eachTech][eachBand];
          res = new Set([...res, ...addedArray]);
        }
      });
    });
  }
  return formatMultiSelectOptions(Array.from(res));
};

export const getLitepointStandard = (techList, bandList) => {
  let res: any = new Set();

  if (Array.isArray(techList) && Array.isArray(bandList)) {
    techList.forEach((eachTech) => {
      bandList.forEach((eachBand) => {
        if (
          NONSIG_LITEPOINT_TECH_MAP[eachTech] &&
          NONSIG_LITEPOINT_TECH_MAP[eachTech][eachBand]
        ) {
          const addedArray = NONSIG_LITEPOINT_TECH_MAP[eachTech][eachBand];
          res = new Set([...res, ...addedArray]);
        }
      });
    });
  }
  return Array.from(res);
};

export const formatMultiSelectOptions = (inputArray) => {
  if (!Array.isArray(inputArray)) {
    return [];
  }
  return inputArray.map((each) => ({
    label: each,
    value: each,
  }));
};

export const filterSubSelectOptions = (
  currentSelected,
  currentOptions,
  coconut = true
) => {
  // Get intersection of user selection and possible options
  let selectedValues = currentSelected;
  let optionsValues = currentOptions;
  if (coconut) {
    selectedValues = currentSelected.map((each) => each.value);
    optionsValues = currentOptions.map((each) => each.value);
  }
  let res = selectedValues.filter((eachValue) =>
    optionsValues.includes(eachValue)
  );
  if (coconut) {
    res = formatMultiSelectOptions(res);
  }
  return res;
};

export const mappedCoconutTestCategory = formatMultiSelectOptions(
  Object.keys(NONSIG_COCONUT_TECH_MAP)
);
export const mappedLitepointTestCategory = formatMultiSelectOptions(
  Object.keys(NONSIG_LITEPOINT_TECH_MAP)
);
export const mappedTempList = formatMultiSelectOptions(
  contains.NONSIG_TEMPERATURE_LIST
);
export const mappedVoltageList = formatMultiSelectOptions(
  contains.NONSIG_VOLTAGE_LIST
);
export const mappedCoconutAntennaList = formatMultiSelectOptions(
  contains.NONSIG_COCONUT_ANTENNA_LIST
);
export const mappedLitepointWiFiAntennaList = formatMultiSelectOptions(
  contains.NONSIG_LITEPOINT_WIFI_ANTENNA_LIST
);
export const mappedLitepointZigbeeBTAntennaList = formatMultiSelectOptions(
  contains.NONSIG_LITEPOINT_ZIGBEE_BT_ANT_LIST
);
