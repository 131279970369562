export const countStatus = (upload_info_list) => {
  const statusMap = {};
  if (Array.isArray(upload_info_list)) {
    upload_info_list.forEach((eachObj) => {
      const currentStatus = eachObj.status;
      statusMap[currentStatus] = statusMap[currentStatus]
        ? statusMap[currentStatus] + 1
        : 1;
    });
  }
  return statusMap;
};
