import React from "react";
import { TEST_STATUS_COLOR } from "../../../../config/color-config";

export const defaultPageSize = 5;

// define columns
export const columnDefinitions = [
  {
    id: "channel",
    header: "Channel",
    label: (sortState) => {
      const isColumnSorted = sortState.sortingColumn === "channel";
      const ascending = !sortState.sortingDescending;
      return `Channel, ${
        isColumnSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => item.channel,
    minWidth: "100px",
    visible: true,
  },
  {
    id: "limit",
    header: "Limit",
    label: (sortState) => {
      const isColumnSorted = sortState.sortingColumn === "limit";
      const ascending = !sortState.sortingDescending;
      return `Limit, ${
        isColumnSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => item.limit,
    minWidth: "100px",
    visible: true,
  },
  {
    id: "result",
    header: "Result",
    label: (sortState) => {
      const isColumnSorted = sortState.sortingColumn === "result";
      const ascending = !sortState.sortingDescending;
      return `Result, ${
        isColumnSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => item.result,
    minWidth: "100px",
    visible: true,
  },
  {
    id: "status",
    header: "Status",
    label: (sortState) => {
      const isColumnSorted = sortState.sortingColumn === "status";
      const ascending = !sortState.sortingDescending;
      return `Status, ${
        isColumnSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => (
      <span
        style={{ color: TEST_STATUS_COLOR[item.status] }}
      >{` ${item.status}`}</span>
    ),
    minWidth: "100px",
    visible: true,
  },
];

// define default sorting column
export const defaultSortingColumn = "channel";

// define sortable columns
export const sortableColumns = [
  { id: "channel", field: "channel" },
  { id: "limit", field: "limit" },
  { id: "result", field: "result" },
  { id: "status", field: "status" },
];

// define visiable columns
export const visibleColumns = [
  {
    label: "Properties",
    options: [
      { id: "channel", label: "Channel", visible: true, editable: false },
      { id: "limit", label: "Limit", visible: true, editable: false },
      { id: "result", label: "Result", visible: true, editable: false },
      { id: "status", label: "Status", visible: true, editable: false },
    ],
  },
];

// define filtering options
export const filteringOptions = [
  {
    groupValuesLabel: "Channel",
    propertyKey: "channel",
    propertyLabel: "Channel",
    values: [],
  },
  {
    groupValuesLabel: "Limit",
    propertyKey: "limit",
    propertyLabel: "Limit",
    values: [],
  },
  {
    groupValuesLabel: "Result",
    propertyKey: "result",
    propertyLabel: "Result",
    values: [],
  },
  {
    groupValuesLabel: "Status",
    propertyKey: "status",
    propertyLabel: "Status",
    values: [],
  },
];
