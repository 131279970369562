import React from "react";
import { PlotData as powerTableBandEdgeHarmonicsPlotData } from "../plot-data/format-power-table-data";
import { PlotData as PassiveScanData } from "../plot-data/format-passive-scan-data";
import { PlotData as ActiveScanData } from "../plot-data/format-active-scan-data";
import GeneralPlotlyChart from "../../../../components/charts/general-plotly-chart";
import ScanTable from "../plot-table/scan-table";
import { ENG_COM_TEST_TYPE } from "../../../../config/constants";

export const powerTableBandEdgeHarmonicsPlotCardDefinition = {
  header: (plot: powerTableBandEdgeHarmonicsPlotData) => plot.title,
  sections: [
    {
      id: "divider",
      content: () => <hr />,
    },
    {
      id: "plot",
      content: (plot: powerTableBandEdgeHarmonicsPlotData) => {
        return (
          <div>
            <GeneralPlotlyChart
              key={plot.title}
              domId={plot.title}
              data={plot.data}
              style={{ width: "100%" }}
              layout={plot.layout}
              config={{ displayModeBar: false }} // not showing mode bar
            />
          </div>
        );
      },
    },
  ],
};

export const PassiveScanPlotCardDefinition = {
  header: (data: PassiveScanData) => data.title,
  sections: [
    {
      id: "divider1",
      content: () => <hr />,
    },
    {
      id: "table",
      content: (data: PassiveScanData) => {
        return (
          <div>
            <ScanTable
              data={data.tableData}
              testType={ENG_COM_TEST_TYPE.PASSIVE_SCAN}
            />
            <br />
          </div>
        );
      },
    },
    {
      id: "divider2",
      content: () => <hr />,
    },
    {
      id: "plot",
      content: (data: PassiveScanData) => {
        return (
          <div>
            <GeneralPlotlyChart
              key={data.title}
              domId={data.title}
              data={data.plotData}
              style={{ width: "100%" }}
              layout={data.layout}
              config={{ displayModeBar: false }} // not showing mode bar
            />
          </div>
        );
      },
    },
  ],
};

export const ActiveScanPlotCardDefinition = {
  header: (data: ActiveScanData) => data.title,
  sections: [
    {
      id: "divider1",
      content: () => <hr />,
    },
    {
      id: "table",
      content: (data: ActiveScanData) => {
        return (
          <div>
            <ScanTable
              data={data.tableData}
              testType={ENG_COM_TEST_TYPE.ACTIVE_SCAN}
            />
            <br />
          </div>
        );
      },
    },
    {
      id: "divider2",
      content: () => <hr />,
    },
    {
      id: "plot",
      content: (data: ActiveScanData) => {
        return (
          <div>
            <GeneralPlotlyChart
              key={data.title}
              domId={data.title}
              data={data.plotData}
              style={{ width: "100%" }}
              layout={data.layout}
              config={{ displayModeBar: false }} // not showing mode bar
            />
          </div>
        );
      },
    },
  ],
};

export const cardsPerRow = [
  {
    cards: 1,
  },
  {
    minWidth: 500,
    cards: 1,
  },
];

export const cardFeatures = ["filtering", "pagination"];

export const cardsPaginationOptions = [
  { value: 2, label: "2 items" },
  { value: 4, label: "4 items" },
  { value: 6, label: "6 items" },
];

export const getFilterCounterText = (count) =>
  `${count} ${count === 1 ? "match" : "matches"}`;

export const filterFields = ["title"];
