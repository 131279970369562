import categoryType from "../types/category-type";
import { config } from "../../config/config";
import endpoints from "../../config/endpoints";
import { METRIC_INFO } from "../../config/constants";

export const getCategoryList = (): any => ({
  type: categoryType.GET_CATEGORY_LIST,
  http: {
    cognito: {
      effect: {
        url: config.BASE_URL + endpoints.getCategoryListEndpoint(),
        method: "GET",
      },
      commit: {
        type: categoryType.GET_CATEGORY_LIST_COMMIT,
        log: {
          initiative: METRIC_INFO.INITIATIVE,
          feature: METRIC_INFO.CATEGORY,
        },
      },
      rollback: {
        type: categoryType.GET_CATEGORY_LIST_ROLLBACK,
        log: {
          initiative: METRIC_INFO.INITIATIVE,
          feature: METRIC_INFO.CATEGORY,
        },
      },
    },
  },
});

export const setCategory = (category: string): any => ({
  type: categoryType.SET_CATEGORY,
  category,
});
