import deltaCompareType from "../types/delta-compare-type";
import constants from "../../config/constants";
import { TestcaseStatusResponse } from "../../model/http-json";
import { CompareItem, CompareDictItem } from "../../model/delta-compare";

const initialState = {
  // test cases portion
  testcaseData: [] as TestcaseStatusResponse,
  testcaseLoadingStatus: constants.LOADING_SUCCESS,

  // compare list portion
  compareList: [] as string[], // element is tcsID
  compareDict: {} as CompareDictItem,
  historyList: [] as CompareItem[],
  compareSelection: [] as CompareItem[],

  // plot portion
  plotData: {} as { [key: string]: { [key: string]: any } },
};

const deltaCompareReducer = (state = initialState, action) => {
  switch (action.type) {
    // test cases portion
    case deltaCompareType.UPDATE_SELECTION:
      return {
        ...state,
        [action.key]: action.value,
      };
    case deltaCompareType.UPDATE_COMPARE_SELECTION:
      return {
        ...state,
        compareSelection: action.selections,
      };
    case deltaCompareType.GET_TEST_CASES:
      return {
        ...state,
        testcaseLoadingStatus: constants.LOADING_LOAD,
        testcaseData: [],
      };
    case deltaCompareType.GET_TEST_CASES_COMMIT:
      return {
        ...state,
        testcaseLoadingStatus: constants.LOADING_SUCCESS,
        testcaseData: action.payload as TestcaseStatusResponse,
      };
    case deltaCompareType.GET_TEST_CASES_ROLLBACK:
      return {
        ...state,
        testcaseLoadingStatus: constants.LOADING_FAIL,
      };
    case deltaCompareType.CLEAR_TEST_CASES:
      return {
        ...state,
        testcaseData: [],
      };
    // compare list portion
    case deltaCompareType.CLEAR_COMPARE_LIST:
      return {
        ...state,
        compareList: [],
        compareDict: {},
      };
    case deltaCompareType.UPDATE_COMPARE_LIST:
      return {
        ...state,
        compareList: [...state.compareList, action.tcsId],
        compareDict: {
          ...state.compareDict,
          [action.tcsId]: { loadingStatus: constants.LOADING_LOAD },
        },
      };
    case deltaCompareType.UPDATE_COMPARE_LIST_COMMIT:
      let updatedCompareDict: CompareDictItem = { ...state.compareDict };

      updatedCompareDict[action.tcsId]["loadingStatus"] =
        constants.LOADING_SUCCESS;
      updatedCompareDict[action.tcsId]["tests"] = {};
      updatedCompareDict[action.tcsId]["testcaseInfo"] = action.testcaseInfo;

      if (action.payload.data && typeof action.payload.data === "object") {
        Object.entries(action.payload.data).forEach(([dataRate, val]) => {
          updatedCompareDict[action.tcsId]["tests"][dataRate] = [];
          if (val && typeof val === "object") {
            if ("limit" in val) {
              updatedCompareDict[action.tcsId]["tests"][dataRate].push("limit");
            }

            if ("y" in val && typeof val["y"] === "object") {
              Object.keys(val["y"]).forEach((power) => {
                updatedCompareDict[action.tcsId]["tests"][dataRate].push(power);
              });
            }
          }
        });
      }
      return {
        ...state,
        compareDict: updatedCompareDict,
      };
    case deltaCompareType.UPDATE_COMPARE_LIST_ROLLBACK:
      updatedCompareDict = { ...state.compareDict };
      updatedCompareDict[action.tcsId]["loadingStatus"] =
        constants.LOADING_FAIL;
      return {
        ...state,
        compareDict: updatedCompareDict,
      };

    case deltaCompareType.UPDATE_HISTORY_LIST:
      return {
        ...state,
        historyList: action.historyList,
      };

    //plot portion
    case deltaCompareType.GET_PLOT_DATA:
      return {
        ...state,
      };
    case deltaCompareType.GET_PLOT_DATA_COMMIT:
      const { key, dataRate, line } = action.selectedItem;
      if (action.payload.data && action.payload.data[dataRate]) {
        return {
          ...state,
          plotData: {
            ...state.plotData,
            [key]: {
              data: {
                x: action.payload.data[dataRate]["x"],
                y:
                  line === "limit"
                    ? action.payload.data[dataRate]["limit"]
                    : action.payload.data[dataRate]["y"]
                    ? action.payload.data[dataRate]["y"][line]
                    : [],
              },
            },
          },
        };
      }
      return state;

    case deltaCompareType.GET_PLOT_DATA_ROLLBACK:
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default deltaCompareReducer;
