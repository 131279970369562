import React, { Component } from "react";
import { config } from "../config/config";

class Analytics extends Component {
  render() {
    return config.STAGE === "prod" ? (
      <div>
        <iframe
          title="Analytics"
          src="https://search-wise-es-fqemetrics-cgj4xsdhpq2rgvcntsyxnxoyue.us-east-2.es.amazonaws.com/_plugin/kibana/app/kibana#/dashboard/2f05aea0-fd1a-11ea-912e-79715bce5c6a?embed=true&_g=(refreshInterval%3A(display%3AOff%2Cpause%3A!f%2Cvalue%3A0)%2Ctime%3A(from%3Anow-90d%2Cmode%3Aquick%2Cto%3Anow))"
          height="1250"
          width="1930"
        ></iframe>
      </div>
    ) : (
      <div>
        <iframe
          title="Analytics"
          src="https://search-wise-es-fqemetrics-cgj4xsdhpq2rgvcntsyxnxoyue.us-east-2.es.amazonaws.com/_plugin/kibana/app/kibana#/dashboard/0b1677f0-fbac-11ea-bebd-5bd2702ac354?embed=true&_g=(refreshInterval%3A(display%3AOff%2Cpause%3A!f%2Cvalue%3A0)%2Ctime%3A(from%3Anow-90d%2Cmode%3Aquick%2Cto%3Anow))"
          height="1250"
          width="1930"
        ></iframe>
      </div>
    );
  }
}

export default Analytics;
