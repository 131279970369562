import React from "react";
import { TEST_STATUS } from "../../../../config/constants";
import { TEST_STATUS_COLOR } from "../../../../config/color-config";

const TestStatusLegend = (props) => {
  return (
    <div className="d-flex align-items-center">
      {Object.keys(TEST_STATUS).map((key, index) => {
        const testType = TEST_STATUS[key];
        return (
          <div key={index} className="d-flex align-items-center mr-2">
            <div
              style={{
                width: "10px",
                height: "10px",
                marginRight: "2px",
                backgroundColor: TEST_STATUS_COLOR[testType],
              }}
            ></div>
            <div className="text-secondary">{testType}</div>
          </div>
        );
      })}
    </div>
  );
};

export default TestStatusLegend;
