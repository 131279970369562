import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import querySearch from "query-string";

// redux
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { getOverviewStatus } from "../../../redux/actions/overview-action"; // redux action
import { OverviewStatusRequest } from "../../../model/http-json";
// child components
import BreadcrumbGroup from "../../../components/breadcrumb-group";
import OverviewPanel from "./overview-panel";
import CountryTypePanel from "./country-type-panel";
import DeviceTypePanel from "./device-type-panel";
// utils & constatns
import {
  addRequestLabToLink,
  capitalizeFirstLetter,
} from "../../../utils/general-utils";
import { ENG_COM_TEST_TYPE } from "../../../config/constants";

interface StateProps {
  overviewReducer: any;
}

interface MatchParams {
  project: string;
  build: string;
  version: string;
  test_category: string;
}

interface QueryString {
  request_id?: number;
  lab_id?: number;
}
// declare prop check
type Props = {
  dispatch: Dispatch<any>;
} & typeof defaultProps &
  RouteComponentProps<MatchParams> &
  StateProps;

// declare init state & default props
const defaultProps = Object.freeze({});
const initialState = Object.freeze({
  requestId: undefined,
  labId: undefined,
});
class ComplianceOverview extends Component<Props> {
  static readonly defaultProps = defaultProps;
  readonly state = initialState;
  componentDidMount() {
    this._getAllData();
  }

  componentDidUpdate(prevProps, prevState) {
    // same component, but when the url changes, e.g project/build/version
    if (prevProps.location.pathname !== this.props.location.pathname) {
      // re-fetch data
      this._getAllData();
    }
  }

  // get overview status, and status for each test_type
  _getAllData = () => {
    let queryString: QueryString = {};
    if (this.props.location && this.props.location.search) {
      queryString = querySearch.parse(this.props.location.search);
    }
    const requestId = queryString.request_id
      ? queryString.request_id
      : undefined;
    const labId = queryString.lab_id ? queryString.lab_id : undefined;

    this.setState({ requestId: requestId, labId: labId });
    // overview
    this._getData(undefined, requestId, labId);

    // each test_type
    Object.keys(ENG_COM_TEST_TYPE).forEach((key) => {
      const testType = ENG_COM_TEST_TYPE[key];
      this._getData(testType, requestId, labId);
    });
  };

  // get data for one type
  _getData = (
    testType: string | undefined = undefined,
    request_id: number | undefined = undefined,
    lab_id: number | undefined = undefined
  ) => {
    const jsonPayload: OverviewStatusRequest = {};
    if (testType) {
      jsonPayload.test_type = testType;
    }
    if (request_id) {
      jsonPayload.request_id = request_id;
    }
    if (lab_id) {
      jsonPayload.lab_id = lab_id;
    }
    const { project, build, version, test_category } = this.props.match.params;
    this.props.dispatch(
      getOverviewStatus(
        project,
        `${build}_${version}`,
        test_category,
        jsonPayload,
        testType
      )
    );
  };

  render() {
    const { project, build, version, test_category } = this.props.match.params;
    const { overviewReducer } = this.props;
    const { requestId, labId } = this.state;
    return (
      <div>
        {/* path title */}
        <BreadcrumbGroup
          textGroup={[
            { text: capitalizeFirstLetter(project) }, // Project
            { text: `${build.toUpperCase()}_${version}` }, // BUILD_version
            { text: capitalizeFirstLetter(test_category) }, // TestCategory
            {
              text: "Overview",
              href: addRequestLabToLink(
                `/${project}/${build}/${version}/${test_category}/overview`,
                requestId,
                labId
              ),
            },
          ]}
        />
        <br />

        {/* overview status */}
        <OverviewPanel
          //this.props.overviewReducer.overviewStatusData
          loadingStatus={this.props.overviewReducer.overviewLoadingStatus}
          statusData={this.props.overviewReducer.overviewStatusData}
          getData={this._getData}
          project={project}
          build={build}
          version={version}
          test_category={test_category}
        />

        {/* power table, passive/active status */}
        <CountryTypePanel
          powerTableLoadingStatus={
            overviewReducer[`${ENG_COM_TEST_TYPE.POWER_TABLE}LoadingStatus`]
          }
          activeScanLoadingStatus={
            overviewReducer[`${ENG_COM_TEST_TYPE.ACTIVE_SCAN}LoadingStatus`]
          }
          passiveScanLoadingStatus={
            overviewReducer[`${ENG_COM_TEST_TYPE.PASSIVE_SCAN}LoadingStatus`]
          }
          powerTableStatusData={
            overviewReducer[`${ENG_COM_TEST_TYPE.POWER_TABLE}StatusData`]
          }
          activeScanStatusData={
            overviewReducer[`${ENG_COM_TEST_TYPE.ACTIVE_SCAN}StatusData`]
          }
          passiveScanStatusData={
            overviewReducer[`${ENG_COM_TEST_TYPE.PASSIVE_SCAN}StatusData`]
          }
          getData={this._getData}
          TCSPagelink={`/${project}/${build}/${version}/${test_category}/testcase`}
          requestId={requestId}
          labId={labId}
        />
        <br />

        {/* Band Edge/Harmonics */}
        <DeviceTypePanel
          bandEdgeLoadingStatus={
            overviewReducer[`${ENG_COM_TEST_TYPE.BAND_EDGE}LoadingStatus`]
          }
          harmonicsLoadingStatus={
            overviewReducer[`${ENG_COM_TEST_TYPE.HARMONICS}LoadingStatus`]
          }
          bandEdgeStatusData={
            overviewReducer[`${ENG_COM_TEST_TYPE.BAND_EDGE}StatusData`]
          }
          harmonicsStatusData={
            overviewReducer[`${ENG_COM_TEST_TYPE.HARMONICS}StatusData`]
          }
          getData={this._getData}
          TCSPagelink={`/${project}/${build}/${version}/${test_category}/testcase`}
          requestId={this.state.requestId}
          labId={this.state.labId}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    overviewReducer: state.overviewReducer,
  };
};
export default withRouter(
  connect<StateProps>(mapStateToProps)(ComplianceOverview)
);
