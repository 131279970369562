import { TEST_STATUS } from "../../../config/constants";

export const mergeStatusData = (
  statusData: Array<any>,
  comparator: ((a: any, b: any) => number) | undefined = undefined
) => {
  /*  
        Give powertable, active scan, passive scan, merge them together
        
        Each statusData in format of:
        {
            countries/devicec: [us,ca...]
            ca: {},
            us: {},
        }

        oder is a array of pre-defined orders
    */

  // define return schema
  const rst = {
    keys: [] as Array<string>, // merged countries/devices list
    values: {}, // merged status values for each country/device
  };

  // merge keys(countries/devices list)
  statusData.forEach((eachStatusData) => {
    if (
      eachStatusData &&
      eachStatusData.keys &&
      Array.isArray(eachStatusData.keys)
    ) {
      eachStatusData.keys.forEach((item: string) => {
        if (!rst.keys.includes(item)) {
          rst.keys.push(item);
        }
      });
    }
  });

  // merge status data
  rst.keys.forEach((key: string) => {
    rst.values[key] = {
      total: 0,
      [TEST_STATUS.PASSED]: 0,
      [TEST_STATUS.FAILED]: 0,
      [TEST_STATUS.WARNING]: 0,
      [TEST_STATUS.UNTESTED]: 0,
      [TEST_STATUS.NO_LIMIT]: 0,
    };
  });

  rst.keys.forEach((key: string) => {
    statusData.forEach((eachStatusData) => {
      if (key in eachStatusData.values) {
        rst.values[key].total += eachStatusData.values[key].total;
        rst.values[key][TEST_STATUS.PASSED] +=
          eachStatusData.values[key][TEST_STATUS.PASSED];
        rst.values[key][TEST_STATUS.FAILED] +=
          eachStatusData.values[key][TEST_STATUS.FAILED];
        rst.values[key][TEST_STATUS.WARNING] +=
          eachStatusData.values[key][TEST_STATUS.WARNING];
        rst.values[key][TEST_STATUS.UNTESTED] +=
          eachStatusData.values[key][TEST_STATUS.UNTESTED];
        rst.values[key][TEST_STATUS.NO_LIMIT] +=
          eachStatusData.values[key][TEST_STATUS.NO_LIMIT];
      }
    });
  });

  if (comparator) {
    rst.keys.sort(comparator);
  }

  return rst;
};
