import { dynamicOverviewType } from "../types/overview-type";
import constants, { ENG_COM_TEST_TYPE } from "../../config/constants";
import { StatusSchema } from "../../model/overview";

const engComInitState = () => {
  const initState: { [key: string]: any } = {};
  Object.keys(ENG_COM_TEST_TYPE).forEach((key: string) => {
    const testType = ENG_COM_TEST_TYPE[key];
    initState[`${testType}LoadingStatus`] = constants.LOADING_LOAD;
    initState[`${testType}StatusData`] = undefined;
  });

  return initState;
};

const initialState = {
  overviewLoadingStatus: constants.LOADING_LOAD,
  overviewStatusData: undefined as StatusSchema | undefined,
  ...engComInitState(),
};

const overviewReducer = (state = initialState, action) => {
  switch (action.type) {
    case dynamicOverviewType(constants.LOADING_LOAD, action.testType):
      return {
        ...state,
        [action.testType
          ? `${action.testType}LoadingStatus`
          : "overviewLoadingStatus"]: constants.LOADING_LOAD,
      };
    case dynamicOverviewType(constants.LOADING_SUCCESS, action.testType):
      return {
        ...state,
        [action.testType
          ? `${action.testType}LoadingStatus`
          : "overviewLoadingStatus"]: constants.LOADING_SUCCESS,
        [action.testType
          ? `${action.testType}StatusData`
          : "overviewStatusData"]: action.testType
          ? action.payload
          : (action.payload as StatusSchema),
      };
    case dynamicOverviewType(constants.LOADING_FAIL, action.testType):
      return {
        ...state,
        [action.testType
          ? `${action.testType}LoadingStatus`
          : "overviewLoadingStatus"]: constants.LOADING_FAIL,
      };
    default:
      return state;
  }
};

export default overviewReducer;
