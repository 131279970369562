import React, { Component } from "react";
// sub components
import { Tabs, Button } from "@amzn/awsui-components-react/polaris";
import styled from "styled-components";
import { CompareListSingleItem, HistoryListSingleItem } from "./single-item";
// redux
import { Dispatch } from "redux";
import { connect } from "react-redux";
import {
  clearCompareList,
  updateCompareSelection,
  updateHistoryList,
} from "../../../redux/actions/delta-compare-action"; // redux action
// constants
import constants from "../../../config/constants";
import { CompareItem } from "../../../model/delta-compare";

const MAX_NUM_OF_HISTORY_ITEMS = 10;
const CompareListDiv = styled.div`
  height: 400px;
  overflow-y: auto;
`;

interface StateProps {
  deltaCompareReducer: any;
}

// declare prop check
type Props = {
  dispatch: Dispatch<any>;
} & StateProps &
  typeof defaultProps;

type State = {} & typeof initialState;

// declare init state & default props
const defaultProps = Object.freeze({});

const initialState = Object.freeze({
  // tab active id
  activeTabId: "compare",
});

class DeltaCompareList extends Component<Props, State> {
  static readonly defaultProps = defaultProps;
  readonly state = initialState;

  // clear compare list
  _onClearCompareList = () => {
    this.props.dispatch(clearCompareList());
    this.props.dispatch(updateCompareSelection([]));
  };

  // onClick on each item on the compare list, add add to the plot
  _onClickCompareListItem = (item: CompareItem) => {
    const { compareSelection, historyList } = this.props.deltaCompareReducer;

    if (compareSelection.some((eachItem) => eachItem.key === item.key)) {
      this.props.dispatch(
        updateCompareSelection(
          compareSelection.filter((eachItem) => eachItem.key !== item.key)
        )
      );
    } else {
      let newCompareSelection = [...compareSelection, item];
      if (newCompareSelection.length > 2) {
        newCompareSelection.splice(0, 1);
      }
      this.props.dispatch(updateCompareSelection(newCompareSelection));

      // add to history also
      // LRU strategy, max capacity is MAX_NUM_OF_HISTORY_ITEMS
      // The most recently used item is at the beginning.
      const newHistoryList = historyList.filter(
        (eachItem) => eachItem.key !== item.key
      );
      newHistoryList.splice(0, 0, item);
      if (newHistoryList.length > MAX_NUM_OF_HISTORY_ITEMS) {
        newHistoryList.splice(newHistoryList.length - 1, 1);
      }
      this.props.dispatch(updateHistoryList(newHistoryList));
    }
  };

  // onChange tab
  _onChangeTab = (event) => {
    this.setState({ activeTabId: event.detail.activeTabId });
  };

  render() {
    const {
      compareList,
      compareDict,
      compareSelection,
      historyList,
    } = this.props.deltaCompareReducer;

    // filter compare list since some are loading data and are not ready.
    const filteredCompareList: string[] = compareList.filter(
      (key) =>
        compareDict[key]["loadingStatus"] &&
        compareDict[key]["loadingStatus"] === constants.LOADING_SUCCESS
    );

    const tabs = [
      {
        label: "Compare List",
        id: "compare",
        content: (
          <CompareListDiv>
            <div className="awsui-util-action-stripe-group">
              <Button variant="primary" onClick={this._onClearCompareList}>
                Clear All
              </Button>
            </div>
            <br />
            {filteredCompareList.length === 0 && (
              <p className="text-secondary">No test case selected.</p>
            )}

            {filteredCompareList.length > 0 &&
              filteredCompareList.map((key, index) => {
                const testcaseInfo: { [key: string]: string } =
                  compareDict[key]["testcaseInfo"];
                const testLines: { [key: string]: string[] } =
                  compareDict[key]["tests"];
                return (
                  <CompareListSingleItem
                    key={key}
                    testcaseInfo={testcaseInfo}
                    testLines={testLines}
                    isLast={index === filteredCompareList.length - 1}
                    selection={compareSelection}
                    onClick={this._onClickCompareListItem}
                  />
                );
              })}
          </CompareListDiv>
        ),
      },
      {
        label: "History",
        id: "history",
        content: (
          <CompareListDiv>
            {historyList.length === 0 && (
              <p className="text-secondary">No history.</p>
            )}

            {historyList.length > 0 &&
              historyList.map((item, index) => (
                <HistoryListSingleItem
                  key={item.key}
                  historyItem={item}
                  isLast={index === historyList.length - 1}
                  selection={compareSelection}
                  onClick={this._onClickCompareListItem}
                />
              ))}
          </CompareListDiv>
        ),
      },
    ];

    return (
      <Tabs
        tabs={tabs}
        activeTabId={this.state.activeTabId}
        variant="container"
        onChange={this._onChangeTab}
      ></Tabs>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    deltaCompareReducer: state.deltaCompareReducer,
  };
};
export default connect<StateProps>(mapStateToProps)(DeltaCompareList);
