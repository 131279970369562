import { dynamicTestcaseType } from "../types/testcase-type";
// AJAX request related
import { config } from "../../config/config";
import endpoints from "../../config/endpoints";
import constants from "../../config/constants";
import { METRIC_INFO } from "../../config/constants";
import { capitalizeFirstLetter } from "../../utils/general-utils";

export const getTestcaseStatus = (
  project: string,
  build: string,
  testCategory: string,
  testType: string,
  countryOrDevice: string,
  requestId: number | undefined,
  labId: number | undefined
) => ({
  type: dynamicTestcaseType(constants.LOADING_LOAD, testType),
  testType,
  http: {
    cognito: {
      effect: {
        url:
          config.BASE_URL +
          endpoints.testcaseStatus(
            project,
            build,
            testCategory,
            testType,
            countryOrDevice,
            requestId,
            labId
          ),
        method: "GET",
      },
      commit: {
        type: dynamicTestcaseType(constants.LOADING_SUCCESS, testType),
        testType,
        log: {
          initiative: METRIC_INFO.INITIATIVE,
          feature: METRIC_INFO.TESTCASE,
          project: capitalizeFirstLetter(project),
          testcategory: METRIC_INFO[testCategory],
        },
      },
      rollback: {
        type: dynamicTestcaseType(constants.LOADING_FAIL, testType),
        testType,
        log: {
          initiative: METRIC_INFO.INITIATIVE,
          feature: METRIC_INFO.TESTCASE,
          project: capitalizeFirstLetter(project),
          testcategory: METRIC_INFO[testCategory],
        },
      },
    },
  },
});
