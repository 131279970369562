import React, { Component } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import {
  Flashbar,
  Button,
  FormField,
  Input,
  ButtonDropdown,
} from "@amzn/awsui-components-react/polaris";
import {
  getChannelPower,
  getCustomPower,
  setLimit,
  setBandwidthChannel,
  setTechnology,
  setCustomFreqList,
  setCustomBandwidthList,
  setCustomId,
  setFreqCustom,
  setBandwidthCustom,
  cleanUpCustomPower,
  setCustomChannelList,
  setCustomResponseResult,
} from "../../../../redux/actions/desense-config-action";
import constants, {
  DESENSE_COMPONENT_ID,
  DROPDOWN_DEFAULT,
  TEST_CATEGORY,
  TECHNOLOGY,
  DEFAULT_DESENSE_LIMIT,
} from "../../../../config/constants";
import { getTestcases } from "../utils/power-helper";
import "./power-config-style.css";
import { getCustomMessageItems } from "./config";

const DEFAULT_BANDWIDTH = {
  [TECHNOLOGY["2G"]]: "20",
  [TECHNOLOGY["5G"]]: "20",
  [TECHNOLOGY.BT]: "1",
  [TECHNOLOGY.ZB]: "2",
  [TECHNOLOGY.LORA]: "0.2",
};

interface StateProps {
  desenseConfigReducer: any;
}

// declare prop check
type Props = {
  dispatch: Dispatch<any>;
  history: any;
} & StateProps;

type State = {
  limit: string; // user input limit
  limitError: string; // input error
  bandwidthChannel: string; // user input bandwidth channel
  bandwidthChannelError: string; // input error
  technology: string; // selected technology dropdown
  technologyError: string; // input error
  freq: string; // user input freq
  freqError: string; // input error
  bandwidthCustom: string; // user input bandwidth custom
  bandwidthCustomError: string; // input error
  channelResponseError: string; // response error from BE
  customResponseError: string; // response error from BE
};

class PowerConfigForm extends Component<Props, State> {
  readonly state: State = Object.freeze({
    limit: "",
    limitError: "",
    bandwidthChannel: "",
    bandwidthChannelError: "",
    technology: DROPDOWN_DEFAULT.TECHNOLOGY,
    technologyError: "",
    freq: "",
    freqError: "",
    bandwidthCustom: "",
    bandwidthCustomError: "",
    channelResponseError: "",
    customResponseError: "",
  });

  componentDidMount() {
    // pre-fill config for user
    const reducer = this.props.desenseConfigReducer;

    const limit: string =
      reducer.limit.length === 0 ? DEFAULT_DESENSE_LIMIT : reducer.limit;
    this.setState({ limit });

    // user select at least one test case
    if (reducer.band) {
      const technology: string = reducer.technology;
      const bandwidthChannel: string = reducer.bandwidthChannel;
      const freq: string = reducer.freq;
      const bandwidthCustom: string = reducer.bandwidthCustom;

      // already submitted Configuration for channel/technology before, refill state
      if (technology !== "" && technology !== DROPDOWN_DEFAULT.TECHNOLOGY) {
        this.setState({
          technology,
          bandwidthChannel,
          freq,
          bandwidthCustom,
        });
      } else {
        // first load, prefill for not 2G technology use case
        // if (reducer.band.toLowerCase() !== TECHNOLOGY["2G"]) {
        //   this.setState({
        //     technology: reducer.band,
        //     bandwidthChannel: DEFAULT_BANDWIDTH[reducer.band.toLowerCase()],
        //   });
        // }
      }
    }
  }

  validateById = (id: string): boolean => {
    const stateVal: string = this.state[id];
    let errorMsg: string = "";

    // validate dropdown
    if (id === DESENSE_COMPONENT_ID.TECHNOLOGY) {
      if (this.state.technology === DROPDOWN_DEFAULT.TECHNOLOGY) {
        errorMsg = "Required";
      }
    } else {
      // validate input
      if (!stateVal) {
        errorMsg = "Required";
      } else if (!/^[0-9]+(\.[0-9]+)?$/i.test(stateVal)) {
        errorMsg = "Numbers only!";
      }
    }

    if (id === DESENSE_COMPONENT_ID.LIMIT) {
      this.setState({
        limitError: errorMsg,
      });
    }
    if (id === DESENSE_COMPONENT_ID.BANDWIDTH_CHANNEL) {
      this.setState({
        bandwidthChannelError: errorMsg,
      });
    }
    if (id === DESENSE_COMPONENT_ID.TECHNOLOGY) {
      this.setState({
        technologyError: errorMsg,
      });
    }
    if (id === DESENSE_COMPONENT_ID.FREQ) {
      this.setState({
        freqError: errorMsg,
      });
    }
    if (id === DESENSE_COMPONENT_ID.BANDWIDTH_CUSTOM) {
      this.setState({
        bandwidthCustomError: errorMsg,
      });
    }

    return errorMsg.length === 0;
  };

  _onSubmitChannel = () => {
    // clean up response error from last submit
    this.setState({ channelResponseError: "" });

    const reducer = this.props.desenseConfigReducer;
    const limitRes = this.validateById(DESENSE_COMPONENT_ID.LIMIT);
    const bandwidthRes = this.validateById(
      DESENSE_COMPONENT_ID.BANDWIDTH_CHANNEL
    );
    const technologyRes = this.validateById(DESENSE_COMPONENT_ID.TECHNOLOGY);
    if (
      !limitRes ||
      !bandwidthRes ||
      (reducer.band &&
        reducer.band.toLowerCase() === TECHNOLOGY["2G"] &&
        !technologyRes)
    ) {
      return;
    }

    const limit: string = this.state.limit;
    const bandwidthChannel: string = this.state.bandwidthChannel;
    const technology: string =
      this.state.technology === DROPDOWN_DEFAULT.TECHNOLOGY
        ? ""
        : this.state.technology;
    this.props.dispatch(setLimit(limit));
    this.props.dispatch(setBandwidthChannel(bandwidthChannel));
    this.props.dispatch(setTechnology(technology));

    const testcases = getTestcases(reducer.selectedData);
    if (testcases.length === 0) {
      window.alert("Select at least one test case.");
      return;
    }

    const requestJson: {} = {
      testcases,
      band: reducer.band,
      limit,
      bandwidth: bandwidthChannel,
      technology,
    };

    this.props.dispatch(getChannelPower(TEST_CATEGORY.DESENSE, requestJson));

    // clean up custom power in reducer
    this.props.dispatch(setCustomFreqList([]));
    this.props.dispatch(setCustomBandwidthList([]));
    this.props.dispatch(setCustomChannelList([]));
    this.props.dispatch(cleanUpCustomPower());
    this.props.dispatch(setCustomId(0));
    this.props.dispatch(setCustomResponseResult(""));

    // clean up custom power in state
    this.clearCustomPowerConfig();
  };

  getCustomInput = (selectedData, freq, bandwidthCustom) => {
    let regions: Array<any> = [];
    Object.keys(selectedData).forEach((id) => {
      const testcase = selectedData[id];
      if (
        testcase &&
        testcase.channelPower &&
        Array.isArray(Object.keys(testcase.channelPower)) &&
        Object.keys(testcase.channelPower).length > 0
      ) {
        regions = Object.keys(testcase.channelPower);
      }
    });

    let id: number = this.props.desenseConfigReducer.customId;
    const customInput = {};
    regions.forEach((region) => {
      customInput[region] = {
        custom_channel: [`Custom ${id}`],
        custom_freq: [parseFloat(freq)],
        custom_bandwidth: [parseFloat(bandwidthCustom)],
      };
    });

    return customInput;
  };

  _onAddCustom = () => {
    // clean up response error from last submit
    this.setState({ customResponseError: "" });

    const reducer = this.props.desenseConfigReducer;
    // check Configuration for Channel/Technology
    const limit: string = reducer.limit;
    const technology: string = reducer.technology;
    if (
      !limit ||
      limit.length === 0 ||
      !technology ||
      technology.length === 0 ||
      technology === DROPDOWN_DEFAULT.TECHNOLOGY
    ) {
      window.alert(
        "Select and submit Configuration for Channel/Technology first."
      );
      return;
    }

    // check Custom Point Adder
    const freqRes = this.validateById(DESENSE_COMPONENT_ID.FREQ);
    const bandwidthRes = this.validateById(
      DESENSE_COMPONENT_ID.BANDWIDTH_CUSTOM
    );
    if (!freqRes || !bandwidthRes) {
      return;
    }

    const freq: string = this.state.freq;
    const bandwidthCustom: string = this.state.bandwidthCustom;
    let id: number = this.props.desenseConfigReducer.customId;
    id++;

    // add custom freq, custom bandwidth and custom id to list
    this.props.dispatch(
      setCustomFreqList([...reducer.customFreqList, parseFloat(freq)])
    );
    this.props.dispatch(
      setCustomBandwidthList([
        ...reducer.customBandwidthList,
        parseFloat(bandwidthCustom),
      ])
    );
    this.props.dispatch(setCustomId(id));
    this.props.dispatch(
      setCustomChannelList([...reducer.customChannelList, `Custom ${id}`])
    );
    this.props.dispatch(setFreqCustom(freq));
    this.props.dispatch(setBandwidthCustom(bandwidthCustom));

    const selectedData = this.props.desenseConfigReducer.selectedData;
    const testcases = getTestcases(selectedData);
    if (testcases.length === 0) {
      window.alert("Select at least one test case.");
      return;
    }

    const custom_input = this.getCustomInput(
      selectedData,
      freq,
      bandwidthCustom
    );
    if (Object.keys(custom_input).length === 0) {
      window.alert(
        "Select and submit Configuration for Channel/Technology first."
      );
      return;
    }

    const requestJson: {} = {
      testcases,
      limit: parseFloat(limit),
      technology,
      custom_input,
    };

    console.log("requestJson: ", requestJson);

    this.props.dispatch(getCustomPower(TEST_CATEGORY.DESENSE, requestJson));
  };

  // change it from clear to reset. As user requires an default limit.
  // Also, it's known issue that if clear the limit,
  // then onchange won't work when input the default limit at the first time
  resetChannelPowerConfig = () => {
    this.setState({
      limit: DEFAULT_DESENSE_LIMIT,
      limitError: "",
      bandwidthChannel: "",
      bandwidthChannelError: "",
      technology: DROPDOWN_DEFAULT.TECHNOLOGY,
      technologyError: "",
    });
  };

  clearCustomPowerConfig = () => {
    this.setState({
      freq: "",
      freqError: "",
      bandwidthCustom: "",
      bandwidthCustomError: "",
      customResponseError: "",
    });
  };

  _onChangeInput = (event: any) => {
    const id: string = event.target.id;
    const value: string = event.detail.value;

    if (id === DESENSE_COMPONENT_ID.LIMIT) {
      this.setState({
        limit: value,
        limitError: "",
      });
    }
    if (id === DESENSE_COMPONENT_ID.BANDWIDTH_CHANNEL) {
      this.setState({
        bandwidthChannel: value,
        bandwidthChannelError: "",
      });
    }
    if (id === DESENSE_COMPONENT_ID.FREQ) {
      this.setState({
        freq: value,
        freqError: "",
      });
    }
    if (id === DESENSE_COMPONENT_ID.BANDWIDTH_CUSTOM) {
      this.setState({
        bandwidthCustom: value,
        bandwidthCustomError: "",
      });
    }

    // validate user input
    this.validateById(id);
  };

  _onChangeTechnology = (event: any) => {
    let technology: string = event.detail.id;

    if (technology !== this.state.technology) {
      this.setState({
        technology,
        technologyError: "",
        bandwidthChannel: DEFAULT_BANDWIDTH[technology.toLowerCase()],
        bandwidthChannelError: "",
      });
    }
  };

  getItems = (categoryList: Array<string>): any => {
    const items: Array<ButtonDropdown.Item> = [];

    categoryList.forEach((category) => {
      const item: ButtonDropdown.Item = { id: category, text: category };
      items.push(item);
    });

    return items;
  };

  render() {
    // dropdown items show to user
    const technologyItems = this.getItems(Object.keys(TECHNOLOGY));
    const { channelLoadingStatus, customLoadingStatus, customResponseResult } =
      this.props.desenseConfigReducer;

    return (
      <div className="desense-power-config-form">
        <div className="desense-power-config-form-section">
          <h2>Configuration for Channel/Technology</h2>
          <div className="desense-power-config-form-section-input">
            <FormField
              label="Desense Tolerance value (db)"
              errorText={this.state.limitError}
            >
              <div className="create-form-input">
                <Input
                  id={DESENSE_COMPONENT_ID.LIMIT}
                  placeholder={DEFAULT_DESENSE_LIMIT}
                  value={this.state.limit}
                  onChange={this._onChangeInput}
                ></Input>
              </div>
            </FormField>
            <FormField
              className="desense-tech-dropdown"
              label="Technology"
              errorText={this.state.technologyError}
              stretch={true}
            >
              <div className="create-form-dropdown">
                <ButtonDropdown
                  items={technologyItems}
                  onItemClick={this._onChangeTechnology}
                  children={this.state.technology}
                ></ButtonDropdown>
              </div>
            </FormField>
            <FormField
              label="Bandwidth (MHz)"
              errorText={this.state.bandwidthChannelError}
            >
              <div className="create-form-input">
                <Input
                  id={DESENSE_COMPONENT_ID.BANDWIDTH_CHANNEL}
                  value={this.state.bandwidthChannel}
                  onChange={this._onChangeInput}
                ></Input>
              </div>
            </FormField>
          </div>
          <br />

          {/* action stripe group */}
          <div className="awsui-util-action-stripe-group">
            <Button onClick={this.resetChannelPowerConfig}>Reset</Button>
            <Button
              variant="primary"
              onClick={this._onSubmitChannel}
              loading={channelLoadingStatus === constants.LOADING_LOAD}
            >
              Submit
            </Button>
          </div>
          <br />
          <br />
        </div>

        <div className="desense-power-config-form-section">
          <h2>Custom Point Adder</h2>
          <div>
            <div className="desense-power-config-form-section-input">
              <FormField
                label="Frequency Point (MHz)"
                errorText={this.state.freqError}
              >
                <div className="create-form-input">
                  <Input
                    id={DESENSE_COMPONENT_ID.FREQ}
                    // placeholder="2412"
                    value={this.state.freq}
                    onChange={this._onChangeInput}
                  ></Input>
                </div>
              </FormField>

              <FormField
                label="Bandwidth (MHz)"
                errorText={this.state.bandwidthCustomError}
              >
                <div className="create-form-input">
                  <Input
                    id={DESENSE_COMPONENT_ID.BANDWIDTH_CUSTOM}
                    // placeholder="20"
                    value={this.state.bandwidthCustom}
                    onChange={this._onChangeInput}
                  ></Input>
                </div>
              </FormField>
            </div>
            <br />

            {/* action stripe group */}
            <div className="awsui-util-action-stripe-group">
              <Button onClick={this.clearCustomPowerConfig}>Clear</Button>
              <Button
                variant="primary"
                onClick={this._onAddCustom}
                loading={customLoadingStatus === constants.LOADING_LOAD}
              >
                Add
              </Button>
            </div>
            <br />

            {customResponseResult && (
              <Flashbar
                items={getCustomMessageItems(customResponseResult)}
              ></Flashbar>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    desenseConfigReducer: state.desenseConfigReducer,
  };
};

export default connect<StateProps>(mapStateToProps)(PowerConfigForm);
