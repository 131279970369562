const deltaCompareType = {
  // update dropdown selection
  UPDATE_SELECTION: "DELTA_COMPARE_UPDATE_SELECTION",
  // update compare selection
  UPDATE_COMPARE_SELECTION: "DELTA_COMPARE_UPDATE_COMPARE_SELECTION",
  // ajax action to get testcases
  GET_TEST_CASES: "DELTA_COMPARE_GET_TESTS_CASES",
  GET_TEST_CASES_COMMIT: "DELTA_COMPARE_GET_TEST_CASES_COMMIT",
  GET_TEST_CASES_ROLLBACK: "DELTA_COMPARE_GET_TEST_CASES_ROLLBACK",
  // clear testcases
  CLEAR_TEST_CASES: "DELTA_COMPARE_CLEAR_TESTS_CASE",
  // ajax action to update compare list
  UPDATE_COMPARE_LIST: "DELTA_COMPARE_UPDATE_COMPARE_LIST",
  UPDATE_COMPARE_LIST_COMMIT: "DELTA_COMPARE_UPDATE_COMPARE_LIST_COMMIT",
  UPDATE_COMPARE_LIST_ROLLBACK: "DELTA_COMPARE_UPDATE_COMPARE_LIST_ROLLBACK",
  // update history list
  UPDATE_HISTORY_LIST: "DELTA_COMPARE_UPDATE_HISTORY_LIST",
  // clear compare list
  CLEAR_COMPARE_LIST: "DELTA_COMPARE_CLEAR_COMPARE_LIST",
  // ajax action to get plot data
  GET_PLOT_DATA: "DELTA_COMPARE_GET_PLOT_DATA",
  GET_PLOT_DATA_COMMIT: "DELTA_COMPARE_GET_PLOT_DATA_COMMIT",
  GET_PLOT_DATA_ROLLBACK: "DELTA_COMPARE_GET_PLOT_DATA_ROLLBACK",
};

export default deltaCompareType;
