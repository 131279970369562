const metadataType = {
  // get metadata
  GET_METADATA: "GET_METADATA",
  GET_METADATA_COMMIT: "GET_METADATA_COMMIT",
  GET_METADATA_ROLLBACK: "GET_METADATA_ROLLBACK",

  // project build nexted object
  PROJECT_BUILD_INFO_CALL: "PROJECT_BUILD_INFO_CALL",
  PROJECT_BUILD_INFO_CALL_COMMIT: "PROJECT_BUILD_INFO_CALL_COMMIT",
  PROJECT_BUILD_INFO_CALL_ROLLBACK: "PROJECT_BUILD_INFO_CALL_ROLLBACK",
};

export default metadataType;
