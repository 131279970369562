import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import NonsigLineChart from "../nonsig-line-chart";
import {
  DEFAULT_VISIBLE,
  SERIES_OPTIONS,
  LEGEND_OPTIONS,
} from "./comparison-constants";
import { formatChartFromPreferences } from "./comparison-utils";
import {
  CollectionPreferences,
  Popover,
  SpaceBetween,
} from "@amzn/awsui-components-react-v3";

const vertCenterHorizRightCSS = {
  display: "flex",
  justifyContent: "end",
  alignItems: "center",
};

const labels = {
  xaxis: { title: "Frequency (MHz)" },
  yaxis: { title: "Click to edit" },
};

const NonSigComparisonPlot = () => {
  // Redux State to fetch plot data
  const { nonsigReducer } = useSelector((state: any) => ({
    nonsigReducer: state.nonsigReducer,
  }));
  const { comparisonPlot, comparisonPlotMetadata } = nonsigReducer;

  // Component States
  const [preferences, setPreferences] = useState<Object>({
    visibleContent: DEFAULT_VISIBLE,
  });
  const [filteredPlotData, setFilteredPlotData] = useState<Object[]>([]);

  // Reformat Plot data anytime Preferences or Plat data changes
  useEffect(() => {
    const formattedPlotData = formatChartFromPreferences(
      comparisonPlot,
      comparisonPlotMetadata,
      preferences
    );
    setFilteredPlotData(formattedPlotData);
  }, [
    comparisonPlot,
    comparisonPlotMetadata,
    preferences,
    setFilteredPlotData,
  ]);

  return (
    <SpaceBetween size="s" direction="vertical">
      <div style={vertCenterHorizRightCSS}>
        <Popover
          dismissButton={false}
          position="top"
          size="small"
          triggerType="custom"
          content={"Click the gear icon"}
        >
          {<b>Chart Settings</b>}
        </Popover>
        <CollectionPreferences
          onConfirm={({ detail }) => setPreferences(detail)}
          preferences={preferences}
          visibleContentPreference={{
            title: "Chart Preferences",
            options: [
              {
                label: "Display Limit Series",
                options: SERIES_OPTIONS,
              },
              {
                label: "Legend Attributes",
                options: LEGEND_OPTIONS,
              },
            ],
          }}
          cancelLabel="Cancel"
          confirmLabel="Confirm"
          title="Chart Preferences"
        />
      </div>
      <NonsigLineChart
        title={"Comparison Chart"}
        data={filteredPlotData}
        layoutProps={{
          autosize: true,
          height: 800,
          showlegend: true,
          legend: {
            x: 0,
            yanchor: "top",
            orientation: "h",
          },
        }}
        axisLabels={labels}
        config={{ editable: true }}
      />
    </SpaceBetween>
  );
};

export default NonSigComparisonPlot;
