import categoryType from "../types/category-type";
import constants from "../../config/constants";
import { capitalizeFirstLetter } from "../../utils/general-utils";

const initialState = {
  category: "", // category created
  categoryList: [] as Array<string>,
  loadingStatus: constants.LOADING_LOAD,
};

const categoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case categoryType.GET_CATEGORY_LIST:
      return {
        ...state,
        loadingStatus: constants.LOADING_LOAD,
      };
    case categoryType.GET_CATEGORY_LIST_COMMIT:
      return {
        ...state,
        loadingStatus: constants.LOADING_SUCCESS,
        categoryList: Array.isArray(action.payload)
          ? formatCategoryList(action.payload as Array<string>)
          : [],
      };
    case categoryType.GET_CATEGORY_LIST_ROLLBACK:
      return {
        ...state,
        loadingStatus: constants.LOADING_FAIL,
      };
    case categoryType.SET_CATEGORY:
      return {
        ...state,
        category: action.category,
      };
    default:
      return state;
  }
};

const formatCategoryList = (categoryList: Array<string>): any => {
  const newCategoryList: Array<string> = [];
  categoryList.sort()
  categoryList.forEach((category) => {
    newCategoryList.push(capitalizeFirstLetter(category));
  });
  return newCategoryList;
};

export default categoryReducer;
