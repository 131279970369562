import { DESENSE_KEYS } from "../../../../config/constants";
// input an array of object
// return format data looks like
// var data = [{
//     type: "scattergl",
//     mode: "markers",
//     name:"trace1",
//     x: X,
//     y: Y
// }, {
//     type: "scattergl",
//     mode: "markers",
//     name:"trace2",
//     marker: {
//         line: {
//             width: 1,
//             color: '#404040'}
//     },
//     x: X,
//     y: Y
// }]
// adjust x range value to MHZ level
const xAxisAdjust = 1000000;
const dataKey = "data";

export const formatDesensChartData = (selectedData) => {
  const returnData = [];

  Object.keys(selectedData).forEach((testcaseKey) => {
    const currentObj = selectedData[testcaseKey];
    if (dataKey in currentObj) {
      const eachObject = currentObj[dataKey];
      const currentTestCase = [
        {
          type: "scattergl",
          mode: "lines",
          name: `Baseline ${testcaseKey}`,
          x: eachObject[DESENSE_KEYS.FREQ].map((x) => x / xAxisAdjust),
          y: eachObject[DESENSE_KEYS.BASELINE],
          hovertemplate: "<b>Freq</b>:%{x:.2f} <br><b>Baseline</b>:%{y:.2f}",
        },
        {
          type: "scattergl",
          mode: "lines",
          name: `Desense ${testcaseKey}`,
          x: eachObject[DESENSE_KEYS.FREQ].map((x) => x / xAxisAdjust),
          y: eachObject[DESENSE_KEYS.DESENSE],
          hovertemplate: "<b>Freq</b>:%{x:.2f} <br><b>Desense</b>:%{y:.2f}",
        },
      ];
      returnData.push(...currentTestCase);
    }
  });

  return returnData;
};

// check edge for input
export const checkEdge = (selectedData) => {
  let returnObject = {
    xMin: Number.MAX_VALUE,
    yMin: Number.MAX_VALUE,
    xMax: Number.MIN_VALUE,
    yMax: Number.MIN_VALUE,
  };
  Object.keys(selectedData).forEach((testcaseKey) => {
    const currentObj = selectedData[testcaseKey];
    if (dataKey in currentObj) {
      const eachObject = currentObj[dataKey];
      const currentyMin = Math.min(
        ...[
          Math.min(...eachObject[DESENSE_KEYS.DESENSE]),
          Math.min(...eachObject[DESENSE_KEYS.BASELINE]),
        ]
      );
      const currentyMax = Math.max(
        ...[
          Math.max(...eachObject[DESENSE_KEYS.DESENSE]),
          Math.max(...eachObject[DESENSE_KEYS.BASELINE]),
        ]
      );
      const currentxMax =
        Math.max(...eachObject[DESENSE_KEYS.FREQ]) / xAxisAdjust;
      const currentxMin =
        Math.min(...eachObject[DESENSE_KEYS.FREQ]) / xAxisAdjust;

      if (currentyMin < returnObject.yMin) {
        returnObject["yMin"] = currentyMin;
      }
      if (currentxMin < returnObject.xMin) {
        returnObject["xMin"] = currentxMin;
      }
      if (currentyMax > returnObject.yMax) {
        returnObject["yMax"] = currentyMax;
      }
      if (currentxMax > returnObject.xMax) {
        returnObject["xMax"] = currentxMax;
      }
    }
  });

  // if (!Array.isArray(desenseObjectArray) || !desenseObjectArray) {
  //   return returnObject;
  // }
  // desenseObjectArray.forEach((eachObject) => {
  //   // eachObject[DESENSE_KEYS.FREQ],
  //   // eachObject[DESENSE_KEYS.DESENSE],
  // });

  return returnObject;
};
