import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
// redux
import { Dispatch } from "redux";
import { connect } from "react-redux";
import {
  Button,
  Spinner,
  Icon,
  Alert,
  // Toggle,
  Select,
} from "@amzn/awsui-components-react/polaris";
import { capitalizeFirstLetter } from "../../../utils/general-utils";
import {
  antennaPassive,
  antennaActive,
  antennaOptions,
} from "./antenna-config";
import constants, {
  DEFAULT_DROPDOWN_LIMIT,
  TEST_CATEGORY,
} from "../../../config/constants";
// child components
import BreadcrumbGroup from "../../../components/breadcrumb-group";
// sub components
import AntennaDropdownList from "./dropdown/dropdown-list";
import AntennaPassiveVisualization from "../../visualization/antenna/antenna-passive/antenna-passive-visualization";
import AntennaActiveVisualization from "../../visualization/antenna/antenna-active/antenna-active-visualization";
import AntennaTabTable from "./antenna-tab-table/antenna-tab-table-root";
import querySearch from "query-string";

// redux action
import {
  setUserSelectDict,
  initAntennaLoadingStatus,
  postTcsResult,
} from "../../../redux/actions/antenna-config-action";

interface StateProps {
  antennaConfigReducer: any;
}

interface MatchParams {
  project: string;
  build: string;
  version: string;
  test_category: string;
}

interface QueryString {
  request_id?: number;
  lab_id?: number;
}

// declare prop check
type Props = {
  dispatch: Dispatch<any>;
} & typeof defaultProps &
  RouteComponentProps<MatchParams> &
  QueryString &
  StateProps;

// declare init state & default props
const defaultProps = Object.freeze({});
const initialState = Object.freeze({
  userTestCase: [1] as number[],
  antennaStatus: antennaPassive,
});

class AntennaTestcase extends Component<Props> {
  readonly state = initialState;
  private plotRef: React.RefObject<HTMLInputElement>;

  constructor(props) {
    super(props);
    this.plotRef = React.createRef();
  }

  onClickAddDropdown = () => {
    const { selectedData } = this.props.antennaConfigReducer;

    const currentTestCastObject = { ...selectedData };
    const newKeyNumber =
      Math.max(
        ...Object.keys(currentTestCastObject).map((key) => parseInt(key))
      ) + 1;
    currentTestCastObject[newKeyNumber] = {};

    this.props.dispatch(setUserSelectDict(currentTestCastObject));
  };
  onClickClear = () => {
    const { project, build, version } = this.props.match.params;

    // completed clean the object, remove states in dropdownlist
    const cleanSelectedData = {};
    this.props.dispatch(setUserSelectDict(cleanSelectedData));
    // init object, ask re-render component dropdownlist
    const initSelectedData = {
      0: {
        project: project,
        build: build,
        version: version,
      },
    };
    this.props.dispatch(setUserSelectDict(initSelectedData));
    this.props.dispatch(initAntennaLoadingStatus());
  };

  componentWillUnmount() {
    const cleanSelectedData = {};
    this.props.dispatch(setUserSelectDict(cleanSelectedData));
    // init object, ask re-render component dropdownlist
    const initSelectedData = {
      0: {},
    };
    this.props.dispatch(setUserSelectDict(initSelectedData));
    this.props.dispatch(initAntennaLoadingStatus());
  }

  _dataStatusChange = ({ detail: { selectedId } }) => {
    // this.setState({
    //   antennaStatus: selectedId,
    // });
    const { selectedData, testcaseDropdown } = this.props.antennaConfigReducer;
    // Our selectedData length limit is 5
    Object.keys(selectedData).forEach((eachUserSelect) => {
      if (
        selectedData[eachUserSelect] &&
        selectedData[eachUserSelect]["dsn"] &&
        !selectedData[eachUserSelect][selectedId] //checked for exist
      ) {
        // if (!selectedData[eachUserSelect][selectedId]) {
        //   console.log(
        //     selectedData[eachUserSelect][selectedId],
        //     !selectedData[eachUserSelect][selectedId]
        //   );
        // }
        const currentDSN = selectedData[eachUserSelect]["dsn"];
        const currentProject = selectedData[eachUserSelect]["project"];
        if (currentDSN !== null) {
          const tcsid = testcaseDropdown[currentDSN][selectedId];
          const requestJson = {
            test_case_key: eachUserSelect,
          };
          this.props.dispatch(initAntennaLoadingStatus());
          this.props.dispatch(
            postTcsResult(
              currentProject,
              TEST_CATEGORY.ANTENNA,
              tcsid,
              requestJson
            )
          );
        }
      }
    });
    this.setState({
      antennaStatus: selectedId,
    });
  };

  render() {
    const { tcsResultLoadingStatus, tcsResultMessage } =
      this.props.antennaConfigReducer;
    const { project, build, version } = this.props.match.params;
    const { selectedData } = this.props.antennaConfigReducer;

    // const showBandCheckWarnning = false;

    let queryString: QueryString = {};
    if (this.props.location && this.props.location.search) {
      queryString = querySearch.parse(this.props.location.search);
    }
    const requestId = queryString.request_id
      ? queryString.request_id
      : undefined;
    const labId = queryString.lab_id ? queryString.lab_id : undefined;

    const stopAddNew =
      Object.keys(selectedData).length >= DEFAULT_DROPDOWN_LIMIT ? true : false;

    const mapMultiDropdown = Object.keys(selectedData).map((eachKey, index) => (
      <div key={index}>
        Testcase {eachKey}:
        <AntennaDropdownList
          antennaStatus={this.state.antennaStatus}
          indexNumber={eachKey}
          project={project}
          build={build}
          version={version}
          requestId={requestId}
          labId={labId}
        />
      </div>
    ));

    return (
      <div>
        <BreadcrumbGroup
          textGroup={[
            { text: "Dashboard", href: "/" },
            { text: `Antenna Testcase View` }, // TestCategory
          ]}
        />
        <br />

        {mapMultiDropdown}
        <br />
        <div className="Btn-left-bottom">
          <Button onClick={this.onClickClear}>Clear</Button>
          <Button disabled={stopAddNew} onClick={this.onClickAddDropdown}>
            Add
          </Button>
          <div className="Btn-right-bottom float-right">
            {/* user select for this page to get correct info */}
            <Select
              options={antennaOptions}
              selectedOption={{
                id: this.state.antennaStatus,
                label: capitalizeFirstLetter(this.state.antennaStatus),
              }}
              onChange={this._dataStatusChange}
            />
            {/* <Toggle onChange={this._dataStatusChange}>Switch to Active</Toggle> */}
          </div>
        </div>
        <br />
        {constants.LOADING_BEFORE_START === tcsResultLoadingStatus && (
          <div className="desense-notice-info">
            Click submit & load the visualization
          </div>
        )}
        {constants.LOADING_LOAD === tcsResultLoadingStatus && (
          <div>
            <Spinner size="large" />
          </div>
        )}
        {constants.LOADING_SUCCESS === tcsResultLoadingStatus && (
          <div>
            {tcsResultMessage.includes("not") ? (
              <div>
                <Icon name="status-negative" size="normal" variant="error" />
                {tcsResultMessage}
              </div>
            ) : (
              <div>
                {this.state.antennaStatus === antennaPassive && (
                  <AntennaPassiveVisualization />
                )}
                {this.state.antennaStatus === antennaActive && (
                  <div>
                    <AntennaActiveVisualization />
                    <AntennaTabTable />
                  </div>
                )}
              </div>
            )}
          </div>
        )}
        {constants.LOADING_FAIL === tcsResultLoadingStatus && (
          <div>
            <Alert type="error" header="Fetch data failed">
              Loading Failed. {tcsResultMessage}
            </Alert>
          </div>
        )}
        <div style={{ left: "", bottom: "0", position: "fixed" }}>
          <Button
            onClick={() => {
              window.scrollTo(0, 0);
            }}
          >
            Jump to Top
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    antennaConfigReducer: state.antennaConfigReducer,
  };
};
export default withRouter(
  connect<StateProps>(mapStateToProps)(AntennaTestcase)
);
