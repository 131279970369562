export const menuItems = [
  {
    text: "Home",
    href: "/",
    type: "link",
  },
  {
    text: "Upload",
    type: "link",
    href: "/upload",
  },
  {
    text: "Tools",
    type: "dropdown",
    options: [
      {
        text: "Compliance Box Plot",
        href: "/compliance/box-plot",
      },
      {
        text: "Compliance Delta Compare",
        href: "/compliance/delta-compare",
      },
      {
        text: "Report History",
        href: "/reports/history",
      },
    ],
  },
  {
    text: "Project Management",
    type: "dropdown",
    options: [
      {
        text: "Create Category",
        href: "/category/create",
        type: "link",
      },
      {
        text: "Create Project",
        href: "/project/create",
        type: "link",
      },
      {
        text: "Create Build",
        href: "/build/create",
        type: "link",
      },
      {
        text: "Edit Build",
        href: "/build/edit",
        type: "link",
      },
    ],
  },
];

export const helpItem = {
  text: "Help & Support",
  type: "dropdown",
  options: [
    {
      text: "JIRA Dashboard",
      href:
        "https://issues.labcollab.net/secure/Dashboard.jspa?selectPageId=53025",
      useATag: true,
      newTab: true,
    },
    {
      text: "How to Create JIRA",
      href:
        "https://wiki.labcollab.net/confluence/display/WDI/How+to+Create+Jira+Reporting+to+WISE+Team#HowtoCreateJiraReportingtoWISETeam-SteptoCreateaJira",
      useATag: true,
      newTab: true,
    },
  ],
};

export const analyticsItem = {
  text: "Analytics",
  href: "/analytics",
};
