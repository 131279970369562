import React, { useState } from "react";
import { useCollection } from "@amzn/awsui-collection-hooks";
import {
  Table,
  Button,
  Pagination,
  PropertyFilter,
  Header,
} from "@amzn/awsui-components-react-v3";
import {
  TableEmptyState,
  EmptyState,
  getMatchesCountText,
  paginationLabels,
} from "../../../../../components/polaris/common-components";
import {
  genVisibleContentOpts,
  Preferences,
  PROPERTY_FILTERING_I18N_STRINGS,
} from "../nonsig-table-config";
import { useSelector, useDispatch } from "react-redux";
import { removeSelectedComparisonTests } from "../../../../../redux/actions/nonsig-config-action";
import {
  DEFAULT_PREFERENCES,
  FILTER_PROPS,
  COLUMN_DEFINITIONS,
  TABLE_MAP,
} from "./nonsig-comparison-config";

const NonSigComparisonTable = () => {
  const dispatch = useDispatch();
  const { nonsigReducer } = useSelector((state: any) => ({
    nonsigReducer: state.nonsigReducer,
  }));
  const { comparisonTable } = nonsigReducer;

  const [preferences, setPreferences] = useState(DEFAULT_PREFERENCES);
  const [selectedItems, setSelectedItems] = useState<any[]>([]);

  const {
    items,
    actions,
    filteredItemsCount,
    collectionProps,
    paginationProps,
    propertyFilterProps,
  } = useCollection(comparisonTable, {
    propertyFiltering: {
      filteringProperties: FILTER_PROPS,
      empty: <TableEmptyState resourceName={"Test case"} />,
      noMatch: (
        <EmptyState
          title="No matches"
          subtitle="We can’t find a match."
          action={
            <Button onClick={() => actions.setFiltering("")}>
              Clear filter
            </Button>
          }
        />
      ),
    },
    pagination: { pageSize: preferences.pageSize },
    sorting: {},
    selection: {},
  });

  return (
    <Table
      {...collectionProps}
      trackBy="id"
      selectionType="multi"
      onSelectionChange={({ detail }) => setSelectedItems(detail.selectedItems)}
      selectedItems={selectedItems}
      columnDefinitions={COLUMN_DEFINITIONS}
      items={items}
      // loading={tcsTableDataLoadingStatus}
      loadingText="Loading resources"
      visibleColumns={preferences.visibleContent}
      empty={"No test cases selected for comparison!"}
      header={
        <Header
          actions={
            <Button
              onClick={() => {
                // use map to make deep copy
                dispatch(
                  removeSelectedComparisonTests(selectedItems.map((i) => i))
                );
                setSelectedItems([]);
              }}
              disabled={items.length === 0 || selectedItems.length === 0}
            >
              Remove
            </Button>
          }
        ></Header>
      }
      resizableColumns={true}
      wrapLines={preferences.wrapLines}
      filter={
        <PropertyFilter
          {...propertyFilterProps}
          i18nStrings={PROPERTY_FILTERING_I18N_STRINGS}
          countText={getMatchesCountText(filteredItemsCount)}
        />
      }
      pagination={
        <Pagination {...paginationProps} ariaLabels={paginationLabels} />
      }
      preferences={
        <Preferences
          preferences={preferences}
          disabled={items.length === 0}
          setPreferences={(detail) => {
            setPreferences(detail);
          }}
          visibleContentOption={genVisibleContentOpts(TABLE_MAP)}
        />
      }
    />
  );
};

export default NonSigComparisonTable;
