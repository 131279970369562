import sampleType from "../types/sample-type";

const initialState = {
  exampleCallData: "",
  exampleCallStatusCode: "",
  // this is just for the demo
  // -1:not loaded, 0:loading, 1:loaded
  exampleCallIsLoaded: -1,
};

// interface Action {
//   type: string;
// }
const sampleReducer = (state = initialState, action) => {
  switch (action.type) {
    case sampleType.EXAMPLE_CALL:
      return {
        ...state,
        exampleCallIsLoaded: 0,
      };
    case sampleType.EXAMPLE_CALL_COMMIT:
      return {
        ...state,
        exampleCallData: action.payload.message,
        exampleCallStatusCode: action.statusCode,
        exampleCallIsLoaded: 1,
      };
    case sampleType.EXAMPLE_CALL_ROLLBACK:
      return {
        ...state,
        exampleCallData: "Error!",
        exampleCallStatusCode: action.statusCode ? action.statusCode : "",
        exampleCallIsLoaded: 1,
      };
    default:
      return state;
  }
};

export default sampleReducer;
