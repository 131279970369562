import { ScanTableRow } from "../../../../model/passive-active-scan";

export const activeScanPlotlyFormat = (data: {}) => {
  let rst: PlotData[] = [];
  Object.keys(data).forEach((dataRate) => {
    let onePlot = new PlotData(dataRate);

    // get table data
    const tableData: {} = { ...data[dataRate]["table"] };
    if (
      tableData["channel"] &&
      tableData["limit"] &&
      tableData["result"] &&
      tableData["status"] &&
      tableData["channel"].length === tableData["limit"].length &&
      tableData["channel"].length === tableData["result"].length &&
      tableData["channel"].length === tableData["status"].length
    ) {
      for (let i = 0; i < tableData["channel"].length; i++) {
        const row: ScanTableRow = {
          channel: tableData["channel"][i],
          limit: isNaN(tableData["limit"][i])
            ? ""
            : tableData["limit"][i] === 1
            ? "on"
            : "off",
          result: isNaN(tableData["result"][i])
            ? ""
            : tableData["result"][i] === 1
            ? "on"
            : "off",
          status: tableData["status"][i],
        };
        onePlot.tableData.push(row);
      }
    }

    // get plot data
    const plotData: {} = { ...data[dataRate]["plot"] };
    if (plotData["x"] && plotData["y"]) {
      const x = plotData["x"];
      const y = plotData["y"];
      onePlot.plotData.push(getLineData("power", x, y));
    }

    rst.push(onePlot);
  });

  return rst;
};

export class PlotData {
  tableData: any[];
  plotData: any[];
  layout: {};
  title: string;
  constructor(band: string) {
    this.tableData = [] as any;
    this.plotData = [] as any;
    this.title = band;
    this.layout = {
      xaxis: {
        title: "frequency",
        titlefont: {
          family: "Roboto",
          size: 18,
        },
      },
      yaxis: {
        title: {
          text: "power",
          titlefont: {
            family: "Roboto",
            size: 18,
          },
        },
      },
      margin: {
        t: 30,
      },
    };
  }
}

const getLineData = (name: string, x: string[], y: string[]): {} => {
  let line = {};
  return { name, x, y, line };
};
