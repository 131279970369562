import React from "react";
import { useSelector } from "react-redux";
import NonSigComparisonPlot from "../../../visualization/nonsig/line-chart/comparison-chart/nonsig-comparison-chart";
import NonSigComparisonTable from "../nonsig-table/comparison-table/nonsig-comparison-table";
import { SpaceBetween } from "@amzn/awsui-components-react-v3";

const ComparisonContainer = () => {
  const { nonsigReducer } = useSelector((state: any) => ({
    nonsigReducer: state.nonsigReducer,
  }));
  const { comparisonTable } = nonsigReducer;

  return (
    <>
      <SpaceBetween size={"s"}>
        <NonSigComparisonTable />
        {comparisonTable.length !== 0 ? <NonSigComparisonPlot /> : null}
      </SpaceBetween>
    </>
  );
};

export default ComparisonContainer;
