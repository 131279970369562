import React from "react";
// using the same color set
import { TEST_STATUS_COLOR } from "../../../../../config/color-config";

export const defaultPageSize = 5;

// define columns
export const columnDefinitions = [
  {
    id: "channel",
    header: "Channel",
    label: (sortState) => {
      const isColumnSorted = sortState.sortingColumn === "channel";
      const ascending = !sortState.sortingDescending;
      return `Channel, ${
        isColumnSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => item.channel,
    minWidth: "50px",
    visible: true,
  },
  {
    id: "freq",
    header: "Freq(MHz)",
    label: (sortState) => {
      const isColumnSorted = sortState.sortingColumn === "freq";
      const ascending = !sortState.sortingDescending;
      return `Freq, ${
        isColumnSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => item.freq,
    minWidth: "100px",
    visible: true,
  },
  {
    id: "bandwidth",
    header: "Bandwidth",
    label: (sortState) => {
      const isColumnSorted = sortState.sortingColumn === "bandwidth";
      const ascending = !sortState.sortingDescending;
      return `Bandwidth, ${
        isColumnSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => <span>{item.bandwidth} MHz</span>,
    minWidth: "100px",
    visible: true,
  },
  {
    id: "baseline",
    header: "Baseline",
    label: (sortState) => {
      const columnIsSorted = sortState.sortingColumn === "baseline";
      const ascending = !sortState.sortingDescending;
      return `Baseline, ${
        columnIsSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) =>
      item.baseline && !isNaN(item.baseline)
        ? item.baseline.toFixed(4)
        : item.baseline,
    minWidth: "100px",
    visible: true,
  },
  {
    id: "desense",
    header: "Desense",
    label: (sortState) => {
      const isColumnSorted = sortState.sortingColumn === "desense";
      const ascending = !sortState.sortingDescending;
      return `Desense, ${
        isColumnSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) =>
      item.desense && !isNaN(item.desense)
        ? item.desense.toFixed(4)
        : item.desense,
    minWidth: "100px",
    visible: true,
  },
  {
    id: "delta",
    header: "Delta Desense",
    label: (sortState) => {
      const isColumnSorted = sortState.sortingColumn === "delta";
      const ascending = !sortState.sortingDescending;
      return `Delta Desense, ${
        isColumnSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) =>
      item.delta && !isNaN(item.delta) ? item.delta.toFixed(4) : item.delta,
    minWidth: "120px",
    visible: true,
  },
  {
    id: "status",
    header: "Pass/Fail status",
    label: (sortState) => {
      const isColumnSorted = sortState.sortingColumn === "status";
      const ascending = !sortState.sortingDescending;
      return `Status, ${
        isColumnSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => (
      <span
        style={{ color: TEST_STATUS_COLOR[item.status], cursor: "pointer" }}
      >{` ${item.status ? item.status : ""}`}</span>
    ),
    minWidth: "100px",
    visible: true,
  },
];

// define default sorting column
export const defaultSortingColumn = "timestamp";
export const defaultSortingDescending = true;

// define sortable columns
export const sortableColumns = [
  { id: "channel", field: "channel" },
  { id: "freq", field: "freq" },
  { id: "bandwidth", field: "bandwidth" },
  { id: "baseline", field: "baseline" },
  { id: "desense", field: "desense" },
  { id: "delta", field: "delta" },
  { id: "status", field: "status" },
];

// define visiable columns
export const getVisibleColumns = () => [
  {
    label: "Properties",
    options: [
      { id: "channel", label: "Channel", visible: true, editable: false },
      { id: "freq", label: "Freq(MHz)", visible: true, editable: false },
      { id: "bandwidth", label: "Bandwidth", visible: true, editable: false },
      { id: "baseline", label: "Baseline", visible: true, editable: false },
      { id: "desense", label: "Desense", visible: true, editable: false },
      {
        id: "delta",
        label: "Delta Desense",
        visible: true,
        editable: false,
      },
      { id: "status", label: "Status", visible: true, editable: false },
    ],
  },
];

// define filtering options
export const filteringOptions = [
  {
    groupValuesLabel: "Channel",
    propertyKey: "channel",
    propertyLabel: "Channel",
    values: [],
  },
  {
    groupValuesLabel: "Freq(MHz)",
    propertyKey: "freq",
    propertyLabel: "Freq(MHz)",
    values: [],
  },
  {
    groupValuesLabel: "Bandwidth",
    propertyKey: "bandwidth",
    propertyLabel: "Bandwidth",
    values: [],
  },
  {
    groupValuesLabel: "Baseline",
    propertyKey: "baseline",
    propertyLabel: "Baseline",
    values: [],
  },
  {
    groupValuesLabel: "Desense",
    propertyKey: "desense",
    propertyLabel: "Desense",
    values: [],
  },
  {
    groupValuesLabel: "Delta Desense",
    propertyKey: "delta",
    propertyLabel: "Delta Desense",
    values: [],
  },
  {
    groupValuesLabel: "Status",
    propertyKey: "status",
    propertyLabel: "Status",
    values: [],
  },
];
