import landingType from "../types/landing-type";
import constants from "../../config/constants";
import { LandingResponse } from "../../model/http-json";
import { changeDateFormat } from "../../utils/date-utils";
import { LANDING_STATUS_TEXT } from "../../config/constants";

const initialState = {
  landingData: [] as LandingResponse,
  loadingStatus: constants.LOADING_LOAD,
};

const landingReducer = (state = initialState, action) => {
  switch (action.type) {
    case landingType.LANDING_SERVICE_CALL:
      return {
        ...state,
        loadingStatus: constants.LOADING_LOAD,
      };
    case landingType.LANDING_SERVICE_CALL_COMMIT:
      return {
        ...state,
        loadingStatus: constants.LOADING_SUCCESS,
        landingData: Array.isArray(action.payload)
          ? formatLandingTableData(action.payload as LandingResponse)
          : [],
      };
    case landingType.LANDING_SERVICE_CALL_ROLLBACK:
      return {
        ...state,
        loadingStatus: constants.LOADING_FAIL,
      };
    default:
      return state;
  }
};

export default landingReducer;

const formatLandingTableData = (data: LandingResponse) => {
  data.forEach((item, index) => {
    item.index = index;
    item.status = LANDING_STATUS_TEXT[item.status];
    item.created_time = changeDateFormat(item.created_time);
    item.updated_time = changeDateFormat(item.updated_time);
  });

  return data;
};
