import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Button } from "@amzn/awsui-components-react/polaris";
import {
  ContainerSpninner,
  ContainerFail,
  ContainerNoData,
} from "../../../components/container-status-indicator";
import ProgressContent from "./progress-content/progress-content";
import { mergeStatusData } from "./utils";
import constants, {
  ENG_COM_TEST_TYPE,
  COUNTRY,
} from "../../../config/constants";
import { StatusSchema } from "../../../model/overview";

const PRE_DEFINED_COUNTRY_ORDER = [COUNTRY.US, COUNTRY.CA];

// declare prop check
type Props = {
  powerTableLoadingStatus: number;
  activeScanLoadingStatus: number;
  passiveScanLoadingStatus: number;
  powerTableStatusData: StatusSchema | undefined | { [key: string]: any };
  activeScanStatusData: StatusSchema | undefined | { [key: string]: any };
  passiveScanStatusData: StatusSchema | undefined | { [key: string]: any };
  getData: (type: string) => void;
  TCSPagelink: string;
  requestId?: number;
  labId?: number;
} & typeof defaultProps &
  RouteComponentProps<any>;

// declare init state & default props
const defaultProps = Object.freeze({});

class CountryTypePanel extends Component<Props> {
  static readonly defaultProps = defaultProps;

  _countryComparator = (a, b) => {
    // sort countrydevice list, based on the position in pre_defined_country_list order list
    /*    
    e.g. keys = [ca, us, eu, jp], order = [us, ca]
    after sort, keys = [us, ca, eu, jp]
  */
    let indexOfA = PRE_DEFINED_COUNTRY_ORDER.indexOf(a);
    if (indexOfA === -1) {
      indexOfA = Number.MAX_SAFE_INTEGER;
    }
    let indexOfB = PRE_DEFINED_COUNTRY_ORDER.indexOf(b);
    if (indexOfB === -1) {
      indexOfB = Number.MAX_SAFE_INTEGER;
    }

    return indexOfA - indexOfB;
  };

  render() {
    const {
      powerTableLoadingStatus,
      activeScanLoadingStatus,
      passiveScanLoadingStatus,
      powerTableStatusData,
      activeScanStatusData,
      passiveScanStatusData,
      getData,
      TCSPagelink,
      requestId,
      labId,
    } = this.props;

    let content: any = null;
    if (
      powerTableLoadingStatus === constants.LOADING_LOAD ||
      activeScanLoadingStatus === constants.LOADING_LOAD ||
      passiveScanLoadingStatus === constants.LOADING_LOAD
    ) {
      content = <ContainerSpninner />;
    } else if (
      powerTableLoadingStatus === constants.LOADING_FAIL ||
      activeScanLoadingStatus === constants.LOADING_FAIL ||
      passiveScanLoadingStatus === constants.LOADING_FAIL
    ) {
      content = <ContainerFail />;
    } else if (
      !powerTableStatusData &&
      !activeScanStatusData &&
      !passiveScanStatusData
    ) {
      content = <ContainerNoData />;
    } else {
      const { keys, values } = mergeStatusData(
        [powerTableStatusData, activeScanStatusData, passiveScanStatusData],
        this._countryComparator
      );

      content = (
        <ProgressContent
          keys={keys}
          values={values}
          TCSPagelink={TCSPagelink}
          defaultTestType={ENG_COM_TEST_TYPE.POWER_TABLE}
          requestId={requestId}
          labId={labId}
        />
      );
    }

    return (
      <div className="awsui-util-container">
        {/* header */}
        <div className="awsui-util-container-header">
          <div className="awsui-util-action-stripe">
            {/* title */}
            <div className="awsui-util-action-stripe-title">
              <h2>Power Table, Passive Scan and Active Scan</h2>
            </div>
            {/* button group */}
            <div className="awsui-util-action-stripe-group">
              <Button
                // variant="icon"
                icon="refresh"
                onClick={() => {
                  getData(ENG_COM_TEST_TYPE.POWER_TABLE);
                  getData(ENG_COM_TEST_TYPE.ACTIVE_SCAN);
                  getData(ENG_COM_TEST_TYPE.PASSIVE_SCAN);
                }}
              ></Button>
            </div>
          </div>
        </div>

        {/* body */}
        <div>{content}</div>
      </div>
    );
  }
}

export default withRouter(CountryTypePanel);
