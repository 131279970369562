import React, { Component } from "react";
// redux
import { Dispatch } from "redux";
// directly use plotly
import Plot from "react-plotly.js";
import { connect } from "react-redux";
import { Icon, Button, Toggle } from "@amzn/awsui-components-react/polaris";
import {
  formatSelectedData,
  updateLayoutName,
  getListofTech,
} from "./antenna-active-helper";
import { xOptionMap } from "./antenna-active-config";
import { capitalizeFirstLetter } from "../../../../../utils/general-utils";
import "./antenna-chart-style.css";

interface StateProps {
  antennaConfigReducer: any;
}

// declare prop check
type Props = {
  filterObject: Object;
  ySelect: string;
  width: number;
  chartType: string;
  dispatch: Dispatch<any>;
} & typeof defaultProps &
  StateProps;

// declare init state & default props
const defaultProps = Object.freeze({});
const initialState = Object.freeze({
  xTitle: xOptionMap[Object.keys(xOptionMap)[0]],
  xSelect: Object.keys(xOptionMap)[0],
  toggled: true,
});

class AntennaActiveChart extends Component<Props> {
  readonly state = initialState;
  private plotRef: React.RefObject<HTMLInputElement>;

  constructor(props) {
    super(props);
    this.plotRef = React.createRef();
  }

  _resetInput = () => {
    this.setState({
      xSelect: Object.keys(xOptionMap)[0],
    });
  };

  _onChangeToggle = ({ detail: { checked } }) => {
    const toggleValue = checked
      ? Object.keys(xOptionMap)[0]
      : Object.keys(xOptionMap)[1];

    this.setState({
      xSelect: toggleValue,
      toggled: !this.state.toggled,
    });
  };

  render() {
    const { xSelect } = this.state;
    const { ySelect, width, chartType } = this.props;

    const { selectedData } = this.props.antennaConfigReducer;

    // dynamic get list of tech and map to charts
    const mapCharts = getListofTech(selectedData, chartType).map(
      (eachTech, index) => {
        const formatData = formatSelectedData(
          selectedData,
          xSelect,
          ySelect,
          eachTech,
          chartType
        );
        return (
          <div key={index} className={"each-antenna-small-chart"}>
            <Plot
              data={formatData ? formatData : []}
              layout={updateLayoutName(eachTech, chartType, width)}
              config={{ displayModeBar: false }} // not showing mode bar
            />
          </div>
        );
      }
    );

    return (
      <div
        className={"antenna-base-chart"}
        style={{ width: `${width}` }}
        ref={this.plotRef}
      >
        <div className="awsui-util-container">
          <div className={"antenna-chart-group"}>{mapCharts}</div>

          {/* <div className={"rvr-x-title"}>{this.state.xTitle}</div> */}
          <div
            className="awsui-util-container-footer"
            // style={{ display: "flex" }}
          >
            <span className="antenna-footer-text">
              {"Switch X axis : "}
              <span style={{ marginLeft: "5px" }}>
                <Toggle
                  checked={this.state.toggled}
                  onChange={this._onChangeToggle}
                >
                  {xOptionMap[this.state.xSelect]}
                </Toggle>
              </span>
              {`, Y axis: ${capitalizeFirstLetter(ySelect)}`}
            </span>

            <div className="antenna-reset-btn">
              <Button onClick={this._resetInput}>
                <Icon name="undo" size="normal" variant="normal" />
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    antennaConfigReducer: state.antennaConfigReducer,
  };
};

export default connect<StateProps>(mapStateToProps)(AntennaActiveChart);
