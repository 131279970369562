import React from "react";
// define visiable columns

import { CollectionPreferences } from "@amzn/awsui-components-react-v3";
import { TEST_STATUS_COLOR } from "../../../../config/color-config";
import constants from "../../../../config/constants";

export const RESULT_COL_KEY = "result";

// define columns
export const createColumnDefs = (data: Object[], testFormat: string) => {
  const reducerTableMap = testFormat
    ? NONSIG_REDUCER_TABLE_MAP[testFormat]
    : [];

  const firstObject = data[0];
  const formattedConfig = firstObject
    ? Object.keys(firstObject)
        .filter((eachKey) => eachKey in reducerTableMap)
        .map((eachKey) => ({
          label: (sortState) => {
            const isColumnSorted = sortState.sortingColumn === eachKey;
            const ascending = !sortState.sortingDescending;
            return `${eachKey}, ${
              isColumnSorted
                ? `sorted ${ascending ? "ascending" : "descending"}`
                : "not sorted"
            }.`;
          },
          minWidth: 80,
          sortingField: eachKey,
          visible: true,
          cell: (item) => item[eachKey],
          ...NONSIG_REDUCER_TABLE_MAP[testFormat][eachKey].columnDefinitions,
        }))
    : [];

  return formattedConfig;
};

export const genVisibleContentOpts = (reducerTableMap: Object) => {
  // Generate options of preference selector based on column definitions
  const visibleOptions = Object.values(reducerTableMap)
    .filter(
      (eachValue) =>
        eachValue?.columnDefinitions?.id &&
        eachValue?.columnDefinitions?.header
    )
    .map((eachValue) => ({
      id: eachValue.columnDefinitions.id,
      label: eachValue.columnDefinitions.header,
      editable: true,
    }));

  return [
    {
      label: "Properties",
      options: visibleOptions,
    },
  ];
};

// define default sorting
export const DEFAULT_SORTING_COLUMN = {
  [constants.NONSIG_LITEPOINT]: {
    id: "upload_time",
    sortingField: "upload_time",
  },
  [constants.NONSIG_COCONUT]: {
    id: "uploaded_time",
    sortingField: "uploaded_time",
  },
};

export const DEFAULT_SORTING_DESCENDING = true;

export const PAGE_SIZE_OPTIONS = [
  { value: 5, label: "5 items" },
  { value: 10, label: "10 items" },
  { value: 25, label: "25 items" },
  { value: 50, label: "50 items" },
  { value: 100, label: "100 items" },
];

export const defaultPreferences = (COLUMN_DEFINITIONS) => {
  return {
    pageSize: 10,
    visibleContent: COLUMN_DEFINITIONS.filter((column) => column.visible).map(
      (column) => column.id
    ),
    wrapLines: false,
  };
};

export const Preferences = ({
  preferences,
  disabled,
  setPreferences,
  visibleContentOption,
}) => (
  <CollectionPreferences
    title="Preferences"
    confirmLabel="Confirm"
    cancelLabel="Cancel"
    disabled={disabled}
    preferences={preferences}
    onConfirm={({ detail }) => setPreferences(detail)}
    pageSizePreference={{
      title: "Page size",
      options: PAGE_SIZE_OPTIONS,
    }}
    wrapLinesPreference={{
      label: "Wrap text",
      description: "Click to wrap text in columns",
    }}
    visibleContentPreference={{
      title: "Select visible columns",
      options: visibleContentOption,
    }}
  />
);

export const PROPERTY_FILTERING_I18N_STRINGS = {
  filteringAriaLabel: "Search with property filtering",
  dismissAriaLabel: "Dismiss",
  filteringPlaceholder: "Search",
  groupValuesText: "Values",
  groupPropertiesText: "Properties",
  operatorsText: "Operators",
  operationAndText: "and",
  operationOrText: "or",
  operatorLessText: "Less than",
  operatorLessOrEqualText: "Less than or equal",
  operatorGreaterText: "Greater than",
  operatorGreaterOrEqualText: "Greater than or equal",
  operatorContainsText: "Contains",
  operatorDoesNotContainText: "Does not contain",
  operatorEqualsText: "Equals",
  operatorDoesNotEqualText: "Does not equal",
  editTokenHeader: "Edit filter",
  propertyText: "Property",
  operatorText: "Operator",
  valueText: "Value",
  cancelActionText: "Cancel",
  applyActionText: "Apply",
  allPropertiesLabel: "All properties",
  tokenLimitShowMore: "Show more",
  tokenLimitShowFewer: "Show fewer",
  clearFiltersText: "Clear filters",
  removeTokenButtonAriaLabel: () => "Remove token",
  enteredTextLabel: (text) => `Use: "${text}"`,
};

interface ReducerTableMap {
  [testFormat: string]: {
    [columnLabel: string]: any;
  };
}

export const NONSIG_REDUCER_TABLE_MAP: ReducerTableMap = {
  [constants.NONSIG_COCONUT]: {
    technology: {
      columnDefinitions: {
        id: "technology",
        header: "Tech",
      },
      filteringProps: {
        key: "technology",
        operators: ["=", "!=", ":", "!:"],
        propertyLabel: "Tech",
        groupValuesLabel: "Tech values",
      },
    },
    sub_technology: {
      columnDefinitions: {
        id: "sub_technology",
        header: "Test",
      },
      filteringProps: {
        key: "sub_technology",
        operators: ["=", "!=", ":", "!:"],
        propertyLabel: "Test",
        groupValuesLabel: "Test values",
      },
    },
    temperature: {
      columnDefinitions: {
        id: "temperature",
        header: "Temp",
      },
      filteringProps: {
        key: "temperature",
        operators: ["=", "!=", ":", "!:"],
        propertyLabel: "Temp",
        groupValuesLabel: "Temp values",
      },
    },
    voltage: {
      columnDefinitions: {
        id: "voltage",
        header: "Volt",
      },
      filteringProps: {
        key: "voltage",
        operators: ["=", "!=", ":", "!:"],
        propertyLabel: "Volt",
        groupValuesLabel: "Volt values",
      },
    },
    standard: {
      columnDefinitions: {
        id: "standard",
        header: "Standard",
      },
      filteringProps: {
        key: "standard",
        operators: ["=", "!=", ":", "!:"],
        propertyLabel: "Standard",
        groupValuesLabel: "Standard values",
      },
    },
    tx_chain: {
      columnDefinitions: {
        id: "chain",
        header: "Tx Ant",
      },
      filteringProps: {
        key: "chain",
        operators: ["=", "!=", ">", "<", "<=", ">="],
        propertyLabel: "Tx Ant",
        groupValuesLabel: "Tx Ant values",
      },
    },
    band: {
      columnDefinitions: {
        id: "band",
        header: "Band",
        sortingComparator: (a, b) => {
          a = a.band ? stringFreqToNumber(a.band) : -1;
          b = b.band ? stringFreqToNumber(b.band) : -1;
          return a - b;
        },
      },
      filteringProps: {
        key: "band",
        operators: ["=", "!=", ":", "!:"],
        propertyLabel: "Band",
        groupValuesLabel: "Band values",
      },
    },
    bandwidth: {
      columnDefinitions: {
        id: "bandwidth",
        header: "Bandwidth",
        sortingComparator: (a, b) => {
          a = a.bandwidth ? stringFreqToNumber(a.bandwidth) : -1;
          b = b.bandwidth ? stringFreqToNumber(b.bandwidth) : -1;
          return a - b;
        },
      },
      filteringProps: {
        key: "bandwidth",
        operators: ["=", "!=", ":", "!:"],
        propertyLabel: "Bandwidth",
        groupValuesLabel: "Bandwidth values",
      },
    },
    result: {
      columnDefinitions: {
        id: "result",
        header: "Result",
        cell: (item) => (
          <span
            style={{
              color: `${TEST_STATUS_COLOR[item.result.toLowerCase()]}`,
              fontWeight: 700,
            }}
          >
            {item.result}
          </span>
        ),
      },
      filteringProps: {
        key: "result",
        operators: ["=", "!=", ":", "!:"],
        propertyLabel: "Result",
        groupValuesLabel: "Result values",
      },
    },
    uploaded_time: {
      columnDefinitions: {
        id: "uploaded_time",
        header: "Upload Time",
      },
      filteringProps: {
        key: "uploaded_time",
        operators: ["=", "!=", ":", "!:"],
        propertyLabel: "Upload Time",
        groupValuesLabel: "Upload Time values",
      },
    },
  },

  [constants.NONSIG_LITEPOINT]: {
    technology: {
      columnDefinitions: {
        id: "technology",
        header: "Tech",
        width: 100,
      },
      filteringProps: {
        key: "technology",
        operators: ["=", "!=", ":", "!:"],
        propertyLabel: "Tech",
        groupValuesLabel: "Tech values",
      },
    },
    test_type: {
      columnDefinitions: {
        id: "test_type",
        header: "Sub-Tech",
        width: 200,
      },
      filteringProps: {
        key: "test_type",
        operators: ["=", "!=", ":", "!:"],
        propertyLabel: "Sub-Tech",
        groupValuesLabel: "Sub-Tech values",
      },
    },
    test_item: {
      columnDefinitions: {
        id: "test_item",
        header: "Test",
        width: 300,
      },
      filteringProps: {
        key: "test_item",
        operators: ["=", "!=", ":", "!:"],
        propertyLabel: "Test",
        groupValuesLabel: "Test values",
      },
    },
    temperature: {
      columnDefinitions: {
        id: "temperature",
        header: "Temp",
        width: 100,
      },
      filteringProps: {
        key: "temperature",
        operators: ["=", "!=", ":", "!:"],
        propertyLabel: "Temp",
        groupValuesLabel: "Temp values",
      },
    },
    voltage: {
      columnDefinitions: {
        id: "voltage",
        header: "Volt",
        width: 100,
      },
      filteringProps: {
        key: "voltage",
        operators: ["=", "!=", ":", "!:"],
        propertyLabel: "Volt",
        groupValuesLabel: "Volt values",
      },
    },
    standard: {
      columnDefinitions: {
        id: "standard",
        header: "Standard",
      },
      filteringProps: {
        key: "standard",
        operators: ["=", "!=", ":", "!:"],
        propertyLabel: "Standard",
        groupValuesLabel: "Standard values",
      },
    },
    chain: {
      columnDefinitions: {
        id: "chain",
        header: "Tx Ant.",
        width: 120,
      },
      filteringProps: {
        key: "chain",
        operators: ["=", "!=", ">", "<", "<=", ">="],
        propertyLabel: "Tx Ant.",
        groupValuesLabel: "Tx Ant. values",
      },
    },
    band: {
      columnDefinitions: {
        id: "band",
        header: "Band",
        sortingComparator: (a, b) => {
          a = a.band ? stringFreqToNumber(a.band) : -1;
          b = b.band ? stringFreqToNumber(b.band) : -1;
          return a - b;
        },
      },
      filteringProps: {
        key: "band",
        operators: ["=", "!=", ":", "!:"],
        propertyLabel: "Band",
        groupValuesLabel: "Band values",
      },
    },
    bandwidth: {
      columnDefinitions: {
        id: "bandwidth",
        header: "Bandwidth",
        width: 150,
        sortingComparator: (a, b) => {
          a = a.bandwidth ? stringFreqToNumber(a.bandwidth) : -1;
          b = b.bandwidth ? stringFreqToNumber(b.bandwidth) : -1;
          return a - b;
        },
      },
      filteringProps: {
        key: "bandwidth",
        operators: ["=", "!=", ":", "!:"],
        propertyLabel: "Bandwidth",
        groupValuesLabel: "Bandwidth values",
      },
    },
    // TODO: Dev DDB currently uses "result" - short fix
    result: {
      columnDefinitions: {
        id: "result",
        header: "Result",
        cell: (item) => (
          <span
            style={{
              color: `${TEST_STATUS_COLOR[item.result.toLowerCase()]}`,
              fontWeight: 700,
            }}
          >
            {item.result}
          </span>
        ),
      },
      filteringProps: {
        key: "result",
        operators: ["=", "!=", ":", "!:"],
        propertyLabel: "Result",
        groupValuesLabel: "Result values",
      },
    },
    upload_time: {
      columnDefinitions: {
        id: "upload_time", // match default sorting column constant
        header: "Upload Time (UTC)",
      },
      filteringProps: {
        key: "upload_time", // match default sorting column constant
        operators: ["=", "!=", ">", "<", "<=", ">=", ":", "!:"],
        propertyLabel: "Upload Time (UTC)",
        groupValuesLabel: "UTC Upload Time values",
      },
    },
  },
};

export const COCONUT_PLOT_POST_ATTR = {
  id_data_attr: "record_id", // Coconut ID comes from data

  // JSON keys for Body
  tc_id_json_key: constants.NONSIG_TCS_ID,
  dsn_config_json_key: constants.NONSIG_DSN_CONFIG_ID,
  // Row names to fetch data
  tc_id_data_attr: constants.NONSIG_TCS_ID,
  dsn_config_data_attr: constants.NONSIG_DSN_CONFIG_ID,
};
export const LITEPOINT_PLOT_POST_ATTR = {
  id: "litepoint", // Litepoint ID is constant

  // JSON keys for Body
  s3_key_json_key: "s3_key",
  s3_version_json_key: "s3_version_id",
  // Row names to fetch data
  s3_key_data_attr: "plot_s3_key",
  s3_key_version_attr: "plot_s3_version_id",
};

const stringFreqToNumber = (input) => {
  if (
    typeof input === "string" &&
    input.trim() in STRING_FREQUENCY_TO_NUMBERS
  ) {
    return STRING_FREQUENCY_TO_NUMBERS[input.trim()];
  }
  return -1;
};

const STRING_FREQUENCY_TO_NUMBERS = {
  // Remove leading zeros
  // -- Coconut
  // Band
  "900Mhz": 9,
  "2G": 20,
  "5G": 50,

  // Bandwidth
  "125k": 125, // 125 KHz
  "500k": 500, // 500 kHz,
  // three values for 20 MHz due to data inconsistency.
  // Use slightly different values so the returned sort is deterministic
  "2k": 1998, // typo in DB, should be 20 MHz
  "20.0": 1999, // 20 MHz
  "20": 2000, // 20 MHz
  "40": 4000, // 40 MHz
  "80": 8000, // 80 MHz

  // --- Litepoint
  // Band
  "900 MHz": 9,
  "2.4 GHz": 24,
  "5 GHz": 50,
  "6 GHz": 60,

  // Bandwidth
  "125 kHz": 125,
  "250 kHz": 250,
  "500 kHz": 500,
  "20 MHz": 2000,
  "40 MHz": 4000,
  "80 MHz": 8000,
  "160 MHz": 16000,
};
