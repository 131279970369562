import { dynamicTestcaseType } from "../types/testcase-type";
import constants, { ENG_COM_TEST_TYPE } from "../../config/constants";
import { TestcaseStatusResponse } from "../../model/http-json";
import { TestcaseTableRow } from "../../model/testcase";
import { changeDateFormat } from "../../utils/date-utils";

const engComInitState = () => {
  const initState: { [key: string]: any } = {};
  Object.keys(ENG_COM_TEST_TYPE).forEach((key: string) => {
    const testType = ENG_COM_TEST_TYPE[key];
    initState[`${testType}LoadingStatus`] = constants.LOADING_LOAD;
    initState[`${testType}TestcaseData`] = [] as TestcaseStatusResponse;
  });

  return initState;
};

const initialState = {
  testcaseData: [] as TestcaseStatusResponse,
  loadingStatus: constants.LOADING_LOAD,
  ...engComInitState(),
};

const testcaseReducer = (state = initialState, action) => {
  switch (action.type) {
    case dynamicTestcaseType(constants.LOADING_LOAD, action.testType):
      return {
        ...state,
        [action.testType
          ? `${action.testType}LoadingStatus`
          : "loadingStatus"]: constants.LOADING_LOAD,
      };
    case dynamicTestcaseType(constants.LOADING_SUCCESS, action.testType):
      return {
        ...state,
        [action.testType
          ? `${action.testType}LoadingStatus`
          : "loadingStatus"]: constants.LOADING_SUCCESS,
        [action.testType
          ? `${action.testType}TestcaseData`
          : "testcaseData"]: Array.isArray(action.payload)
          ? formatTestcaseStatusData(action.payload as TestcaseStatusResponse)
          : [],
      };
    case dynamicTestcaseType(constants.LOADING_FAIL, action.testType):
      return {
        ...state,
        [action.testType
          ? `${action.testType}LoadingStatus`
          : "loadingStatus"]: constants.LOADING_FAIL,
      };
    default:
      return state;
  }
};

export default testcaseReducer;

// format timestamp to local time
const formatTestcaseStatusData = (
  data: Array<TestcaseTableRow>
): Array<TestcaseTableRow> => {
  data.forEach((item) => {
    item.timestamp = changeDateFormat(item.timestamp);
  });
  return data;
};
