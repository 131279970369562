import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Button } from "@amzn/awsui-components-react/polaris";
import {
  ContainerSpninner,
  ContainerFail,
  ContainerNoData,
} from "../../../components/container-status-indicator";
import ProgressContent from "./progress-content/progress-content";
import { mergeStatusData } from "./utils";
import constants, { ENG_COM_TEST_TYPE } from "../../../config/constants";
import { StatusSchema } from "../../../model/overview";

// declare prop check
type Props = {
  bandEdgeLoadingStatus: number;
  harmonicsLoadingStatus: number;
  bandEdgeStatusData: StatusSchema | undefined | { [key: string]: any };
  harmonicsStatusData: StatusSchema | undefined | { [key: string]: any };
  getData: (testTtyp: string) => void;
  TCSPagelink: string;
  requestId?: number;
  labId?: number;
} & typeof defaultProps &
  RouteComponentProps<any>;

// declare init state & default props
const defaultProps = Object.freeze({});

class DeviceTypePanel extends Component<Props> {
  static readonly defaultProps = defaultProps;

  render() {
    const {
      bandEdgeLoadingStatus,
      harmonicsLoadingStatus,
      bandEdgeStatusData,
      harmonicsStatusData,
      getData,
      TCSPagelink,
      requestId,
      labId,
    } = this.props;
    let content: any = null;
    if (
      bandEdgeLoadingStatus === constants.LOADING_LOAD ||
      harmonicsLoadingStatus === constants.LOADING_LOAD
    ) {
      content = <ContainerSpninner />;
    } else if (
      bandEdgeLoadingStatus === constants.LOADING_FAIL ||
      harmonicsLoadingStatus === constants.LOADING_FAIL
    ) {
      content = <ContainerFail />;
    } else if (!bandEdgeStatusData && !harmonicsStatusData) {
      content = <ContainerNoData />;
    } else {
      const { keys, values } = mergeStatusData([
        bandEdgeStatusData,
        harmonicsStatusData,
      ]);
      content = (
        <ProgressContent
          keys={keys}
          values={values}
          TCSPagelink={TCSPagelink}
          defaultTestType={ENG_COM_TEST_TYPE.BAND_EDGE}
          requestId={requestId}
          labId={labId}
        />
      );
    }

    return (
      <div className="awsui-util-container">
        {/* header */}
        <div className="awsui-util-container-header">
          <div className="awsui-util-action-stripe">
            {/* title */}
            <div className="awsui-util-action-stripe-title">
              <h2>Band Edge and Harmonics</h2>
            </div>
            {/* button group */}
            <div className="awsui-util-action-stripe-group">
              <Button
                // variant="icon"
                icon="refresh"
                onClick={() => {
                  getData(ENG_COM_TEST_TYPE.BAND_EDGE);
                  getData(ENG_COM_TEST_TYPE.HARMONICS);
                }}
              ></Button>
            </div>
          </div>
        </div>

        {/* body */}
        <div>{content}</div>
      </div>
    );
  }
}

export default withRouter(DeviceTypePanel);
