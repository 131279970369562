import React from "react";
import { useSelector } from "react-redux";

import { Spinner, Alert } from "@amzn/awsui-components-react-v3";

import NonSigMainTable from "../nonsig-table/nonsig-main-table";
import NonSigLineCharts from "../../../visualization/nonsig/nonsig-visualization";
import constants from "../../../../config/constants";

const NonsigTableChartContainer = () => {
  const { nonsigReducer } = useSelector((state: any) => ({
    nonsigReducer: state.nonsigReducer,
  }));

  const {
    tcsSelection,
    tcsTableDataLoadingStatus,
    tcsResultLoadingStatus,
    tcsTableDataErrorMessage,
    tcsResultMessage,
  } = nonsigReducer;

  return (
    <>
      {tcsTableDataLoadingStatus === constants.LOADING_BEFORE_START && (
        <div>Please select to view detail testcase</div>
      )}
      {tcsTableDataLoadingStatus === constants.LOADING_LOAD && (
        <div>
          <Spinner /> Loading Testcase Table data ....
        </div>
      )}
      {tcsTableDataLoadingStatus === constants.LOADING_FAIL && (
        <Alert dismissAriaLabel="Close alert" type="warning">
          Error Message: {tcsTableDataErrorMessage}
        </Alert>
      )}
      {tcsTableDataLoadingStatus === constants.LOADING_SUCCESS && (
        <NonSigMainTable />
      )}
      <br />
      {/* chars here: */}
      {tcsResultLoadingStatus === constants.LOADING_LOAD && (
        <div>
          <Spinner /> Loading visualization data ....
        </div>
      )}
      {tcsResultLoadingStatus === constants.LOADING_FAIL && (
        <Alert dismissAriaLabel="Close alert" type="error">
          Error Message: {tcsResultMessage}
        </Alert>
      )}
      {tcsSelection &&
        tcsSelection.length > 0 &&
        tcsResultLoadingStatus === constants.LOADING_SUCCESS && (
          <NonSigLineCharts />
        )}{" "}
    </>
  );
};

export default NonsigTableChartContainer;
