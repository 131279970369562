// formatSelect for select
import { capitalizeFirstLetter } from "../../../../utils/general-utils";
import { DESENSE_SELECTION_KEY } from "../../../../config/constants";

const SPLIT_BY = "_";
// 1_EMMC_ANT1_2G
export const formatTCSSelect = (inputString: string) => {
  let returnString = "";
  // split string remove the 1 which is tcs id
  if (inputString.split(SPLIT_BY)) {
    const splitedString = inputString.split(SPLIT_BY).slice(1);
    returnString = splitedString.join(SPLIT_BY);
  }
  return returnString;
};

// input array: buildName_version, or config_dsn_firmware
// sepcifal case tcs_SUBSYSTEM_antenna_band, id is tcs_SUBSYSTEM but what we show to user
// is tcs_SUBSYSTEM
export const handleLvlOptions = (
  inputArray: Array<string>,
  levelKey: String
) => {
  let sliceStart = 0;
  let sliceEnd = 0;
  // slice differently for build api, config api, and tcs api
  if (levelKey === DESENSE_SELECTION_KEY.BUILD) {
    sliceEnd = 1;
  }
  if (
    levelKey === DESENSE_SELECTION_KEY.CONFIG ||
    levelKey === DESENSE_SELECTION_KEY.SUBSYSTEM
  ) {
    sliceEnd = 2;
  }

  const filteredArray = inputArray.map((eachCombine) =>
    eachCombine.includes(SPLIT_BY)
      ? eachCombine.split(SPLIT_BY).slice(sliceStart, sliceEnd).join(SPLIT_BY)
      : null
  );
  const uniqueList = Array.from(new Set(filteredArray));

  let resultList = uniqueList.map((item) => ({
    label: item ? capitalizeFirstLetter(item) : "",
    id: item,
  }));
  // take tcs_id, but dont show to user
  if (levelKey === DESENSE_SELECTION_KEY.SUBSYSTEM) {
    resultList = uniqueList.map((item) => ({
      label: item ? formatTCSSelect(item) : "",
      id: item,
    }));
  }

  return resultList;
};

// control the input "test_test_xxx" use inputArray and selectedValue,
// then filter to test_xxx,
// if want to get xxx, we input inputArray and test_test for selectedValue,
export const getSublvlOptions = (
  inputArray: Array<string>,
  selectedValue: string
) => {
  // filter selectedValues in inputArray,
  // each item format in filteredlistare the same as input array
  const filteredlist = inputArray.filter((eachValue) =>
    eachValue.includes(`${selectedValue}_`)
  );
  // using set to get unique
  const uniqueList = Array.from(new Set(filteredlist));
  const resultList = uniqueList.map((item) => {
    // chaneg item to current level
    const currentItem = item.replace(`${selectedValue}_`, "");
    return {
      label: currentItem ? capitalizeFirstLetter(currentItem) : "",
      id: currentItem,
    };
  });
  return resultList;
};

// handle antenna since this is complex than other sub levels,
// and adding another param in above func is not good since ths the
// only one issue
export const getAntennaOptions = (inputArray: Array<string>) => {
  // 1_EMMC_ANT1_2G, antenna is in the 2 index
  const filteredlist = inputArray.map((item) => {
    if (item.includes(SPLIT_BY)) {
      if (item.split(SPLIT_BY).length > 3) {
        item = item.split(SPLIT_BY)[2];
      }
    }
    return item;
  });
  const uniqueList = Array.from(new Set(filteredlist));

  const resultList = uniqueList.map((item) => {
    let filteredItem = item;
    const returnObject = {
      label: filteredItem,
      id: filteredItem,
    };
    return returnObject;
  });
  return resultList;
};

// TODO: a good to have:  handle build filter
// as user requested: 2G shall only compare with 2G band
// so in band level, once one is selected the others should be the same
