import React from "react";

export const defaultPageSize = 5;

// define columns
export const columnDefinitions = [
  {
    id: "technology",
    header: "Technology",
    label: (sortState) => {
      const isColumnSorted = sortState.sortingColumn === "technology";
      const ascending = !sortState.sortingDescending;
      return `Technology, ${
        isColumnSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => item.technology,
    minWidth: "50px",
    visible: true,
  },
  {
    id: "channel",
    header: "Channel",
    label: (sortState) => {
      const isColumnSorted = sortState.sortingColumn === "channel";
      const ascending = !sortState.sortingDescending;
      return `Channel, ${
        isColumnSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => item.channel,
    minWidth: "50px",
    visible: true,
  },
  {
    id: "freq",
    header: "Freq",
    label: (sortState) => {
      const isColumnSorted = sortState.sortingColumn === "freq";
      const ascending = !sortState.sortingDescending;
      return `Freq, ${
        isColumnSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => item.freq,
    minWidth: "100px",
    visible: true,
  },
  //   {
  //     id: "DSN",
  //     header: "DSN",
  //     label: (sortState) => {
  //       const isColumnSorted = sortState.sortingColumn === "DSN";
  //       const ascending = !sortState.sortingDescending;
  //       return `DSN, ${
  //         isColumnSorted
  //           ? `sorted ${ascending ? "ascending" : "descending"}`
  //           : "not sorted"
  //       }.`;
  //     },
  //     cell: (item) => <span>{item.DSN}</span>,
  //     minWidth: "100px",
  //     visible: true,
  //   },
  {
    id: "chamber",
    header: "Chamber",
    label: (sortState) => {
      const isColumnSorted = sortState.sortingColumn === "chamber";
      const ascending = !sortState.sortingDescending;
      return `Chamber, ${
        isColumnSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => <span>{item.chamber}</span>,
    minWidth: "100px",
    visible: true,
  },
  {
    id: "wifi",
    header: "WIFI MIMO",
    label: (sortState) => {
      const isColumnSorted = sortState.sortingColumn === "wifi";
      const ascending = !sortState.sortingDescending;
      return `WIFI, ${
        isColumnSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => <span>{item.wifi}</span>,
    minWidth: "100px",
    visible: true,
  },
  {
    id: "bt",
    header: "BT",
    label: (sortState) => {
      const isColumnSorted = sortState.sortingColumn === "bt";
      const ascending = !sortState.sortingDescending;
      return `bt, ${
        isColumnSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => <span>{item.bt}</span>,
    minWidth: "100px",
    visible: true,
  },
  {
    id: "zigbee",
    header: "Zigbee",
    label: (sortState) => {
      const isColumnSorted = sortState.sortingColumn === "zigbee";
      const ascending = !sortState.sortingDescending;
      return `Zigbee, ${
        isColumnSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => <span>{item.zigbee}</span>,
    minWidth: "100px",
    visible: true,
  },
];

// define default sorting column
export const defaultSortingColumn = "technology";
export const defaultSortingDescending = true;

// define sortable columns
export const sortableColumns = [
  { id: "technology", field: "technology" },
  { id: "channel", field: "channel" },
  { id: "freq", field: "freq" },
  //   { id: "DSN", field: "DSN" },
  { id: "chamber", field: "chamber" },
  { id: "wifi", field: "wifi" },
  { id: "bt", field: "bt" },
  { id: "zigbee", field: "zigbee" },
];

// define visiable columns
export const getVisibleColumns = () => [
  {
    label: "Properties",
    options: [
      { id: "technology", label: "Technology", visible: true, editable: false },
      { id: "channel", label: "Channel", visible: true, editable: false },
      { id: "freq", label: "Freq", visible: true, editable: false },
      //   { id: "DSN", label: "DSN", visible: true, editable: false },
      { id: "chamber", label: "Chamber", visible: true, editable: false },
      { id: "wifi", label: "WIFI MIMO", visible: true, editable: false },
      { id: "bt", label: "BT", visible: true, editable: false },
      { id: "zigbee", label: "Zigbee", visible: true, editable: false },
    ],
  },
];

// define filtering options
export const filteringOptions = [
  {
    groupValuesLabel: "Technology",
    propertyKey: "technology",
    propertyLabel: "Technology",
    values: [],
  },
  {
    groupValuesLabel: "Channel",
    propertyKey: "channel",
    propertyLabel: "Channel",
    values: [],
  },
  {
    groupValuesLabel: "Freq",
    propertyKey: "freq",
    propertyLabel: "Freq",
    values: [],
  },
];
