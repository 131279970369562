const tcsResultType = {
  // get tcs result STATUS
  GET_TCS_RESULT_STATUS: "GET_TCS_RESULT_STATUS",
  GET_TCS_RESULT_STATUS_COMMIT: "GET_TCS_RESULT_STATUS_COMMIT",
  GET_TCS_RESULT_STATUS_ROLLBACK: "GET_TCS_RESULT_STATUS_ROLLBACK",

  POST_TCS_RESULT_STATUS: "POST_TCS_RESULT_STATUS",
  POST_TCS_RESULT_STATUS_COMMIT: "POST_TCS_RESULT_STATUS_COMMIT",
  POST_TCS_RESULT_STATUS_ROLLBACK: "POST_TCS_RESULT_STATUS_ROLLBACK",

  SET_TCS_RESULT_STATUS: "SET_TCS_RESULT_STATUS",
  CLEAN_DESENSE_ARRAY:"CLEAN_DESENSE_ARRAY",
  DECREASE_DESENSE_ARRAY:"DECREASE_DESENSE_ARRAY",
};

export default tcsResultType;
