import React, { Component } from "react";
import AntennaActiveChart from "./active-charts/antenna-active-chart";
import { ANTENNA_ACTIVE_KEY_LIST } from "../../../../config/constants";
import {awsRum} from "../../../../index";

interface StateProps {}

// declare prop check
type Props = {} & typeof defaultProps & StateProps;

// declare init state & default props
const defaultProps = Object.freeze({});
const initialState = Object.freeze({
  // inherit
  mainPlotWidth: window.innerWidth,
});

class AntennaActiveVisualization extends Component<Props> {
  readonly state = initialState;
  private plotRef: React.RefObject<HTMLInputElement>;

  constructor(props) {
    super(props);
    this.plotRef = React.createRef();
    awsRum?.recordEvent("antenna_active_Visualization",{
      metricCategory: 'antenna_active_Visualization'
    });
  }

  componentDidMount() {
    // handle resize for the visualization
    const antennaChartNode = this.plotRef.current;
    if (antennaChartNode) {
      this.setState({
        mainPlotWidth: antennaChartNode.clientWidth,
      });
    }
    // handle init new value
  }

  updateWindowDimensions = () => {
    // const node = this.dashboardRightRef.current;
    const antennaChartNode = this.plotRef.current;
    if (antennaChartNode) {
      this.setState({
        mainPlotWidth: antennaChartNode.clientWidth,
      });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    //  Add event listener to window resize event
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  render() {
    const currentPageWidth = this.state.mainPlotWidth - 20;
    return (
      <div className={"rvr-base-chart"} ref={this.plotRef}>
        <div>
          {ANTENNA_ACTIVE_KEY_LIST.map((eachKey, index) => {
            return (
              <div key={index}>
                <AntennaActiveChart
                  ySelect={"power"}
                  width={currentPageWidth / 2.1}
                  chartType={eachKey}
                />
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default AntennaActiveVisualization;
