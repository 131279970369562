import React, { Component } from "react";
// redux
import { Dispatch } from "redux";
import { connect } from "react-redux";
// sub components
import { triggerAutoUpload } from "../../../redux/actions/upload-action";
import { Button, Icon, Spinner } from "@amzn/awsui-components-react/polaris";
// constatns
import constants from "../../../config/constants";

interface StateProps {
  uploadReducer: any;
}
type Props = {
  dispatch: Dispatch<any>;
} & typeof defaultProps &
  StateProps;
type State = typeof initialState;

const defaultProps = Object.freeze({});
const initialState = Object.freeze({});

class TriggerAutoUploadBtn extends Component<Props, State> {
  static readonly defaultProps = defaultProps;
  readonly state = initialState;

  _onClickTriggger = (event) => {
    this.props.dispatch(triggerAutoUpload(false));
  };

  render() {
    const {
      autoUploadLoadingStatus,
      autoUploadLoadingMessage,
      syncTime,
    } = this.props.uploadReducer;

    return (
      <React.Fragment>
        <div style={{ padding: "7px" }}>
          {autoUploadLoadingStatus === constants.LOADING_SUCCESS && (
            <span className="awsui-util-status-positive">
              <Icon name="status-positive" />{" "}
              <span style={{ fontSize: "14px" }}>
                Sync up started(Track in below upload history table after 10min)
              </span>
            </span>
          )}
          <span className="awsui-util-header-counter">{`Last Sync up:${syncTime}`}</span>

          {autoUploadLoadingStatus === constants.LOADING_FAIL && (
            <span className="awsui-util-status-negative">
              <Icon variant="error" name="status-negative" />
              <span style={{ fontSize: "14px" }}>
                Error. {autoUploadLoadingMessage}
              </span>
            </span>
          )}
        </div>
        <Button
          variant="primary"
          wrapText={false}
          onClick={this._onClickTriggger}
          disabled={
            autoUploadLoadingStatus === constants.LOADING_LOAD ? true : false
          }
        >
          {autoUploadLoadingStatus === constants.LOADING_LOAD ? (
            <Spinner />
          ) : (
            "Auto Upload Sync up"
          )}
        </Button>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    uploadReducer: state.uploadReducer,
  };
};
export default connect(mapStateToProps)(TriggerAutoUploadBtn);
