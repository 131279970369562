import React from "react";
import { TEST_STATUS_COLOR } from "../../../config/color-config";

export const defaultPageSize = 5;

const MIN_WIDTH = "50px";

// define columns
export const getColumnDefinitions = (getAddButton) => [
  {
    id: "tcs_id",
    header: "Add",
    cell: getAddButton,
    minWidth: MIN_WIDTH,
  },
  {
    id: "technology",
    header: "Tech",
    label: (sortState) => {
      const isColumnSorted = sortState.sortingColumn === "technology";
      const ascending = !sortState.sortingDescending;
      return `Technology, ${
        isColumnSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => (item.technology ? item.technology.toUpperCase() : ""),
    minWidth: MIN_WIDTH,
  },
  {
    id: "mode",
    header: "Mode",
    label: (sortState) => {
      const isColumnSorted = sortState.sortingColumn === "mode";
      const ascending = !sortState.sortingDescending;
      return `Mode, ${
        isColumnSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => item.mode,
    minWidth: MIN_WIDTH,
  },
  {
    id: "bandwidth",
    header: "Bandwidth",
    label: (sortState) => {
      const isColumnSorted = sortState.sortingColumn === "bandwidth";
      const ascending = !sortState.sortingDescending;
      return `Bandwidth, ${
        isColumnSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => item.bandwidth,
    minWidth: MIN_WIDTH,
  },
  {
    id: "antenna",
    header: "Ant",
    label: (sortState) => {
      const columnIsSorted = sortState.sortingColumn === "antenna";
      const ascending = !sortState.sortingDescending;
      return `Antenna, ${
        columnIsSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => item.antenna,
    minWidth: MIN_WIDTH,
  },
  {
    id: "firmware",
    header: "Firmware",
    label: (sortState) => {
      const isColumnSorted = sortState.sortingColumn === "firmware";
      const ascending = !sortState.sortingDescending;
      return `Firmware, ${
        isColumnSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => item.firmware,
    minWidth: MIN_WIDTH,
  },
  {
    id: "status",
    header: "Status",
    label: (sortState) => {
      const isColumnSorted = sortState.sortingColumn === "status";
      const ascending = !sortState.sortingDescending;
      return `Status, ${
        isColumnSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => (
      <span
        style={{ color: TEST_STATUS_COLOR[item.status] }}
      >{` ${item.status}`}</span>
    ),
    minWidth: MIN_WIDTH,
  },
];

// define default sorting column
export const defaultSortingColumn = "status";
export const defaultSortingDescending = true;

// define sortable columns
export const sortableColumns = [
  { id: "technology", field: "technology" },
  { id: "mode", field: "mode" },
  { id: "bandwidth", field: "bandwidth" },
  { id: "antenna", field: "antenna" },
  { id: "firmware", field: "firmware" },
  { id: "test_type", field: "test_type" },
  { id: "status", field: "status" },
];

export const filteringOptions = [
  {
    groupValuesLabel: "Technology",
    propertyKey: "technology",
    propertyLabel: "Technology",
    values: [],
  },
  {
    groupValuesLabel: "Mode",
    propertyKey: "mode",
    propertyLabel: "Mode",
    values: [],
  },
  {
    groupValuesLabel: "Bandwidth",
    propertyKey: "bandwidth",
    propertyLabel: "Bandwidth",
    values: [],
  },
  {
    groupValuesLabel: "Antenna",
    propertyKey: "antenna",
    propertyLabel: "Antenna",
    values: [],
  },
  {
    groupValuesLabel: "Firmware",
    propertyKey: "firmware",
    propertyLabel: "Firmware",
    values: [],
  },
  {
    groupValuesLabel: "Test Type",
    propertyKey: "test_type",
    propertyLabel: "Test Type",
    values: [],
  },
  {
    groupValuesLabel: "Status",
    propertyKey: "status",
    propertyLabel: "Status",
    values: [],
  },
];
