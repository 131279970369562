import React from "react";
import {
  DESENSE_TABLE_KEYS_DEFAULT,
  DESENSE_TABLE_KEYS_CUSTOM,
} from "../../../../../config/constants";
// using the same color set

// OUTPUT tableFormat = [
//     {
//       name: "Item 1",
//       alt: "First",
//       description: "This is the first item",
//       col1: "1A",
//       col2: "Small",
//     },
//     {
//       name: "Item 2",
//       alt: "Second",
//       description: "This is the second item",
//       col1: "1B",
//       col2: "Large",
//     },]

// const selectedData = {
//   0: {
//     channelPower: {
//       default: {
//         channel: [1, 2],
//         freq: [2420, 2422],
//         bandwidth: [20, 20],
//         baseline_power: [-127.01209708780584, -128.01209708780584],
//         desense_power: [-126.96525857450574, -127],
//         delta: [0.046838513300102136, 0.046],
//         status: ["passed", "failed"],
//       },
//     },
//   },
//   1: {
//     channelPower: {
//       default: {
//         channel: [2, 3],
//         freq: [2420, 2422],
//         bandwidth: [20, 20],
//         baseline_power: [-127.01209708780584, -128.01209708780584],
//         desense_power: [-126.96525857450574, -127],
//         delta: [0.046838513300102136, 0.046],
//         status: ["passed", "failed"],
//       },
//     },
//   },
// };
export const formatCompareData = (inputObject: Object, regionKey: string) => {
  let channelPowerList = [];
  let customPowerList = [];
  // the only one place we use in this table, so leave it here
  const desense_key = "delta";
  const channel_key = "channel";

  // default is a value got from reducer
  // pick 0 for getting channel, fre, bandwidth, each ele are the same just pick the first
  if (inputObject[0]) {
    const channelObj = inputObject[0][DESENSE_TABLE_KEYS_DEFAULT][regionKey];

    if (channelObj) {
      const loopArray = channelObj[channel_key];
      // looped the forst object, cha
      if (loopArray.length > 0) {
        channelPowerList = loopArray.map((value, index) => {
          const currentObj = {
            channel: channelObj["channel"][index],
            freq: channelObj["freq"][index],
            bandwidth: channelObj["bandwidth"][index],
          };
          Object.keys(inputObject).forEach((eachKey) => {
            const keyDesense =
              inputObject[eachKey][DESENSE_TABLE_KEYS_DEFAULT][regionKey][
                desense_key
              ];
            if (keyDesense[index]) {
              currentObj[eachKey] = keyDesense[index];
            }
          });
          // for dyanmic appended item, the key is

          return currentObj;
        });
      }
    }

    if (inputObject[0][DESENSE_TABLE_KEYS_CUSTOM]) {
      const customObj = inputObject[0][DESENSE_TABLE_KEYS_CUSTOM][regionKey];
      const loopCustomArray = customObj[channel_key];

      customPowerList = loopCustomArray.map((value, index) => {
        const currentObj = {
          channel: `${value}`,
          freq: customObj["freq"][index],
          bandwidth: customObj["bandwidth"][index],
        };
        Object.keys(inputObject).forEach((eachKey) => {
          // double for this key, customChannelKey existed
          if (inputObject[eachKey][DESENSE_TABLE_KEYS_CUSTOM]) {
            const keyDesense =
              inputObject[eachKey][DESENSE_TABLE_KEYS_CUSTOM][regionKey][
                desense_key
              ];
            currentObj[eachKey] = keyDesense[index];
          }
        });
        // for dyanmic appended item, the key is

        return currentObj;
      });
    }
  }

  let resultList = channelPowerList.concat(customPowerList);
  // [...channelPowerList, ...customPowerList];
  return resultList;
};

export const defaultPageSize = 5;

// define columns
export const columnDefinitions = [
  {
    id: "channel",
    header: "Channel",
    label: (sortState) => {
      const isColumnSorted = sortState.sortingColumn === "channel";
      const ascending = !sortState.sortingDescending;
      return `Channel, ${
        isColumnSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => item.channel,
    minWidth: "50px",
    visible: true,
  },
  {
    id: "freq",
    header: "Freq(MHz)",
    label: (sortState) => {
      const isColumnSorted = sortState.sortingColumn === "freq";
      const ascending = !sortState.sortingDescending;
      return `Freq, ${
        isColumnSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => item.freq,
    minWidth: "100px",
    visible: true,
  },
  {
    id: "bandwidth",
    header: "Bandwidth",
    label: (sortState) => {
      const isColumnSorted = sortState.sortingColumn === "bandwidth";
      const ascending = !sortState.sortingDescending;
      return `Bandwidth, ${
        isColumnSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => <span>{item.bandwidth} MHz</span>,
    minWidth: "100px",
    visible: true,
  },
];

// define default sorting column
export const defaultSortingColumn = "timestamp";
export const defaultSortingDescending = true;

// define sortable columns
export const sortableColumns = [
  { id: "channel", field: "channel" },
  { id: "freq", field: "freq" },
  { id: "bandwidth", field: "bandwidth" },
];

// define visiable columns
export const getVisibleColumns = () => [
  {
    label: "Properties",
    options: [
      { id: "channel", label: "Channel", visible: true, editable: false },
      { id: "freq", label: "Freq(MHz)", visible: true, editable: false },
      { id: "bandwidth", label: "Bandwidth", visible: true, editable: false },
    ],
  },
];

// define filtering options
export const filteringOptions = [
  {
    groupValuesLabel: "Channel",
    propertyKey: "channel",
    propertyLabel: "Channel",
    values: [],
  },
  {
    groupValuesLabel: "Freq(MHz)",
    propertyKey: "freq",
    propertyLabel: "Freq(MHz)",
    values: [],
  },
  {
    groupValuesLabel: "Bandwidth",
    propertyKey: "bandwidth",
    propertyLabel: "Bandwidth",
    values: [],
  },
];
