import React, { Component } from "react";
import { connect } from "react-redux";
import { capitalizeFirstLetter } from "../../../../utils/general-utils";
import "./test-case-label-style.css";
import {
  DESENSE_SELECTION_KEY,
  DESENSE_SHORT_SELECTION_KEY,
} from "../../../../config/constants";

// not sure why I cannot control the style in tsx, write by jsx for ad-hoc
// as user requested it soon, will refactor this to tsx after p2 release
class DesenseTestcaseLabel extends Component {
  state = {
    hide: true,
    locked: true,
  };

  handleClose = (e) => {
    this.setState({
      hide: !this.state.hide,
    });
  };

  handleLock = (e) => {
    this.setState({
      locked: !this.state.locked,
    });
  };

  render() {
    const { selectedData } = this.props.desenseConfigReducer;
    const { notShortDropdown } = this.props;

    const unshowIcon = <i className="fas fa-eye-slash"></i>;
    const showIcon = <i className="fas fa-eye"></i>;
    const unlockedIcon = <i className="fas fa-thumbtack"></i>;
    const lockedIcon = (
      <i
        className="fas fa-thumbtack"
        style={{ color: "var(--wise-blue)", transform: "translateY(2px)" }}
      ></i>
    );

    // unlock & locked style
    const fixedStyle = {
      position: "fixed",
      top: "140px",
      right: "20px",
      zIndex: "10",
    };
    const positionStyle = {
      position: "absolute",
      top: "140px",
      right: "20px",
      zIndex: "10",
    };

    const currentLoopKey = notShortDropdown
      ? DESENSE_SELECTION_KEY
      : DESENSE_SHORT_SELECTION_KEY;

    let mapInnerLabel = Object.keys(selectedData).map((eachTC, index) => {
      const currentObj = selectedData[eachTC];
      let testcaseItems = "";
      testcaseItems = Object.keys(currentLoopKey).map((eachKey) => {
        const curKey = currentLoopKey[eachKey];

        return currentObj[curKey] ? (
          <div key={eachKey} className={"desense-testcase-inner"}>
            <span className={"desense-testcase-inner-key"}>
              {capitalizeFirstLetter(curKey)}
            </span>
            :
            <span className={"desense-testcase-inner-value"}>
              {currentObj[curKey]}
            </span>
          </div>
        ) : (
          <span key={eachKey} />
        );
      });

      return (
        <div key={index} className={"desense-label-expanded"}>
          <div
            className={"desense-testcase-label"}
          >{`Testcase ${eachTC} :`}</div>{" "}
          {testcaseItems}
        </div>
      );
    });
    return (
      <div>
        <div
          id={"desense-test-case-label"}
          style={this.state.locked ? positionStyle : fixedStyle}
        >
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              width: "50px",
            }}
          >
            <button
              type="button"
              className="icon-btn"
              onClick={this.handleClose}
            >
              {this.state.hide ? unshowIcon : showIcon}
            </button>
            <button
              type="button"
              className="icon-pin"
              style={{ marginLeft: "4px" }}
              onClick={this.handleLock}
            >
              {this.state.locked ? lockedIcon : unlockedIcon}
            </button>
          </div>
          {!this.state.hide && (
            <div className={"label component"}>{mapInnerLabel}</div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    desenseConfigReducer: state.desenseConfigReducer,
  };
};
export default connect(mapStateToProps)(DesenseTestcaseLabel);
