import React, { Component } from "react";
import { Tabs } from "@amzn/awsui-components-react/polaris";
import { connect } from "react-redux";
// import constants from "../../../../config/constants";
import { formatTableObj } from "./tab-config-desense";
import DesenseTestcaseTable from "./test-case-table";
import {
  DESENSE_TABLE_KEYS_DEFAULT,
  DESENSE_TABLE_KEYS_CUSTOM,
} from "../../../../../config/constants";

const defaultProps = Object.freeze({});

const initialState = Object.freeze({
  currentPageIndex: 1,
  pagesCount: 0,
});

interface StateProps {
  desenseConfigReducer: any;
}

// declare prop check
type Props = {
  data: Object;
  loadingStatus: number;
  getData: () => void;
} & typeof defaultProps &
  StateProps;

type State = typeof initialState;

// declare init state & default props
class DesenseTabs extends Component<Props, State> {
  static readonly defaultProps = defaultProps;
  readonly state = initialState;

  render() {
    // data is the data with table keys, filtered in desense-root-table
    const { data } = this.props;
    const { powerRegion } = this.props.desenseConfigReducer;

    const mappedTabTableData = Object.keys(data).map((eachKey, index) => {
      const tableDataDefault = data[eachKey][DESENSE_TABLE_KEYS_DEFAULT]
        ? formatTableObj(data[eachKey][DESENSE_TABLE_KEYS_DEFAULT][powerRegion])
        : [];

      const tableDataCustom = data[eachKey][DESENSE_TABLE_KEYS_CUSTOM]
        ? formatTableObj(data[eachKey][DESENSE_TABLE_KEYS_CUSTOM][powerRegion])
        : [];

      return {
        label: `Testcase ${eachKey}`,
        id: `Testcase_${eachKey}`,
        content: (
          <div>
            <DesenseTestcaseTable
              data={tableDataDefault.concat(tableDataCustom)}
            />
          </div>
        ),
      };
    });

    // activeTabId="Testcase_0" is the same as above id
    return <Tabs tabs={mappedTabTableData} activeTabId="Testcase_0" />;
  }
}

const mapStateToProps = (state) => {
  return {
    desenseConfigReducer: state.desenseConfigReducer,
  };
};
export default connect(mapStateToProps)(DesenseTabs);
