import antennaConfigType from "../types/antenna-config-type";
import { config } from "../../config/config";
import endpoints from "../../config/endpoints";
import { METRIC_INFO } from "../../config/constants";
import { capitalizeFirstLetter } from "../../utils/general-utils";

// for any user changes on select update this one
export const setUserSelectDict = (selectedData: Object): any => ({
  type: antennaConfigType.SET_USER_SELECTION,
  selectedData,
});

export const initAntennaLoadingStatus = (): any => ({
  type: antennaConfigType.INIT_LOADING_STATUS,
});

export const postTcsResult = (
  project: string,
  testCategory: string,
  tcsId: string,
  requestJson: object
) => ({
  type: antennaConfigType.POST_ANTENNA_TCS_RESULT,
  http: {
    cognito: {
      effect: {
        url: config.BASE_URL + endpoints.tcsResultURL(testCategory, tcsId),
        method: "POST",
        json: requestJson,
      },
      commit: {
        type: antennaConfigType.POST_ANTENNA_TCS_RESULT_COMMIT,
        log: {
          initiative: METRIC_INFO.INITIATIVE,
          feature: METRIC_INFO.DATA,
          project: capitalizeFirstLetter(project),
          testcategory: METRIC_INFO[testCategory],
        },
      },
      rollback: {
        type: antennaConfigType.POST_ANTENNA_TCS_RESULT_ROLLBACK,
        log: {
          initiative: METRIC_INFO.INITIATIVE,
          feature: METRIC_INFO.DATA,
          project: capitalizeFirstLetter(project),
          testcategory: METRIC_INFO[testCategory],
        },
      },
    },
  },
});

export const getTCSDropdown = (
  project: string,
  build: string,
  version: string,
  testcategory: string,
  requestId: number | undefined = undefined,
  labId: number | undefined = undefined
) => ({
  type: antennaConfigType.GET_ANTENNA_DEVICE_LIST,
  http: {
    cognito: {
      effect: {
        url:
          config.BASE_URL +
          endpoints.getDropdownListEndpoint(
            project,
            build,
            version,
            testcategory,
            requestId,
            labId
          ),
        method: "GET",
      },
      commit: {
        type: antennaConfigType.GET_ANTENNA_DEVICE_LIST_COMMIT,
        log: {
          initiative: METRIC_INFO.INITIATIVE,
          feature: METRIC_INFO.DATA,
          project: capitalizeFirstLetter(project),
          testcategory: METRIC_INFO[testcategory],
        },
      },
      rollback: {
        type: antennaConfigType.GET_ANTENNA_DEVICE_LIST_ROLLBACK,
        log: {
          initiative: METRIC_INFO.INITIATIVE,
          feature: METRIC_INFO.DATA,
          project: capitalizeFirstLetter(project),
          testcategory: METRIC_INFO[testcategory],
        },
      },
    },
  },
});
