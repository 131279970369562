import React, { Component } from "react";
// polaris
import { Spinner, ButtonDropdown } from "@amzn/awsui-components-react/polaris";
// sub component
import { ToastContainer } from "react-toastify";
import {
  createSuccessToast,
  createFailToast,
  createEmailNotVerifyToast,
} from "./toast-notification";
// utils
import moment from "moment";
import { postData, Response } from "../../utils/cognito-fetch";
import { ReportRequest, EmailVerificationRequest } from "../../model/http-json";
// constants
import { username } from "../../cognito-auth/session";
import { config } from "../../config/config.js";
import endpoint from "../../config/endpoints";
import constants, {
  REPORT_TYPE,
  REPORT_TYPE_TEXT,
  METRIC_INFO,
  TEST_CATEGORY
} from "../../config/constants";
import { getLogData } from "../../utils/general-utils";

const MAX_REPORTS = 3;

// declare prop check
type Props = {
  selectedItems: any[];
} & typeof defaultProps;

type State = typeof initialState;

// declare init state & default props
const defaultProps = Object.freeze({});

const initialState = Object.freeze({
  reportStatue: constants.LOADING_SUCCESS,
});

// report dropdown
const reportItems = Object.keys(REPORT_TYPE).map((item) => ({
  id: REPORT_TYPE[item],
  text: REPORT_TYPE_TEXT[REPORT_TYPE[item]],
}));

class ReportDropDown extends Component<Props, State> {
  static readonly defaultProps = defaultProps;
  readonly state = initialState;

  _onReportItemClick = async (event) => {
    const reportType = event.detail.id;
    const { selectedItems } = this.props;

    // 1) check item lenght
    if (selectedItems.length === 0) {
      alert("No item was selected.");
      return;
    }
    if (selectedItems.length > MAX_REPORTS) {
      alert(`At most ${MAX_REPORTS} reports can be generated at a time.`);
      return;
    }

    // validate test category
    // need to return in for loop
    for (let i = 0; i < selectedItems.length; i++) {
      const item = selectedItems[i];
      if (!item || !item["test_category"] || [TEST_CATEGORY.COMPLIANCE].indexOf(item["test_category"]) === -1) {
        alert(`We only support the following report type and test category:\n${REPORT_TYPE.FAILURE_REPORT}: ${TEST_CATEGORY.COMPLIANCE}`);
        return;
      }
    }

    // loading status -> load
    this.setState({
      reportStatue: constants.LOADING_LOAD,
    });

    // 2) verify email
    const emailVerificationPayload: EmailVerificationRequest = {
      email: `${username}@amazon.com`,
      send_verification_email: true,
    };

    const emailResponse: Response = await postData(
      config.BASE_URL + endpoint.emailVerificationEndpoint(),
      emailVerificationPayload,
      getLogData(METRIC_INFO.REPORT)
    );

    // response not 200
    if (!emailResponse.ok) {
      const responseMsg =
        emailResponse.json && emailResponse.json.message
          ? emailResponse.json.message
          : emailResponse.status_text;
      createFailToast(responseMsg);
      this.setState({
        reportStatue: constants.LOADING_FAIL,
      });
      return;
    }

    // email not verifieds
    if (
      !emailResponse.json ||
      emailResponse.json.status !== constants.STATUS_SUCCESS
    ) {
      createEmailNotVerifyToast();
      this.setState({
        reportStatue: constants.LOADING_FAIL,
      });
      return;
    }

    // 3) submit report request
    const reportPayload: ReportRequest = {
      user: username,
      report_type: reportType,
      created_time: moment.utc().format("YYYY-MM-DD HH:mm:ss"),
      project: selectedItems.map(({ project }) => project),
      build: selectedItems.map(({ build }) => build),
      version: selectedItems.map(({ version }) => version),
      test_category: selectedItems.map(({ test_category }) => test_category),
    };

    const reportResponse: Response = await postData(
      config.BASE_URL + endpoint.reportEndpoint(),
      reportPayload,
      METRIC_INFO.REPORT
    );

    const responseMsg =
      reportResponse.json && reportResponse.json.message
        ? reportResponse.json.message
        : reportResponse.status_text;

    this.setState({
      reportStatue: reportResponse.ok
        ? constants.LOADING_SUCCESS
        : constants.LOADING_FAIL,
    });

    reportResponse.ok ? createSuccessToast() : createFailToast(responseMsg);
  };

  render() {
    return (
      <React.Fragment>
        <ButtonDropdown
          variant="primary"
          items={reportItems}
          onItemClick={this._onReportItemClick}
        >
          {this.state.reportStatue === constants.LOADING_LOAD && (
            <Spinner></Spinner>
          )}
          &nbsp; Generate Report
        </ButtonDropdown>
        <ToastContainer />
      </React.Fragment>
    );
  }
}

export default ReportDropDown;
