import React, { useEffect, useState } from "react";
import { useCollection } from "@amzn/awsui-collection-hooks";
import {
  Table,
  Button,
  Pagination,
  PropertyFilter,
  Header,
  StatusIndicator,
  Popover,
} from "@amzn/awsui-components-react-v3";
import {
  TableEmptyState,
  EmptyState,
  getMatchesCountText,
  paginationLabels,
} from "../../../../components/polaris/common-components";
import {
  createColumnDefs,
  genVisibleContentOpts,
  defaultPreferences,
  DEFAULT_SORTING_COLUMN,
  DEFAULT_SORTING_DESCENDING,
  Preferences,
  PROPERTY_FILTERING_I18N_STRINGS,
  NONSIG_REDUCER_TABLE_MAP,
  COCONUT_PLOT_POST_ATTR,
  LITEPOINT_PLOT_POST_ATTR,
  RESULT_COL_KEY,
} from "./nonsig-table-config";
import { useSelector, useDispatch } from "react-redux";
import {
  addTestForComparison,
  postTcsResult,
  setTCSSelection,
} from "../../../../redux/actions/nonsig-config-action";
import constants, {
  TEST_STATUS,
  NONSIG_TEST_FORMAT,
} from "../../../../config/constants";

const NonSigMainTable = () => {
  const dispatch = useDispatch();
  const { nonsigReducer } = useSelector((state: any) => ({
    nonsigReducer: state.nonsigReducer,
  }));
  const {
    tcsSelection,
    tcsTableData,
    tcsTableDataErrorMessage,
    tcsTableDataLoadingStatus,
    tcsResultLoadingStatus,
    testFormat,
    comparisonTable,
  } = nonsigReducer;

  const [columnDefs, setColumnDefs] = useState<any[]>(createColumnDefs([], ""));
  const [preferences, setPreferences] = useState(defaultPreferences([]));
  const [filteringProperties, setFilteringProperties] = useState<any[] | []>(
    []
  );

  const currentTableData = tcsTableData.length > 0 ? tcsTableData : [];
  useEffect(() => {
    if (
      testFormat === NONSIG_TEST_FORMAT.LITEPOINT ||
      testFormat === NONSIG_TEST_FORMAT.COCONUT
    ) {
      const createdColumnDefs = createColumnDefs(currentTableData, testFormat);
      setColumnDefs(createdColumnDefs);
      setPreferences(defaultPreferences(createdColumnDefs));
      setFilteringProperties(
        Object.values(NONSIG_REDUCER_TABLE_MAP[testFormat]).map(
          (element) => element.filteringProps
        )
      );
    }
  }, [testFormat, currentTableData]);

  const {
    items,
    actions,
    filteredItemsCount,
    collectionProps,
    paginationProps,
    propertyFilterProps,
  } = useCollection(currentTableData, {
    propertyFiltering: {
      filteringProperties: filteringProperties,
      empty: <TableEmptyState resourceName={"Test case"} />,
      noMatch: (
        <EmptyState
          title="No matches"
          subtitle="We can’t find a match."
          action={
            <Button onClick={() => actions.setFiltering("")}>
              Clear filter
            </Button>
          }
        />
      ),
    },
    pagination: { pageSize: preferences.pageSize },
    sorting: {
      defaultState: {
        sortingColumn: DEFAULT_SORTING_COLUMN[testFormat],
        isDescending: DEFAULT_SORTING_DESCENDING,
      },
    },
    selection: {},
  });

  const _onSelectChange = ({ detail }) => {
    const currentSelect = detail.selectedItems[0];

    const test_result = currentSelect[RESULT_COL_KEY]?.toLowerCase();

    // Only trigger if test is passed/failed
    if (
      test_result === TEST_STATUS.PASSED ||
      test_result === TEST_STATUS.FAILED
    ) {
      dispatch(setTCSSelection(detail.selectedItems));
      let requestJson;

      if (testFormat === NONSIG_TEST_FORMAT.LITEPOINT) {
        const id = LITEPOINT_PLOT_POST_ATTR.id; // Litepoints ID is just a constant
        let s3_version_id =
          currentSelect[LITEPOINT_PLOT_POST_ATTR.s3_key_version_attr];
        if (typeof s3_version_id === "undefined") {
          // If Version ID doesnt exist, just get the latest version of the object.
          s3_version_id = null;
        }

        requestJson = {
          [LITEPOINT_PLOT_POST_ATTR.s3_key_json_key]:
            currentSelect[LITEPOINT_PLOT_POST_ATTR.s3_key_data_attr],
          [LITEPOINT_PLOT_POST_ATTR.s3_version_json_key]: s3_version_id,
        };
        dispatch(postTcsResult(id, requestJson));
      } else if (testFormat === NONSIG_TEST_FORMAT.COCONUT) {
        const id = currentSelect[COCONUT_PLOT_POST_ATTR.id_data_attr]; // Coconuts id comes form the data
        const requestJson = {
          [COCONUT_PLOT_POST_ATTR.tc_id_json_key]:
            currentSelect[COCONUT_PLOT_POST_ATTR.tc_id_data_attr],
          [constants.NONSIG_DSN_CONFIG_ID]:
            currentSelect[constants.NONSIG_DSN_CONFIG_ID],
        };
        dispatch(postTcsResult(id, requestJson));
      }
    } else {
      alert("This test case has not been tested yet.");
    }
  };

  const _doesItemExistsInComparisonTable = () => {
    // Return T if Item already exists in Comparion Table
    if (tcsSelection.length === 0) {
      // nothing is selected
      return false;
    }

    const currSel = tcsSelection[0];
    return (
      comparisonTable.findIndex(
        (item) =>
          item["s3Key"] === currSel[LITEPOINT_PLOT_POST_ATTR.s3_key_data_attr]
      ) !== -1
    );
  };

  const _headerActionBtn = () => (
    <Button
      onClick={() => {
        if (!_doesItemExistsInComparisonTable()) {
          dispatch(addTestForComparison());
        }
      }}
      loading={tcsResultLoadingStatus === constants.LOADING_LOAD}
      disabled={
        // disable if not LP OR no selection OR no results loaded
        testFormat !== NONSIG_TEST_FORMAT.LITEPOINT ||
        tcsSelection.length === 0 ||
        tcsResultLoadingStatus !== constants.LOADING_SUCCESS
      }
    >
      Add for Comparison
    </Button>
  );

  const _headerActions = () => {
    if (_doesItemExistsInComparisonTable()) {
      return (
        <Popover
          dismissButton={false}
          position="top"
          size="small"
          triggerType="custom"
          content={
            <StatusIndicator type="warning">Test already added</StatusIndicator>
          }
        >
          {_headerActionBtn()}
        </Popover>
      );
    } else {
      return _headerActionBtn();
    }
  };

  return (
    <React.Fragment>
      {tcsTableDataErrorMessage && (
        <StatusIndicator type="error">
          {tcsTableDataErrorMessage}
        </StatusIndicator>
      )}
      <Table
        {...collectionProps}
        trackBy="tcs_id"
        selectionType="single"
        onSelectionChange={_onSelectChange}
        selectedItems={tcsSelection}
        columnDefinitions={columnDefs}
        items={items}
        // loading={tcsTableDataLoadingStatus}
        loadingText="Loading resources"
        visibleColumns={preferences.visibleContent}
        empty={<TableEmptyState resourceName={"Test case"} />}
        header={<Header actions={_headerActions()}></Header>}
        resizableColumns={true}
        wrapLines={preferences.wrapLines}
        filter={
          <PropertyFilter
            {...propertyFilterProps}
            i18nStrings={PROPERTY_FILTERING_I18N_STRINGS}
            countText={getMatchesCountText(filteredItemsCount)}
          />
        }
        pagination={
          <Pagination {...paginationProps} ariaLabels={paginationLabels} />
        }
        preferences={
          <Preferences
            preferences={preferences}
            disabled={
              tcsTableDataLoadingStatus === constants.LOADING_LOAD ||
              items.length === 0
            }
            setPreferences={(detail) => {
              setPreferences(detail);
            }}
            visibleContentOption={genVisibleContentOpts(
              NONSIG_REDUCER_TABLE_MAP[testFormat]
            )}
          />
        }
      />
    </React.Fragment>
  );
};

export default NonSigMainTable;
