import boxPlotType from "./../types/box-plot-type";
import { config } from "../../config/config";
import endpoints from "../../config/endpoints";
import { BoxPlotRequest } from "../../model/http-json";
import { METRIC_INFO } from "../../config/constants";
import { capitalizeFirstLetter } from "../../utils/general-utils";

// update dropdown selection
export const updateSelection = (selectionKey, selectionValue) => ({
  type: boxPlotType.UPDATE_SELECTION,
  key: selectionKey,
  value: selectionValue,
});

export const getPlotData = (requestJson: BoxPlotRequest) => ({
  type: boxPlotType.GET_PLOT_DATA,
  http: {
    cognito: {
      effect: {
        url: config.BASE_URL + endpoints.boxPlotEndpoint(),
        method: "POST",
        json: requestJson,
      },
      commit: {
        type: boxPlotType.GET_PLOT_DATA_COMMIT,
        log: {
          initiative: METRIC_INFO.INITIATIVE,
          feature: METRIC_INFO.BOX_PLOT,
          project: capitalizeFirstLetter(requestJson.project),
          testcategory: METRIC_INFO[requestJson.test_category],
        },
      },
      rollback: {
        type: boxPlotType.GET_PLOT_DATA_ROLLBACK,
        log: {
          initiative: METRIC_INFO.INITIATIVE,
          feature: METRIC_INFO.BOX_PLOT,
          project: capitalizeFirstLetter(requestJson.project),
          testcategory: METRIC_INFO[requestJson.test_category],
        },
      },
    },
  },
});
