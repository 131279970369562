import buildType from "../types/build-type";
import constants from "../../config/constants";
import { capitalizeFirstLetter } from "../../utils/general-utils";

const initialState = {
  build: "",
  buildList: [] as Array<string>,
  loadingStatus: constants.LOADING_LOAD,
  canCoconutSubmit: false,
  canLitepointSubmit: false,
  nonSigCoconutSelect: {} as any,

  nonSigLitepointVersion: constants.NONSIG_LITEPOINT_VERSION_DEFAULT,
  nonSigLitepointSelect: {
    tech: [],
    band: [],
    standard: [],
    temp: [],
    volt: [],
    wifi_ant: [],
    // lora_ant = [0] if lora in tech else [0]
    lora_ant: [],
    // have zigbee track bt, as they were coupled in WLENS
    bt_ant: [],
    zigbee_ant: [],
  } as any,
};

const buildReducer = (state = initialState, action) => {
  switch (action.type) {
    case buildType.GET_BUILD_LIST:
      return {
        ...state,
        loadingStatus: constants.LOADING_LOAD,
      };
    case buildType.GET_BUILD_LIST_COMMIT:
      return {
        ...state,
        loadingStatus: constants.LOADING_SUCCESS,
        buildList: Array.isArray(action.payload)
          ? action.payload.map((build: string) => capitalizeFirstLetter(build))
          : [],
      };
    case buildType.GET_BUILD_LIST_ROLLBACK:
      return {
        ...state,
        loadingStatus: constants.LOADING_FAIL,
      };
    case buildType.SET_BUILD:
      return {
        ...state,
        build: action.build,
      };
    case buildType.SET_CAN_LITEPOINT_SUBMIT:
      return {
        ...state,
        canLitepointSubmit: action.canLitepointSubmit,
      };
    case buildType.SET_CAN_COCONUT_SUBMIT:
      return {
        ...state,
        canCoconutSubmit: action.canCoconutSubmit,
      };
    case buildType.SET_NONSIG_COCONUT_SELECT:
      return {
        ...state,
        nonSigCoconutSelect: action.nonSigCoconutSelect,
      };
    case buildType.SET_NONSIG_LITEPOINT_SELECT:
      return {
        ...state,
        nonSigLitepointSelect: {
          ...state.nonSigLitepointSelect,
          ...action.newSelected,
        },
      };
    case buildType.SET_NONSIG_LITEPOINT_VERSION:
      return {
        ...state,
        nonSigLitepointVersion: action.version,
      };
    case buildType.CLEAR_NONSIG_LITEPOINT_SELECT:
      return {
        ...state,
        nonSigLitepointVersion: constants.NONSIG_LITEPOINT_VERSION_DEFAULT,
        nonSigLitepointSelect: {
          tech: [],
          band: [],
          standard: [],
          temp: [],
          volt: [],
          wifi_ant: [],
          bt_ant: [],
          lora_ant: [],
          zigbee_ant: [],
        },
      };
    default:
      return state;
  }
};

export default buildReducer;
