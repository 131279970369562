import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { TEST_CATEGORY } from "../../config/constants";
import ComplianceTestcase from "./compliance-testcase/compliance-testcase";
import DesenseTestcase from "./desense-testcase/desense-main.jsx";
import RVRTestcase from "./rvr-testcase/rvr-testcase";
import BatteryTestcase from "./battery-testcase/battery-testcase";
import AntennaTestcase from "./antenna-testcase/antenna-testcase";
import NonSigTestcase from "./nonsig-testcase/nonsig-testcase";
// interface to check router match params type
interface MatchParams {
  test_category: string;
}

// declare prop check
type Props = {} & typeof defaultProps & RouteComponentProps<MatchParams>;

// declare init state & default props
const defaultProps = Object.freeze({});

class Testcase extends Component<Props> {
  render() {
    const { test_category } = this.props.match.params;
    if (test_category.toLocaleLowerCase() === TEST_CATEGORY.COMPLIANCE) {
      return <ComplianceTestcase />;
    }
    if (test_category.toLocaleLowerCase() === TEST_CATEGORY.DESENSE) {
      return <DesenseTestcase />;
    }
    if (test_category.toLocaleLowerCase() === TEST_CATEGORY.RVR) {
      return <RVRTestcase />;
    }
    if (test_category.toLocaleLowerCase() === TEST_CATEGORY.BATTERY) {
      return <BatteryTestcase />;
    }
    if (test_category.toLowerCase() === TEST_CATEGORY.ANTENNA) {
      return <AntennaTestcase />;
    }
    if (test_category.toLowerCase() === TEST_CATEGORY.NON_SIG) {
      return <NonSigTestcase />;
    }
    // test category not supported
    this.props.history.push("/not-found");
  }
}

export default Testcase;
