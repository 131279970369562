const buildType = {
  GET_BUILD_LIST: "GET_BUILD_LIST",
  GET_BUILD_LIST_COMMIT: "GET_BUILD_LIST_COMMIT",
  GET_BUILD_LIST_ROLLBACK: "GET_BUILD_LIST_ROLLBACK",
  SET_BUILD: "SET_BUILD",
  SET_CAN_COCONUT_SUBMIT: "SET_CAN_COCONUT_SUBMIT",
  SET_CAN_LITEPOINT_SUBMIT: "SET_CAN_LITEPOINT_SUBMIT",
  SET_NONSIG_COCONUT_SELECT: "SET_NONSIG_COCONUT_SELECT",
  SET_NONSIG_LITEPOINT_SELECT: "SET_NONSIG_LITEPOINT_SELECT",
  SET_NONSIG_LITEPOINT_VERSION: "SET_NONSIG_LITEPOINT_VERSION",
  CLEAR_NONSIG_LITEPOINT_SELECT: "CLEAR_NONSIG_LITEPOINT_SELECT",
};

export default buildType;
