import { dynamicOverviewType } from "../types/overview-type";
// AJAX request related
import { config } from "../../config/config";
import endpoints from "../../config/endpoints";
import { OverviewStatusRequest } from "../../model/http-json";
import constants from "../../config/constants";
import { METRIC_INFO } from "../../config/constants";
import { capitalizeFirstLetter } from "../../utils/general-utils";

export const getOverviewStatus = (
  project: string,
  build: string,
  testCategory: string,
  requestJson: OverviewStatusRequest,
  testType: string | undefined = undefined
) => ({
  type: dynamicOverviewType(constants.LOADING_LOAD, testType),
  testType,
  http: {
    cognito: {
      effect: {
        //simple local url here will change it later
        url:
          config.BASE_URL +
          endpoints.overviewStatus(project, build, testCategory),
        method: "POST",
        json: requestJson,
      },
      commit: {
        type: dynamicOverviewType(constants.LOADING_SUCCESS, testType),
        testType,
        log: {
          initiative: METRIC_INFO.INITIATIVE,
          feature: METRIC_INFO.OVERVIEW,
          project: capitalizeFirstLetter(project),
          testcategory: METRIC_INFO[testCategory],
        },
      },
      rollback: {
        type: dynamicOverviewType(constants.LOADING_FAIL, testType),
        testType,
        log: {
          initiative: METRIC_INFO.INITIATIVE,
          feature: METRIC_INFO.OVERVIEW,
          project: capitalizeFirstLetter(project),
          testcategory: METRIC_INFO[testCategory],
        },
      },
    },
  },
});
