import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Dispatch } from "redux";
import { connect } from "react-redux";

import PowerConfigForm from "./power-config-form";
// import "./create-form.css";

interface StateProps {
  desenseConfigReducer: any;
}

// declare prop check
type Props = {
  dispatch: Dispatch<any>;
} & StateProps &
  RouteComponentProps<any>;

class PowerConfig extends Component<Props> {
  render() {
    return (
      <PowerConfigForm history={this.props.history} />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    desenseConfigReducer: state.desenseConfigReducer,
  };
};

export default withRouter(connect<StateProps>(mapStateToProps)(PowerConfig));
