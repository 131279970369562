import React from "react";
import Plot from "react-plotly.js";

const GeneralPlotlyChart = (props) => {
  return (
    <div id={props.domId}>
      <Plot
        images={props.images}
        style={props.style}
        data={props.data}
        layout={props.layout}
        frames={props.frames}
        config={props.config}
        onInitialized={props.onInitialized}
        onUpdate={props.onUpdate}
        useResizeHandler={true}
        onClick={props.onClick}
      />
    </div>
  );
};

export default GeneralPlotlyChart;
