export const formatBoxPlotCombineData = (data: {}) => {
  let rst: PlotData[] = [];

  Object.keys(data).forEach((device) => {
    let onePlot = new PlotData(device);
    onePlot.layout["xaxis"]["title"] = "Device";
    const trace: {} = getTrace(device, data[device]);
    trace["name"] = device;
    onePlot.data.push(trace);
    rst.push(onePlot);
  });

  return rst;
};

export const formatBoxPlotSplitData = (data: {}) => {
  let rst: PlotData[] = [];

  Object.keys(data).forEach((dataRate) => {
    let onePlot = new PlotData(dataRate);
    onePlot.layout["xaxis"]["title"] = "Set Power";

    const traces: Object[] = [];
    Object.keys(data[dataRate]).forEach((setPower) => {
      const trace: {} = getTrace(setPower, data[dataRate][setPower]);
      trace["name"] = `Set Power:${setPower}`;
      traces.push(trace);
    });

    onePlot.data = traces;
    rst.push(onePlot);
  });

  return rst;
};

export class PlotData {
  data: any[];
  layout: {};
  title: string;

  constructor(category: string) {
    this.data = [] as any;
    this.title = category;
    this.layout = {
      xaxis: {
        titlefont: {
          family: "Roboto",
          size: 18,
        },
      },
      yaxis: {
        title: "Power",
        titlefont: {
          family: "Roboto",
          size: 18,
        },
      },
      margin: {
        t: 30,
      },
    };
  }
}

const getTrace = (setPower: string, y: number[]) => {
  if (y && y.length === 5) {
    const min = y[0];
    const q1 = y[1];
    const med = y[2];
    const q3 = y[3];
    const max = y[4];

    return {
      y: [min, q1, med, med, q3, max],
      type: "box",
      boxpoints: false,
    };
  }

  return {};
};
