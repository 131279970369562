import boxPlotType from "./../types/box-plot-type";
import constants from "../../config/constants";
import { BoxPlotResponse } from "../../model/http-json";

const initialState = {
  loadingStatus: constants.LOADING_LOAD,
  plotData: {} as BoxPlotResponse,
  device: null, // init define here, use to determine if show plot or not
};

const boxPlotReducer = (state = initialState, action) => {
  switch (action.type) {
    // dropdown selection
    case boxPlotType.UPDATE_SELECTION:
      return {
        ...state,
        [action.key]: action.value,
      };

    //plot data
    case boxPlotType.GET_PLOT_DATA:
      return {
        ...state,
        loadingStatus: constants.LOADING_LOAD,
      };
    case boxPlotType.GET_PLOT_DATA_COMMIT:
      if (action.payload) {
        return {
          ...state,
          loadingStatus: constants.LOADING_SUCCESS,
          plotData: action.payload,
        };
      }
      return {
        state,
        loadingStatus: constants.LOADING_SUCCESS,
      };
    case boxPlotType.GET_PLOT_DATA_ROLLBACK:
      return {
        ...state,
        loadingStatus: constants.LOADING_FAIL,
      };

    default:
      return state;
  }
};

export default boxPlotReducer;
