import { put, takeEvery } from "redux-saga/effects";
import { logMetric } from "../redux/actions/metric-action";
import metricType from "../redux/types/metric-type";
import { METRICS_STATUS } from "../config/constants";

//Watcher: listen to specific action and call worker to take actions
export function* watchCommitRollbackActions() {
  yield takeEvery("*", sendRequest);
}

//Worker
export function* sendRequest(action) {
  if (
    action.type.includes("COMMIT") &&
    action.type !== metricType.POST_METRIC_DATA_COMMIT
  ) {
    let actionObj = logMetric(action.log, METRICS_STATUS.PASS);
    yield put(actionObj);
  }
  if (
    action.type.includes("ROLLBACK") &&
    action.type !== metricType.POST_METRIC_DATA_ROLLBACK
  ) {
    let actionObj = logMetric(action.log, METRICS_STATUS.FAIL);
    yield put(actionObj);
  }
}
