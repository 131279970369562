import React, { Component } from "react";
// redux
import { Dispatch } from "redux";
// directly use plotly
import Plot from "react-plotly.js";
import { connect } from "react-redux";
import {
  Select,
  Multiselect,
  Input,
  Icon,
  Button,
} from "@amzn/awsui-components-react/polaris";
import {
  formatSelectedData,
  getAxisOptions,
  showAlert,
} from "./base-chart-helper";
import "./rvr-base-style.css";

interface StateProps {
  rvrConfigReducer: any;
}

// declare prop check
type Props = {
  filterObject: Object;
  dispatch: Dispatch<any>;
} & typeof defaultProps &
  StateProps;

// declare init state & default props
const defaultProps = Object.freeze({});
const initialState = Object.freeze({
  mainPlotWidth: window.innerWidth,
  xTitle: "rssi",
  yTitle: "thoughput(Mbps)",
  xSelect: "rssi",
  ySelect: ["throughput_mbps"],
  // 5.590814982119331, -99.59081498211933
  xMin: "0",
  xMax: "-99",
  yMin: null,
  yMax: null,
});

class RvRBaseChart extends Component<Props> {
  readonly state = initialState;
  private plotRef: React.RefObject<HTMLInputElement>;

  constructor(props) {
    super(props);
    this.plotRef = React.createRef();
  }

  componentDidMount() {
    // handle resize for the visualization
    const rvrChartNode = this.plotRef.current;
    if (rvrChartNode) {
      this.setState({
        mainPlotWidth: rvrChartNode.clientWidth,
      });
    }
    // handle init new value
  }

  updateWindowDimensions = () => {
    // const node = this.dashboardRightRef.current;
    const rvrChartNode = this.plotRef.current;
    if (rvrChartNode) {
      this.setState({
        mainPlotWidth: rvrChartNode.clientWidth,
      });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    // Add event listener to window resize event
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  _resetInput = () => {
    this.setState({
      xSelect: "rssi",
      ySelect: ["throughput_mbps"],
      // 5.590814982119331, -99.59081498211933
      xMin: "0",
      xMax: "-99",
      yMin: null,
      yMax: null,
    });
  };

  render() {
    const {
      xSelect,
      ySelect,
      xTitle,
      yTitle,
      xMin,
      yMin,
      xMax,
      yMax,
    } = this.state;
    const { selectedData } = this.props.rvrConfigReducer;
    // chart layout
    const layout = {
      title: "RvR",
      width: this.state.mainPlotWidth - 20,
      margin: {
        t: 25, //top margin
        b: 30, //bottom margin
      },
      yaxis: {
        // title: this.state.yTitle,
      },
      xaxis: {
        // title: this.state.xTitle,
      },
    };
    // if user input range & also double check
    if ([xMin, xMax].every((ele) => ele !== null)) {
      if ([xMin, xMax].every((ele) => !isNaN(Number(ele)))) {
        layout["xaxis"]["range"] = [xMin, xMax];
      }
    }

    if ([yMin, yMax].every((ele) => ele !== null)) {
      if ([yMin, yMax].every((ele) => !isNaN(Number(ele)))) {
        layout["yaxis"]["range"] = [yMin, yMax];
      }
    }
    // chart data
    const formatData = formatSelectedData(selectedData, xSelect, ySelect);
    // user option for x and y
    const axisOptions = getAxisOptions(selectedData);

    // User chart setting, set x y:  title, select, min, max
    // Too long for each, so split x & y for easiler reading & faster search
    const userSettingX = (
      <div className={"axis-select-component"}>
        {/* X axis input */}
        <div className={"axis-dropdown-element"}>
          <div className={"axis-dropdown-label"}>X Axis Title:</div>
          <div className={"axis-dropdown-input"}>
            <Input
              onChange={({ detail }) => {
                this.setState({
                  xTitle: detail.value,
                });
              }}
              value={xTitle}
            />
          </div>
        </div>
        {/* X axis select */}
        <div className={"axis-dropdown-element"}>
          <div className={"axis-dropdown-label"}>X Axis:</div>
          <div className={"axis-dropdown-select"}>
            <Select
              id={"xSelect"}
              empty="No options"
              placeholder="x axis select"
              selectedLabel="Selected"
              options={axisOptions}
              selectedOption={xSelect ? { id: xSelect, label: xSelect } : null}
              onChange={({ detail: { selectedId } }) => {
                this.setState({
                  xSelect: selectedId,
                });
              }}
              filteringType="auto"
            ></Select>
          </div>
        </div>
        {/* xmin */}
        <div className={"axis-dropdown-element-range"}>
          <div className={"axis-dropdown-label"}>X Min:</div>
          <div className={"axis-dropdown-input"}>
            <Input
              onChange={({ detail }) => {
                this.setState({
                  xMin: detail.value,
                });
              }}
              value={xMin}
            />
          </div>
        </div>
        {/* xmax */}
        <div className={"axis-dropdown-element-range"}>
          <div className={"axis-dropdown-label"}>X Max:</div>
          <div className={"axis-dropdown-input"}>
            <Input
              onChange={({ detail }) => {
                this.setState({
                  xMax: detail.value,
                });
              }}
              value={xMax}
            />
          </div>
        </div>

        {showAlert(xMin, xMax) && (
          <div className={"axis-input-warnning"}>
            <Icon name="status-warning" size="normal" variant="error" />
            <span className="rvr-input-warnning-text">
              Need both Min&Max to filter
            </span>
          </div>
        )}
      </div>
    );
    const userSettingY = (
      <div className={"axis-select-component"}>
        {/* Y axis input */}
        <div className={"axis-dropdown-element"}>
          <div className={"axis-dropdown-label"}>Y Axis Title:</div>
          <div className={"axis-dropdown-input"}>
            <Input
              onChange={({ detail }) => {
                this.setState({
                  yTitle: detail.value,
                });
              }}
              value={yTitle}
            />
          </div>
        </div>
        {/* Y axis select */}
        <div className={"axis-dropdown-element"}>
          <div className={"axis-dropdown-label"}>Y Axis:</div>
          <div className={"axis-dropdown-select"}>
            <Multiselect
              id={"ySelect"}
              empty="No options"
              placeholder="x axis select"
              selectedLabel="Selected"
              options={axisOptions}
              selectedOptions={
                ySelect
                  ? ySelect.map((eachSelect) => {
                      return { id: eachSelect, label: eachSelect };
                    })
                  : []
              }
              onChange={({ detail: { selectedIds } }) => {
                this.setState({
                  ySelect: selectedIds,
                });
              }}
              filteringType="auto"
            ></Multiselect>
          </div>
        </div>
        {/* y min */}
        <div className={"axis-dropdown-element-range"}>
          <div className={"axis-dropdown-label"}>Y Min:</div>
          <div className={"axis-dropdown-input"}>
            <Input
              onChange={({ detail }) => {
                this.setState({
                  yMin: detail.value,
                });
              }}
              value={yMin}
            />
          </div>
        </div>
        {/* ymax */}
        <div className={"axis-dropdown-element-range"}>
          <div className={"axis-dropdown-label"}>Y Max:</div>
          <div className={"axis-dropdown-input"}>
            <Input
              onChange={({ detail }) => {
                this.setState({
                  yMax: detail.value,
                });
              }}
              value={yMax}
            />
          </div>
        </div>

        {showAlert(yMin, yMax) && (
          <div className={"axis-input-warnning"}>
            <Icon name="status-warning" size="normal" variant="error" />
            <span className="rvr-input-warnning-text">
              Need both Min&Max to filter
            </span>
          </div>
        )}
      </div>
    );

    return (
      <div className={"rvr-base-chart"} ref={this.plotRef}>
        <div className="awsui-util-container rvr-chart-container">
          <div className={"rvr-y-title"}>{this.state.yTitle}</div>
          <div className={"rvr-plot"}>
            <Plot
              data={formatData ? formatData : []}
              layout={layout}
              config={{ displayModeBar: false }} // not showing mode bar
            />
          </div>
          <div className={"rvr-x-title"}>{this.state.xTitle}</div>
          <div className="visual-reset-btn">
            <Button onClick={this._resetInput}>
              <Icon name="undo" size="normal" variant="normal" />
            </Button>
          </div>
        </div>

        {userSettingX}
        {userSettingY}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    rvrConfigReducer: state.rvrConfigReducer,
  };
};

export default connect<StateProps>(mapStateToProps)(RvRBaseChart);
