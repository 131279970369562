import desenseConfigType from "../types/desense-config-type";
import constants, {
  DESENSE_TABLE_KEYS_DEFAULT,
  DESENSE_TABLE_KEYS_CUSTOM,
} from "../../config/constants";
import { handleMultiTestcaseData } from "../../utils/general-utils";

const initialState = {
  desenseDeviceList: [] as Array<string>,
  desenseTCSList: [] as Array<string>,
  loadingStatus: constants.LOADING_LOAD,
  deviceLoadingStatus: constants.LOADING_LOAD,
  tcsListLoadingStatus: constants.LOADING_LOAD,
  // plot required data
  // {testcase0:{key1:value1, key2:value2}}
  band: "2G",
  // TODO, control tcs_id
  tcs_id: "",
  powerRegion: "default",

  tcsResultLoadingStatus: constants.LOADING_BEFORE_START,
  tcsResultMessage: "",
  selectedData: {
    0: {
      channelPower: {},
    },
  },
  limit: "" as string,
  bandwidthChannel: "" as string,
  technology: "" as string,
  freq: "" as string,
  bandwidthCustom: "" as string,
  customFreqList: [] as Array<number>,
  customBandwidthList: [] as Array<number>,
  customChannelList: [] as Array<string>,
  channelLoadingStatus: constants.LOADING_BEFORE_START,
  customLoadingStatus: constants.LOADING_BEFORE_START,
  customId: 0,
  customResponseResult: "" as string,
};

const desenseConfigReducer = (state = initialState, action) => {
  switch (action.type) {
    case desenseConfigType.GET_DESENSE_DEVICE_LIST:
      return {
        ...state,
        deviceLoadingStatus: constants.LOADING_LOAD,
      };
    case desenseConfigType.GET_DESENSE_DEVICE_LIST_COMMIT:
      return {
        ...state,
        deviceLoadingStatus: constants.LOADING_SUCCESS,
        desenseDeviceList: Array.isArray(action.payload)
          ? (action.payload as Array<string>)
          : [],
      };
    case desenseConfigType.GET_DESENSE_DEVICE_LIST_ROLLBACK:
      return {
        ...state,
        deviceLoadingStatus: constants.LOADING_FAIL,
      };

    // --------------init loading for clean components -------------
    case desenseConfigType.INIT_LOADING_STATUS:
      return {
        ...state,
        tcsResultLoadingStatus: constants.LOADING_BEFORE_START,
        channelLoadingStatus: constants.LOADING_BEFORE_START,
        customLoadingStatus: constants.LOADING_BEFORE_START,
      };
    // --------------TCS ----------------
    case desenseConfigType.GET_DESENSE_TCS_LIST:
      return {
        ...state,
        tcsListLoadingStatus: constants.LOADING_LOAD,
      };
    case desenseConfigType.GET_DESENSE_TCS_LIST_COMMIT:
      return {
        ...state,
        tcsListLoadingStatus: constants.LOADING_SUCCESS,
        desenseTCSList: Array.isArray(action.payload)
          ? (action.payload as Array<string>)
          : [],
      };
    case desenseConfigType.GET_DESENSE_TCS_LIST_ROLLBACK:
      return {
        ...state,
        tcsListLoadingStatus: constants.LOADING_FAIL,
      };
    // --------------control user selection---------
    case desenseConfigType.SET_USER_SELECTION:
      return {
        ...state,
        selectedData: action.selectedData,
      };

    case desenseConfigType.SET_POWER_REGION:
      return {
        ...state,
        powerRegion: action.powerRegion,
      };

    case desenseConfigType.SET_DESENSE_BAND:
      return {
        ...state,
        band: action.band,
      };

    case desenseConfigType.SET_DESENSE_TCS_ID:
      return {
        ...state,
        tcs_id: action.tcs_id,
      };

    // --------------tcs post get result for visual
    case desenseConfigType.POST_DESENSE_TCS_RESULT:
      return {
        ...state,
        tcsResultLoadingStatus: constants.LOADING_LOAD,
      };

    case desenseConfigType.POST_DESENSE_TCS_RESULT_COMMIT:
      // console.log(state.tcsDesenseData)
      return {
        ...state,
        tcsResultLoadingStatus: constants.LOADING_SUCCESS,
        selectedData: handleMultiTestcaseData(
          state.selectedData,
          action.payload.data,
          action.payload.key
        ),
        tcsResultMessage: action.payload.message,
      };
    case desenseConfigType.POST_DESENSE_TCS_RESULT_ROLLBACK:
      return {
        ...state,
        tcsResultLoadingStatus: constants.LOADING_FAIL,
      };
    // -------------- Power ----------------
    case desenseConfigType.GET_CHANNEL_POWER:
      return {
        ...state,
        channelLoadingStatus: constants.LOADING_LOAD,
      };
    case desenseConfigType.GET_CHANNEL_POWER_COMMIT:
      // console.log(action.payload);
      return {
        ...state,
        channelLoadingStatus: constants.LOADING_SUCCESS,
        selectedData: addChannelPower(
          state.selectedData,
          action.payload,
          DESENSE_TABLE_KEYS_DEFAULT
        ),
      };
    case desenseConfigType.GET_CHANNEL_POWER_ROLLBACK:
      return {
        ...state,
        channelLoadingStatus: constants.LOADING_FAIL,
      };
    case desenseConfigType.GET_CUSTOM_POWER:
      return {
        ...state,
        customLoadingStatus: constants.LOADING_LOAD,
        customResponseResult: "",
      };
    case desenseConfigType.GET_CUSTOM_POWER_COMMIT:
      console.log(action.payload);
      return {
        ...state,
        customLoadingStatus: constants.LOADING_SUCCESS,
        selectedData: appendCustomPower(
          state.selectedData,
          action.payload,
          DESENSE_TABLE_KEYS_CUSTOM
        ),
        customResponseResult: action.payload
          ? "Power has been added to the end of tables successfully."
          : "",
      };
    case desenseConfigType.GET_CUSTOM_POWER_ROLLBACK:
      return {
        ...state,
        customLoadingStatus: constants.LOADING_FAIL,
      };
    case desenseConfigType.SET_CUSTOM_ID:
      return {
        ...state,
        customId: action.customId,
      };
    case desenseConfigType.SET_LIMIT:
      return {
        ...state,
        limit: action.limit,
      };
    case desenseConfigType.SET_TECHNOLOGY:
      return {
        ...state,
        technology: action.technology,
      };
    case desenseConfigType.SET_BANDWIDTH_CHANNEL:
      return {
        ...state,
        bandwidthChannel: action.bandwidthChannel,
      };
    case desenseConfigType.SET_FREQ_CUSTOM:
      return {
        ...state,
        freq: action.freq,
      };
    case desenseConfigType.SET_BANDWIDTH_CUSTOM:
      return {
        ...state,
        bandwidthCustom: action.bandwidthCustom,
      };
    case desenseConfigType.SET_CUSTOM_RESPONSE_RESULT:
      return {
        ...state,
        customResponseResult: action.customResponseResult,
      };
    case desenseConfigType.CLEAN_UP_CUSTOM_POWER:
      return {
        ...state,
        customLoadingStatus: constants.LOADING_SUCCESS,
        selectedData: cleanUpCustomPower(
          state.selectedData,
          DESENSE_TABLE_KEYS_CUSTOM
        ),
      };
    case desenseConfigType.SET_CUSTOM_FREQ_LIST:
      return {
        ...state,
        customFreqList: action.customFreqList,
      };
    case desenseConfigType.SET_CUSTOM_BANDWIDTH_LIST:
      return {
        ...state,
        customBandwidthList: action.customBandwidthList,
      };
    case desenseConfigType.SET_CUSTOM_CHANNEL_LIST:
      return {
        ...state,
        customChannelList: action.customChannelList,
      };
    case desenseConfigType.SET_TCS_RESULT_LOADING_STATUS:
      return {
        ...state,
        tcsResultLoadingStatus: action.tcsResultLoadingStatus,
      };
    default:
      return state;
  }
};

const addChannelPower = (
  existingObj: Object,
  data: Object,
  channelPower: string
) => {
  let newObj = { ...existingObj };

  // add new channel power
  Object.keys(data).forEach((testcaseKey) => {
    newObj[testcaseKey][channelPower] = data[testcaseKey];
  });

  return newObj;
};

const cleanUpCustomPower = (existingObj: Object, customPower: string) => {
  let newObj = { ...existingObj };
  // clean up existing custom power
  Object.keys(newObj).forEach((testcaseKey) => {
    if (
      customPower in newObj[testcaseKey] &&
      Object.keys(newObj[testcaseKey][customPower]).length > 0
    ) {
      Object.keys(newObj[testcaseKey][customPower]).forEach((region) => {
        Object.keys(newObj[testcaseKey][customPower][region]).forEach(
          (column) => {
            newObj[testcaseKey]["customPower"][region][column] = [];
          }
        );
      });
    }
  });

  return newObj;
};

const appendCustomPower = (
  existingObj: Object,
  data: Object,
  customPower: string
) => {
  let newObj = { ...existingObj };

  if (data) {
    Object.keys(data).forEach((testcaseKey) => {
      if (!(customPower in newObj[testcaseKey])) {
        newObj[testcaseKey][customPower] = data[testcaseKey];
      } else {
        Object.keys(data[testcaseKey]).forEach((region) => {
          if (!(region in newObj[testcaseKey][customPower])) {
            newObj[testcaseKey][customPower][region] =
              data[testcaseKey][region];
          } else {
            Object.keys(data[testcaseKey][region]).forEach((column) => {
              if (!(column in newObj[testcaseKey][customPower][region])) {
                newObj[testcaseKey][customPower][region][column] =
                  data[testcaseKey][region][column];
              } else {
                newObj[testcaseKey][customPower][region][column] = [
                  ...newObj[testcaseKey][customPower][region][column],
                  ...data[testcaseKey][region][column],
                ];
              }
            });
          }
        });
      }
    });
  }

  return newObj;
};

export default desenseConfigReducer;
