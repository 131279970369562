import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Toggle } from "@amzn/awsui-components-react/polaris";
import { ContainerNoData } from "../../../../components/container-status-indicator";
import SingleProgress from "./single-progress";
import TestStatusLegend from "./test-status-legend";

// declare prop check
type Props = {
  TCSPagelink: string;
  keys: Array<string>;
  values: { [key: string]: any };
  defaultTestType: string;
  requestId?: number;
  labId?: number;
} & typeof defaultProps &
  RouteComponentProps<any>;

type State = typeof initialState;

// declare init state & default props
const defaultProps = Object.freeze({});

const initialState = Object.freeze({
  showPercentage: true,
});

class ProgressContent extends Component<Props, State> {
  static readonly defaultProps = defaultProps;
  readonly state = initialState;

  _onChangeToggle = (e) => {
    this.setState({ showPercentage: e.detail.checked });
  };

  render() {
    const { keys, values, TCSPagelink, defaultTestType, requestId, labId } =
      this.props;
    if (keys.length === 0) {
      return <ContainerNoData />;
    }

    return (
      <div>
        {/* show percentage toggle and color legend */}
        <div className="d-flex justify-content-between align-items-center">
          <Toggle
            checked={this.state.showPercentage}
            onChange={this._onChangeToggle}
          >
            Show Percentage
          </Toggle>

          <TestStatusLegend />
        </div>
        <br />

        {/* progress list */}
        {keys.map((key, index) => {
          return (
            <div key={index}>
              <SingleProgress
                text={key}
                data={values[key]}
                href={TCSPagelink}
                testType={defaultTestType}
                showPercentage={this.state.showPercentage}
                requestId={requestId}
                labId={labId}
              />
              {index !== keys.length - 1 && <div className="mb-3"> </div>}
            </div>
          );
        })}
      </div>
    );
  }
}

export default withRouter(ProgressContent);
