import { DESENSE_SPLIT_BY } from "../../../../config/constants";
// return true for the same bands for current selectedData
export const theSameBands = (selectedData, isShort: boolean) => {
  // init
  let bandKey = "band";
  let sameBands = false;

  if (isShort) {
    bandKey = "tcs";
    const checkedArray = Object.keys(selectedData).map((eachDropdownKey) => {
      let currentBand = "";
      if (
        selectedData[eachDropdownKey] &&
        selectedData[eachDropdownKey][bandKey]
      ) {
        const currentTCS = selectedData[eachDropdownKey][bandKey];
        if (currentTCS && currentTCS.includes(DESENSE_SPLIT_BY)) {
          currentBand = currentTCS.split(DESENSE_SPLIT_BY)[
            currentTCS.split(DESENSE_SPLIT_BY).length - 1
          ];
        }
      }
      return currentBand;
    });
    // assign value (boolean)
    sameBands = checkedArray.every((eachBand) => eachBand === checkedArray[0]);
  } else {
    const checkedArray = Object.keys(selectedData).map((eachDropdownKey) => {
      let currentBand = "";
      if (
        selectedData[eachDropdownKey] &&
        selectedData[eachDropdownKey][bandKey]
      ) {
        currentBand = selectedData[eachDropdownKey][bandKey];
      }
      return currentBand;
    });
    sameBands = checkedArray.every((eachBand) => eachBand === checkedArray[0]);
  }

  return sameBands;
};
