import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
//polaris
import { Select } from "@amzn/awsui-components-react/polaris";
//redux
import { Dispatch } from "redux";
import { connect } from "react-redux";
// ajax
import { getData } from "../../../../utils/cognito-fetch";
import { config } from "../../../../config/config.js";
import endpoint from "../../../../config/endpoints";
//style
import "./dropdown-list.css";
import constants, {
  TEST_CATEGORY,
  METRIC_INFO,
  DESENSE_SPLIT_BY,
} from "../../../../config/constants";
import {
  setUserSelectDict,
  getDeviceList,
  getDesenseTCSList,
  PostTcsResult,
  getChannelPower,
  getCustomPower,
  // set band and tcs id in reducer
  setSelectedBand,
  setSelectedTCSID,
} from "../../../../redux/actions/desense-config-action";
// utils & constatns
import {
  capitalizeFirstLetter,
  getLogData,
} from "../../../../utils/general-utils";
import {
  getSublvlOptions,
  handleLvlOptions,
  formatTCSSelect,
  getAntennaOptions,
} from "./dropdown-helper-func";
import { DESENSE_SELECTION_KEY } from "../../../../config/constants";
import { getCustomInput } from "../utils/power-helper";

interface StateProps {
  // testcaseReducer: any;
  desenseConfigReducer: any;
}

interface indexProps {
  indexNumber: string;
}

interface MatchParams {
  project: string;
  build: string;
  version: string;
  test_category: string;
}

// declare prop check
type Props = {
  indexNumber: string;
  requestId: number;
  labId: number;
  dispatch: Dispatch<any>;
} & typeof defaultProps &
  RouteComponentProps<MatchParams> &
  StateProps;

// declare init state & default props
const defaultProps = Object.freeze({});
const initialState = Object.freeze({
  projectOptions: [] as any[],
  // build-version
  buildOptions: [] as any[],
  // all projects
  project: "",
  // build_version
  build: "",
  version: "",
  // config_dsn + firmware
  config_dsn: "",
  firmware: "",
  // subsystem + antenna + band
  // tcs: "",
  subsystem: "",
  antenna: "",
  band: "",
  // loading
  projectLoadingStatus: constants.LOADING_SUCCESS,
  buildLoadingStatus: constants.LOADING_SUCCESS,
});

class DesenseLongDropdownList extends Component<Props> {
  readonly state = initialState;
  private dropdownRef: React.RefObject<HTMLInputElement>;

  constructor(props) {
    super(props);
    this.dropdownRef = React.createRef();
  }

  componentDidMount() {
    const { project, build, version } = this.props.match.params;
    const { indexNumber } = this.props;
    this.setState({
      project: project,
      build: build,
      version: version,
    });

    const { selectedData } = this.props.desenseConfigReducer;
    const currentObject = {
      project: project,
      build: build,
      version: version,
    };
    const updateSelected = { ...selectedData };
    updateSelected[indexNumber] = currentObject;
    // {0:{project:project, build:build, version:version}}
    this.props.dispatch(setUserSelectDict(updateSelected));

    this._getProjectOptions();
    this._getBuildOptions(currentObject.project);
    this._getDeviceOptions();
  }

  componentDidUpdate(prevProps, prevState) {
    const { indexNumber } = this.props;
    const { test_category } = this.props.match.params;
    const { project, subsystem, antenna, band } = this.state;
    if (this.state.project && this.state.project !== prevState.project) {
      //project
      this._getBuildOptions(this.state.project);
    }
    if (this.state.version && this.state.version !== prevState.version) {
      //build, version
      this._getDeviceOptions();
    }
    if (this.state.firmware && this.state.firmware !== prevState.firmware) {
      // config_dsn, and firmware
      this._getTCSOptions();
    }
    // currently, we check in band level, once select dirrectly send post
    if (this.state.band && this.state.band !== prevState.band) {
      // config_dsn, and firmware
      const tcsId = parseInt(subsystem.split(DESENSE_SPLIT_BY)[0]);
      const requestJson = {
        test_case_key: indexNumber.toString(),
        tcs_id: subsystem.split(DESENSE_SPLIT_BY)[0],
        sub_system: subsystem.split(DESENSE_SPLIT_BY)[1],
        antenna: antenna,
        band: band,
      };
      this.props.dispatch(
        PostTcsResult(project, test_category, tcsId, requestJson)
      );

      const desenseReducer = this.props.desenseConfigReducer;
      const testcases = [
        {
          testcase_key: indexNumber.toString(),
          // TODO: update tcsId
          tcs_id: subsystem.split(DESENSE_SPLIT_BY)[0],
          sub_system: subsystem.split(DESENSE_SPLIT_BY)[1],
          antenna: antenna,
          band: band,
        },
      ];

      // generate channel power
      if (
        desenseReducer.band &&
        desenseReducer.limit &&
        desenseReducer.bandwidthChannel &&
        desenseReducer.technology
      ) {
        const requestJsonChannelPower = {
          testcases,
          band: desenseReducer.band,
          limit: parseFloat(desenseReducer.limit),
          bandwidth: parseFloat(desenseReducer.bandwidthChannel),
          technology: desenseReducer.technology,
        };

        this.props.dispatch(
          getChannelPower(TEST_CATEGORY.DESENSE, requestJsonChannelPower)
        );
      }

      // get custom power
      if (
        desenseReducer.selectedData &&
        desenseReducer.freq &&
        desenseReducer.bandwidthCustom
      ) {
        const custom_input = getCustomInput(
          desenseReducer.selectedData,
          desenseReducer.customChannelList,
          desenseReducer.customFreqList,
          desenseReducer.customBandwidthList
        );
        // no custom freq and bandwidth added
        if (Object.keys(custom_input).length === 0) {
          return;
        }

        if (desenseReducer.limit && desenseReducer.technology) {
          const requestJsonCustomPower: {} = {
            testcases,
            limit: parseFloat(desenseReducer.limit),
            technology: desenseReducer.technology,
            custom_input,
          };
          this.props.dispatch(
            getCustomPower(TEST_CATEGORY.DESENSE, requestJsonCustomPower)
          );
        }
      }
    }
  }

  // this page may completely change logic
  // Ajax call for Project options
  _getProjectOptions = () => {
    // change load flag
    this.setState({
      projectLoadingStatus: constants.LOADING_LOAD,
    });

    // ajax call
    getData(
      config.BASE_URL + endpoint.getProjectListWithoutCategoryEndpoint(),
      getLogData(
        METRIC_INFO.DESENSE_TESTCASE,
        METRIC_INFO[TEST_CATEGORY.DESENSE]
      )
    )
      .then((response) => {
        const projectOptions = response.json.map((item) => ({
          label: capitalizeFirstLetter(item),
          id: item,
        }));
        this.setState({
          projectOptions,
          projectLoadingStatus: constants.LOADING_SUCCESS,
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          projectLoadingStatus: constants.LOADING_FAIL,
        });
      });
  };

  // Ajax call for build options
  _getBuildOptions = (projectName: string) => {
    const { indexNumber } = this.props;
    // change load flag
    this.setState({
      buildLoadingStatus: constants.LOADING_LOAD,
    });
    const project =
      projectName !== "null"
        ? projectName
        : this.props.desenseConfigReducer.selectedData[indexNumber].project;
    // ajax call
    getData(
      config.BASE_URL + endpoint.getBuildList(project),
      getLogData(
        METRIC_INFO.DESENSE_TESTCASE,
        METRIC_INFO[TEST_CATEGORY.DESENSE]
      )
    )
      .then((response) => {
        const buildOptions = response.json.map((item) => item);
        this.setState({
          buildLoadingStatus: constants.LOADING_SUCCESS,
          buildOptions,
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          buildLoadingStatus: constants.LOADING_FAIL,
        });
      });
  };

  _getDeviceOptions = () => {
    const { project, build, version } = this.state;
    const { requestId, labId } = this.props;
    if (project !== "" && build !== "" && version !== "") {
      // If requestId and LabId undefined, won't show up in payload
      const requestJson = {
        project: project,
        build: build,
        version: version,
        requestId: requestId,
        labId: labId,
      };
      this.props.dispatch(getDeviceList(requestJson));
    }
  };

  _getTCSOptions = () => {
    // change load flag
    this.setState({
      deviceLoadingStatus: constants.LOADING_LOAD,
    });
    const { project, build, version, config_dsn, firmware } = this.state;
    const { requestId, labId } = this.props;
    // change id and update in state
    if (
      project !== "" &&
      build !== "" &&
      version !== "" &&
      config_dsn !== "" &&
      firmware !== ""
    ) {
      const requestJson = {
        project: project,
        build: build,
        version: version,
        device: config_dsn,
        firmware: firmware,
        requestId: requestId,
        labId: labId,
      };
      this.props.dispatch(getDesenseTCSList(requestJson));
    }
  };

  //DYNAMIC id for setState
  _dataOnChange = (event: any) => {
    // event.detail {selectedId: "test", selectedOption: {…}}
    const currentValue: string = event.detail.selectedOption.id;
    const key = event.target.id;
    // dynamic add state
    // TODO for TCS data, saved in reducer not combine with subsys

    this.setState({
      [key]: currentValue,
    });
    // console.log([key], currentValue);

    this._setSelected(key, currentValue);

    const desenseKeyArray = Object.values(DESENSE_SELECTION_KEY);
    // checks user changes & clear keys

    const currentCleanArray = desenseKeyArray.slice(
      desenseKeyArray.indexOf(key) + 1
    );
    this._clearSelectionArray(currentCleanArray);
  };

  // set in reducer
  _setSelected = (key: any, currentValue: string) => {
    const { selectedData } = this.props.desenseConfigReducer;
    const { indexNumber } = this.props;
    // TODO remove tcs infront of subsystem
    let assignValue = currentValue;
    if (key === DESENSE_SELECTION_KEY.BAND) {
      this.props.dispatch(setSelectedBand(currentValue));
    }
    if (key === DESENSE_SELECTION_KEY.SUBSYSTEM) {
      const currentTCSId = currentValue.split(DESENSE_SPLIT_BY)[0];
      this.props.dispatch(setSelectedTCSID(parseInt(currentTCSId)));
      // TODO change subsystem, remove tcs in the value
      // need to double check where it used in that way
    }

    const updateSelected = { ...selectedData };
    updateSelected[indexNumber][key] = assignValue;

    this.props.dispatch(setUserSelectDict(updateSelected));
  };

  // array to clean set
  _clearSelectionArray = (cleanArray: Array<string>) => {
    if (Array.isArray(cleanArray)) {
      cleanArray.forEach((key) => {
        this._clearSelection(key);
      });
    }
  };

  _clearSelection = (key: string) => {
    // clear selections
    this.setState({
      [key]: null,
    });
  };

  onClickRemoveDropdown = () => {
    const { selectedData } = this.props.desenseConfigReducer;
    const { indexNumber } = this.props;

    const currentTestCastObject = { ...selectedData };

    if (Object.keys(currentTestCastObject).length >= 2) {
      delete currentTestCastObject[indexNumber];
    } else {
      window.alert("The only left one cannot be removed");
    }

    this.props.dispatch(setUserSelectDict(currentTestCastObject));
  };

  render() {
    const {
      projectOptions,
      buildOptions,
      // loading status
      projectLoadingStatus,
      buildLoadingStatus,
      // selected value
      project,
      build,
      version,
      config_dsn,
      firmware,
      // subsystem + antenna + band
      subsystem,
      antenna,
      band,
    } = this.state;
    // reducer get 2 levels data
    const {
      desenseTCSList,
      desenseDeviceList,
      deviceLoadingStatus,
      tcsListLoadingStatus,
    } = this.props.desenseConfigReducer;
    const { indexNumber, requestId, labId } = this.props;
    // each build, config, subsystem are levels
    const buildListOptions = handleLvlOptions(
      buildOptions,
      DESENSE_SELECTION_KEY.BUILD
    );
    const versionListOption = getSublvlOptions(buildOptions, build);
    // device level dropdown
    const configListOptions = handleLvlOptions(
      desenseDeviceList,
      DESENSE_SELECTION_KEY.CONFIG
    );
    const firmwareOptions = getSublvlOptions(desenseDeviceList, config_dsn);
    // tcs level dropdown
    const subsystemOptions = handleLvlOptions(
      desenseTCSList,
      DESENSE_SELECTION_KEY.SUBSYSTEM
    );
    const antennaOptions = getAntennaOptions(desenseTCSList);

    // TODO add 'filter' option based on reducer for 2G only left 2G
    const bandOptions = getSublvlOptions(
      desenseTCSList,
      `${subsystem}_${antenna}`
    );

    // all select are using the same format, but each are separate control here and ther
    // DETAIL settings in each level are too complex to loop and map here
    // To create object map to loop will required much more effort than this
    return (
      <div>
        <div className={"testcase-dropdown-list"} ref={this.dropdownRef}>
          {/* project */}
          <div className={"testcase-dropdown-element"}>
            <Select
              id={"project"}
              empty="No options"
              placeholder="Project"
              selectedLabel="Selected"
              options={projectOptions}
              statusType={
                projectLoadingStatus === constants.LOADING_LOAD
                  ? "loading"
                  : null
              }
              selectedOption={
                project
                  ? { id: project, label: capitalizeFirstLetter(project) }
                  : null
              }
              disabled={requestId && labId ? true : false}
              onChange={this._dataOnChange}
              filteringType="auto"
            ></Select>
          </div>

          {/* build */}
          <div className={"testcase-dropdown-element"}>
            <Select
              id={"build"}
              selectedLabel="Selected"
              empty="No options"
              placeholder="Build"
              options={buildListOptions}
              statusType={
                buildLoadingStatus === constants.LOADING_LOAD ? "loading" : null
              }
              selectedOption={
                build
                  ? { id: build, label: capitalizeFirstLetter(build) }
                  : null
              }
              disabled={requestId && labId ? true : false}
              onChange={this._dataOnChange}
              filteringType="auto"
            ></Select>
          </div>

          {/* version */}
          <div className={"testcase-dropdown-element"}>
            <Select
              id={"version"}
              selectedLabel="Selected"
              empty="No options"
              placeholder="version"
              options={versionListOption}
              statusType={
                buildLoadingStatus === constants.LOADING_LOAD ? "loading" : null
              }
              selectedOption={
                version
                  ? { id: version, label: capitalizeFirstLetter(version) }
                  : null
              }
              disabled={requestId && labId ? true : false}
              onChange={this._dataOnChange}
              filteringType="auto"
            ></Select>
          </div>
          {/* config_dsn */}
          <div className={"testcase-dropdown-element"}>
            <Select
              id={"config_dsn"}
              selectedLabel="Selected"
              empty="No options"
              placeholder="config_dsn"
              options={configListOptions}
              statusType={
                deviceLoadingStatus === constants.LOADING_LOAD
                  ? "loading"
                  : null
              }
              selectedOption={
                config_dsn
                  ? { id: config_dsn, label: capitalizeFirstLetter(config_dsn) }
                  : null
              }
              onChange={this._dataOnChange}
              filteringType="auto"
            ></Select>
          </div>
          {/* firmware */}
          <div className={"testcase-dropdown-element"}>
            <Select
              id={"firmware"}
              selectedLabel="Selected"
              empty="No options"
              placeholder="firmware"
              options={firmwareOptions}
              statusType={
                deviceLoadingStatus === constants.LOADING_LOAD
                  ? "loading"
                  : null
              }
              selectedOption={
                firmware
                  ? { id: firmware, label: capitalizeFirstLetter(firmware) }
                  : null
              }
              onChange={this._dataOnChange}
              filteringType="auto"
            ></Select>
          </div>
          {/* subsystem */}
          <div className={"testcase-dropdown-element"}>
            <Select
              id={"subsystem"}
              selectedLabel="Selected"
              empty="No options"
              placeholder="subsystem"
              options={subsystemOptions}
              statusType={
                tcsListLoadingStatus === constants.LOADING_LOAD
                  ? "loading"
                  : null
              }
              selectedOption={
                subsystem
                  ? { id: subsystem, label: formatTCSSelect(subsystem) }
                  : null
              }
              onChange={this._dataOnChange}
              filteringType="auto"
            ></Select>
          </div>
          {/* antenna */}
          <div className={"testcase-dropdown-element"}>
            <Select
              id={"antenna"}
              selectedLabel="Selected"
              empty="No options"
              placeholder="antenna"
              options={antennaOptions}
              statusType={
                tcsListLoadingStatus === constants.LOADING_LOAD
                  ? "loading"
                  : null
              }
              selectedOption={
                antenna
                  ? { id: antenna, label: capitalizeFirstLetter(antenna) }
                  : null
              }
              onChange={this._dataOnChange}
              filteringType="auto"
            ></Select>
          </div>

          {/* band */}
          <div className={"testcase-dropdown-element"}>
            <Select
              id={"band"}
              selectedLabel="Selected"
              empty="No options"
              placeholder="band"
              options={bandOptions}
              statusType={
                tcsListLoadingStatus === constants.LOADING_LOAD
                  ? "loading"
                  : null
              }
              selectedOption={
                band ? { id: band, label: capitalizeFirstLetter(band) } : null
              }
              onChange={this._dataOnChange}
              filteringType="auto"
            ></Select>
          </div>

          {indexNumber !== "0" && (
            <button
              className={"icon-btn "}
              onClick={this.onClickRemoveDropdown}
            >
              <i className="fas fa-times fa-lg"></i>
            </button>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    desenseConfigReducer: state.desenseConfigReducer,
  };
};

export default withRouter(
  connect<StateProps>(mapStateToProps)(DesenseLongDropdownList)
);
