export class PlotData {
  data: any[];
  layout: {};
  title: string;
  constructor(datarate: string) {
    this.data = [] as any;
    this.title = datarate;
    this.layout = {
      // title: datarate,
      xaxis: {
        title: "channel",
        titlefont: {
          family: "Roboto",
          size: 18,
        },
      },
      yaxis: {
        title: "power",
        titlefont: {
          family: "Roboto",
          size: 18,
        },
      },
      margin: {
        t: 30,
      },
    };
  }
}
export const powerTalbePlotlyFormat = (data: {}) => {
  var rst: PlotData[] = [];
  Object.keys(data).forEach((datarate) => {
    let onePlot = new PlotData(datarate);
    onePlot.data.push(getLineData(data, datarate, "output_power", false));
    onePlot.data.push(getLineData(data, datarate, "set_power", false));
    onePlot.data.push(getLineData(data, datarate, "soft_usl", true));
    onePlot.data.push(getLineData(data, datarate, "soft_lsl", true));
    onePlot.data.push(getLineData(data, datarate, "hard_usl", true));
    onePlot.data.push(getLineData(data, datarate, "hard_lsl", true));
    rst.push(onePlot);
  });
  return rst;
};

export const getLineData = (
  data: {},
  datarate: string,
  name: string,
  limit: boolean
): {} => {
  let line = {};
  if (limit === true) {
    line["dash"] = "dot";
  }

  return {
    x: data[datarate]["channel"],
    y: data[datarate][name],
    name: name,
    line: line,
  };
};
