import React, { Component } from "react";
import { withRouter } from "react-router-dom";
// redux
import { connect } from "react-redux";
import {
  initDesenseLoadingStatus,
  setUserSelectDict,
  setLimit,
  setTechnology,
  setBandwidthChannel,
  setFreqCustom,
  setBandwidthCustom,
  setCustomId,
  setCustomFreqList,
  setCustomBandwidthList,
  setCustomChannelList,
  setCustomResponseResult,
  setTcsResultLoadingStatus,
} from "../../../redux/actions/desense-config-action";
import {
  Button,
  Spinner,
  Icon,
  Toggle,
  Alert,
} from "@amzn/awsui-components-react/polaris";
import constants, {
  DEFAULT_DROPDOWN_LIMIT,
  DROPDOWN_DEFAULT,
  DEFAULT_DESENSE_LIMIT,
} from "../../../config/constants";
// child components
import BreadcrumbGroup from "../../../components/breadcrumb-group";
// desense compoments
import DesenseDropdownList from "./dropdown/dropdown-list";
import DesenseLongDropdownList from "./dropdown/long-dropdown-list";
import DesenseVisualization from "../../visualization/desense/desenseVisualization";
// import DesenseTabs from "./tabs/desense-tabs";
import DesenseRootTable from "./table/desense-root-table";
import DesenseTestcaseLabel from "./test-case-label/test-case-label";
//style for this desense page
import "./desense-testcase.css";
import PowerConfig from "./power-config/power-config";
import { theSameBands } from "./utils/post-checking-alert";
import querySearch from "query-string";

class DesenseTestcase extends Component {
  state = {
    notShortDropdown: true,
  };

  onClickAddDropdown = () => {
    const { selectedData } = this.props.desenseConfigReducer;

    const currentTestCastObject = { ...selectedData };
    const newKeyNumber =
      Math.max(
        ...Object.keys(currentTestCastObject).map((key) => parseInt(key))
      ) + 1;
    currentTestCastObject[newKeyNumber] = {};
    this.props.dispatch(setUserSelectDict(currentTestCastObject));
    // this.setState({
    //   userTestCase: currentTestCastArray,
    // });
  };
  onClickClear = () => {
    const { project, build, version } = this.props.match.params;

    // completed clean the object, remove states in dropdownlist
    const cleanSelectedData = {};
    this.props.dispatch(setUserSelectDict(cleanSelectedData));
    // init object, ask re-render component dropdownlist
    const initSelectedData = {
      0: {
        project: project,
        build: build,
        version: version,
        buildCombine: `${build}_${version}`,
        channelPower: {},
      },
    };
    this.props.dispatch(setUserSelectDict(initSelectedData));
    this.props.dispatch(initDesenseLoadingStatus());

    this.cleanUpPowerConfig();
    this.props.dispatch(
      setTcsResultLoadingStatus(constants.LOADING_BEFORE_START)
    );
  };

  switchDropdown = () => {
    const { project, build, version } = this.props.match.params;

    this.setState({
      notShortDropdown: !this.state.notShortDropdown,
    });
    // switch dropdown will need to add current param
    const initSelectedData = {
      0: {
        project: project,
        build: build,
        version: version,
        buildCombine: `${build}_${version}`,
        channelPower: {},
      },
    };
    this.props.dispatch(setUserSelectDict(initSelectedData));
    this.props.dispatch(initDesenseLoadingStatus());

    this.cleanUpPowerConfig();
    this.props.dispatch(
      setTcsResultLoadingStatus(constants.LOADING_BEFORE_START)
    );
  };

  componentWillUnmount() {
    this.cleanUpPowerConfig();

    this.props.dispatch(
      setTcsResultLoadingStatus(constants.LOADING_BEFORE_START)
    );
  }

  cleanUpPowerConfig = () => {
    this.props.dispatch(setLimit(DEFAULT_DESENSE_LIMIT));
    this.props.dispatch(setTechnology(DROPDOWN_DEFAULT.TECHNOLOGY));
    this.props.dispatch(setBandwidthChannel(""));

    this.props.dispatch(setFreqCustom(""));
    this.props.dispatch(setBandwidthCustom(""));
    this.props.dispatch(setCustomId(0));

    this.props.dispatch(setCustomFreqList([]));
    this.props.dispatch(setCustomBandwidthList([]));
    this.props.dispatch(setCustomChannelList([]));

    this.props.dispatch(setCustomResponseResult(""));
  };

  // handle generating channel power and custom power, after user select a test case
  // handlePower = (id) => {
  //   // const test_case_obj = this.props.desenseConfigReducer.selectedData[id];
  // };

  render() {
    const {
      tcsResultLoadingStatus,
      tcsResultMessage,
      selectedData,
      channelLoadingStatus,
    } = this.props.desenseConfigReducer;

    // const { selectedData } = this.props.desenseConfigReducer;
    const { notShortDropdown } = this.state;
    // for performance concern, limit stopAddNew to DEFAULT_DROPDOWN_LIMIT
    const stopAddNew =
      Object.keys(selectedData).length >= DEFAULT_DROPDOWN_LIMIT ? true : false;

    const showBandCheckWarnning = !theSameBands(
      selectedData,
      !notShortDropdown
    );

    let queryString = {};
    if (this.props.location && this.props.location.search) {
      queryString = querySearch.parse(this.props.location.search);
    }
    const requestId = queryString.request_id
      ? queryString.request_id
      : undefined;
    const labId = queryString.lab_id ? queryString.lab_id : undefined;

    const mapMultiDropdown = Object.keys(selectedData).map((eachKey, index) => (
      <div key={index}>
        {notShortDropdown ? (
          <div>
            Testcase {eachKey}:
            <DesenseLongDropdownList
              indexNumber={eachKey}
              requestId={requestId}
              labId={labId}
            />
          </div>
        ) : (
          <div>
            Testcase {eachKey}:
            <DesenseDropdownList
              indexNumber={eachKey}
              requestId={requestId}
              labId={labId}
            />
          </div>
        )}
      </div>
    ));

    return (
      <div>
        <BreadcrumbGroup
          textGroup={[
            { text: "Dashboard", href: "/" },
            { text: `Desense View` }, // TestCategory
          ]}
        />
        <Toggle checked={notShortDropdown} onChange={this.switchDropdown}>
          {notShortDropdown ? "switch short dropdown" : "switch full dropdown"}
        </Toggle>
        <br />
        {/* band check, if user select 2 differ band */}
        {showBandCheckWarnning && (
          <Alert
            dismissAriaLabel="Close alert"
            header="Your current band values are not the same"
          >
            Your must select the same band for multi-comparison.
          </Alert>
        )}

        {/* from desenseVisualization main file*/}
        {mapMultiDropdown}

        <div className="Btn-right-outer">
          <Button disabled={stopAddNew} onClick={this.onClickClear}>
            Clear
          </Button>
          <Button disabled={stopAddNew} onClick={this.onClickAddDropdown}>
            Add
          </Button>
        </div>
        <DesenseTestcaseLabel notShortDropdown={notShortDropdown} />
        <br />
        {constants.LOADING_BEFORE_START === tcsResultLoadingStatus && (
          <div className="desense-notice-info">
            Click submit & load the visualization
          </div>
        )}
        {constants.LOADING_LOAD === tcsResultLoadingStatus && (
          <div>
            <Spinner size="large" />
          </div>
        )}
        {constants.LOADING_SUCCESS === tcsResultLoadingStatus && (
          <div>
            {tcsResultMessage.includes("not") ? (
              <div>
                <Icon name="status-negative" size="normal" variant="error" />
                {tcsResultMessage}
              </div>
            ) : (
              <div>
                <DesenseVisualization />
                <br />
                <PowerConfig />
                {constants.LOADING_SUCCESS === channelLoadingStatus && (
                  <div>
                    <DesenseRootTable />
                    {/* <DesenseDynamicColTable /> */}
                  </div>
                )}
                {/* Table loading */}
                {constants.LOADING_LOAD === channelLoadingStatus && (
                  <div>
                    <Spinner size="large" /> Loading Table Data ...
                  </div>
                )}
              </div>
            )}
          </div>
        )}
        {constants.LOADING_FAIL === tcsResultLoadingStatus && (
          <div>
            <Icon name="status-negative" size="big" variant="error" />
            Loading Failed
          </div>
        )}

        {/* visualization for desense view */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    tcsResultReducer: state.tcsResultReducer,
    desenseConfigReducer: state.desenseConfigReducer,
  };
};
export default withRouter(connect(mapStateToProps)(DesenseTestcase));
