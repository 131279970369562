import { METRIC_INFO } from "../config/constants";

export const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const getLogData = (
  feature: string,
  test_category: any = null,
  category: any = null,
  project: any = null
) => {
  const data: {} = { initiative: METRIC_INFO.INITIATIVE, feature };
  if (typeof test_category === "string" && test_category.length > 0)
    data["testcategory"] = test_category; // need to remove _, otherwise can't match in Kibana
  if (typeof category === "string" && category.length > 0)
    data["category"] = capitalizeFirstLetter(category);
  if (typeof project === "string" && project.length > 0)
    data["project"] = capitalizeFirstLetter(project);

  return data;
};

export const financialToFixed = (
  inputNumber: any = null,
  fixedNumber: number
) => {
  if (inputNumber && !isNaN(inputNumber)) {
    return Number.parseFloat(inputNumber).toFixed(fixedNumber);
  }
  return inputNumber;
};

// general func to handl multi-dropdown data merge to selectedData
// merge new object as key/value in existed Object
export const handleMultiTestcaseData = (
  existedObj: Object,
  newObject: Object,
  key: string
) => {
  // add new object value to the
  let resultObject = existedObj;
  let indexKeyNumber = -1;
  if (key) {
    indexKeyNumber = parseInt(key);
  }

  if (indexKeyNumber >= 0) {
    resultObject[indexKeyNumber] = {
      ...resultObject[indexKeyNumber],
      ...{ data: newObject },
    };
  }

  return resultObject;
};

export const addRequestLabToLink = (
  link: string,
  requestId: number | undefined,
  labId: number | undefined
) => {
  let newLink = link;
  if (requestId) {
    if (newLink.includes("?")) {
      newLink = `${newLink}&request_id=${requestId}`;
    } else {
      newLink = `${newLink}?request_id=${requestId}`;
    }
  }
  if (labId) {
    if (newLink.includes("?")) {
      newLink = `${newLink}&lab_id=${labId}`;
    } else {
      newLink = `${newLink}?lab_id=${labId}`;
    }
  }
  return newLink;
};
