import React from "react";
import { TEST_STATUS_COLOR } from "../../../../../config/color-config";

export const TABLE_MAP = {
  pbv: {
    columnDefinitions: {
      id: "pbv",
      header: "Proj - Build",
      width: 200,
    },
    filteringProps: {
      key: "pbv",
      operators: ["=", "!=", ":", "!:", ">", ">=", "<", "<="],
      propertyLabel: "Proj - Build",
      groupValuesLabel: "Proj - Build values",
    },
  },
  configDevice: {
    columnDefinitions: {
      id: "configDevice",
      header: "Config - Device",
      width: 180,
    },
    filteringProps: {
      key: "configDevice",
      operators: ["=", "!=", ":", "!:", ">", ">=", "<", "<="],
      propertyLabel: "Config - Device",
      groupValuesLabel: "Config - Device values",
    },
  },
  test: {
    columnDefinitions: {
      id: "test",
      header: "Test Item",
      width: 500,
    },
    filteringProps: {
      key: "test",
      operators: ["=", "!=", ":", "!:", ">", ">=", "<", "<="],
      propertyLabel: "Test Item",
      groupValuesLabel: "Test Item values",
    },
  },
  result: {
    columnDefinitions: {
      id: "result",
      header: "Result",
      cell: (item) => (
        <span
          style={{
            color: `${TEST_STATUS_COLOR[item.result.toLowerCase()]}`,
            fontWeight: 700,
          }}
        >
          {item.result}
        </span>
      ),
    },
    filteringProps: {
      key: "result",
      operators: ["=", "!=", ":", "!:"],
      propertyLabel: "Result",
      groupValuesLabel: "Result values",
    },
  },
  upload_time: {
    columnDefinitions: {
      id: "upload_time",
      header: "Upload Time (UTC)",
      visible: false,
    },
    filteringProps: {
      key: "upload_time",
      operators: ["=", "!=", ">", "<", "<=", ">=", ":", "!:"],
      propertyLabel: "Upload Time (UTC)",
      groupValuesLabel: "UTC Upload Time values",
    },
  },
};

export const COLUMN_DEFINITIONS = Object.keys(TABLE_MAP).map((eachKey) => ({
  label: (sortState) => {
    const isColumnSorted = sortState.sortingColumn === eachKey;
    const ascending = !sortState.sortingDescending;
    return `${eachKey}, ${
      isColumnSorted
        ? `sorted ${ascending ? "ascending" : "descending"}`
        : "not sorted"
    }.`;
  },
  minWidth: 120,
  sortingField: eachKey,
  visible: true,
  cell: (item) => item[eachKey],
  ...TABLE_MAP[eachKey].columnDefinitions,
}));

export const DEFAULT_PREFERENCES = {
  pageSize: 10,
  visibleContent: COLUMN_DEFINITIONS.filter((column) => column.visible).map(
    (column) => column.id
  ),
  wrapLines: false,
};

export const FILTER_PROPS: any[] = Object.values(TABLE_MAP).map(
  (element) => element.filteringProps
);
