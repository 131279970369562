import constants from "../../config/constants";
import { TcsResultResponse } from "../../model/http-json";
import tcsResultType from "../types/tcs-result-type";

const initialState = {
  tcsResultLoadingStatus: constants.LOADING_BEFORE_START,
  tcsResultData: {} as TcsResultResponse,
  tcsDesenseData: [],
  tcsResultMessage: "",
};

const tcsResultReducer = (state = initialState, action) => {
  switch (action.type) {
    case tcsResultType.GET_TCS_RESULT_STATUS:
      return {
        ...state,
        tcsResultLoadingStatus: constants.LOADING_LOAD,
      };
    case tcsResultType.GET_TCS_RESULT_STATUS_COMMIT:
      return {
        ...state,
        tcsResultLoadingStatus: constants.LOADING_SUCCESS,
        tcsResultData: action.payload.data as TcsResultResponse,
        tcsResultMessage: action.payload.message,
      };
    case tcsResultType.GET_TCS_RESULT_STATUS_ROLLBACK:
      return {
        ...state,
        tcsResultLoadingStatus: constants.LOADING_FAIL,
      };

    case tcsResultType.POST_TCS_RESULT_STATUS:
      return {
        ...state,
        tcsResultLoadingStatus: constants.LOADING_LOAD,
      };

    case tcsResultType.POST_TCS_RESULT_STATUS_COMMIT:
      // console.log(state.tcsDesenseData)
      return {
        ...state,
        tcsResultLoadingStatus: constants.LOADING_SUCCESS,
        tcsDesenseData: [...state.tcsDesenseData, action.payload.data],
        tcsResultMessage: action.payload.message,
      };
    case tcsResultType.POST_TCS_RESULT_STATUS_ROLLBACK:
      return {
        ...state,
        tcsResultLoadingStatus: constants.LOADING_FAIL,
      };

    case tcsResultType.SET_TCS_RESULT_STATUS:
      return {
        ...state,
        tcsResultLoadingStatus: action.tcsResultLoadingStatus,
        tcsResultData: action.tcsResultData,
      };

    case tcsResultType.CLEAN_DESENSE_ARRAY:
      return {
        ...state,
        tcsDesenseData: action.tcsDesenseData,
      };

    case tcsResultType.DECREASE_DESENSE_ARRAY:
      const currentTcsDesenseData = state.tcsDesenseData;
      currentTcsDesenseData.pop();
      return {
        ...state,
        tcsDesenseData: currentTcsDesenseData,
      };
    default:
      return state;
  }
};
export default tcsResultReducer;
