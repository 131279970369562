import React, { useState } from "react";
//polaris
import { Input, Button } from "@amzn/awsui-components-react/polaris";
//styles for filter
import "./rangeFilter.css";
import VisualizationChart from "./desnese-plot-chart/visualizationChart";
import {awsRum} from "../../../index";

function DesenseVisualization() {
  // input filter
  const [filterValue, writeFilterValue] = useState({
    xMin: null,
    xMax: null,
    yMin: null,
    yMax: -100, // default for y, since most value around -120
  });
  //confim filter
  function inputFilter(e) {
    writeFilterValue({ ...filterValue, [e.target.id]: e.detail.value });
  }

  function cleanInput(e) {
    writeFilterValue({
      ...filterValue,
      xMin: null,
      xMax: null,
      yMin: null,
      yMax: -100,
    });
  }
  awsRum?.recordEvent("desense_visulaization",{
      metricCategory: 'desense_visualization',
  });

  // mapped range filter div
  const mappedKeys = Object.keys(filterValue);
  let mappedRangeComponent = mappedKeys.map((eachKey, index) => (
    <div className={"desense-filter-element"} key={index}>
      <label htmlFor={eachKey}>{eachKey}</label>
      <span>
        <Input
          id={eachKey}
          name={eachKey}
          value={filterValue[eachKey] ? filterValue[eachKey].toString() : null}
          onChange={inputFilter}
        ></Input>
      </span>
    </div>
  ));
  return (
    <div>
      {/* handle chart */}
      <VisualizationChart filterObject={filterValue} />
      {/* filtering */}
      <div className={"desense-range-filter"}>{mappedRangeComponent}</div>

      <div className="Btn-right-outer">
        <div className="Btn-right-inner">
          <Button className={"pull-right"} onClick={cleanInput}>
            Clear Filter
          </Button>
          {/* <Button disable>Fill</Button> */}
        </div>
      </div>
      <br />
    </div>
  );
}

export default DesenseVisualization;
