import rvrConfigType from "../types/rvr-config-type";
import constants, { RANGE_CONSTANTS_5G , DEFAULT_TECHNOLOGY } from "../../config/constants";
import { handleMultiTestcaseData } from "../../utils/general-utils";

const initialState = {
  testcaseDropdown: {},
  testcaseDropdownMessage: "",
  testcaseDropdownLoadingStatus: constants.LOADING_LOAD,

  loadingStatus: constants.LOADING_LOAD,
  tcsResultLoadingStatus: constants.LOADING_BEFORE_START,
  tcsResultMessage: "",

  channelTechnologyMapping: [],
  rangeCalculator: {
    ...RANGE_CONSTANTS_5G,
    selectedTechnology:DEFAULT_TECHNOLOGY,
  },
  // Main Object for control all data in RVR page and mult-dropdown
  selectedData: {
    0: {},
  },
};

const rvrConfigReducer = (state = initialState, action) => {
  switch (action.type) {
    case rvrConfigType.GET_RVR_DEVICE_LIST:
      return {
        ...state,
        testcaseDropdownLoadingStatus: constants.LOADING_LOAD,
      };
    case rvrConfigType.GET_RVR_DEVICE_LIST_COMMIT:
      return {
        ...state,
        testcaseDropdownLoadingStatus: constants.LOADING_SUCCESS,
        testcaseDropdown: action.payload,
      };
    case rvrConfigType.GET_RVR_DEVICE_LIST_ROLLBACK:
      return {
        ...state,
        testcaseDropdownLoadingStatus: constants.LOADING_FAIL,
        testcaseDropdownMessage: action.payload.message,
      };

    // --------------init loading for clean components -------------
    case rvrConfigType.INIT_LOADING_STATUS:
      return {
        ...state,
        loadingStatus: constants.LOADING_BEFORE_START,
        tcsResultLoadingStatus: constants.LOADING_BEFORE_START,
      };
    // --------------TCS ----------------
    case rvrConfigType.POST_RVR_TCS_RESULT:
      return {
        ...state,
        tcsResultLoadingStatus: constants.LOADING_LOAD,
      };
    case rvrConfigType.POST_RVR_TCS_RESULT_COMMIT:
      return {
        ...state,
        tcsResultLoadingStatus: constants.LOADING_SUCCESS,
        selectedData: handleMultiTestcaseData(
          state.selectedData,
          action.payload.data,
          action.payload.key
        ),
        tcsResultMessage: action.payload.message,
      };
    case rvrConfigType.POST_RVR_TCS_RESULT_ROLLBACK:
      return {
        ...state,
        tcsResultLoadingStatus: constants.LOADING_FAIL,
        tcsResultMessage: action.payload.message,
      };

    // --------------control user selection---------
    case rvrConfigType.SET_USER_SELECTION:
      return {
        ...state,
        selectedData: action.selectedData,
      };

    case rvrConfigType.SET_RANGE_CALCULATOR:
      return {
        ...state,
        rangeCalculator: action.rangeCalculator,
      };

    case rvrConfigType.GET_CHANNEL_TECHNOLOGY_MAPPING:
      return {
        ...state,
        channelTechnologyMapping: action.payload,
      };

      
    default:
      return state;
  }
};
export default rvrConfigReducer;
