import React from "react";
import { NavLink } from "react-router-dom";

const BreadcrumbGroup = (props) => {
  return (
    <div>
      {props.textGroup.map((item: { [key: string]: any }, index: number) => (
        <span key={index}>
          {item.href && <NavLink to={item.href}>{item.text}</NavLink>}
          {!item.href && <span>{item.text}</span>}
          {index < props.textGroup.length - 1 && (
            <span>
              &nbsp;
              <i className="fas fa-chevron-right"></i>&nbsp;
            </span>
          )}
        </span>
      ))}
    </div>
  );
};

export default BreadcrumbGroup;
