import { TEST_CATEGORY } from "../../../../config/constants";
import { capitalizeFirstLetter } from "../../../../utils/general-utils";

export const testCategoryOptions = [
  {
    label: capitalizeFirstLetter(TEST_CATEGORY.COMPLIANCE),
    id: TEST_CATEGORY.COMPLIANCE,
  },
  {
    label: capitalizeFirstLetter(TEST_CATEGORY.DESENSE),
    id: TEST_CATEGORY.DESENSE,
  },
];

export const getCustomMessageItems = (customResponseResult: string) => [
  {
    type: "success",
    content: customResponseResult,
    dismissible: true,
  },
];
