import base_config from "./base_config";

const prod_config = {
  ...base_config,
  STAGE: "prod",

  // Amplify config for cognito
  AMPLIFY_CONFIG: {
    aws_project_region: base_config.REGION,
    aws_cognito_region: base_config.REGION,
    aws_user_pools_id: "us-west-2_7jaT8GnzE",
    aws_user_pools_web_client_id: "7h5ec3if4vohn56bdh9ol04p8v",
    oauth: {
      domain: "wise-general-cog-prod.auth.us-west-2.amazoncognito.com",
      scope: base_config.TOKEN_SCOPES_ARRAY,
      redirectSignIn: base_config.CALL_BACK_URL,
      redirectSignOut: base_config.CALL_BACK_URL,
      responseType: base_config.OAUTH_RESPONSE_TYPE,
    },
  },
  // Base url
  BASE_URL: "https://khymbw4f90.execute-api.us-west-2.amazonaws.com/prod",
  // Upload service base url
  UPLOAD_BASE_URL:
    "https://oyyu1bze98.execute-api.us-west-2.amazonaws.com/prod",

  // Configuration for Cloudwatch RUM
  APPLICATION_ID: "5cc2c5a5-fd39-49c9-af84-69cdc25b7372",
  APPLICATION_VERSION:"1.0.0",
  APPLICATION_REGION: "us-west-2",
  rum_config: {
    sessionSampleRate: 1,
    guestRoleArn: "arn:aws:iam::599891808472:role/RUM-Monitor-us-west-2-599891808472-0448049931271-Unauth",
    identityPoolId: "us-west-2:f2e63485-a441-48d6-865d-71e66ddc01cc",
    endpoint: "https://dataplane.rum.us-west-2.amazonaws.com",
    telemetries: ["performance","errors",["http",{recordAllRequests: true}]],
    allowCookies: true,
    enableXRay: false,
    disableAutoPageView: false
  },
};

export default prod_config;
