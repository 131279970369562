import { TEST_STATUS_COLOR_RGB } from "../../../../config/color-config";
import { TEST_STATUS } from "../../../../config/constants";

export const coconutLineChartFormat = (dataRateObj) => {
  let res: any = [];

  const powerKeys = Object.keys(dataRateObj);

  if (Object.keys(dataRateObj).length > 0) {
    // high limit
    res.push({
      name: "high_limits",
      x: dataRateObj[powerKeys[0]]["freqs"],
      y: dataRateObj[powerKeys[0]]["high_limits"],
      mode: "lines",
      line: {
        color: TEST_STATUS_COLOR_RGB[TEST_STATUS.FAILED],
        width: 1,
      },
    });

    // low limit
    res.push({
      name: "low_limits",
      x: dataRateObj[powerKeys[0]]["freqs"],
      y: dataRateObj[powerKeys[0]]["low_limits"],
      mode: "lines",
      line: {
        color: TEST_STATUS_COLOR_RGB[TEST_STATUS.FAILED],
        width: 1,
      },
    });

    // all power series
    powerKeys.forEach((eachPower) => {
      res.push({
        name: eachPower,
        x: dataRateObj[eachPower]["freqs"],
        y: dataRateObj[eachPower]["results"],
        mode: "lines+markers",
      });
    });
  }

  res = res.flat().filter((ele, index, res) => res.findIndex((target) => target.name === ele.name) === index);
  return res;
};
