import React from "react";
import { NavLink } from "react-router-dom";
import DashboardProgressCell from "./progress-cell";
import { capitalizeFirstLetter } from "../../../utils/general-utils";
import { LANDING_COLOR } from "../../../config/color-config";
import { DIRECT_TESTCASE_LIST } from "../../../config/constants";

export const defaultPageSize = 10;

// define columns
export const columnDefinitions = [
  {
    id: "project",
    header: "Project",
    label: (sortState) => {
      const isColumnSorted = sortState.sortingColumn === "project";
      const ascending = !sortState.sortingDescending;
      return `Project, ${
        isColumnSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => getNavLink(item, capitalizeFirstLetter(item.project)),
    minWidth: "100px",
    visible: true,
  },
  {
    id: "build",
    header: "Build",
    label: (sortState) => {
      const isColumnSorted = sortState.sortingColumn === "build";
      const ascending = !sortState.sortingDescending;
      return `Build, ${
        isColumnSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => getNavLink(item, item.build.toUpperCase()),
    minWidth: "100px",
    visible: true,
  },
  {
    id: "version",
    header: "Version",
    label: (sortState) => {
      const isColumnSorted = sortState.sortingColumn === "version";
      const ascending = !sortState.sortingDescending;
      return `Version, ${
        isColumnSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => getNavLink(item, item.version),
    minWidth: "100px",
    visible: true,
  },
  {
    id: "test_category",
    header: "Test Category",
    label: (sortState) => {
      const columnIsSorted = sortState.sortingColumn === "test_category";
      const ascending = !sortState.sortingDescending;
      return `Test Category, ${
        columnIsSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => item.test_category,
    minWidth: "100px",
    visible: true,
  },
  {
    id: "created_time",
    header: "Created Time",
    label: (sortState) => {
      const columnIsSorted = sortState.sortingColumn === "created_time";
      const ascending = !sortState.sortingDescending;
      return `Created Time, ${
        columnIsSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => item.created_time,
    minWidth: "100px",
    visible: false,
  },
  {
    id: "updated_time",
    header: "Latest Updated Time",
    label: (sortState) => {
      const columnIsSorted = sortState.sortingColumn === "updated_time";
      const ascending = !sortState.sortingDescending;
      return `Latest Update Time, ${
        columnIsSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => item.updated_time,
    minWidth: "100px",
    visible: true,
  },
  {
    id: "status",
    header: "Status",
    label: (sortState) => {
      const isColumnSorted = sortState.sortingColumn === "status";
      const ascending = !sortState.sortingDescending;
      return `Status, ${
        isColumnSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => (
      <div
        style={{
          color: `${
            item.status in LANDING_COLOR ? LANDING_COLOR[item.status] : `black`
          }`,
        }}
      >
        {item.status}
      </div>
    ),
    minWidth: "100px",
    visible: true,
  },
  {
    id: "progress",
    header: "Test Progress",
    cell: (item) => <DashboardProgressCell progressData={item.progress} />,
    minWidth: "75px",
    visible: true,
  },
];

// define default sorting column
export const defaultSortingColumn = "updated_time";

// define sortable columns
export const sortableColumns = [
  { id: "project", field: "project" },
  { id: "build", field: "build" },
  { id: "version", field: "version" },
  { id: "created_time", field: "created_time" },
  { id: "updated_time", field: "updated_time" },
  { id: "test_category", field: "test_category" },
  { id: "status", field: "status" },
];

// define visiable columns
export const visibleColumns = [
  {
    label: "Properties",
    options: [
      { id: "project", label: "Project", visible: true, editable: false },
      { id: "build", label: "Build", visible: true, editable: false },
      { id: "version", label: "Version", visible: true, editable: false },
      {
        id: "test_category",
        label: "Test Category",
        visible: true,
        editable: false,
      },
      {
        id: "created_time",
        label: "Created Time",
        visible: false,
      },
      {
        id: "updated_time",
        label: "Latest Updated Time",
        visible: true,
      },
      { id: "status", label: "Status", visible: true },
      { id: "progress", label: "Progress", visible: true },
    ],
  },
];

// define filtering options
export const filteringOptions = [
  {
    groupValuesLabel: "Project",
    propertyKey: "project",
    propertyLabel: "Project",
    values: [],
  },
  {
    groupValuesLabel: "Build",
    propertyKey: "build",
    propertyLabel: "Build",
    values: [],
  },
  {
    groupValuesLabel: "Version",
    propertyKey: "version",
    propertyLabel: "Version",
    values: [],
  },
  {
    groupValuesLabel: "Status",
    propertyKey: "status",
    propertyLabel: "Status",
    values: [],
  },
  {
    groupValuesLabel: "Latest Update Time",
    propertyKey: "updated_time",
    propertyLabel: "Latest Update Time",
    values: [],
  },
  {
    groupValuesLabel: "Test Category",
    propertyKey: "test_category",
    propertyLabel: "Test Category",
    values: [],
  },
];

export const features = [
  "propertyFiltering",
  "pagination",
  "selection",
  "sorting",
];

const getNavLink = (item, text) => {
  // desense doesn't have an overview page
  if (DIRECT_TESTCASE_LIST.includes(item.test_category)) {
    return (
      <NavLink
        to={`/${item.project}/${item.build}/${item.version}/${item.test_category}/testcase`}
      >
        {text}
      </NavLink>
    );
  }

  return (
    <NavLink
      to={`/${item.project}/${item.build}/${item.version}/${item.test_category}/overview`}
    >
      {text}
    </NavLink>
  );
};
