import React from "react";
import constants, { STATUS_TEXT } from "../../../config/constants";

// default page size
export const defaultPageSize = 10;

export const badgeColorMap = {
  [STATUS_TEXT[constants.STATUS_SUCCESS]]: "green" as "green",
  [STATUS_TEXT[constants.STATUS_FAIL]]: "red" as "red",
  [STATUS_TEXT[constants.STATUS_PROCESS]]: "blue" as "blue",
};
const statusClassNameMap = {
  [STATUS_TEXT[constants.STATUS_SUCCESS]]: "text-success",
  [STATUS_TEXT[constants.STATUS_FAIL]]: "text-danger",
  [STATUS_TEXT[constants.STATUS_PROCESS]]: "text-info",
};

// page size option
export const pageSizeOptions = [
  { value: 10, label: "10 items" },
  { value: 20, label: "20 items" },
  { value: 50, label: "50 items" },
];

// define columns
export const columnDefinitions = [
  {
    id: "user",
    header: "User",
    label: (sortState) => {
      const isColumnSorted = sortState.sortingColumn === "user";
      const ascending = !sortState.sortingDescending;
      return `User, ${
        isColumnSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => item.user,
    minWidth: "100px",
  },
  {
    id: "tool",
    header: "Automation Tool",
    label: (sortState) => {
      const isColumnSorted = sortState.sortingColumn === "tool";
      const ascending = !sortState.sortingDescending;
      return `Automation Tool, ${
        isColumnSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => item.tool,
    minWidth: "100px",
  },
  {
    id: "file_name",
    header: "File Name",
    label: (sortState) => {
      const isColumnSorted = sortState.sortingColumn === "file_name";
      const ascending = !sortState.sortingDescending;
      return `File Name, ${
        isColumnSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => item.file_name,
    minWidth: "100px",
  },
  {
    id: "upload_time",
    header: "Upload Time(local)",
    label: (sortState) => {
      const isColumnSorted = sortState.sortingColumn === "upload_time";
      const ascending = !sortState.sortingDescending;
      return `Upload Time, ${
        isColumnSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => item.upload_time,
    minWidth: "100px",
  },
  {
    id: "status",
    header: "Status",
    label: (sortState) => {
      const isColumnSorted = sortState.sortingColumn === "status";
      const ascending = !sortState.sortingDescending;
      return `Status, ${
        isColumnSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => {
      return (
        <div className={statusClassNameMap[item.status]}>{item.status}</div>
      );
    },
    minWidth: "100px",
  },
  {
    id: "error_message",
    header: "Message",
    label: (sortState) => {
      const isColumnSorted = sortState.sortingColumn === "error_message";
      const ascending = !sortState.sortingDescending;
      return `Message, ${
        isColumnSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => {
      let className = "";
      // check Error only, others the same
      if (item.status === STATUS_TEXT[constants.STATUS_FAIL]) {
        className = "awsui-util-status-negative";
      }
      return <div className={className}>{item.error_message}</div>;
    },
    minWidth: "100px",
  },
];

// define default sorting column
export const defaultSortingColumn = "upload_time";

// define sortable columns
export const sortableColumns = [
  { id: "user", field: "user" },
  { id: "tool", field: "tool" },
  { id: "file_name", field: "file_name" },
  { id: "upload_time", field: "upload_time" },
  { id: "status", field: "status" },
];

// define visiable columns
export const visibleColumns = [
  {
    label: "Properties",
    options: [
      { id: "user", label: "User", visible: true, editable: false },
      { id: "tool", label: "Tool", visible: true, editable: false },
      { id: "file_name", label: "File Name", visible: true, editable: false },
      {
        id: "upload_time",
        label: "Upload Time",
        visible: true,
        editable: false,
      },
      { id: "status", label: "Status", visible: true },
      { id: "error_message", label: "Message", visible: true },
    ],
  },
];

// define filtering options
export const filteringOptions = [
  {
    groupValuesLabel: "User",
    propertyKey: "user",
    propertyLabel: "User",
    values: [],
  },
  {
    groupValuesLabel: "Tool",
    propertyKey: "tool",
    propertyLabel: "Tool",
    values: [],
  },
  {
    groupValuesLabel: "Filename",
    propertyKey: "file_name",
    propertyLabel: "Filename",
    values: [],
  },
  {
    groupValuesLabel: "Upload Time",
    propertyKey: "upload_time",
    propertyLabel: "Upload Time",
    values: [],
  },
  {
    groupValuesLabel: "Status",
    propertyKey: "status",
    propertyLabel: "Status",
    values: Object.values(STATUS_TEXT),
  },
  {
    groupValuesLabel: "Message",
    propertyKey: "error_message",
    propertyLabel: "Message",
    values: [],
  },
];

// export const features = ["filtering", "pagination", "selection", "sorting"];
