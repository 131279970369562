import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import ComplianceOverview from "./compliance-overview/compliance-overview";
import { TEST_CATEGORY } from "../../config/constants";

// interface to check router match params type
interface MatchParams {
  test_category: string;
}

// declare prop check
type Props = {} & typeof defaultProps & RouteComponentProps<MatchParams>;

// declare init state & default props
const defaultProps = Object.freeze({});

// this component is to route to child overview component based on the test category
class Overview extends Component<Props> {
  static readonly defaultProps = defaultProps;

  render() {
    const { test_category } = this.props.match.params;
    if (test_category.toLocaleLowerCase() === TEST_CATEGORY.COMPLIANCE) {
      return <ComplianceOverview />;
    }

    // test category not supported
    this.props.history.push("/not-found");
  }
}

export default Overview;
