export const bandedgeHarmonicsPlotlyFormat = (data: {}) => {
  let rst: PlotData[] = [];

  Object.keys(data).forEach((category) => {
    const x = data[category]["x"];
    const y = data[category]["y"];
    const limit = data[category]["limit"];
    let onePlot = new PlotData(category);
    Object.keys(y).forEach((setPower) => {
      onePlot.data.push(getLineData(`Set Power:${setPower}`, x, y[setPower]));
    });
    onePlot.data.push(getLineData("Limit", x, limit));
    rst.push(onePlot);
  });
  return rst;
};

class PlotData {
  data: any[];
  layout: {};
  title: string;
  constructor(category: string) {
    this.data = [] as any;
    this.title = category;
    this.layout = {
      // title: category,
      xaxis: {
        title: "channel",
        titlefont: {
          family: "Roboto",
          size: 18,
        },
      },
      yaxis: {
        title: "power",
        titlefont: {
          family: "Roboto",
          size: 18,
        },
      },
      margin: {
        t: 30,
      },
    };
  }
}

const getLineData = (name: string, x: string[], y: []): {} => {
  let line = {};
  if (name === "Limit") {
    line["dash"] = "dot";
    line["color"] = "red";
  }

  return { name, x, y, line };
};
