import desenseConfigType from "../types/desense-config-type";
import { config } from "../../config/config";
import endpoints from "../../config/endpoints";
import { METRIC_INFO, TEST_CATEGORY } from "../../config/constants";
import { DesenseTCSRequest, DesenseDeviceRequest } from "../../model/http-json";
import { capitalizeFirstLetter } from "../../utils/general-utils";

export const getDeviceList = (requestJson: DesenseDeviceRequest): any => ({
  type: desenseConfigType.GET_DESENSE_DEVICE_LIST,
  http: {
    cognito: {
      effect: {
        url: config.BASE_URL + endpoints.getDesenseDeviceListEndpoint(),
        method: "POST",
        json: requestJson,
      },
      commit: {
        type: desenseConfigType.GET_DESENSE_DEVICE_LIST_COMMIT,
        log: {
          initiative: METRIC_INFO.INITIATIVE,
          feature: METRIC_INFO.CATEGORY,
        },
      },
      rollback: {
        type: desenseConfigType.GET_DESENSE_DEVICE_LIST_ROLLBACK,
        log: {
          initiative: METRIC_INFO.INITIATIVE,
          feature: METRIC_INFO.CATEGORY,
        },
      },
    },
  },
});

export const getDesenseTCSList = (requestJson: DesenseTCSRequest): any => ({
  type: desenseConfigType.GET_DESENSE_TCS_LIST,
  http: {
    cognito: {
      effect: {
        url: config.BASE_URL + endpoints.getDesenseTCSListEndpoint(),
        method: "POST",
        json: requestJson,
      },
      commit: {
        type: desenseConfigType.GET_DESENSE_TCS_LIST_COMMIT,
        log: {
          initiative: METRIC_INFO.INITIATIVE,
          feature: METRIC_INFO.CATEGORY,
        },
      },
      rollback: {
        type: desenseConfigType.GET_DESENSE_TCS_LIST_ROLLBACK,
        log: {
          initiative: METRIC_INFO.INITIATIVE,
          feature: METRIC_INFO.CATEGORY,
        },
      },
    },
  },
});

export const setUserSelectDict = (selectedData: Object): any => ({
  type: desenseConfigType.SET_USER_SELECTION,
  selectedData,
});

export const setSelectedBand = (band: String): any => ({
  type: desenseConfigType.SET_DESENSE_BAND,
  band,
});

export const setSelectedTCSID = (tcs_id: Number): any => ({
  type: desenseConfigType.SET_DESENSE_TCS_ID,
  tcs_id,
});

export const setSelectedPowerRegion = (powerRegion: String): any => ({
  type: desenseConfigType.SET_POWER_REGION,
  powerRegion,
});

// action to init loading status, for tcs result, power config
export const initDesenseLoadingStatus = (): any => ({
  type: desenseConfigType.INIT_LOADING_STATUS,
});

// merge desense tcs result to desense for easier handl with dropdown's data
export const PostTcsResult = (
  // testcaseKey: number,
  project: string,
  testCategory: string,
  tcsId: number,
  requestJson: object
) => ({
  type: desenseConfigType.POST_DESENSE_TCS_RESULT,
  http: {
    cognito: {
      effect: {
        //simple local url here will change it later
        url: config.BASE_URL + endpoints.tcsResultURL(testCategory, tcsId),
        method: "POST",
        json: requestJson,
      },
      commit: {
        type: desenseConfigType.POST_DESENSE_TCS_RESULT_COMMIT,
        log: {
          initiative: METRIC_INFO.INITIATIVE,
          feature: METRIC_INFO.DATA,
          project: capitalizeFirstLetter(project),
          testcategory: METRIC_INFO[testCategory],
        },
      },
      rollback: {
        type: desenseConfigType.POST_DESENSE_TCS_RESULT_ROLLBACK,
        log: {
          initiative: METRIC_INFO.INITIATIVE,
          feature: METRIC_INFO.DATA,
          project: capitalizeFirstLetter(project),
          testcategory: METRIC_INFO[testCategory],
        },
      },
    },
  },
});

export const getChannelPower = (
  testcategory: string,
  requestJson: any
): any => ({
  type: desenseConfigType.GET_CHANNEL_POWER,
  http: {
    cognito: {
      effect: {
        url: config.BASE_URL + endpoints.channelPowerEndpoint(testcategory),
        method: "POST",
        json: requestJson,
      },
      commit: {
        type: desenseConfigType.GET_CHANNEL_POWER_COMMIT,
        log: {
          initiative: METRIC_INFO.INITIATIVE,
          feature: METRIC_INFO[TEST_CATEGORY.DESENSE],
        },
      },
      rollback: {
        type: desenseConfigType.GET_CHANNEL_POWER_ROLLBACK,
        log: {
          initiative: METRIC_INFO.INITIATIVE,
          feature: METRIC_INFO[TEST_CATEGORY.DESENSE],
        },
      },
    },
  },
});

export const getCustomPower = (
  testcategory: string,
  requestJson: any
): any => ({
  type: desenseConfigType.GET_CUSTOM_POWER,
  http: {
    cognito: {
      effect: {
        url: config.BASE_URL + endpoints.customPowerEndpoint(testcategory),
        method: "POST",
        json: requestJson,
      },
      commit: {
        type: desenseConfigType.GET_CUSTOM_POWER_COMMIT,
        log: {
          initiative: METRIC_INFO.INITIATIVE,
          feature: METRIC_INFO[TEST_CATEGORY.DESENSE],
        },
      },
      rollback: {
        type: desenseConfigType.GET_CUSTOM_POWER_ROLLBACK,
        log: {
          initiative: METRIC_INFO.INITIATIVE,
          feature: METRIC_INFO[TEST_CATEGORY.DESENSE],
        },
      },
    },
  },
});

export const setLimit = (limit: string) => ({
  type: desenseConfigType.SET_LIMIT,
  limit,
});

export const setBandwidthChannel = (bandwidthChannel: string) => ({
  type: desenseConfigType.SET_BANDWIDTH_CHANNEL,
  bandwidthChannel,
});

export const setTechnology = (technology: string) => ({
  type: desenseConfigType.SET_TECHNOLOGY,
  technology,
});

export const setFreqCustom = (freq: string) => ({
  type: desenseConfigType.SET_FREQ_CUSTOM,
  freq,
});

export const setBandwidthCustom = (bandwidthCustom: string) => ({
  type: desenseConfigType.SET_BANDWIDTH_CUSTOM,
  bandwidthCustom,
});

export const setCustomFreqList = (customFreqList: Array<Number>) => ({
  type: desenseConfigType.SET_CUSTOM_FREQ_LIST,
  customFreqList,
});

export const setCustomBandwidthList = (customBandwidthList: Array<Number>) => ({
  type: desenseConfigType.SET_CUSTOM_BANDWIDTH_LIST,
  customBandwidthList,
});

export const setCustomChannelList = (customChannelList: Array<Number>) => ({
  type: desenseConfigType.SET_CUSTOM_CHANNEL_LIST,
  customChannelList,
});

export const setCustomId = (customId: number) => ({
  type: desenseConfigType.SET_CUSTOM_ID,
  customId,
});

export const cleanUpCustomPower = () => ({
  type: desenseConfigType.CLEAN_UP_CUSTOM_POWER,
});

export const setCustomResponseResult = (customResponseResult: string) => ({
  type: desenseConfigType.SET_CUSTOM_RESPONSE_RESULT,
  customResponseResult,
});

export const setTcsResultLoadingStatus = (tcsResultLoadingStatus) => ({
  type: desenseConfigType.SET_TCS_RESULT_LOADING_STATUS,
  tcsResultLoadingStatus,
});
