import React, { Component } from "react";
// redux
import { Dispatch } from "redux";
import { connect } from "react-redux";
import {
  Button,
  FormField,
  Input,
  Modal,
} from "@amzn/awsui-components-react/polaris";
import { setRangeCalculator } from "../../../../redux/actions/rvr-config-action";
import { 
  DEFAULT_TECHNOLOGY,
  RANGE_TYPE_OUTDOOR, 
  RANGE_CONSTANTS_5G, 
  RANGE_CONSTANTS_2G, 
  RANGE_CONSTANT,
  RANGE_CONSTANT_ERROR } from "../../../../config/constants";

interface StateProps {
  rvrConfigReducer: any;
}

// declare prop check
type Props = {
  filterObject: Object;
  dispatch: Dispatch<any>;
  onDismiss: () => void; // onDismiss event
  onClose: () => void; // onClose event
} & typeof defaultProps &
  StateProps;

// declare init state & default props
const defaultProps = Object.freeze({
  showModal: false,
});
const initialState = Object.freeze({
  // default values
  ...RANGE_CONSTANTS_5G,

  // error
  txGainError: "",
  rxGainError: "",
  desenseError: "",
  shadowFadingError: "",
  frequencyError: "",
  dutToDutVariationError: "",
  belMedianError: "",
  belSigmaError: "",
  sfSigmaError: "",
});

class RvRRangeModal extends Component<Props> {
  readonly state = initialState;


  componentDidUpdate(prevProps, prevState) {
    if(this.props.rvrConfigReducer.rangeCalculator.selectedTechnology !==  prevProps.rvrConfigReducer.rangeCalculator.selectedTechnology){
      this._onReset()
    }
  }
  handleSubmit = () => {
    const { txGain, rxGain, shadowFading, desense, frequency, dutToDutVariation, belMedian, belSigma, sfSigma } = this.state;
    // clean up response error from last submit
    this._cleanup();
    // map obj for dyanmic match error

    const mappedObj = {
      txGain: { value: this.state.txGain, error: RANGE_CONSTANT_ERROR["txGain"]},
      rxGain: { value: this.state.rxGain, error: RANGE_CONSTANT_ERROR["rxGain"]},
      desense: { value: this.state.desense, error: RANGE_CONSTANT_ERROR["desense"]},
      shadowFading: { value: this.state.shadowFading,error: RANGE_CONSTANT_ERROR["shadowFading"]},
      frequency: { value: this.state.frequency, error: RANGE_CONSTANT_ERROR["frequency"]},
      dutToDutVariation: { value: this.state.dutToDutVariation, error: RANGE_CONSTANT_ERROR["dutToDutVariation"]},
      belMedian: { value: this.state.belMedian, error: RANGE_CONSTANT_ERROR["belMedian"]},
      belSigma: { value: this.state.belSigma, error: RANGE_CONSTANT_ERROR["belSigma"]},
      sfSigma: { value: this.state.sfSigma, error: RANGE_CONSTANT_ERROR["sfSigma"]},
    };
    const isValid = this.validate(mappedObj);
    if (isValid) {
      this.props.dispatch(
        setRangeCalculator({
          ...this.props.rvrConfigReducer.rangeCalculator,
          txGain: txGain,
          rxGain: rxGain,
          desense: desense,
          shadowFading: shadowFading,
          frequency: frequency,
          dutToDutVariation: dutToDutVariation,  
          belMedian: belMedian,  
          belSigma: belSigma, 
          sfSigma: sfSigma
        })
      );
      this.props.onDismiss();
    }
  };

  validate = (inputObj) => {
    let result = true;
    Object.keys(inputObj).forEach((eachKey) => {
      if (!/^-?\d*\.?\d+$/i.test(inputObj[eachKey].value)) {
        result = false;
        this.setState({
          [inputObj[eachKey].error]: "Must input number",
        });
      }
    });

    return result;
  };

  _onReset = () => {
    var constants = RANGE_CONSTANTS_5G;
    const selectedTechnology = this.props.rvrConfigReducer.rangeCalculator.selectedTechnology ?? DEFAULT_TECHNOLOGY;
    
    if(selectedTechnology !== DEFAULT_TECHNOLOGY ){
      constants = {
        ...constants,
        ...RANGE_CONSTANTS_2G
      }
    }
    this.setState({
      ...constants,
      txGainError: "",
      rxGainError: "",
      shadowFadingError: "",
      desenseError: "",
      frequencyError: "",
      dutToDutVariationError: "",
      belMedianError: "",
      belSigmaError: "",
      sfSigmaError: "",
    });
  };


  _cleanup = () => {
    this.setState({
      txGainError: "",
      rxGainError: "",
      shadowFadingError: "",
      desenseError: "",
      frequencyError: "",
      dutToDutVariationError: "",
      belMedianError: "",
      belSigmaError: "",
      sfSigmaError: "",
    });
  };

  render() {
    const { showModal, onDismiss, onClose } = this.props;
    const { range } = this.props.rvrConfigReducer.selectedData[0] ? this.props.rvrConfigReducer.selectedData[0] : "";

    const {
      txGain,
      rxGain,
      shadowFading,
      desense,
      frequency,
      dutToDutVariation, 
      belMedian,  
      belSigma, 
      sfSigma, 
      txGainError,
      rxGainError,
      desenseError,
      shadowFadingError,
      frequencyError,
      dutToDutVariationError,
      belMedianError,
      belSigmaError,
      sfSigmaError,
    } = this.state;
    return (
      <div>
        <Modal
          visible={showModal}
          onDismiss={onDismiss}
          size="medium"
          footer={
            <span className="awsui-util-f-r">
              <Button variant="link" onClick={onClose}>
                Cancel
              </Button>
              <Button onClick={this._onReset}>reset</Button>
              <Button variant="primary" onClick={this.handleSubmit}>
                Done
              </Button>
            </span>
          }
        >
          
          <FormField
            label={RANGE_CONSTANT["antTx"]["label"]}
            description={RANGE_CONSTANT["antTx"]["description"]}
            errorText={txGainError}
            stretch={true}
          >
            <Input
              onChange={({ detail }) => {
                this.setState({
                  txGain: detail.value,
                });
              }}
              value={txGain.toString()}
            />
          </FormField>
          <br />

          <FormField
            label={RANGE_CONSTANT["antRx"]["label"]}
            description={RANGE_CONSTANT["antRx"]["description"]}
            errorText={rxGainError}
            stretch={true}
          >
            <Input
              onChange={({ detail }) => {
                this.setState({
                  rxGain: detail.value,
                });
              }}
              value={rxGain.toString()}
            />
          </FormField>
      
          { range === RANGE_TYPE_OUTDOOR ?
            <>
              <br />
              <FormField
                label={RANGE_CONSTANT["sf"]["label"]}
                description={RANGE_CONSTANT["sf"]["description"]}
                errorText={sfSigmaError}
                stretch={true}
              >
                <Input
                  onChange={({ detail }) => {
                    this.setState({
                      sfSigma: detail.value,
                    });
                  }}
                  value={sfSigma.toString()}
                />
              </FormField> <br />
              <FormField
                label={RANGE_CONSTANT["bel"]["label"]}
                description={RANGE_CONSTANT["bel"]["description"]}
                errorText={belSigmaError}
                stretch={true}
              >
                <Input
                  onChange={({ detail }) => {
                    this.setState({
                      belSigma: detail.value,
                    });
                  }}
                  value={belSigma.toString()}
                />
              </FormField> <br />
              <FormField
                label={RANGE_CONSTANT["dutVariation"]["label"]}
                description={RANGE_CONSTANT["dutVariation"]["description"]}
                errorText={dutToDutVariationError}
                stretch={true}
              >
                <Input
                  onChange={({ detail }) => {
                    this.setState({
                      dutToDutVariation: detail.value,
                    });
                  }}
                  value={dutToDutVariation.toString()}
                />
              </FormField> <br />
              <FormField
                label={RANGE_CONSTANT["belMdian"]["label"]}
                description={RANGE_CONSTANT["belMdian"]["description"]}
                errorText={belMedianError}
                stretch={true}
              >
                <Input
                  onChange={({ detail }) => {
                    this.setState({
                      belMedian: detail.value,
                    });
                  }}
                  value={belMedian.toString()}
                />
              </FormField> 
              </>
              : 
              <>
              <br />
              <FormField
                label={RANGE_CONSTANT["desense"]["label"]}
                description={RANGE_CONSTANT["desense"]["description"]}
                errorText={desenseError}
                stretch={true}
              >
                <Input
                  onChange={({ detail }) => {
                    this.setState({
                      desense: detail.value,
                    });
                  }}
                  value={desense.toString()}
                />
              </FormField>
              <br />
              <FormField
                label={RANGE_CONSTANT["shadowFading"]["label"]}
                description={RANGE_CONSTANT["shadowFading"]["description"]}
                errorText={shadowFadingError}
                stretch={true}
              >
                <Input
                  onChange={({ detail }) => {
                    this.setState({
                      shadowFading: detail.value,
                    });
                  }}
                  value={shadowFading.toString()}
                />
              </FormField>
              <br />
              <FormField
                label={RANGE_CONSTANT["frequency"]["label"]}
                description={RANGE_CONSTANT["frequency"]["description"]}
                errorText={frequencyError}
                stretch={true}
              >
                <Input
                  onChange={({ detail }) => {
                    this.setState({
                      frequency: detail.value,
                    });
                  }}
                  value={frequency.toString()}
                />
              </FormField>
            </>
          }
          
        </Modal>
        {/* path title */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    rvrConfigReducer: state.rvrConfigReducer,
  };
};

export default connect<StateProps>(mapStateToProps)(RvRRangeModal);
