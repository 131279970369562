import React, { Component } from "react";
// sub components
import {
  Table,
  TablePropertyFiltering,
  TablePagination,
  TableSorting,
  Button,
} from "@amzn/awsui-components-react/polaris";
import { customizedFilteringFunction } from "../../../components/polaris/table/property-filtering-utils";
import {
  defaultPageSize,
  getColumnDefinitions,
  defaultSortingColumn,
  defaultSortingDescending,
  sortableColumns,
  filteringOptions,
} from "./table-config";
// redux
import { Dispatch } from "redux";
import { connect } from "react-redux";
import {
  getTestcases,
  updateCompareList,
} from "../../../redux/actions/delta-compare-action"; // redux action
// constants
import constants, {
  TEST_CATEGORY,
  TEST_STATUS,
} from "../../../config/constants";

interface StateProps {
  deltaCompareReducer: any;
}

// declare prop check
type Props = {
  dispatch: Dispatch<any>;
} & StateProps &
  typeof defaultProps;

type State = {} & typeof initialState;

// declare init state & default props
const defaultProps = Object.freeze({});

const initialState = Object.freeze({
  selectedItems: [] as any[],
});

class DeltaCompareTestcaseTable extends Component<Props, State> {
  static readonly defaultProps = defaultProps;
  readonly state = initialState;

  // leave it here for debug purpose, do not check in
  // componentDidMount() {
  //   this.props.dispatch(
  //     updateCompareList(16509, TEST_CATEGORY.COMPLIANCE, {
  //       project: "demoproject",
  //       build: "demobuild_2.0",
  //       testType: "band_edge",
  //       device: "config123_dsn123",
  //       testCategory: "compliance",
  //       tcs_id: 16509,
  //       technology: "wifi",
  //       mode: "b",
  //       bandwidth: "20",
  //       antenna: "1",
  //       firmware: "firmware1",
  //     })
  //   );
  // }

  componentDidUpdate(prevProps, prevState) {
    const { project, build, testType, device } = this.props.deltaCompareReducer;

    // when the device is selected, then trigger the ajax call to get testcase data
    if (
      this.props.deltaCompareReducer.device &&
      this.props.deltaCompareReducer.device !==
        prevProps.deltaCompareReducer.device
    ) {
      this.props.dispatch(
        getTestcases(project, build, TEST_CATEGORY.COMPLIANCE, testType, device)
      );
      this.setState({ selectedItems: [] });
    }
  }

  // get Add button, since columndefinitions need a func.
  _getAddButton = (item) => {
    const { compareDict } = this.props.deltaCompareReducer;
    return (
      <Button
        variant="primary"
        icon="add-plus"
        loading={
          compareDict[item.tcs_id] &&
          compareDict[item.tcs_id]["loadingStatus"] === constants.LOADING_LOAD
            ? true
            : false
        }
        disabled={item.status === TEST_STATUS.UNTESTED}
        onClick={() => this._updateCompareList(item)}
      ></Button>
    );
  };

  // click Add button in the testcase table, add to compare list.
  _updateCompareList = (item) => {
    const { project, build, testType, device } = this.props.deltaCompareReducer;
    this.props.dispatch(
      updateCompareList(item.tcs_id, TEST_CATEGORY.COMPLIANCE, {
        project,
        build,
        testCategory: TEST_CATEGORY.COMPLIANCE,
        testType,
        device,
        ...item,
      })
    );
  };

  render() {
    const {
      testcaseData,
      testcaseLoadingStatus,
    } = this.props.deltaCompareReducer;

    return (
      <Table
        loadingText="Loading resources"
        loading={testcaseLoadingStatus === constants.LOADING_LOAD}
        columnDefinitions={getColumnDefinitions(this._getAddButton)}
        items={testcaseData}
        empty={
          <div className="awsui-util-t-c">
            <div className="awsui-util-pt-s awsui-util-mb-xs">
              <b>No resources</b>
            </div>
            <p className="awsui-util-mb-s">No resources to display.</p>
          </div>
        }
        noMatch={
          <div className="awsui-util-t-c">
            <div className="awsui-util-pt-s awsui-util-mb-xs">
              <b>No matches</b>
            </div>
            <p className="awsui-util-mb-s">We can’t find a match.</p>
            <div className="awsui-util-mb-m">
              <Button>Clear filter</Button>
            </div>
          </div>
        }
      >
        <TablePropertyFiltering
          filteringOptions={filteringOptions}
          filteringFunction={customizedFilteringFunction}
          groupValuesText="Values"
          groupPropertiesText="Properties"
          operationAndText="and"
          operationNotAndText="and not"
          operationOrText="or"
          operationNotOrText="or not"
          clearFiltersText="Clear filter"
          placeholder="Filter resources by property or value"
          allowFreeTextFiltering={true}
          filteringCountTextFunction={(count) =>
            `${count} ${count === 1 ? "match" : "matches"}`
          }
        ></TablePropertyFiltering>
        <TablePagination pageSize={defaultPageSize}></TablePagination>
        <TableSorting
          sortingColumn={defaultSortingColumn}
          sortingDescending={defaultSortingDescending}
          sortableColumns={sortableColumns}
        ></TableSorting>
      </Table>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    deltaCompareReducer: state.deltaCompareReducer,
  };
};
export default connect<StateProps>(mapStateToProps)(DeltaCompareTestcaseTable);
