import { TEST_STATUS_COLOR_RGB } from "../../../../config/color-config";
import { TEST_STATUS } from "../../../../config/constants";



export const litepointLineChartFormat = (dataRateObj) => {

  let series_data: any = []
  const { x_label, y_label, legend, ...seriesData } = dataRateObj;
  const seriesKeys = Object.keys(seriesData);

  seriesKeys.forEach((key) => {
    const { x, y, usl, lsl, uslb, lslb } = seriesData[key]
    
    series_data.push({
      name: key,
      x: x,
      y: y,
      mode: "lines+markers",
    });
    if (lsl) {
      series_data.push({
        name: "LSL_" + key,
        x: x,
        y: lsl,
        mode: "lines",
        line: {
          color: TEST_STATUS_COLOR_RGB[TEST_STATUS.FAILED],
          width: 1,
        },
      });
    }
    if (lslb) {
      series_data.push({
        name: "LSLb_" + key,
        x: x,
        y: lslb,
        mode: "lines",
        line: {
          color: TEST_STATUS_COLOR_RGB[TEST_STATUS.FAILED],
          width: 1,
        },
      });
    }
    if (usl) {
      series_data.push({
        name: "USL_" + key,
        x: x,
        y: usl,
        mode: "lines",
        line: {
          color: TEST_STATUS_COLOR_RGB[TEST_STATUS.FAILED],
          width: 1,
        },
      });
    }
    if (uslb) {
      series_data.push({
        name: "USLb_" + key,
        x: x,
        y: uslb,
        mode: "lines",
        line: {
          color: TEST_STATUS_COLOR_RGB[TEST_STATUS.FAILED],
          width: 1,
        },
      });
    }
  })
  return { x_label: x_label, y_label: y_label, data: series_data };
}
