import tcsResultType from "../types/tcs-result-type";
import { config } from "../../config/config";
import endpoints from "../../config/endpoints";
import { TcsResultResponse } from "../../model/http-json";
import { METRIC_INFO } from "../../config/constants";
import { capitalizeFirstLetter } from "../../utils/general-utils";

export const getTcsResult = (
  project: string,
  testCategory: string,
  tcsId: number
) => ({
  type: tcsResultType.GET_TCS_RESULT_STATUS,
  http: {
    cognito: {
      effect: {
        //simple local url here will change it later
        url: config.BASE_URL + endpoints.tcsResultURL(testCategory, tcsId),
        method: "GET",
      },
      commit: {
        type: tcsResultType.GET_TCS_RESULT_STATUS_COMMIT,
        log: {
          initiative: METRIC_INFO.INITIATIVE,
          feature: METRIC_INFO.DATA,
          project: capitalizeFirstLetter(project),
          testcategory: METRIC_INFO[testCategory],
        },
      },
      rollback: {
        type: tcsResultType.GET_TCS_RESULT_STATUS_ROLLBACK,
        log: {
          initiative: METRIC_INFO.INITIATIVE,
          feature: METRIC_INFO.DATA,
          project: capitalizeFirstLetter(project),
          testcategory: METRIC_INFO[testCategory],
        },
      },
    },
  },
});

// post method for tcs (desense using this one)
// TODO may remove it later : P2 desense cannot suit common tcs reducer well
export const PostTcsResult = (
  // testcaseKey: number,
  project: string,
  testCategory: string,
  tcsId: number,
  requestJson: object
) => ({
  type: tcsResultType.POST_TCS_RESULT_STATUS,
  http: {
    cognito: {
      effect: {
        //simple local url here will change it later
        url: config.BASE_URL + endpoints.tcsResultURL(testCategory, tcsId),
        method: "POST",
        json: requestJson,
      },
      commit: {
        type: tcsResultType.POST_TCS_RESULT_STATUS_COMMIT,
        log: {
          initiative: METRIC_INFO.INITIATIVE,
          feature: METRIC_INFO.DATA,
          project: capitalizeFirstLetter(project),
          testcategory: METRIC_INFO[testCategory],
        },
      },
      rollback: {
        type: tcsResultType.POST_TCS_RESULT_STATUS_ROLLBACK,
        log: {
          initiative: METRIC_INFO.INITIATIVE,
          feature: METRIC_INFO.DATA,
          project: capitalizeFirstLetter(project),
          testcategory: METRIC_INFO[testCategory],
        },
      },
    },
  },
});

export const setTcsResult = (
  tcsResultLoadingStatus: number,
  tcsResultData: TcsResultResponse
) => ({
  type: tcsResultType.SET_TCS_RESULT_STATUS,
  tcsResultLoadingStatus,
  tcsResultData,
});

export const cleanDesneseArray = () => ({
  type: tcsResultType.CLEAN_DESENSE_ARRAY,
  tcsDesenseData: [],
});

export const decreaseDesneseArray = () => ({
  type: tcsResultType.DECREASE_DESENSE_ARRAY,
  tcsDesenseData: [],
});
