import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import BuildForm from "./build-form";
import "./edit-form.css";

interface StateProps {
  categoryReducer: any;
  projectReducer: any;
}

// declare prop check
type Props = {
  dispatch: Dispatch<any>;
} & StateProps &
  RouteComponentProps<any>;

class EditBuild extends Component<Props> {
  render() {
    return (
      <div className="create-form">
        <BuildForm history={this.props.history} />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    categoryReducer: state.categoryReducer,
    projectReducer: state.projectReducer,
  };
};

export default withRouter(connect<StateProps>(mapStateToProps)(EditBuild));
