const categoryType = {
  GET_CATEGORY_LIST: "GET_CATEGORY_LIST",
  GET_CATEGORY_LIST_COMMIT: "GET_CATEGORY_LIST_COMMIT",
  GET_CATEGORY_LIST_ROLLBACK: "GET_CATEGORY_LIST_ROLLBACK",

  CREATE_CATEGORY: "CREATE_CATEGORY",
  CREATE_CATEGORY_COMMIT: "CREATE_CATEGORY_COMMIT",
  CREATE_CATEGORY_ROLLBACK: "CREATE_CATEGORY_ROLLBACK",

  SET_CATEGORY: "SET_CATEGORY",
};

export default categoryType;
