import React, { Component } from "react";
import AntennaPassiveChart from "./antenna-passive-chart";
import {awsRum} from "../../../../index";

interface StateProps {}

// declare prop check
type Props = {} & typeof defaultProps & StateProps;

// declare init state & default props
const defaultProps = Object.freeze({});
const initialState = Object.freeze({
  // inherit
  mainPlotWidth: window.innerWidth,
});

// map file for Antenna Passive Visualization
class AntennaPassiveVisualization extends Component<Props> {
  readonly state = initialState;
  private plotRef: React.RefObject<HTMLInputElement>;

  constructor(props) {
    super(props);
    this.plotRef = React.createRef();
    awsRum?.recordEvent("antenna_passive_Visualization",{
        metricCategory: 'antenna_passive_Visualization'
    });
  }

  render() {
    return (
      <div className={"rvr-base-chart"} ref={this.plotRef}>
        <div>
          <AntennaPassiveChart ySelect={"efficiency"} />
        </div>
        <hr />
        <div>
          <AntennaPassiveChart ySelect={"gain"} />
        </div>
      </div>
    );
  }
}

export default AntennaPassiveVisualization;
