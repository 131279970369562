import authzType from "../types/authz-type";
import { config } from "../../config/config";
import { METRIC_INFO } from "../../config/constants";

// demo authz call
export const authZCall = (username, bindleType) => ({
  type: authzType.AUTHZ_CALL,
  http: {
    cognito: {
      effect: {
        //simple local url here will change it later
        url: config.BASE_URL + "/authz",
        method: "POST",
        json: { username: username, bindle_type: bindleType },
      },
      commit: {
        type: authzType.AUTHZ_CALL_COMMIT,
        log: {
          initiative: METRIC_INFO.INITIATIVE,
          feature: METRIC_INFO.AUTHORIZATION,
        },
      },
      rollback: {
        type: authzType.AUTHZ_CALL_ROLLBACK,
        log: {
          initiative: METRIC_INFO.INITIATIVE,
          feature: METRIC_INFO.AUTHORIZATION,
        },
      },
    },
  },
});
