import React, { Component } from "react";
import { Tabs } from "@amzn/awsui-components-react/polaris";
import { connect } from "react-redux";
import {
  formatActiveTableObj,
  formatSignalingTableObj,
  mapMainTabs,
} from "./antenna-tab-config";
import AntennaActiveTable from "./antenna-active-table";
import AntennaSignalingTable from "./antenna-signaling-table";

import { ANTENNA_ACTIVE_KEY_LIST } from "../../../../config/constants";

const defaultProps = Object.freeze({});

const initialState = Object.freeze({
  currentPageIndex: 1,
  pagesCount: 0,
});

interface StateProps {
  antennaConfigReducer: any;
}

// declare prop check
type Props = {
  data: Object;
  loadingStatus: number;
  getData: () => void;
} & typeof defaultProps &
  StateProps;

type State = typeof initialState;
class AntennaTabTable extends Component<Props, State> {
  static readonly defaultProps = defaultProps;
  readonly state = initialState;

  render() {
    const { selectedData } = this.props.antennaConfigReducer;

    const mappedTabTableData = Object.keys(selectedData).map(
      (eachKey, index) => {
        return {
          label: `Testcase ${eachKey}`,
          id: `Testcase_${eachKey}`,
          content: (
            <div>
              <AntennaActiveTable
                data={formatActiveTableObj(selectedData[eachKey])}
              />
              <AntennaSignalingTable
                data={formatSignalingTableObj(
                  selectedData[eachKey],
                  ANTENNA_ACTIVE_KEY_LIST[1]
                )}
                headerName={mapMainTabs[ANTENNA_ACTIVE_KEY_LIST[1]]}
              />
              <AntennaSignalingTable
                data={formatSignalingTableObj(
                  selectedData[eachKey],
                  ANTENNA_ACTIVE_KEY_LIST[2]
                )}
                headerName={mapMainTabs[ANTENNA_ACTIVE_KEY_LIST[2]]}
              />
            </div>
          ),
        };
      }
    );

    // activeTabId="Testcase_0" is the same as above id
    return (
      <div>
        <Tabs tabs={mappedTabTableData} activeTabId="Testcase_0" />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    antennaConfigReducer: state.antennaConfigReducer,
  };
};
export default connect(mapStateToProps)(AntennaTabTable);
