import React, { Component } from "react";
// sub components
import DeltaComparePlot from "./compare-plot/plot";
import DeltaCompareTestcaseInput from "./testcase-input/testcase-input";
import DeltaCompareTestcaseTable from "./testcase-table/table";
import DeltaCompareList from "./compare-list/compare-list";
// css
import "./delta-compare.css";

class ComplianceDeltaCompare extends Component<{}> {
  render() {
    return (
      <div>
        <div className="text-center delta-compare-page-title ">
          Compliance Delta Compare
        </div>
        <br />
        <div className="d-flex flex-row">
          <div className="delta-compare-tcs-part">
            <p className="text-secondary">
              Find test cases and add to Compare List
            </p>
            <DeltaCompareTestcaseInput />
            <br />
            <DeltaCompareTestcaseTable />
          </div>
          <div className="delta-compare-compare-list-part">
            <DeltaCompareList />
          </div>
        </div>
        <br />

        <br />

        <DeltaComparePlot />
      </div>
    );
  }
}

export default ComplianceDeltaCompare;
