// {
//     channel: [1, 2],
//     freq: [2420, 2422],
//     bandwidth: [20, 20],
//     baseline_power: [-127.01209708780584, -128.01209708780584],
//     desense_power: [-126.96525857450574, -127],
//     delta: [0.046838513300102136, 0.046],
//     status: ["passed", "failed"],
//   }

// OUTPUT tableFormat = [
//     {
//       name: "Item 1",
//       alt: "First",
//       description: "This is the first item",
//       type: "1A",
//       size: "Small",
//     },
//     {
//       name: "Item 2",
//       alt: "Second",
//       description: "This is the second item",
//       type: "1B",
//       size: "Large",
//     },]

export const formatTableObj = (inputObject: Object) => {
  let resultList = [];

  if (inputObject) {
    const loopArray = inputObject[Object.keys(inputObject)[0]];

    if (loopArray.length > 0) {
      resultList = loopArray.map((value, index) => {
        return {
          channel: inputObject["channel"][index],
          freq: inputObject["freq"][index],
          bandwidth: inputObject["bandwidth"][index],
          baseline: inputObject["baseline_power"][index],
          desense: inputObject["desense_power"][index],
          delta: inputObject["delta"][index],
          status: inputObject["status"][index],
        };
      });
    }
  }

  return resultList;
};
