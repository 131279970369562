import nonsigConfigType from "../types/nonsig-config-type";
import { config } from "../../config/config";
import endpoints from "../../config/endpoints";
import { METRIC_INFO, TEST_CATEGORY } from "../../config/constants";
import { capitalizeFirstLetter } from "../../utils/general-utils";

export const setTCSSelection = (tcsSelection) => ({
  type: nonsigConfigType.SET_TCS_SELECTION,
  tcsSelection,
});

export const postTcsResult = (
  id: string,
  requestJson: object,
  testCategory: string | undefined = TEST_CATEGORY.NON_SIG
) => ({
  type: nonsigConfigType.POST_NONSIG_TCS_RESULT,
  http: {
    cognito: {
      effect: {
        url: config.BASE_URL + endpoints.tcsResultURL(testCategory, id),
        method: "POST",
        // also uploaded_time in request json
        json: requestJson,
      },
      commit: {
        type: nonsigConfigType.POST_NONSIG_TCS_RESULT_COMMIT,
        log: {
          initiative: METRIC_INFO.INITIATIVE,
          feature: METRIC_INFO.DATA,
          testcategory: METRIC_INFO[testCategory],
        },
      },
      rollback: {
        type: nonsigConfigType.POST_NONSIG_TCS_RESULT_ROLLBACK,
        log: {
          initiative: METRIC_INFO.INITIATIVE,
          feature: METRIC_INFO.DATA,
          testcategory: METRIC_INFO[testCategory],
        },
      },
    },
  },
});

export const getStatusTable = (
  // dict LP/coconut map types
  project: string,
  buildVersion: string,
  input_config: string,
  dsn: string,
  testType: string,
  dbKey: string | number,
  testcategory: string | undefined = TEST_CATEGORY.NON_SIG
) => {
  return {
    // constants for commit and rollback
    type: nonsigConfigType.NONSIG_TCS_STATUS_RESULT,
    http: {
      cognito: {
        effect: {
          url:
            config.BASE_URL +
            endpoints.tcsStatus(
              testcategory,
              dsn,
              input_config,
              testType,
              dbKey
            ),
          method: "GET",
        },
        commit: {
          type: nonsigConfigType.NONSIG_TCS_STATUS_RESULT_COMMIT,
          log: {
            initiative: METRIC_INFO.INITIATIVE,
            feature: METRIC_INFO.NONSIG_TESTCASE,
            project: capitalizeFirstLetter(project),
            testcategory: METRIC_INFO[testcategory],
          },
          project: project,
          build: buildVersion,
          config: input_config,
          device: dsn,
        },
        rollback: {
          type: nonsigConfigType.NONSIG_TCS_STATUS_RESULT_ROLLBACK,
          log: {
            initiative: METRIC_INFO.INITIATIVE,
            feature: METRIC_INFO.NONSIG_TESTCASE,
            project: capitalizeFirstLetter(project),
            testcategory: METRIC_INFO[testcategory],
          },
        },
      },
    },
  };
};

export const clearConfigDsnDropdown = () => ({
  type: nonsigConfigType.CLEAR_NONSIG_DEVICE_LIST,
});

export const clearTestTypeDropdown = () => ({
  type: nonsigConfigType.CLEAR_TEST_FORMAT,
});

export const getTCSDropdown = (
  project: string,
  build: string,
  version: string,
  testcategory: string | undefined = TEST_CATEGORY.NON_SIG,
  requestId: number | undefined = undefined,
  labId: number | undefined = undefined
) => ({
  type: nonsigConfigType.GET_NONSIG_DEVICE_LIST,
  http: {
    cognito: {
      effect: {
        url:
          config.BASE_URL +
          endpoints.getDropdownListEndpoint(
            project,
            build,
            version,
            testcategory,
            requestId,
            labId
          ),
        method: "GET",
      },
      commit: {
        type: nonsigConfigType.GET_NONSIG_DEVICE_LIST_COMMIT,
        log: {
          initiative: METRIC_INFO.INITIATIVE,
          feature: METRIC_INFO.NONSIG_TESTCASE,
          project: capitalizeFirstLetter(project),
          testcategory: METRIC_INFO[testcategory],
        },
      },
      rollback: {
        type: nonsigConfigType.GET_NONSIG_DEVICE_LIST_ROLLBACK,
        log: {
          initiative: METRIC_INFO.INITIATIVE,
          feature: METRIC_INFO.NONSIG_TESTCASE,
          project: capitalizeFirstLetter(project),
          testcategory: METRIC_INFO[testcategory],
        },
      },
    },
  },
});

export const setTestFormat = (testFormat: string | null) => ({
  type: nonsigConfigType.SET_TEST_FORMAT,
  testFormat,
});

export const addTestForComparison = () => ({
  type: nonsigConfigType.ADD_TEST_FOR_COMPARISON,
});

export const removeSelectedComparisonTests = (selectedItems) => ({
  type: nonsigConfigType.REMOVE_TESTS_FOR_COMPARISON,
  selectedItems,
});
