import { TEST_CATEGORY } from "../../config/constants";
import constants from "../../config/constants";
import { capitalizeFirstLetter } from "../../utils/general-utils";

export const testCategoryOptions = [
  {
    label: capitalizeFirstLetter(TEST_CATEGORY.COMPLIANCE),
    id: TEST_CATEGORY.COMPLIANCE,
  },
  {
    label: capitalizeFirstLetter(TEST_CATEGORY.DESENSE),
    id: TEST_CATEGORY.DESENSE,
  },
  {
    label: constants.RVR,
    id: TEST_CATEGORY.RVR,
  },
  {
    label: constants.BATTERY,
    id: TEST_CATEGORY.BATTERY,
  },
  {
    label: capitalizeFirstLetter(TEST_CATEGORY.ANTENNA),
    id: TEST_CATEGORY.ANTENNA,
  },
  {
    label: capitalizeFirstLetter(TEST_CATEGORY.NON_SIG),
    id: TEST_CATEGORY.NON_SIG,
  },
];

export const getSelectedOptions = (testcategories: Array<string>) => {
  return testcategories.map((testCategory) => ({
    label:
      testCategory === TEST_CATEGORY.RVR
        ? constants.RVR
        : capitalizeFirstLetter(testCategory),
    id: testCategory,
    disabled: false,
  }));
};

export const validateCategory = (category: string): string => {
  /**
   * Validate Category name
   * - Letters only
   * - 50 Char max (RDS limit)
   */
  let categoryError: string = "";
  if (!category) {
    categoryError = "Required";
  } else if (!/^[A-Za-z]+$/i.test(category)) {
    categoryError = "Letters only.";
  } else if (!/^[A-Za-z]{1,50}$/i.test(category)) {
    categoryError = "Max 50 Characters.";
  }
  return categoryError;
};

export const validateProject = (project: string): string => {
  /**
   * Validate Project name
   * - Letters/Digits only
   * - 50 Char max (RDS limit)
   */
  let projectError: string = "";
  if (!project) {
    projectError = "Required";
  } else if (!/^[A-Za-z0-9]+$/i.test(project)) {
    projectError = "Letters and numbers only.";
  } else if (!/^[A-Za-z0-9]{1,50}$/i.test(project)) {
    projectError = "Max 50 characters.";
  }
  return projectError;
};

export const validateBuild = (build: string): string => {
  /**
   * Validate Build name
   * - Letters only
   * - 50 Char max (RDS limit)
   */
  let buildError: string = "";
  if (!build) {
    buildError = "Required";
  } else if (!/^[A-Za-z]+$/i.test(build)) {
    buildError = "Letters only";
  } else if (!/^[A-Za-z]{1,50}$/i.test(build)) {
    buildError = "Max 50 Characters.";
  }
  return buildError;
};

export const validateBuildVersion = (version: string): string => {
  /**
   * Validate the Build Version to be %5.2f compliant
   */
  let versionError: string = "";
  if (!version) {
    versionError = "Required";
  } else if (!/^[0-9]+(\.[0-9]+)?$/i.test(version)) {
    versionError = "Numbers only. For example: 1.0";
  } else if (!/^[0-9]{1,5}(\.[0-9]+)?$/i.test(version)) {
    versionError = "Cannot be larger than 99,999";
  } else if (!/^[0-9]{1,5}(\.[0-9]{1,2})?$/i.test(version)) {
    versionError = "No more than 2 decimal values.";
  }
  return versionError;
};
