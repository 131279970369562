import React, { Component } from "react";
import Plot from "react-plotly.js";
import { Button, Toggle } from "@amzn/awsui-components-react/polaris";
import { ExportPDF } from "@amzn/lens-react-ui";
import {
  ContainerSpninner,
  ContainerFail,
  ContainerNoData,
} from "../../../components/container-status-indicator";
import TestStatusLegend from "./progress-content/test-status-legend";
import ReportDropdown from "../../../containers/report-generation/report-dropdown";
import constants, { TEST_STATUS } from "../../../config/constants";
import { TEST_STATUS_COLOR_RGB } from "../../../config/color-config";
import { StatusSchema } from "../../../model/overview";

// declare prop check
type Props = {
  loadingStatus: number;
  statusData: StatusSchema | undefined | { [key: string]: any };
  getData: () => void;
  project: string;
  build: string;
  version: string;
  test_category: string;
} & typeof defaultProps;

type State = typeof initialState;

// declare init state & default props
const defaultProps = Object.freeze({});

const initialState = Object.freeze({
  showPercentage: true,
});

class OverviewPanel extends Component<Props, State> {
  static readonly defaultProps = defaultProps;
  readonly state = initialState;

  _onChangeToggle = (e) => {
    this.setState({ showPercentage: e.detail.checked });
  };

  render() {
    const {
      loadingStatus,
      statusData,
      getData,
      project,
      build,
      version,
      test_category,
    } = this.props;

    let content: any = null;
    if (loadingStatus === constants.LOADING_LOAD) {
      content = <ContainerSpninner />;
    } else if (loadingStatus === constants.LOADING_FAIL) {
      content = <ContainerFail />;
    } else if (
      loadingStatus === constants.LOADING_SUCCESS &&
      (!statusData || !statusData.total)
    ) {
      content = <ContainerNoData />;
    } else {
      content = (
        <div>
          {/* toggle button for showing percentage or not */}
          <div className="d-flex justify-content-between align-items-center">
            <Toggle
              checked={this.state.showPercentage}
              onChange={this._onChangeToggle}
            >
              Show Percentage
            </Toggle>

            <TestStatusLegend />
          </div>
          <br />
          {/* total number */}
          Total: {statusData ? statusData.total : 0}
          {/* pie chart */}
          <div
            className="d-flex justify-content-center"
            id="overview-pie-chart"
          >
            <Plot
              data={[
                {
                  values: Object.keys(TEST_STATUS).map((key) =>
                    statusData ? statusData[TEST_STATUS[key]] : 0
                  ),
                  labels: Object.keys(TEST_STATUS).map(
                    (key) => TEST_STATUS[key]
                  ),
                  marker: {
                    colors: Object.keys(TEST_STATUS).map(
                      (key) => TEST_STATUS_COLOR_RGB[TEST_STATUS[key]]
                    ),
                  },
                  type: "pie",
                  textposition: "inside",
                  textinfo: this.state.showPercentage
                    ? "label+percent"
                    : "label+value",
                },
              ]}
              layout={{
                height: 250,
                width: 400,
                showlegend: false,
                margin: {
                  l: 20,
                  r: 20,
                  b: 10,
                  t: 10,
                  pad: 4,
                },
              }}
              config={{ displayModeBar: false }} // not showing mode bar
            />
          </div>
        </div>
      );
    }

    return (
      <div className="awsui-util-container">
        {/* header */}
        <div className="awsui-util-container-header">
          <div className="awsui-util-action-stripe">
            {/* title */}
            <div className="awsui-util-action-stripe-title">
              <h2>Overview</h2>
              <div className="awsui-util-container-header-description">
                Engineering Compliance Overview
              </div>
            </div>
            {/* button group */}
            <div className="awsui-util-action-stripe-group">
              <Button
                // variant="icon"
                icon="refresh"
                onClick={() => getData()}
              ></Button>
              <ExportPDF
                domId="overview-pie-chart" // map to the dom id which you want to export
                filename="overview"
                button={Button}
                buttonProps={{ icon: "download", variant: "primary" }}
              >
                Export Chart
              </ExportPDF>
              <ReportDropdown
                selectedItems={[{ project, build, version, test_category }]}
              />
            </div>
          </div>
        </div>

        {/* body */}
        {content}
      </div>
    );
  }
}

export default OverviewPanel;
